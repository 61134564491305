import { Box, Stack, Typography } from "@mui/material";
import { CustomTextField } from "components/formComponents/style";
import DataTable from "components/table/DataTable";
import NoData from "components/table/NoData";
import { ROUTE_BRAND_CREATE } from "data/routeVariables";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { assignToBrandPayload } from "redux/modules";
import { useGetBrandRequestListQuery } from "services/modules/brand";
import { capitalizeFirstLetter } from "utils/string";
import { debounce } from "utils/debounce";
import { requestStatus } from "./CreateBrand";

const BrandRequestList = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const page = parseInt(searchParams.get("page") || 1);
	const [searchQuery, setSearchQuery] = useState("");
	const handleChange = (e) => {
		setSearchQuery(e.target.value);
	};

	const columns = [
		{ field: "koreanName", headerName: t("brand.koreanName"), flex: 1 },
		{ field: "englishName", headerName: t("brand.englishName"), flex: 1 },
		{
			field: "businessName",
			headerName: t("signUp.businessName"),
			flex: 1,
			valueGetter: (params) => {
				return params?.row?.partner?.team?.name;
			}
		},
		{
			field: "email",
			headerName: t("signUp.email"),
			flex: 1,
			valueGetter: (params) => {
				return params?.row?.partner?.email;
			}
		},
		{
			field: "contactNumber",
			headerName: t("signUp.contactNumber"),
			flex: 1,
			valueGetter: (params) => {
				return params?.row?.partner?.team.contactNumber;
			}
		},
		{
			field: "status",
			headerName: "Status",
			flex: 1,
			renderCell: (params) => (
				<Typography
					color={
						params.value === "approved"
							? "text.success"
							: params.value === "pending"
							? "black"
							: "text.error"
					}
					variant="subtitle2">
					{capitalizeFirstLetter(params.value)}
				</Typography>
			)
		}
	];

	const { data, isFetching, isError } = useGetBrandRequestListQuery({
		currentPage: page,
		query: searchQuery
	});

	const rows = data?.response?.data || [];

	const onRowClick = (rowData) => {
		if (rowData?.row?.status === requestStatus.PENDING) {
			dispatch(
				assignToBrandPayload({
					type: "create",
					id: rowData.row.id,
					koreanName: rowData.row.koreanName,
					name: rowData.row.englishName
				})
			);
			navigate(ROUTE_BRAND_CREATE);
		}
	};

	return (
		<Box>
			<Typography component="h2" variant="h2" pb={24}>
				{t("sidebar.requestedBrands")}
			</Typography>

			<Stack justifyContent="space-between" direction="row" mb={14}>
				<CustomTextField
					onChange={debounce(handleChange, 1000)}
					placeholder={`${t("Search")}...`}
					size="small"
				/>
			</Stack>
			{isError ? (
				<NoData message={t("alertMessage.noRequestListing")} />
			) : (
				<DataTable
					columns={columns}
					rows={rows}
					isLoading={isFetching}
					isRenderAction={false}
					onRowClick={onRowClick}
				/>
			)}
		</Box>
	);
};

export default BrandRequestList;
