import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { showAlert } from "redux/modules";
import { useLogoutMutation } from "services/modules/auth";
import { authenticationStatusChange, resetAuth } from "redux/modules";

export function useLogout() {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [logout] = useLogoutMutation();

	return () =>
		logout()
			.then(
				() => dispatch(authenticationStatusChange(false)),
				() => {
					dispatch(
						showAlert({
							severity: "error",
							message: t("alertMessage.auth.logoutUnsuccessful")
						})
					);
				}
			)
			.then(() =>
				dispatch(
					showAlert({
						severity: "success",
						message: t("alertMessage.auth.logoutSuccessful")
					})
				)
			)
			.then(() => dispatch(resetAuth()));
}
