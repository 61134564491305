const OwnerIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M20.2933 18.0199C19.8418 16.9502 19.1865 15.9787 18.3639 15.1593C17.5439 14.3375 16.5725 13.6823 15.5033 13.2299C15.4937 13.2251 15.4842 13.2227 15.4746 13.2179C16.9659 12.1407 17.9354 10.386 17.9354 8.40635C17.9354 5.12685 15.2783 2.46973 11.9988 2.46973C8.71929 2.46973 6.06217 5.12685 6.06217 8.40635C6.06217 10.386 7.03166 12.1407 8.523 13.2203C8.51342 13.2251 8.50385 13.2275 8.49427 13.2323C7.42185 13.6847 6.45954 14.3334 5.63368 15.1617C4.81191 15.9817 4.15672 16.9531 3.70428 18.0223C3.2598 19.0689 3.02009 20.1911 2.99811 21.3281C2.99747 21.3536 3.00195 21.3791 3.01129 21.4028C3.02062 21.4266 3.03463 21.4483 3.05248 21.4666C3.07032 21.4849 3.09165 21.4994 3.1152 21.5094C3.13875 21.5193 3.16405 21.5244 3.18961 21.5244H4.62589C4.73122 21.5244 4.815 21.4406 4.8174 21.3377C4.86527 19.4897 5.60735 17.7589 6.91915 16.4471C8.27644 15.0898 10.079 14.343 11.9988 14.343C13.9186 14.343 15.7212 15.0898 17.0784 16.4471C18.3902 17.7589 19.1323 19.4897 19.1802 21.3377C19.1826 21.443 19.2664 21.5244 19.3717 21.5244H20.808C20.8335 21.5244 20.8588 21.5193 20.8824 21.5094C20.9059 21.4994 20.9273 21.4849 20.9451 21.4666C20.963 21.4483 20.977 21.4266 20.9863 21.4028C20.9956 21.3791 21.0001 21.3536 20.9995 21.3281C20.9755 20.1839 20.7386 19.0707 20.2933 18.0199ZM11.9988 12.5237C10.9 12.5237 9.86592 12.0952 9.08794 11.3172C8.30995 10.5392 7.88146 9.50511 7.88146 8.40635C7.88146 7.3076 8.30995 6.27348 9.08794 5.49549C9.86592 4.71751 10.9 4.28902 11.9988 4.28902C13.0976 4.28902 14.1317 4.71751 14.9097 5.49549C15.6876 6.27348 16.1161 7.3076 16.1161 8.40635C16.1161 9.50511 15.6876 10.5392 14.9097 11.3172C14.1317 12.0952 13.0976 12.5237 11.9988 12.5237Z"
				fill="black"
				fillOpacity="0.85"
			/>
		</svg>
	);
};

export default OwnerIcon;
