import { useTranslation } from "react-i18next";
import { Typography, Grid, InputAdornment, Divider, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useForm } from "react-hook-form";
import HelpIcon from "@mui/icons-material/Help";

import theme from "theme/theme";
import { SearchTextField } from "components/formComponents/style";
import { FormInputDate } from "components/formComponents";
import {
	Button,
	SECONDARY,
	PRIMARY,
	SelectedOrAllDownloadButtons
} from "components/layout/Button";
import { StackRow } from "components/shared";
import DataTable from "components/table/DataTable";
import { MuiPagination } from "components/table/Pagination";
import { useTodo } from "utils/useTodo";

import SummaryBox from "./SummaryBox";
import { useStyles } from "./style";

const dummy = Array.from({ length: 3 }, (_, i) => ({
	id: `${i + 1}`,
	fundingCode: "2022A0010001",
	fundingName: "Y2K 패션을 연출하기 좋은 체크 뷔스티에 토드백",
	partnerCompanyCode: "C2203001",
	partnerMission: "주식회사 가나다",
	salesTerm: "2022. 5. 15 ~ 2022. 6. 10",
	fluctuation: "0",
	afterFluctuation: "200",
	content: "압고",
	changeDate: "2022.11.11 14:21"
}));

const commonColumnFields = {
	headerAlign: "center",
	align: "center"
};

const InventoryList = () => {
	const { t } = useTranslation();
	const { control } = useForm();
	const styles = useStyles();

	const rows = dummy;

	const columns = [
		{
			field: "fundingCode",
			headerName: t("inventoryManagement.tableHeaders.fundingCode"),
			minWidth: 150
		},
		{
			field: "fundingName",
			headerName: t("inventoryManagement.tableHeaders.fundingName"),
			minWidth: 350,

			cellClassName: styles.textUnderline
		},
		{
			field: "partnerCompanyCode",
			headerName: t("inventoryManagement.tableHeaders.partnerCompanyCode"),
			minWidth: 200
		},
		{
			field: "partnerMission",
			headerName: t("inventoryManagement.tableHeaders.partnerMission"),
			minWidth: 200,
			cellClassName: styles.textUnderline
		},
		{
			field: "salesTerm",
			headerName: t("inventoryManagement.tableHeaders.salesTerm"),
			minWidth: 200
		},
		{
			field: "fluctuation",
			headerName: t("inventoryManagement.tableHeaders.fluctuation"),
			minWidth: 200
		},
		{
			field: "afterFluctuation",
			headerName: t("inventoryManagement.tableHeaders.afterFluctuation"),
			minWidth: 200
		},
		{
			field: "content",
			headerName: t("inventoryManagement.tableHeaders.content"),
			minWidth: 200
		},
		{
			field: "changeDate",
			headerName: t("inventoryManagement.tableHeaders.changeDate"),
			minWidth: 200
		}
	].map((column) => ({ ...column, ...commonColumnFields }));

	const todo = useTodo();

	return (
		<div>
			<Typography component="h2" variant="h2" pb={24}>
				{t("sidebar.inventory")}
			</Typography>

			<Grid container alignItems="center" gap={15} pb={32}>
				<Grid item xs>
					<SearchTextField
						placeholder={t("partner.searchBoxPlaceholder")}
						fullWidth
						size="small"
						variant="outlined"
						InputProps={{
							style: { padding: 10, backgroundColor: theme.palette.white },
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							)
						}}
					/>
				</Grid>

				<Grid item>
					<FormInputDate
						name="startDate"
						control={control}
						sx={{ backgroundColor: theme.palette.white }}
					/>
				</Grid>
				<Typography component="h2" variant="h2">
					~
				</Typography>
				<Grid item>
					<FormInputDate
						name="endDate"
						control={control}
						sx={{ backgroundColor: theme.palette.white }}
					/>
				</Grid>

				<Button color={SECONDARY}>{t("Search")}</Button>
			</Grid>

			<SummaryBox />

			<StackRow
				variant="text17"
				fontWeight={500}
				color="gray.tertiary"
				justifyContent="space-between"
				pb={20}>
				<StackRow
					gap={12}
					divider={
						<Divider orientation="vertical" variant="middle" flexItem />
					}>
					<Typography>{`${t("inventoryManagement.onSale")} 13`}</Typography>
					<Typography>{`${t(
						"inventoryManagement.salesCompleted"
					)} 7`}</Typography>
					<Typography>{`${t(
						"inventoryManagement.fullFunding"
					)} 20`}</Typography>
				</StackRow>
				<StackRow gap={7}>
					{t("Help")}
					<HelpIcon />
				</StackRow>
			</StackRow>

			<DataTable columns={columns} rows={rows} isRenderCheckBox={true} />

			<Box>
				<MuiPagination
					// dummy for now
					page={1}
					meta={{
						currentPage: 1,
						lastPage: 1,
						links: [{}, { label: 1, active: true }, {}]
					}}
				/>
				<StackRow alignItems="center" justifyContent="space-between">
					<SelectedOrAllDownloadButtons
						handleFullDownload={todo}
						handleSelectedDownload={todo}
					/>

					<Button color={PRIMARY}>
						{t("inventoryManagement.wearingInspection")}
					</Button>
				</StackRow>
			</Box>
		</div>
	);
};

export default InventoryList;
