import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { showAlert } from "redux/modules";

export const usePromiseHandler = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	return (promise, successMsg, failMsg) =>
		promise
			.then(() => {
				dispatch(
					showAlert({
						severity: "success",
						message: t(successMsg || "Success!")
					})
				);
			})
			.catch((error) => {
				dispatch(
					showAlert({
						severity: "error",
						message: failMsg
							? t(failMsg)
							: error?.data?.message || t("alertMessage.somethingWentWrong")
					})
				);
			});
};
