import {
	Box,
	Grid,
	IconButton,
	InputAdornment,
	Link,
	Stack,
	Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import { useLoginMutation } from "services/modules/auth";
import { showAlert, authenticationStatusChange } from "redux/modules";
import { LoginSchema } from "utils/validationSchema";
import {
	ROUTE_DASHBOARD,
	ROUTE_FORGOT_PASSWORD,
	ROUTE_REGISTER_PARTNER
} from "data/routeVariables";
import { PasswordField } from "components/formComponents/custom/PasswordField";

import { RightBorder } from "./style";

const animate = {
	opacity: 1,
	y: 0,
	transition: {
		duration: 0.6,
		ease: [0.6, -0.05, 0.01, 0.99],
		delay: 0.16
	}
};

export const LoginForm = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [showPassword, setShowPassword] = useState(false);
	const [login, { data, isLoading, error }] = useLoginMutation();

	useEffect(() => {
		if (data) {
			dispatch(authenticationStatusChange(data));
			navigate(ROUTE_DASHBOARD);
			dispatch(
				showAlert({
					severity: "success",
					message: t("alertMessage.auth.loginSuccessful")
				})
			);
		}
		if (error?.status === 500) {
			dispatch(
				showAlert({
					severity: "error",
					message: t("alertMessage.auth.loginError")
				})
			);
		}
		if (error) {
			dispatch(
				showAlert({
					severity: "error",
					message: error?.data?.message
				})
			);
		}
	}, [data, error]);

	const methods = useForm({
		defaultValues: {
			email: "",
			password: ""
		},
		mode: "onBlur",
		resolver: yupResolver(LoginSchema(t))
	});

	const { handleSubmit } = methods;

	return (
		<FormProvider {...methods}>
			<form autoComplete="off" noValidate onSubmit={handleSubmit(login)}>
				<Stack
					direction="row"
					animate={{
						transition: {
							staggerChildren: 0.55
						}
					}}>
					<Stack
						direction="column"
						pr={23}
						initial={{ opacity: 0, y: 40 }}
						animate={animate}
						sx={{ width: "100%" }}>
						<Grid container>
							<Grid item xs={2} pt={15}>
								<Typography variant="text15" color="text.secondary">
									{t("login.id")}
								</Typography>
							</Grid>
							<Grid item xs={10} mb={16}>
								<PasswordField
									name="email"
									type="email"
									placeholder="email"
									autoComplete="email"
								/>
							</Grid>
							<Grid item xs={2} pt={15}>
								<Typography variant="text15" color="text.secondary">
									{t("login.fillPassword")}
								</Typography>
							</Grid>
							<Grid item xs={10}>
								<PasswordField
									name="password"
									type={showPassword ? "text" : "password"}
									isPassword={true}
									placeholder={t("login.eightOrMore")}
									autoComplete="current-password"
									inputProps={
										<InputAdornment position="end">
											<IconButton
												onClick={() => setShowPassword((prev) => !prev)}>
												{showPassword ? (
													<VisibilityIcon />
												) : (
													<VisibilityOffIcon />
												)}
											</IconButton>
										</InputAdornment>
									}
								/>
							</Grid>
							<Grid item xs={2}></Grid>
							<Grid item xs={10} pt={22}>
								<Stack direction="row">
									<RightBorder>
										<Link
											variant="subtitle2"
											component={RouterLink}
											underline="none"
											to={ROUTE_REGISTER_PARTNER}>
											<Typography
												variant="text14"
												color="text.tertiary"
												fontWeight="bold">
												{t("login.joinPartner")}
											</Typography>
										</Link>
									</RightBorder>
									<Link
										variant="subtitle2"
										component={RouterLink}
										underline="none"
										to={ROUTE_FORGOT_PASSWORD}>
										<Typography
											variant="text14"
											pl={16}
											color="text.tertiary"
											fontWeight="bold">
											{t("login.forgotPassword")}
										</Typography>
									</Link>
								</Stack>
							</Grid>
						</Grid>
					</Stack>

					<Box initial={{ opacity: 0, y: 20 }} animate={animate}>
						<LoadingButton
							loading={isLoading}
							fullWidth
							size="large"
							type="submit"
							variant="contained"
							sx={{
								height: "calc(100% - 45px)",
								backgroundColor: "button.light"
							}}>
							Login
						</LoadingButton>
					</Box>
				</Stack>
			</form>
		</FormProvider>
	);
};
