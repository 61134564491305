export const absoluteCenter = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	outline: "none"
};

export * from "./PageTitle";
export * from "./ListTitle";
export * from "./SubTitle";
export * from "./TextHover";
export * from "./TextSummary";
export * from "./StackRow";
export * from "./FlexContainer";
