import { API_CATEGORY } from "services/endpoints";
import { TAG_CATEGORY } from "services/tags";

export default (build) =>
	build.query({
		query: ({ currentPage, level, perPage, query, parentCategoryId }) => {
			return {
				url: API_CATEGORY,
				params: {
					page: currentPage,
					level,
					per_page: perPage,
					q: query,
					parent_category_id: parentCategoryId
				},
				method: "GET"
			};
		},
		providesTags: [TAG_CATEGORY]
	});
