import { Box, Typography } from "@mui/material";
import AdditionalInfo from "components/auth/partnerRegister/AdditionalInfo";
import JoinPartners from "components/auth/partnerRegister/JoinPartners";
import { BasePlate, Navbar } from "components/layout";
import { Button, SECONDARY } from "components/layout/Button";
import { StackRow } from "components/shared";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import theme from "theme/theme";
import { yupResolver } from "@hookform/resolvers/yup";
import {
	AdditionalInfoSchema,
	PartnerRegisterSchema
} from "utils/validation/signUpSchema";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTE_LOGIN } from "data/routeVariables";
import { showAlert } from "redux/modules";
import { useSignupMutation } from "services/modules/auth";
import PrimaryAlertDialog from "components/dialogs/PrimaryAlertDialog";
import BackDropLoader from "components/shared/BackDropLoader";

const PartnerRegister = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [fillAdditionalData, setFillAdditionalData] = useState(false);
	const [isOTPVerified, setOTPVerified] = useState(false);
	const [registerSuccessDialogOpen, setRegisterSuccessDialogOpen] =
		useState(false);
	const [termsAccepted, setTermsAccepted] = useState({
		term1: false,
		term2: false
	});

	const [
		signUp,
		{ data, error: signUpError, isSuccess: signUpSuccess, isLoading }
	] = useSignupMutation();

	const { handleSubmit, formState, watch, setFocus, trigger, ...methods } =
		useForm({
			defaultValues: {
				newEmail: "",
				newPassword: "",
				businessName: "",
				representativeName: "",
				representativeEmail: "",
				verificationCode: "",
				managerName: "",
				managerEmail: "",
				companyAddress: "",
				companyDetailedAddress: ""
			},
			mode: "all",
			resolver: yupResolver(
				fillAdditionalData ? AdditionalInfoSchema(t) : PartnerRegisterSchema(t)
			)
		});

	const watchVerificationField = watch("verificationCode");

	const onSubmit = (values) => {
		signUp(values);
	};

	const loginWithRegisteredAccount = () => {
		setRegisterSuccessDialogOpen(false);
		navigate(ROUTE_LOGIN);
	};

	useEffect(() => {
		signUpSuccess && data && setRegisterSuccessDialogOpen(true);

		signUpError?.status === 500 &&
			dispatch(
				showAlert({
					severity: "error",
					message: t("alertMessage.auth.signUpError")
				})
			);

		signUpError &&
			signUpError?.status !== 500 &&
			dispatch(
				showAlert({
					severity: "error",
					message: signUpError?.data?.message
				})
			);
	}, [signUpSuccess, signUpError]);

	return (
		<Box sx={{ mx: { lg: 180, xs: 40 } }}>
			<Navbar />
			<FormProvider
				handleSubmit={handleSubmit}
				watch={watch}
				formState={formState}
				setFocus={setFocus}
				{...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<StackRow mt={130} pb={24}>
						<Typography variant="h2" component="h2" pr={10}>
							{t("signUp.becomePartner")}
						</Typography>
						<Typography
							variant="h2"
							component="h2"
							color={theme.palette.text.secondary}>
							{t("signUp.essentialInfo")}
						</Typography>
					</StackRow>

					<BasePlate sx={{ p: 38 }}>
						{fillAdditionalData === true ? (
							<AdditionalInfo />
						) : (
							<JoinPartners
								termsAccepted={termsAccepted}
								setTermsAccepted={setTermsAccepted}
								setOTPVerified={setOTPVerified}
							/>
						)}
					</BasePlate>

					<StackRow gap={20} justifyContent="flex-end" paddingBottom={68}>
						{!fillAdditionalData && (
							<Button
								disabled={
									!Object.values(termsAccepted).every((el) => el === true) ||
									watchVerificationField === "" ||
									isOTPVerified === false
								}
								onClick={() => {
									trigger().then((isValid) => {
										window.scrollTo(0, 0);
										isValid && setFillAdditionalData(true);
									});
								}}
								sx={{ width: 160 }}
								color={SECONDARY}>
								{t("Next")}
							</Button>
						)}
						{fillAdditionalData && (
							<>
								<Button
									onClick={() => setFillAdditionalData(false)}
									sx={{
										width: 160,
										backgroundColor: theme.palette.text.white
									}}>
									{t("Todo next")}
								</Button>
								<Button type="submit" sx={{ width: 160 }} color={SECONDARY}>
									{t("Sign Up Complete")}
								</Button>
							</>
						)}
					</StackRow>
				</form>
			</FormProvider>

			<BackDropLoader open={isLoading} />

			<PrimaryAlertDialog
				isReason
				isDialogOpen={registerSuccessDialogOpen}
				setDialogOpen={setRegisterSuccessDialogOpen}
				message={t("partner.dialog.successMessage")}
				firstBtn={t("Confirm")}
				firstAction={loginWithRegisteredAccount}
			/>
		</Box>
	);
};

export default PartnerRegister;
