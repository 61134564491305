const BRANDS = "/brands";

const CATEGORY = "/category";
const CUSTOMER = "/customer";

const INVENTORY = "/inventory";

const TRADE = "/trade";

const SETTLEMENT = "/settlement";

export const DEV_STORYBOOK = {
	id: "development-storyBook",
	path: "/storybook"
};

export const ROUTE_BRAND_LIST = `${BRANDS}/brand-list`;
export const ROUTE_BRAND_CREATE = `${BRANDS}/brand-create`;
export const ROUTE_BRAND_EDIT = `${ROUTE_BRAND_LIST}/:brandId`;

export const ROUTE_BUSINESS_INFO = "/business-info";

export const ROUTE_CATEGORY = CATEGORY;
export const ROUTE_CHILD_CATEGORY = `${ROUTE_CATEGORY}/:category`;

export const ROUTE_CUSTOMER_INVESTMENT_REVIEW = `${CUSTOMER}/investment-review`;
export const ROUTE_CUSTOMER_RECOMMENDATION = `${CUSTOMER}/recommendation`;
export const ROUTE_CUSTOMER_TIMELINE = `${CUSTOMER}/timeline`;

export const ROUTE_TRADE_MANAGEMENT = `${TRADE}`;

export const ROUTE_SETTLEMENT_MANAGEMENT = `${SETTLEMENT}`;

export const ROUTE_DASHBOARD = "/dashboard";

export const ROUTE_FORGOT_PASSWORD = "/forgot-password";

export const ROUTE_FUNDING = "/funding";

export const ROUTE_INVENTORY_MANAGEMENT = `${INVENTORY}/inventory-management`;
export const ROUTE_INVENTORY_WAREHOUSE = `${INVENTORY}/warehouse`;

export const ROUTE_LOGIN = "/login";

export const ROUTE_NOTFOUND = "/404";

export const ROUTE_PARTNER_LIST = "/partners/partner-list";
export const ROUTE_PARTNER_DETAILS = `${ROUTE_PARTNER_LIST}/:partnerId`;
export const ROUTE_PARTNER_MESSAGE = `${ROUTE_PARTNER_LIST}/message`;

export const ROUTE_PRODUCT_CREATE = `${ROUTE_FUNDING}/create-product`;
export const ROUTE_PRODUCT_STEP = `${ROUTE_FUNDING}/create-product/step`;
export const ROUTE_PRODUCT_EDIT = `${ROUTE_FUNDING}/edit`;
export const ROUTE_PRODUCT_CREATE_CHOOSE_PARTNER = `${ROUTE_FUNDING}/choosePartner`;

export const ROUTE_PROFIT_CALCULATION = "/profit-calculation";

const OWNER = "/owner";
export const ROUTE_OWNER = OWNER;
export const ROUTE_OWNER_DETAILS = `${ROUTE_OWNER}/:ownerId`;

export const ROUTE_RECEIPT = `${INVENTORY}/receipt`;

export const ROUTE_REGISTER_PARTNER = "/register-partner";

export const ROUTE_REQUESTED_BRAND_LIST = `${BRANDS}/requested-brand-list`;

export const ROUTE_SETTING = "/setting";

export const ROUTE_SHIPPING = `${INVENTORY}/shipping-list`;

export const ROUTE_SHIPPING_REQUEST = `${INVENTORY}/shipping-request`;

export const ROUTE_SIGNUP = "/signup";
export const ROUTE_SIGNUP_ID = "/signup-id";
