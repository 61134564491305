import { Box, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

const Setting = () => {
	const { t } = useTranslation();

	return (
		<Box>
			<Typography component="h2" variant="h2" pb={24}>
				{t("setting.header")}
			</Typography>
		</Box>
	);
};

export default Setting;
