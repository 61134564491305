import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
	Button,
	PRIMARY,
	SelectedOrAllDownloadButtons
} from "components/layout/Button";
import { StackRow } from "components/shared";
import { MuiPagination } from "components/table/Pagination";
import useFilter from "components/shared/list/useFilter";
import FundingListFilter from "components/shared/list/filter";
import { FilterStatusWithHookForm } from "components/shared/list/status";
import { useNumberSearchParams } from "utils/useNumbered";
import {
	ROUTE_PRODUCT_CREATE_CHOOSE_PARTNER,
	ROUTE_PRODUCT_EDIT
} from "data/routeVariables";
import MainTitle from "components/shared/list/MainTitle";
import MarketIcon from "assets/icons/sidebar/MarketIcon";
import { useAdminFundingListQuery } from "services/modules/fundingAdmin";
import DataTable from "components/table/DataTable";
import { useSelectedDownload } from "utils/useDownload";
import { downloadFile } from "utils/downloadFile";

import FundingAdminEmptyView from "../FundingAdminEmpty";
import OrderStatus from "../OrderStatus";
import Summary from "../Summary";
import useColumns from "./useColumns";

const FundingAdmin = () => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const page = useNumberSearchParams();

	const [filters, methods] = useFilter();

	const [selected, setSelected] = useState([]);

	const { watch } = methods;

	const columns = useColumns(methods);

	const { data, isFetching } = useAdminFundingListQuery(
		{ currentPage: page, ...filters },
		{ refetchOnMountOrArgChange: true }
	);

	const statusFilter = watch("statusFilter");

	const { data: rows = [], meta = { total: 0 } } = data?.response || {};

	const isEmptyList =
		!rows.length & !isFetching & !Object.values(filters).length;

	const isSearchResultEmpty =
		!rows.length & !isFetching & !!Object.values(filters).length &&
		statusFilter === "all";

	const dl = useSelectedDownload();

	return (
		<FormProvider {...methods}>
			<MainTitle pageName={t("funding.title")} logo={<MarketIcon />} />

			{isEmptyList ? (
				<FundingAdminEmptyView />
			) : (
				<>
					<OrderStatus />

					<FundingListFilter />

					<FilterStatusWithHookForm total={meta.total} />

					{isSearchResultEmpty ? (
						<FundingAdminEmptyView searchEmpty />
					) : (
						<>
							<Summary />

							<DataTable
								columns={columns}
								rows={rows}
								isRenderCheckBox={true}
								isLoading={isFetching}
								onRowsSelection={setSelected}
								onRowClick={({ id }) => {
									navigate(`${ROUTE_PRODUCT_EDIT}/${id}/step/1`);
								}}
							/>

							<StackRow
								pt={20}
								alignItems="center"
								justifyContent="space-between">
								<SelectedOrAllDownloadButtons
									handleSelectedDownload={() => {
										dl(selected, () =>
											downloadFile("funding/export", "fundings", {
												funding_ids: selected
											})()
										);
									}}
									handleFullDownload={() =>
										dl(undefined, () =>
											downloadFile("funding/export", "fundings_all")()
										)
									}
								/>

								<Button
									onClick={() => navigate(ROUTE_PRODUCT_CREATE_CHOOSE_PARTNER)}
									color={PRIMARY}>
									{t("funding.create")}
								</Button>
							</StackRow>

							<MuiPagination page={page} meta={meta} />
						</>
					)}
				</>
			)}
		</FormProvider>
	);
};

export default FundingAdmin;
