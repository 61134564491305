import { Checkbox, FormControlLabel } from "@mui/material";
import { SECONDARY } from "components/layout/Button";
import React from "react";
import { Controller } from "react-hook-form";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const FormInputCheckbox = ({ control, name, label }) => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<FormControlLabel
					control={
						<Checkbox
							icon={<CircleOutlinedIcon />}
							checkedIcon={<CheckCircleIcon />}
							defaultChecked
							color={SECONDARY}
							checked={field.value}
							onChange={(e) => field.onChange(e.target.checked)}
						/>
					}
					label={label}
				/>
			)}
		/>
	);
};

export default FormInputCheckbox;
