import { Button, WHITE } from "components/layout/Button";
import { StackRow } from "components/shared";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { showAlert } from "redux/modules";
import { useLazyDocDownloadQuery } from "services/modules/partner";
import { downloadWithWindow } from "utils/downloadFile";

const BUSINESS_LICENSE = "business_license";
const COMPANY_PROFILE = "company_profile";
const COPY_OF_PASSPORT = "copy_of_passport";
const FINANCIAL_STATEMENT = "financial_statement";

const PartnerFileDownloadButtons = ({ partnerId }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [downlodDocs] = useLazyDocDownloadQuery();

	async function onDownloadDocs(docType) {
		return downlodDocs({ partnerId, docType }).then(({ data }) => {
			return data?.response
				? downloadWithWindow(data?.response)
				: dispatch(
						showAlert({
							severity: "error",
							message: t("File not uploaded")
						})
				  );
		});
	}

	return (
		<StackRow gap={11}>
			<Button color={WHITE} onClick={() => onDownloadDocs(BUSINESS_LICENSE)}>
				{t("signUp.businessRegistration")}
			</Button>
			<Button color={WHITE} onClick={() => onDownloadDocs(COMPANY_PROFILE)}>
				{t("signUp.companyProfile")}
			</Button>
			<Button color={WHITE} onClick={() => onDownloadDocs(COPY_OF_PASSPORT)}>
				{t("signUp.copyOfBankbook")}
			</Button>
			<Button color={WHITE} onClick={() => onDownloadDocs(FINANCIAL_STATEMENT)}>
				{t("signUp.financialStatement")}
			</Button>
		</StackRow>
	);
};

export default PartnerFileDownloadButtons;
