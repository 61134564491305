import { Alert, Snackbar } from "@mui/material";
import { createRoot } from "react-dom/client";
import { t } from "i18next";

import { AppMainWrapper } from "index";

let toastRoot;

const getRoot = () => {
	if (!toastRoot) {
		toastRoot = createRoot(document.getElementById("toast-root"));
	}

	return toastRoot;
};

export function showToast(message, severity = "error") {
	const root = getRoot();

	root.render(
		<AppMainWrapper>
			<Snackbar
				open
				autoHideDuration={3000}
				onClose={() => {
					root.render(null);
				}}
				anchorOrigin={{ horizontal: "center", vertical: "top" }}>
				<Alert
					variant="filled"
					severity={severity}
					sx={{ width: "100%", color: "white" }}>
					{t(message || "alertMessage.somethingWentWrong")}
				</Alert>
			</Snackbar>
		</AppMainWrapper>
	);

	return null;
}
