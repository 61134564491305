import { Grid, Typography } from "@mui/material";
import { FlatPlate } from "components/layout";
import { StackRow } from "components/shared";
import { Box } from "@mui/system";
import { FormInputText } from "components/formComponents";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, PRIMARY } from "components/layout/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { partnerMessageValidation } from "utils/validationSchema";
import { savePartnerData, showAlert } from "redux/modules";
import { useDispatch } from "react-redux";
import {
	useGetPartnerDetailsQuery,
	useMessagePartnerMutation
} from "services/modules/partner";
import { useEffect } from "react";
import PrimaryDialog from "../PrimaryDialog";
import MainInfo from "pages/partner/MainInfo";
import FormInputCheckbox from "components/formComponents/FormInputCheckbox";
import { useParams } from "react-router-dom";

const MessagePartnerDialog = ({ isDialogOpen, setDialogOpen }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { partnerId } = useParams();

	const { data: partnerDetails } = useGetPartnerDetailsQuery({
		partnerId
	});

	const partnerDetailsPayload = partnerDetails
		? partnerDetails?.response.data
		: {};

	const { control, handleSubmit, reset, clearErrors, watch } = useForm({
		defaultValues: {
			partnerId: partnerId,
			title: "",
			content: "",
			message: true,
			kakao: false,
			email: false
		},
		mode: "onBlur",
		resolver: yupResolver(partnerMessageValidation(t))
	});

	const checkBoxStates = watch(["message", "kakao", "email"]);

	const watchTitle = watch("title");
	const watchContent = watch("content");

	const [
		messagePartner,
		{ isError: messageError, isSuccess: messageSuccess, isLoading: isSending }
	] = useMessagePartnerMutation();

	useEffect(() => {
		messageError &&
			dispatch(
				showAlert({
					severity: "error",
					message: t("alertMessage.partner.messageSendFail")
				})
			);

		if (messageSuccess) {
			dispatch(
				showAlert({
					severity: "success",
					message: t("alertMessage.partner.messageSendSuccess")
				})
			);
			dispatch(savePartnerData({ partnerId: "" }));
			setDialogOpen(false);
		}
	}, [messageError, messageSuccess]);

	const onSubmit = (values) => {
		messagePartner(values);
	};

	return (
		<PrimaryDialog
			width={800}
			reset={reset}
			clearErrors={clearErrors}
			header={t("partner.message.writeMessage")}
			subTitle={t("partner.message.recipient")}
			setDialogOpen={setDialogOpen}
			isDialogOpen={isDialogOpen}>
			<FlatPlate sx={{ p: 24 }}>
				<MainInfo
					partnerDetails={partnerDetailsPayload && partnerDetailsPayload}
				/>
			</FlatPlate>
			<FlatPlate sx={{ p: 24 }}>
				<Box sx={{ color: "gray.tertiary" }}>
					<Grid container mb={24} alignItems="start">
						<Grid item xs={1}>
							<Typography pt={10}>{t("partner.message.subject")}</Typography>
						</Grid>
						<Grid item xs>
							<FormInputText
								name="title"
								placeholder={t("partner.message.subjectPlaceholder")}
								currentWordCount={watchTitle.length}
								control={control}
								withTextIndicator
								maxLength={40}
							/>
						</Grid>
					</Grid>

					<Grid container mb={24} alignItems="start">
						<Grid item xs={1}>
							<Typography>{t("partner.message.content")}</Typography>
						</Grid>
						<Grid item xs>
							<FormInputText
								name="content"
								placeholder={t("partner.message.contentPlaceholder")}
								isTextarea
								currentWordCount={watchContent.length}
								control={control}
								withTextIndicator
								maxLength={500}
							/>
						</Grid>
					</Grid>

					<Grid container mb={24} alignItems="start">
						<Grid item xs={1}>
							<Typography pt={10}>{t("partner.message.sendTo")}</Typography>
						</Grid>
						<Grid item xs>
							<StackRow gap={18}>
								<FormInputCheckbox
									name="message"
									label={t("partner.message.message")}
									control={control}
								/>
								<FormInputCheckbox
									name="kakao"
									label={t("partner.message.kakao")}
									control={control}
								/>
								<FormInputCheckbox
									name="email"
									label={t("partner.message.mail")}
									control={control}
								/>
							</StackRow>
						</Grid>
					</Grid>
				</Box>
			</FlatPlate>
			<StackRow justifyContent="flex-end" gap={10}>
				<Button
					sx={{ width: 126 }}
					onClick={handleSubmit(onSubmit)}
					isLoading={isSending}
					disabled={!checkBoxStates.some((el) => el === true)}
					color={PRIMARY}>
					{t("Send")}
				</Button>
			</StackRow>
		</PrimaryDialog>
	);
};

export default MessagePartnerDialog;
