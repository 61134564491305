import React, { useEffect, useState } from "react";
import OwnerDetailsSummary from "./OwnerDetailsSummary";
import MainTitle from "components/shared/list/MainTitle";
import OwnerIcon from "assets/icons/sidebar/OwnerIcon";
import { useTranslation } from "react-i18next";
import { BackButton, HelpWithIcon } from "components/layout/Button";
import { ROUTE_OWNER } from "data/routeVariables";
import { useNavigate } from "react-router-dom";
import { StackRow } from "components/shared";
import theme from "theme/theme";
import { Divider, Typography } from "@mui/material";
import OwnerInvestmentList from "./investment";
import NoData from "components/table/NoData";
import { useSelector } from "react-redux";
import { selectOwner } from "redux/modules";

const initialState = [
	{
		id: 1,
		label: "owner.investment",
		active: true,
		component: <OwnerInvestmentList />
	},
	{
		id: 2,
		label: "owner.trade",
		active: false,
		component: null
	},
	{
		id: 3,
		label: "owner.ownership",
		active: false,
		component: null
	},
	{
		id: 4,
		label: "owner.accountDetails",
		active: false,
		component: null
	},
	{
		id: 5,
		label: "owner.oneOneRatioInquiry",
		active: false,
		component: null
	},
	{
		id: 6,
		label: "owner.csManagement",
		active: false,
		component: null
	},
	{
		id: 7,
		label: "owner.gradeManagement",
		active: false,
		component: null
	},
	{
		id: 8,
		label: "owner.mileageHistory",
		active: false,
		component: null
	}
];

const OwnerDetailsHost = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [activeFilter, setActiveFilter] = useState(initialState);

	const { ownerData } = useSelector(selectOwner);

	const changeActiveFilter = (e) => {
		setActiveFilter((prevState) =>
			prevState.map((object) => {
				if (object.id === parseInt(e.target.id)) {
					return { ...object, active: true };
				} else {
					return { ...object, active: false };
				}
			})
		);
	};

	const activeComponent = activeFilter.find((item) => item.active)?.component;

	useEffect(() => {
		window.scroll({
			top: 0,
			left: 0,
			behavior: "smooth"
		});
	}, []);

	return (
		<div>
			<BackButton
				onClick={() => navigate(ROUTE_OWNER)}
				btnText={t("To List")}
			/>

			<MainTitle pageName={t("owner.ownerDetails")} logo={<OwnerIcon />} />

			<OwnerDetailsSummary ownerData={ownerData} />

			<StackRow
				variant="text17"
				fontWeight={500}
				justifyContent="space-between"
				pb={20}>
				<StackRow
					gap={12}
					divider={
						<Divider orientation="vertical" variant="middle" flexItem />
					}>
					{activeFilter.map((el) => (
						<Typography
							id={el.id}
							color={
								el.active
									? theme.palette.text.primary
									: theme.palette.text.secondary
							}
							sx={{ cursor: "pointer" }}
							key={el.id}
							onClick={(e) => changeActiveFilter(e)}>{`${t(
							el.label
						)} 0`}</Typography>
					))}
				</StackRow>
				<HelpWithIcon />
			</StackRow>

			<>
				{activeComponent ? (
					activeComponent
				) : (
					<NoData message={t("alertMessage.noListData")} />
				)}
			</>
		</div>
	);
};

export default OwnerDetailsHost;
