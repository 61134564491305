import { lazy, Suspense } from "react";
import { useParams } from "react-router-dom";

import NoData from "components/table/NoData";

export const Steps = {
	1: lazy(() => import("./step1/ProductCreateConditions")),
	2: lazy(() => import("./step2/ProductCreateStep2")),
	3: lazy(() => import("./step3/ProductCreateStep3"))
};

// doing this so that when we enumerate the `Steps` object
// the getter key won't be enumerated
Object.setPrototypeOf(Steps, {
	get length() {
		return Object.keys(Steps).length;
	}
});

export const stepsLength = Steps.length;

const CurrentStep = ({ stepNumber }) => {
	const Component =
		Steps[stepNumber] || (() => <NoData message="Invalid step" />);

	return <Component />;
};

export default function CreateFunding() {
	const { stepNumber, fundingId } = useParams();

	if (stepNumber === 3 && !fundingId) {
		return <NoData message={`Step 3 requires 'fundingId'`} />;
	}

	const currentStep = !stepNumber && !fundingId ? 1 : stepNumber || 1;

	return (
		<Suspense fallback="Loading...">
			<CurrentStep stepNumber={currentStep} />
		</Suspense>
	);
}
