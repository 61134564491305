import { useForm } from "react-hook-form";

export default function useFilter() {
	const methods = useForm({
		defaultValues: {
			dateFilterType: "created_at",
			dateFilterStart: "",
			dateFilterEnd: "",
			search: "",
			statusFilter: "all",
			columnFilter: "all"
		}
	});

	const [
		dateFilterType,
		dateFilterStart,
		dateFilterEnd,
		search,
		statusFilter,
		columnFilter
	] = methods.watch([
		"dateFilterType",
		"dateFilterStart",
		"dateFilterEnd",
		"search",
		"statusFilter",
		"columnFilter"
	]);

	let filters = {};

	if (dateFilterStart && dateFilterEnd) {
		filters = { ...filters, dateFilterType, dateFilterStart, dateFilterEnd };
	}

	if (search) {
		filters = { ...filters, search };
	}

	if (statusFilter !== "all") {
		filters = { ...filters, statusFilter };
	}

	if (columnFilter !== "all") {
		filters = { ...filters, columnFilter };
	}

	return [filters, methods];
}
