import { BasePlate } from "components/layout";
import noDataImg from "assets/images/shared/reason.png";
import { Typography } from "@mui/material";

const NoData = ({ message, error }) => {
	return (
		<BasePlate sx={{ py: 250, textAlign: "center" }}>
			<img src={noDataImg} alt="alert image" />
			<Typography>{message}</Typography>
			<Typography>{error}</Typography>
		</BasePlate>
	);
};

export default NoData;
