import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
	ROUTE_CATEGORY,
	ROUTE_CHILD_CATEGORY,
	ROUTE_DASHBOARD,
	ROUTE_FUNDING,
	ROUTE_PRODUCT_CREATE,
	ROUTE_SETTING,
	ROUTE_CUSTOMER_RECOMMENDATION,
	ROUTE_BRAND_LIST,
	ROUTE_REQUESTED_BRAND_LIST,
	ROUTE_BRAND_CREATE,
	ROUTE_BRAND_EDIT,
	ROUTE_PARTNER_LIST,
	ROUTE_PARTNER_DETAILS,
	ROUTE_SHIPPING,
	ROUTE_BUSINESS_INFO,
	ROUTE_PROFIT_CALCULATION,
	ROUTE_CUSTOMER_INVESTMENT_REVIEW,
	ROUTE_CUSTOMER_TIMELINE,
	ROUTE_INVENTORY_WAREHOUSE,
	ROUTE_SHIPPING_REQUEST,
	ROUTE_INVENTORY_MANAGEMENT,
	DEV_STORYBOOK,
	ROUTE_OWNER,
	ROUTE_OWNER_DETAILS,
	ROUTE_PRODUCT_EDIT,
	ROUTE_TRADE_MANAGEMENT,
	ROUTE_SETTLEMENT_MANAGEMENT,
	ROUTE_PRODUCT_CREATE_CHOOSE_PARTNER
} from "data/routeVariables";
import ProductCreate from "pages/funding/createProduct";
import CustomerRecommendation from "pages/customer/Recommendation";
import CustomerInvestmentReview from "pages/customer/InvestmentReview";
import CustomerTimeline from "pages/customer/Timeline";
import Setting from "pages/setting/Setting";
import Category from "pages/category/Category";
import Dashboard from "pages/dashboard/Dashboard";
import Funding from "pages/funding/Funding";
import BrandsList from "pages/brand/BrandsList";
import BrandRequestList from "pages/brand/BrandRequestList";
import CreateBrand from "pages/brand/CreateBrand";
import PartnerList from "pages/partner/list";
import PartnerDetails from "pages/partner/PartnerDetails";
import ShippingList from "pages/inventory/shippingManagement/list";
import BusinessInfo from "pages/businessInfo/BusinessInfo";
import ProfitCalculation from "pages/profitCalculation/ProfitCalculation";
import WarehouseList from "pages/inventory/warehouseManagement/list";
import InventoryList from "pages/inventory/inventoryManagement/InventoryList";
import FundingAdmin from "pages/fundingAdmin/list";
import StoryBook from "pages/storybook";
import OwnerList from "pages/owner/list";
import EditProduct from "pages/funding/edit";
import ShippingRequest from "pages/inventory/shippingRequest/list";
import OwnerDetailsHost from "pages/ownerDetails";
import ChoosePartner from "pages/fundingAdmin/create/step1/ChoosePartner";

export const privateRoutes = [
	{
		id: "dashboard",
		name: "Dashboard",
		path: ROUTE_DASHBOARD,
		element: <Dashboard />
	},
	{
		id: "funding",
		name: "Funding",
		path: ROUTE_FUNDING,
		element: <Funding />
	},
	{
		id: "fundingAdmin",
		name: "Funding",
		path: ROUTE_FUNDING,
		element: <FundingAdmin />
	},
	{
		id: "shippingRequest",
		name: "ShippingRequest",
		path: ROUTE_SHIPPING_REQUEST,
		element: <ShippingRequest />
	},
	{
		id: "shippingList",
		name: "ShippingList",
		path: ROUTE_SHIPPING,
		element: <ShippingList />
	},
	{
		id: "inventoryManagement",
		name: "InventoryManagement",
		path: ROUTE_INVENTORY_MANAGEMENT,
		element: <InventoryList />
	},
	{
		id: "warehouse",
		name: "Warehouse",
		path: ROUTE_INVENTORY_WAREHOUSE,
		element: <WarehouseList />
	},
	{
		id: "category",
		name: "Category",
		path: ROUTE_CATEGORY,
		element: <Category />
	},
	{
		id: "childCategory",
		name: "ChildCategory",
		path: ROUTE_CHILD_CATEGORY,
		element: <Category />
	},
	{
		id: "brandList",
		name: "BrandList",
		path: ROUTE_BRAND_LIST,
		element: <BrandsList />
	},
	{
		id: "brandRequestList",
		name: "BrandRequestList",
		path: ROUTE_REQUESTED_BRAND_LIST,
		element: <BrandRequestList />
	},
	{
		id: "brandCreate",
		name: "BrandCreate",
		path: ROUTE_BRAND_CREATE,
		element: <CreateBrand />
	},
	{
		id: "brandCreate",
		name: "BrandCreate",
		path: ROUTE_BRAND_EDIT,
		element: <CreateBrand />
	},
	{
		id: "partnerList",
		name: "PartnerList",
		path: ROUTE_PARTNER_LIST,
		element: <PartnerList />
	},
	{
		id: "partnerDetails",
		name: "PartnerDetails",
		path: ROUTE_PARTNER_DETAILS,
		element: <PartnerDetails />
	},
	{
		id: "setting",
		name: "Setting",
		path: ROUTE_SETTING,
		element: <Setting />
	},
	{
		id: "fundingProductCreate",
		name: "FundingProductCreate",
		path: [
			ROUTE_PRODUCT_CREATE,
			`${ROUTE_PRODUCT_CREATE}/step/:stepNumber/:fundingId?`
		],
		element: <ProductCreate />
	},
	{
		id: "fundingChoosePartner",
		name: "FundingChoosePartner",
		path: ROUTE_PRODUCT_CREATE_CHOOSE_PARTNER,
		element: <ChoosePartner />
	},
	{
		id: "fundingProductEdit",
		name: "FundingProductEdit",
		path: `${ROUTE_PRODUCT_EDIT}/:fundingId/step/:stepNumber`,
		element: <EditProduct />
	},
	{
		id: "customerRecommendation",
		name: "CustomerRecommendation",
		path: ROUTE_CUSTOMER_RECOMMENDATION,
		element: <CustomerRecommendation />
	},
	{
		id: "customerInvestmentReview",
		name: "CustomerInvestmentReview",
		path: ROUTE_CUSTOMER_INVESTMENT_REVIEW,
		element: <CustomerInvestmentReview />
	},
	{
		id: "ownerManagement",
		name: "OwnerManagement",
		path: ROUTE_OWNER,
		element: <OwnerList />
	},
	{
		id: "ownerDetails",
		name: "OwnerDetails",
		path: ROUTE_OWNER_DETAILS,
		element: <OwnerDetailsHost />
	},
	{
		id: "customerTimeline",
		name: "CustomerTimeline",
		path: ROUTE_CUSTOMER_TIMELINE,
		element: <CustomerTimeline />
	},
	{
		id: "businessInfo",
		name: "BusinessInfo",
		path: ROUTE_BUSINESS_INFO,
		element: <BusinessInfo />
	},
	{
		id: "profitCalculation",
		name: "ProfitCalculation",
		path: ROUTE_PROFIT_CALCULATION,
		element: <ProfitCalculation />
	},
	{
		id: "trade",
		name: "Trade",
		path: ROUTE_TRADE_MANAGEMENT,
		element: <Stub title="sidebar.trade" />
	},
	{
		id: "settlement",
		name: "Settlement",
		path: ROUTE_SETTLEMENT_MANAGEMENT,
		element: <Stub title="sidebar.settlement" />
	},
	{
		...DEV_STORYBOOK,
		name: "StoryBook",
		element: <StoryBook />
	}
];

function Stub({ title }) {
	const { t } = useTranslation();

	return (
		<Typography component="h2" variant="h2" pb={24}>
			{t(title)}
		</Typography>
	);
}
