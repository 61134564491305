import { API_PARTNER_REJECT } from "services/endpoints";
import { TAG_PARTNER } from "services/tags";

export default (build) =>
	build.mutation({
		query: ({ teamId }) => {
			return {
				url: API_PARTNER_REJECT,
				method: "POST",
				data: {
					team_id: teamId,
					status: "reject"
				}
			};
		},
		invalidatesTags: [TAG_PARTNER]
	});
