import { useTranslation } from "react-i18next";
import HelpIcon from "@mui/icons-material/Help";
import { FormProvider } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import { StackRow } from "components/shared";
import { SelectedOrAllDownloadButtons } from "components/layout/Button";
import OrderPaymentDialog from "components/dialogs/shipping/OrderPaymentDialog";
import { MuiPagination } from "components/table/Pagination";
import useFilter from "components/shared/list/useFilter";
import { useNumberSearchParams } from "utils/useNumbered";
import ShippingRequestListFilter from "components/shared/list/filter";
import {
	useGetShippingListQuery,
	useGetShippingRequestSummaryQuery,
	useGetTabFilterSummaryQuery,
	useLazyDownloadTemplateQuery
} from "services/modules/shipping/request";
import {
	STATUS_CONFIRMED,
	STATUS_REQUEST
} from "pages/inventory/shippingRequest/status";
import MainTitle from "components/shared/list/MainTitle";
import { ShippingIcon } from "assets/icons/sidebar/ShippingIcon";
import TabFilter from "components/shared/list/TabFilter";
import ShippingListEmpty from "pages/inventory/shippingRequest/list/ShippingListEmpty";
import FundingCard from "pages/inventory/shippingRequest/list/FundingCard";
import { useUser } from "redux/modules/useUser";
import { FilterStatusWithHookForm } from "components/shared/list/status";
import SummaryBox from "pages/inventory/inventoryManagement/SummaryBox";
import { useTodo } from "utils/useTodo";
import { downloadWithWindow } from "utils/downloadFile";
import BackDropLoader from "components/shared/BackDropLoader";

const ShippingRequest = () => {
	const { t } = useTranslation();
	const { isSuperAdmin } = useUser();
	const todo = useTodo();

	const page = useNumberSearchParams();

	const [searchParams] = useSearchParams();
	const tabFilter = searchParams.get("filter") || "all";

	const [filters, methods] = useFilter();

	const { watch } = methods;

	const { data: { result = [], meta = {} } = {}, isFetching } =
		useGetShippingListQuery(
			{
				currentPage: page,
				tabFilter: tabFilter,
				...filters
			},
			{ refetchOnMountOrArgChange: true }
		);

	const { data: summaryData } = useGetShippingRequestSummaryQuery();
	const { data: tabFilterSummaryData, isSuccess: tabFiltersReady } =
		useGetTabFilterSummaryQuery();

	const summary = summaryData?.response || {};
	const { shippingSaleStatus_1, shippingSaleStatus_2, shippingSaleStatus_3 } =
		tabFilterSummaryData?.response || {};

	const [trigger] = useLazyDownloadTemplateQuery();

	async function onDownloadTemplate() {
		return trigger().then(({ data }) => {
			return downloadWithWindow(data.response.url);
		});
	}

	const shippingRequests = result.filter(({ deliveryStatusCode }) => {
		return (
			deliveryStatusCode === STATUS_REQUEST ||
			deliveryStatusCode === STATUS_CONFIRMED
		);
	});

	const statusFilter = watch("statusFilter");

	const partnerTabs = [
		{
			id: 1,
			label: "Funding List All",
			active: true,
			value: "",
			count: shippingSaleStatus_1 + shippingSaleStatus_2 + shippingSaleStatus_3
		},
		{
			id: 2,
			label: "On Sale",
			active: false,
			value: "shipping_sale_status_1",
			count: shippingSaleStatus_1
		},
		{
			id: 3,
			label: "Sold Out",
			active: false,
			value: "shipping_sale_status_2",
			count: shippingSaleStatus_2
		},
		{
			id: 4,
			label: "Export Complete",
			active: false,
			value: "shipping_sale_status_3",
			count: shippingSaleStatus_3
		}
	];

	const adminTabs = [
		{
			id: 1,
			label: "On Sale",
			active: false,
			value: "shipping_sale_status_1",
			count: shippingSaleStatus_1
		},
		{
			id: 2,
			label: "Sold Out",
			active: false,
			value: "shipping_sale_status_2",
			count: shippingSaleStatus_2
		},
		{
			id: 3,
			label: "Export Complete",
			active: false,
			value: "shipping_sale_status_3",
			count: shippingSaleStatus_3
		},
		{
			id: 4,
			label: "All",
			active: true,
			value: "",
			count: shippingSaleStatus_1 + shippingSaleStatus_2 + shippingSaleStatus_3
		}
	];

	const isEmptyList =
		!shippingRequests.length && !isFetching && !Object.values(filters).length;

	const isSearchResultEmpty =
		!shippingRequests.length &&
		!isFetching &&
		!Object.values(filters).length &&
		statusFilter === "all";

	return (
		<FormProvider {...methods}>
			<MainTitle pageName={t("Shipping Request")} logo={<ShippingIcon />} />

			<ShippingRequestListFilter />

			{isSuperAdmin && (
				<>
					<FilterStatusWithHookForm total={meta?.total} />

					<SummaryBox summary={summary} />
				</>
			)}

			<StackRow
				variant="text17"
				fontWeight={500}
				color="gray.tertiary"
				justifyContent="space-between"
				pb={20}>
				{tabFiltersReady && (
					<TabFilter tabs={isSuperAdmin ? adminTabs : partnerTabs} />
				)}
				<StackRow gap={7}>
					{t("Help")}
					<HelpIcon />
				</StackRow>
			</StackRow>

			{isEmptyList ? (
				<ShippingListEmpty />
			) : (
				<>
					{isSearchResultEmpty ? (
						<ShippingListEmpty searchEmpty />
					) : (
						shippingRequests.map((data, index) => {
							return (
								<FundingCard
									key={index}
									status={data?.saleStatusName}
									shippingInfoData={data}
								/>
							);
						})
					)}
					<MuiPagination page={page} meta={meta} />
				</>
			)}

			<SelectedOrAllDownloadButtons
				handleFullDownload={onDownloadTemplate}
				handleSelectedDownload={todo}
				wrapperProps={{ justifyContent: "space-between" }}
			/>

			<OrderPaymentDialog />

			<BackDropLoader open={isFetching} />
		</FormProvider>
	);
};

export default ShippingRequest;
