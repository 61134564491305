import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FormInputText } from "components/formComponents";
import { ROUTE_LOGIN } from "data/routeVariables";
import { StackRow } from "components/shared";
import { Button, PRIMARY, SECONDARY } from "components/layout/Button";
import {
	useResetPasswordMutation,
	useSendOTPMutation,
	useVerifyOTPMutation
} from "services/modules/partner";
import { showAlert } from "redux/modules";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { ResetPasswordSchema } from "utils/validation/ResetPasswordSchema";
import ErrorMessage from "components/formComponents/ErrorMessage";

export const ResetPasswordForm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const {
		handleSubmit,
		control,
		watch,
		setValue,
		formState: { errors }
	} = useForm({
		defaultValues: {
			email: "",
			newPassword: "",
			passwordConfirmation: "",
			otpCode: "",
			token: ""
		},
		mode: "onBlur",
		resolver: yupResolver(ResetPasswordSchema(t))
	});

	const otpCodeValue = watch("otpCode");
	const emailValue = watch("email");

	const [
		resetPassword,
		{
			data: pwResetResponse,
			isLoading: pwResetting,
			isError: pwResetError,
			isSuccess: pwResetSuccess
		}
	] = useResetPasswordMutation();

	const pwResetStatus = pwResetResponse?.response.status;

	const [
		sendOTP,
		{
			data: sendEmailResponse,
			isLoading: sendingEmail,
			isError: sendEmailError,
			error: emailErrorInfo,
			isSuccess: sendEmailSuccess
		}
	] = useSendOTPMutation();

	const { message: emailResponseMsg } = sendEmailResponse?.response || {};

	const [
		verifyOTP,
		{
			data: verificationResponse,
			isLoading: verifying,
			error: verifyError,
			isSuccess: verifySuccess
		}
	] = useVerifyOTPMutation();

	const {
		status: verificationStatus,
		message: verificationResponseMsg,
		token: verificationToken
	} = verificationResponse?.response || {};

	const sendOTPEmail = () => {
		sendOTP({ newEmail: emailValue });
	};

	const verifyOTPFunction = () => {
		verifyOTP({ newEmail: emailValue, otp: otpCodeValue });
	};

	const onSubmit = (values) => {
		resetPassword(values);
	};

	useEffect(() => {
		if (sendEmailError || sendEmailSuccess) {
			dispatch(
				showAlert({
					severity: sendEmailError ? "error" : "success",
					message: sendEmailError
						? emailErrorInfo?.data.message
						: emailResponseMsg
					// Temporary Alert Message
				})
			);
		}

		if (verifySuccess && verificationToken) {
			dispatch(
				showAlert({
					severity: "success",
					message: "Verification Success"
				})
			);
			setValue("token", verificationToken);

			verificationStatus === false &&
				dispatch(
					showAlert({
						severity: "error",
						message: verificationResponseMsg
					})
				);
		}

		if (verifyError) {
			dispatch(
				showAlert({
					severity: "error",
					message: "Verification Failed"
				})
			);
		}

		// Password Reset Success/Error Alert
		pwResetError &&
			dispatch(
				showAlert({
					severity: "error",
					message: "Something went wrong"
					// Temporary Alert Message
				})
			);

		pwResetSuccess &&
			dispatch(
				showAlert({
					severity: pwResetStatus === true ? "success" : "error",
					message: pwResetResponse.response.message
				})
			);

		pwResetSuccess && pwResetStatus === true && navigate(ROUTE_LOGIN);
	}, [
		pwResetSuccess,
		pwResetError,
		sendEmailSuccess,
		sendEmailError,
		verificationToken,
		verifySuccess,
		verifyError
	]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container columnSpacing={10}>
				{/* Email Section */}
				<Grid item xs={3} pt={12}>
					<Typography variant="text15" color="text.black">
						{t("signUp.email")}
					</Typography>
				</Grid>
				<Grid item xs={6} mb={10}>
					<FormInputText
						name="email"
						control={control}
						type="email"
						placeholder="abcd@abcd.abcd"
					/>
				</Grid>
				<Grid item xs={3}>
					<Button
						isLoading={sendingEmail}
						onClick={sendOTPEmail}
						color={PRIMARY}
						sx={{ width: 140 }}>
						{t("signUp.sendVerification")}
					</Button>
				</Grid>

				{/* Certification Number Section */}
				<Grid item xs={3} pt={12}>
					<Typography variant="text15" color="text.black">
						{t("signUp.certificationNumber")}
					</Typography>
				</Grid>
				<Grid item xs={6} mb={10}>
					<FormInputText
						type="number"
						name="otpCode"
						control={control}
						maxLength={6}
						placeholder="000000"
					/>
				</Grid>
				<Grid item xs={3}>
					<Button
						isLoading={verifying}
						onClick={verifyOTPFunction}
						disabled={otpCodeValue.length === 0}
						color={SECONDARY}
						sx={{ width: 140 }}>
						{t("signUp.confirm")}
					</Button>
				</Grid>

				{/* Password Section */}
				<Grid item xs={3} pt={12}>
					<Typography variant="text15" color="text.black">
						{t("signUp.password")}
					</Typography>
				</Grid>
				<Grid item xs={9} mb={10}>
					<FormInputText
						name="newPassword"
						control={control}
						type="password"
						placeholder={t("signUp.eightOrMore")}
					/>
				</Grid>
				<Grid item xs={3} pt={12}>
					<Typography variant="text15" color="text.black">
						{t("signUp.rePassword")}
					</Typography>
				</Grid>
				<Grid item xs={9} mb={10}>
					<FormInputText
						name="passwordConfirmation"
						control={control}
						type="password"
						placeholder={t("signUp.eightOrMore")}
					/>
					{errors?.passwordConfirmation?.type === "oneOf" && (
						<ErrorMessage>{errors?.passwordConfirmation?.message}</ErrorMessage>
					)}
				</Grid>
				<Grid item xs={6}></Grid>
				<Grid item xs={6} my={10}>
					<StackRow justifyContent="space-around" mt={50}>
						<Button onClick={() => navigate(ROUTE_LOGIN)} sx={{ width: 140 }}>
							{t("Cancel")}
						</Button>
						<Button
							type="submit"
							disabled={!verificationStatus === true}
							isLoading={pwResetting}
							color={PRIMARY}
							sx={{ width: 140 }}>
							{t("Change Password")}
						</Button>
					</StackRow>
				</Grid>
			</Grid>
		</form>
	);
};
