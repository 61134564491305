import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
	openCreateDialog,
	openDeleteCategoryDialog,
	openEditDialog,
	selectDialog
} from "redux/modules";
import {
	useCategoryDetailQuery,
	useCategoryQuery
} from "services/modules/category";
import { showAlert } from "redux/modules/alert";
import DataTable from "components/table/DataTable";
import { MuiPagination } from "components/table/Pagination";
import CategoryDialog from "components/dialogs/CategoryDialog";
import { DebounceInput } from "react-debounce-input";
import DeleteDialog from "components/dialogs/DeleteDialog";
import {
	generatePath,
	useNavigate,
	useParams,
	useSearchParams
} from "react-router-dom";
import { ROUTE_CATEGORY, ROUTE_CHILD_CATEGORY } from "data/routeVariables";
import { primaryShadow } from "data/styleVariables";
import { Reply } from "@mui/icons-material";
import BreadCrumb from "components/breadcrumbs/Breadcrumbs";
import { Button, PRIMARY } from "components/layout/Button";
import { debounce } from "utils/debounce";
import { CustomTextField } from "components/formComponents/style";

export const CustomDebounceInput = styled(DebounceInput)(() => ({
	width: "25%",
	height: 40,
	border: "1px solid lightgray",
	outline: "none",
	padding: 10,
	boxShadow: primaryShadow,
	borderRadius: 6
}));

const Category = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const deleteCategoryDialog = useSelector((state) => state.category);
	const dialogStatus = useSelector(selectDialog);
	const [searchQuery, setSearchQuery] = useState("");

	const [searchParams] = useSearchParams();
	const page = parseInt(searchParams.get("page") || 1);
	const { category: categoryId } = useParams();
	const { data: categoryDetail } = useCategoryDetailQuery({
		id: categoryId
	});

	const { data, isFetching, error } = useCategoryQuery({
		currentPage: page,
		query: searchQuery,
		parentCategoryId: categoryId
	});

	let parentCategory = categoryDetail?.response?.data;
	const breadcrumbs = [];
	const baseCategory = { name: t("category.header"), path: ROUTE_CATEGORY };

	while (parentCategory) {
		const linkToGo = generatePath(ROUTE_CHILD_CATEGORY, {
			category: parentCategory?.id
		});
		breadcrumbs.unshift({ name: parentCategory?.name, path: linkToGo });
		parentCategory = parentCategory.parent_category;
	}

	breadcrumbs.unshift(baseCategory);

	const handleChange = (e) => {
		setSearchQuery(e.target.value);
	};

	const rows = data?.response?.data || [];
	const meta = data?.response?.meta || [];

	const goToRespectiveSubCategory = (celldata) => {
		if (celldata?.row?.level < 2) {
			const linkToGo = generatePath(ROUTE_CHILD_CATEGORY, {
				category: celldata?.row?.id
			});
			navigate(linkToGo);
		}
	};

	const onCellClick = (celldata) => {
		celldata?.field === "name" && goToRespectiveSubCategory(celldata);
	};

	const onDeleteClick = (cellValues) => {
		dispatch(openDeleteCategoryDialog({ id: cellValues.row?.id }));
	};

	const onEditClick = (cellValues) => {
		dispatch(openEditDialog(cellValues.row));
	};

	useEffect(() => {
		error &&
			dispatch(
				showAlert({
					severity: "error",
					message: t("alertMessage.category.fetchFail")
				})
			);
	}, [error]);

	const columns = [
		{ field: "name", headerName: t("category.header"), flex: 1 }
	];

	return (
		<Box>
			<Typography component="h2" variant="h2" pb={24}>
				{t("category.header")}
			</Typography>
			{categoryId && <BreadCrumb breadCrumbItems={breadcrumbs} />}
			{categoryId && (
				<Box
					onClick={() => navigate(-1)}
					sx={{ cursor: "pointer", marginBottom: "12px" }}>
					<Stack
						mb={13}
						sx={{ color: "gray.text" }}
						alignItems="flex-start"
						direction="row"
						gap={8}>
						<Reply />
						<Typography>{t("Back")}</Typography>
					</Stack>
				</Box>
			)}
			<Stack justifyContent="space-between" direction="row" mb={14}>
				<CustomTextField
					onChange={debounce(handleChange, 1000)}
					placeholder={`${t("Search")}...`}
					size="small"
				/>

				<Button
					onClick={() => {
						dispatch(openCreateDialog(categoryDetail?.response?.data));
					}}
					color={PRIMARY}>
					{t("category.createCategory")}
				</Button>
			</Stack>
			<DataTable
				columns={columns}
				rows={rows}
				isLoading={isFetching}
				isRenderAction={true}
				onDeleteClick={onDeleteClick}
				onEditClick={onEditClick}
				onCellClick={onCellClick}
			/>
			<MuiPagination page={page} meta={meta} />
			<CategoryDialog isDialogOpen={dialogStatus.on} />
			<DeleteDialog isDialogOpen={deleteCategoryDialog.on} />
		</Box>
	);
};

export default Category;
