import { API_BRAND_REQUEST } from "services/endpoints";
import { TAG_BRAND } from "services/tags";

export default (build) =>
	build.mutation({
		query: ({ name, koreanName }) => {
			return {
				url: API_BRAND_REQUEST,
				method: "POST",
				data: {
					name,
					korean_name: koreanName
				}
			};
		},
		invalidatesTags: [TAG_BRAND]
	});
