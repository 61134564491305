import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import {
	AppBar,
	Box,
	Button,
	Menu,
	MenuItem,
	Stack,
	Switch,
	Toolbar,
	Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { toggleSidebar } from "redux/modules";
import { StackRow } from "components/shared";
import { ROUTE_DASHBOARD, ROUTE_LOGIN } from "data/routeVariables";
import { useUser } from "redux/modules/useUser";
import { useLogout } from "utils/useLogout";

const LANG_CACHE_KEY = "language";

export const Navbar = () => {
	const [anchorEl, setAnchorEl] = useState(null);
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const logout = useLogout();

	useEffect(() => {
		const lang =
			localStorage.getItem(LANG_CACHE_KEY) ||
			process.env.REACT_APP_DEFAULT_TRANSLATION ||
			"kor";

		i18n.changeLanguage(lang);
	}, []);

	const handleLangChange = () => {
		const nextLang = i18n.language === "kor" ? "en" : "kor";

		i18n.changeLanguage(nextLang);

		localStorage.setItem(LANG_CACHE_KEY, nextLang);
	};

	const isMenuOpen = Boolean(anchorEl);
	const { isLoggedIn, currentUser } = useUser();

	const handleLogoutBtnOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const renderLogout = (
		<Menu
			anchorEl={anchorEl}
			keepMounted
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right"
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "right"
			}}
			open={isMenuOpen}
			onClose={handleMenuClose}>
			<MenuItem
				onClick={() => {
					handleMenuClose();
					logout();
				}}>
				{t("navbar.logout")}
			</MenuItem>
		</Menu>
	);

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar
				position="fixed"
				sx={{
					zIndex: (theme) => theme.zIndex.drawer + 1,
					backgroundColor: "secondary.main",
					height: 72,
					display: "flex",
					justifyContent: "center",
					boxShadow: "none"
				}}>
				<Toolbar>
					<Stack direction="row" alignItems="center">
						{currentUser && (
							<Button
								onClick={() => dispatch(toggleSidebar())}
								sx={{
									padding: 10,
									color: "white",
									display: { md: "block", lg: "none" }
								}}>
								<MenuIcon />
							</Button>
						)}
						<Stack
							onClick={() =>
								isLoggedIn ? navigate(ROUTE_DASHBOARD) : navigate(ROUTE_LOGIN)
							}
							direction="row"
							flexWrap="nowrap"
							sx={{
								paddingLeft: { sm: 10, md: 34 },
								paddingTop: 3,
								cursor: "pointer"
							}}>
							<Typography
								variant="subtitle1"
								color="primary.main"
								fontSize={19}
								noWrap
								component="span"
								pr={5}>
								OWNERYA
							</Typography>
							<Typography
								variant="subtitle1"
								color="white"
								fontSize={19}
								noWrap
								component="span">
								관리자 센터
							</Typography>
						</Stack>
					</Stack>
					<Box sx={{ flexGrow: 1 }} />
					<Stack sx={{ whiteSpace: "nowrap" }} direction="row" gap={10} mr={30}>
						<StackRow alignItems="center">
							<Typography variant="text12">KR</Typography>
							<Switch
								checked={i18n.language === "en"}
								onChange={handleLangChange}
							/>
							<Typography variant="text12">EN</Typography>
						</StackRow>
						<Button variant="subtitle2" fontSize="17px">
							{t("navbar.faq")}
						</Button>
						<Button variant="subtitle2" fontSize="17px">
							{t("navbar.notice")}
						</Button>
						<Button variant="subtitle2" fontSize="17px">
							{t("navbar.help")}
						</Button>
						{currentUser && (
							<Box
								onClick={handleLogoutBtnOpen}
								sx={{ cursor: "pointer", height: "100%" }}
								bgcolor="gray.profileGray"
								px={33}
								py={6}
								borderRadius={20}>
								<Typography variant="subtitle2">{currentUser.email}</Typography>
							</Box>
						)}
					</Stack>
				</Toolbar>
			</AppBar>
			{renderLogout}
		</Box>
	);
};
