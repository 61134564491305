import { Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { ImageWrapper } from "components/fileUpload/style";
import { FlatPlate } from "components/layout";
import { StackRow } from "components/shared";
import dummyImg from "assets/images/kal-visuals-square.jpg";
import theme from "theme/theme";
import { Button, PRIMARY, SECONDARY } from "components/layout/Button";
import { useOrderPaymentMutation } from "services/modules/shipping/request";
import {
	closeOrderPaymentDialog,
	selectOrderPayment
} from "redux/modules/shippingOrderPayment";
import { showAlert } from "redux/modules";
import numberToThousandComma from "utils/numberToThousandComma";

import PrimaryDialog from "../PrimaryDialog";

const OrderPaymentDialog = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [currentCount, setCurrentCount] = useState(1);

	const decreaseItemCount = () => {
		currentCount !== 1 && setCurrentCount(currentCount - 1);
	};

	const increaseItemCount = () => {
		setCurrentCount(currentCount + 1);
	};

	const totalSettlementAmount = 1460000 * currentCount;

	const { isOrderPaymentDialogOn, shippingInfoId, orderData } =
		useSelector(selectOrderPayment);

	const { funding } = orderData || {};

	const [orderPayment, { isSuccess, isLoading, isError }] =
		useOrderPaymentMutation();

	const payOrder = () => {
		orderPayment({ shippingInfoId, currentCount, totalSettlementAmount });
	};

	useEffect(() => {
		setCurrentCount(1);
	}, [isOrderPaymentDialogOn]);

	useEffect(() => {
		if (isSuccess) {
			dispatch(
				showAlert({
					severity: "success",
					message: "Payment Success"
					// Temp message
				})
			);
		}

		if (isError) {
			dispatch(
				showAlert({
					severity: "error",
					message: "Payment Failed"
					// Temp message
				})
			);
		}

		dispatch(closeOrderPaymentDialog());
		setCurrentCount(1);
	}, [isSuccess, isError]);

	return (
		<PrimaryDialog
			header={t("shipping.card.orderPayment")}
			closeDialogFromState={closeOrderPaymentDialog}
			isDialogOpen={isOrderPaymentDialogOn}>
			<FlatPlate sx={{ p: 24 }}>
				<StackRow>
					<ImageWrapper minWidth={168} height={168}>
						<img height="100%" width="100%" src={dummyImg} />
					</ImageWrapper>
					<Stack gap={12} pl={24} color={theme.palette.gray.tertiary}>
						<Typography>{t("shipping.tableHeaders.fundingName")}</Typography>
						<Typography>{t("shipping.tableHeaders.fundingCode")}</Typography>
						<Typography>{t("shipping.summary.inventoryStatus")}</Typography>
						<Typography>{t("shipping.tableHeaders.shippingStatus")}</Typography>
						<Typography>{t("table.status")}</Typography>
						<Typography>{t("shipping.card.settlementUnitPrice")}</Typography>
					</Stack>
					<Stack gap={12} pl={12}>
						<Typography>{funding?.name}</Typography>
						<Typography>{funding?.code}</Typography>
						<Typography>{`${t("shipping.all")} 400 | ${t(
							"shipping.available"
						)} 292`}</Typography>
						<Typography>{`${t("shipping.deliveryRequest")} 3 | ${t(
							"shipping.shipping"
						)} 5 | ${t("shipping.completed")} 100`}</Typography>
						<Typography>
							{`${t("shipping.unitPrice")} 700000 | ${t(
								"shipping.shippingFee"
							)} 3000 | ${t("shipping.adminFee")} 3000`}
						</Typography>
						<Typography>706,000원</Typography>
					</Stack>
				</StackRow>
			</FlatPlate>
			<FlatPlate sx={{ p: 24 }}>
				<Typography pb={70} fontSize={18} fontWeight={500}>
					{t("shipping.payNotice")}
				</Typography>
				<StackRow
					alignItems="center"
					justifyContent="flex-end"
					gap={20}
					py={24}>
					<Typography color={theme.palette.gray.tertiary}>
						{t("shipping.quantity")}
					</Typography>
					<StackRow gap={25} alignItems="center">
						<Button
							color={SECONDARY}
							sx={{ borderRadius: 6.5 }}
							disabled={currentCount === 1}
							onClick={decreaseItemCount}>
							-
						</Button>
						<Typography>{currentCount}</Typography>
						<Button
							color={SECONDARY}
							sx={{ borderRadius: 6.5 }}
							onClick={increaseItemCount}>
							+
						</Button>
					</StackRow>
				</StackRow>
				<Divider />
				<StackRow
					justifyContent="flex-end"
					alignItems="center"
					gap={20}
					pt={24}>
					<Typography color={theme.palette.gray.tertiary}>
						{t("shipping.totalSettlementAmount")}
					</Typography>
					<Typography fontSize={25} fontWeight={600}>
						{numberToThousandComma(totalSettlementAmount)}원
					</Typography>
				</StackRow>
			</FlatPlate>
			<StackRow justifyContent="flex-end">
				<Button color={PRIMARY} isLoading={isLoading} onClick={payOrder}>
					{t("shipping.payBtn")}
				</Button>
			</StackRow>
		</PrimaryDialog>
	);
};

export default OrderPaymentDialog;
