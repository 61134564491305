import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { showAlert } from "redux/modules";
import { usePromiseHandler } from "utils/usePromiseHandler";

export function useSelectedDownload() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const promiseHandler = usePromiseHandler();

	return (selection, worker, ...rest) => {
		if (selection && selection.length === 0) {
			dispatch(
				showAlert({
					severity: "info",
					message: t("Please select at least one or more items")
				})
			);

			return Promise.resolve();
		} else {
			return promiseHandler(worker(), ...rest);
		}
	};
}
