import { Typography } from "@mui/material";
import React from "react";
import theme from "theme/theme";
import { StackRow } from ".";

const DualTitle = ({ mainTitle, subTitle, mainVariant, subVariant }) => {
	return (
		<StackRow gap={10} alignItems="baseline">
			<Typography {...mainVariant}>{mainTitle}</Typography>
			{subTitle && (
				<Typography
					color={theme.palette.gray.tertiary}
					fontWeight={subVariant.fontWeight}
					fontSize={subVariant.fontSize}>
					|
				</Typography>
			)}
			<Typography {...subVariant}>{subTitle}</Typography>
		</StackRow>
	);
};

export default DualTitle;
