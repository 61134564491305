import { API_CATEGORY } from "services/endpoints";
import { TAG_CATEGORY } from "services/tags";

export default (build) =>
	build.mutation({
		query: ({ name, parentCategoryId }) => {
			return {
				url: API_CATEGORY,
				method: "POST",
				data: {
					name,
					parent_category_id: parentCategoryId
				}
			};
		},
		invalidatesTags: [TAG_CATEGORY]
	});
