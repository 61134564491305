import { api } from "services/api";

import deliveryInfo from "./deliveryInfo";
import downloadTemplate from "./downloadTemplate";
import getShippingList from "./getShippingList";
import getShippingRequestSummary from "./getShippingRequestSummary";
import getTabFilterSummary from "./getTabFilterSummary";
import importShippingInfo from "./importShippingInfo";
import orderPayment from "./orderPayment";
import getStatuses from "./statuses";
import storeProductBatch from "./storeProductBatch";

export const shippingApi = api.injectEndpoints({
	endpoints: (build) => ({
		getShippingList: getShippingList(build),
		getStatuses: getStatuses(build),
		orderPayment: orderPayment(build),
		deliveryInfo: deliveryInfo(build),
		importShippingInfo: importShippingInfo(build),
		downloadTemplate: downloadTemplate(build),
		getShippingRequestSummary: getShippingRequestSummary(build),
		getTabFilterSummary: getTabFilterSummary(build),
		storeProductBatch: storeProductBatch(build)
	}),
	overrideExisting: false
});

export const {
	useGetShippingListQuery,
	useGetStatusesQuery,
	useOrderPaymentMutation,
	useDeliveryInfoMutation,
	useImportShippingInfoMutation,
	useLazyDownloadTemplateQuery,
	useGetShippingRequestSummaryQuery,
	useGetTabFilterSummaryQuery,
	useStoreProductBatchMutation
} = shippingApi;
