import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
	authReducer,
	sidebarReducer,
	alertReducer,
	dialogToggleReducer,
	categoryReducer,
	brandReducer,
	partnerReducer,
	ownerReducer
} from "./modules";
import * as modules from "services/modules";
import { api } from "services/api";
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { clearCookies } from "utils/clearCookies";
import { orderPaymentReducer } from "./modules/shippingOrderPayment";

const reducer = combineReducers({
	auth: authReducer,
	sidebar: sidebarReducer,
	alert: alertReducer,
	dialogToggle: dialogToggleReducer,
	category: categoryReducer,
	brand: brandReducer,
	partner: partnerReducer,
	orderPayment: orderPaymentReducer,
	owner: ownerReducer,
	...Object.values(modules).reduce(
		(acc, module) => ({
			...acc,
			[module.reducerPath]: module.reducer
		}),
		{}
	)
});

const logoutMiddleware = () => (next) => (action) => {
	if (action?.type === "auth/resetAuth") {
		clearCookies();
	}
	next(action);
};

const persistConfig = {
	key: "ownerya",
	storage,
	whitelist: ["auth", "brand", "partner", "owner"]
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
			}
		})
			.concat(api.middleware)
			.concat(logoutMiddleware)
});

export const persistor = persistStore(store);
