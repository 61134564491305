import { API_REQUESTED_BRAND_LIST } from "services/endpoints";
import { TAG_BRAND } from "services/tags";

export default (build) =>
	build.query({
		query: ({ query, currentPage }) => {
			return {
				url: API_REQUESTED_BRAND_LIST,
				params: {
					order: "desc",
					page: currentPage,
					per_page: 10,
					q: query
				},
				method: "GET"
			};
		},
		providesTags: [TAG_BRAND]
	});
