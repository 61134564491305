import { Box } from "@mui/material";
import React from "react";
import { PageTitle, StackRow } from "..";

const MainTitle = ({ pageName, logo }) => {
	return (
		<StackRow pb={20} gap={14} alignItems="center">
			<Box width={22.5}>{logo}</Box>
			<PageTitle>{pageName}</PageTitle>
		</StackRow>
	);
};

export default MainTitle;
