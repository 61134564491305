import { Button, Stack } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
	closeDeleteCategoryDialog,
	selectCategory,
	showAlert
} from "redux/modules";
import { useDeleteCategoryMutation } from "services/modules/category";
import DialogContainer from "./DialogContainer";

const DeleteDialog = ({ isDialogOpen }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const deleteDialogStatus = useSelector(selectCategory);
	const [deleteCategory, { isSuccess, error }] = useDeleteCategoryMutation();

	return (
		<DialogContainer
			isDialogOpen={isDialogOpen}
			dialogTitle={t("category.deleteCategory")}
			dialogMessage={t("category.deleteMessage")}>
			<Stack direction="row" justifyContent="right" px={30} py={15} gap={10}>
				<Button
					variant="outlined"
					onClick={() => dispatch(closeDeleteCategoryDialog())}>
					{t("Cancel")}
				</Button>
				<Button
					variant="contained"
					type="submit"
					onClick={() => {
						deleteCategory(deleteDialogStatus.id);
						isSuccess &&
							dispatch(
								showAlert({
									severity: "success",
									message: t("alertMessage.category.deleteSuccess")
								})
							);

						error &&
							dispatch(
								showAlert({
									severity: "error",
									message: t("alertMessage.category.deleteFail")
								})
							);

						dispatch(closeDeleteCategoryDialog());
					}}>
					{t("Delete")}
				</Button>
			</Stack>
		</DialogContainer>
	);
};

export default DeleteDialog;
