import { IconButton, Stack, Typography } from "@mui/material";
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import theme from "theme/theme";

import { MuiDataGrid, TableWrapper } from "./style";

export default function DataTable({
	rows,
	columns,
	isLoading,
	isRenderAction = false,
	isRenderCheckBox = false,
	onRowsSelection,
	onRowClick,
	onCellClick,
	onEditClick,
	onDeleteClick,
	disableColumnMenu = true,
	sortable = false,
	...rest
}) {
	const { t } = useTranslation();

	const renderError = () => {
		return <Typography>{t("alertMessage.somethingWentWrong")}</Typography>;
	};

	const handleCellFocusOut = (params, event) => {
		event.defaultMuiPrevented = true;
	};

	const renderActionButtons = (cellValues) => {
		return (
			<Stack direction="row">
				<IconButton onClick={() => onEditClick(cellValues)}>
					<EditOutlined />
				</IconButton>
				<IconButton onClick={() => onDeleteClick(cellValues)}>
					<DeleteOutlined />
				</IconButton>
			</Stack>
		);
	};

	const renderColumns = columns.map((column) => ({
		...column,
		sortable
	}));

	const actionObject = {
		field: "actions",
		headerName: t("table.actions"),
		renderCell: renderActionButtons,
		align: "right",
		sortable
	};

	isRenderAction && renderColumns.push(actionObject);

	return (
		<TableWrapper>
			<div style={{ flexGrow: 1 }}>
				<MuiDataGrid
					rows={rows}
					autoHeight
					onError={renderError}
					loading={isLoading}
					columns={renderColumns}
					disableSelectionOnClick
					checkboxSelection={isRenderCheckBox}
					onSelectionModelChange={(ids) =>
						onRowsSelection && onRowsSelection(ids)
					}
					hideFooter
					components={{
						NoRowsOverlay: () => (
							<Stack height="100%" alignItems="center" justifyContent="center">
								{t("alertMessage.category.noData")}
							</Stack>
						)
					}}
					onCellClick={(celldata) => onCellClick && onCellClick(celldata)}
					onRowClick={(rowData) => onRowClick && onRowClick(rowData)}
					onCellFocusOut={handleCellFocusOut}
					sx={{
						[`& .MuiDataGrid-row:hover:hover`]: {
							backgroundColor: theme.palette.primary.sidebar,
							cursor: "pointer"
						}
					}}
					disableColumnMenu={disableColumnMenu}
					{...rest}
				/>
			</div>
		</TableWrapper>
	);
}
