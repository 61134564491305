import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	on: false,
	payload: {}
};

const dialogToggleSlice = createSlice({
	name: "dialogToggle",
	initialState,
	reducers: {
		openCreateDialog: (state, { payload }) => {
			state.on = true;
			state.payload = {
				parent_category: payload
			};
		},
		openEditDialog: (state, { payload }) => {
			state.on = true;
			state.payload = payload;
		},
		closeDialog: (state) => {
			state.on = false;
			state.payload = {};
		}
	}
});

export const selectDialog = (state) => state.dialogToggle || {};

export const { openCreateDialog, openEditDialog, closeDialog } =
	dialogToggleSlice.actions;

export const dialogToggleReducer = dialogToggleSlice.reducer;
