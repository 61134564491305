import { useState } from "react";
import { useDispatch } from "react-redux";
import { showAlert } from "redux/modules";

function useCopyString() {
	const [isCopied, setIsCopied] = useState(false);
	const dispatch = useDispatch();

	const copyString = async (str) => {
		try {
			await navigator.clipboard.writeText(str);
			setIsCopied(true);
		} catch (err) {
			dispatch(
				showAlert({
					severity: "error",
					message: "Failed to copy text"
				})
			);
		}
	};

	return [isCopied, copyString];
}

export default useCopyString;
