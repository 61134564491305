import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

import somethingWrong from "assets/images/shared/somethingWrong.png";

class ErrorBoundaryInner extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	render() {
		const t = this.props.t;

		const {
			message = t("alertMessage.somethingWentWrong"),
			children,
			sx
		} = this.props;

		if (this.state.hasError) {
			return (
				<Box sx={sx}>
					<Stack alignItems="center">
						<Typography mb={30} variant="h2">
							{message}
						</Typography>

						<img src={somethingWrong} />
					</Stack>
				</Box>
			);
		}

		return children;
	}
}

export function ErrorBoundary(props) {
	const { t } = useTranslation();

	return <ErrorBoundaryInner {...props} t={t} />;
}
