import FormInputSelect from "components/formComponents/FormInputSelect";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DATE_FORMAT_THREE, format } from "utils/formatDate";

export default function columns() {
	const { t } = useTranslation();

	const { control, setValue } = useFormContext();

	return [
		{
			field: "code",
			headerName: t("owner.userCode"),
			minWidth: 200
		},
		{
			field: "level",
			renderCell: ({ row: { level } }) => (level ? t(level) : "-"),
			minWidth: 120,
			renderHeader: () => (
				<FormInputSelect
					name="columnFilter"
					variant="text15"
					fontWeight={500}
					control={control}
					options={[
						{ label: t("shipping.all"), value: "all" },
						{ label: t("owner.president"), value: "president" },
						{ label: t("owner.ceo"), value: "ceo" }
					]}
					setValue={setValue}
					hideBorder
				/>
			)
		},
		{
			field: "status",
			renderCell: ({ row: { status } }) => t(`owner.${status}`),
			minWidth: 120,
			renderHeader: () => (
				<FormInputSelect
					name="statusFilter"
					variant="text15"
					fontWeight={500}
					control={control}
					options={[
						{ label: t("shipping.all"), value: "all" },
						{ label: t("owner.active"), value: "active" },
						{ label: t("owner.inactive"), value: "inactive" }
					]}
					setValue={setValue}
					hideBorder
				/>
			)
		},
		{
			field: "nickName",
			headerName: t("owner.nickname"),
			minWidth: 200,
			valueGetter: ({ row: { nickName } }) => nickName || "-"
		},
		{
			field: "name",
			headerName: t("owner.name"),
			minWidth: 200
		},
		{
			field: "age",
			headerName: t("owner.age"),
			minWidth: 150,
			valueGetter: ({ row: { age } }) => age ?? "-"
		},
		{
			field: "gender",
			headerName: t("owner.gender"),
			minWidth: 300,
			valueGetter: ({ row: { gender } }) => gender ?? "-"
		},
		{
			field: "email",
			headerName: t("owner.email"),
			minWidth: 200
		},
		{
			field: "lastLogin",
			headerName: t("owner.lastLogin"),
			minWidth: 200,
			valueGetter: ({ row: { lastLogin } }) =>
				lastLogin ? format(new Date(lastLogin), DATE_FORMAT_THREE) : "-"
		},
		{
			field: "signUp",
			headerName: t("owner.subscription"),
			minWidth: 200,
			valueGetter: ({ row: { signUp } }) =>
				signUp ? format(new Date(signUp), DATE_FORMAT_THREE) : "-"
		},
		{
			field: "marketing",
			headerName: t("owner.marketingConsent"),
			minWidth: 200,
			valueGetter: ({ row: { marketing } }) =>
				marketing ? format(new Date(marketing), DATE_FORMAT_THREE) : "-"
		},
		{
			field: "cumulativeLoginNumber",
			headerName: t("owner.totalLogin"),
			minWidth: 120
		},
		{
			field: "cumulativeInvestmentNumber",
			headerName: t("owner.totalInvestments"),
			minWidth: 120
		},
		{
			field: "cumulativeTradeNumber",
			headerName: t("owner.totalTrades"),
			minWidth: 120
		},
		{
			field: "totalReturn",
			headerName: t("owner.overallReturnRate"),
			minWidth: 120
		},
		{
			field: "marketReturn",
			headerName: t("owner.marketReturnRate"),
			minWidth: 120
		},
		{
			field: "tradeReturn",
			headerName: t("owner.tradeReturn"),
			minWidth: 120
		}
	].map((column) => ({
		headerAlign: "center",
		align: "center",
		minWidth: 120,
		...column
	}));
}
