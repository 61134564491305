import { API_PARTNER } from "services/endpoints";
import { TAG_PARTNER } from "services/tags";

export default (build) =>
	build.query({
		query: ({ currentPage, perPage = 10, search = "" }) => {
			return {
				url: API_PARTNER,
				params: {
					page: currentPage,
					per_page: perPage,
					q: search
				},
				method: "GET"
			};
		},
		providesTags: [TAG_PARTNER]
	});
