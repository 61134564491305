import { api } from "services/api";
import getProducts from "./getProducts";
import getFundingSummary from "./getFundingSummary";
import createProduct, { stepTwo, stepThree } from "./createProduct";
import postDocuments from "./postDocuments";
import raiseLimitRequest from "./raiseLimitRequest";
import getPreloads from "./getPreloads";
import getStatuses from "./statuses";
import editActions from "./edit";

export const productApi = api.injectEndpoints({
	endpoints: (build) => ({
		getProducts: getProducts(build),
		createProduct: createProduct(build),
		postDocuments: postDocuments(build),
		getFundingSummary: getFundingSummary(build),
		raiseLimitRequest: raiseLimitRequest(build),
		getPreloads: getPreloads(build),
		getStatuses: getStatuses(build),
		createStepTwo: stepTwo(build),
		createStepThree: stepThree(build),
		...editActions(build)
	}),
	overrideExisting: false
});

export const {
	useGetProductsQuery,
	useCreateProductMutation,
	usePostDocumentsMutation,
	useGetFundingSummaryQuery,
	useRaiseLimitRequestMutation,
	useGetPreloadsQuery,
	useGetStatusesQuery,
	useCreateStepTwoMutation,
	useCreateStepThreeMutation
} = productApi;
