import {
	Avatar,
	Box,
	CssBaseline,
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	Toolbar,
	Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import theme from "theme/theme";
import { closeSidebar, selectSidebar } from "redux/modules";

import {
	allSidebarElements,
	BRANDS_MENU,
	CATEGORY_MENU,
	INVENTORY_MENU_PARTNER,
	INVENTORY_MENU_SUPER,
	OWNER_MENU,
	PARTNER_MENU
} from "./sidebarElements";
import {
	CustomAccordion,
	CustomAccordionDetails,
	CustomAccordionSummary,
	CustomDrawer,
	ProfileShowcaseWrapper
} from "./style";
import { useUser } from "redux/modules/useUser";
import { useGetPartnerDetailsQuery } from "services/modules/partner";

const getColors = (items) => {
	const isActive = Array.isArray(items)
		? items.find((item) => location.pathname.includes(item.path))
		: location.pathname.includes(items);

	return {
		backgroundColor: isActive && theme.palette.primary.sidebar,
		color: isActive && theme.palette.primary.main
	};
};

export const SideNavbar = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { currentUser, isSuperAdmin } = useUser();

	const { isSidebarOpen } = useSelector(selectSidebar);

	let blackList = [];
	if (isSuperAdmin) {
		blackList = [INVENTORY_MENU_PARTNER];
	} else {
		blackList = [
			BRANDS_MENU,
			CATEGORY_MENU,
			PARTNER_MENU,
			OWNER_MENU,
			INVENTORY_MENU_SUPER
		];
	}

	const { data: partnerDetails } = useGetPartnerDetailsQuery({
		partnerId: currentUser?.id
	});
	// To extract profile pic and name

	const userImg = partnerDetails?.response.data.photos;
	const userName = partnerDetails?.response.data.name;

	const sidebarElements = allSidebarElements.filter(
		(element) => !blackList.includes(element.id)
	);

	const drawer = (
		<div>
			<Toolbar />
			<ProfileShowcaseWrapper>
				<Avatar
					alt="profile"
					src={userImg && userImg[0]?.path}
					sx={{ width: 66, height: 66 }}>
					{userName?.charAt(0)}
				</Avatar>
				<Typography>{userName || t("User")}</Typography>
			</ProfileShowcaseWrapper>
			<Divider />
			<List sx={{ marginTop: 20 }}>
				{sidebarElements.map((sidebarElement, index) =>
					sidebarElement.subItems ? (
						<div key={index}>
							<CustomAccordion>
								<CustomAccordionSummary>
									<ListItem
										sx={{
											gap: 11,
											paddingLeft: 23,
											color: getColors(sidebarElement.subItems).color
										}}>
										<ListItemIcon sx={{ minWidth: 0 }}>
											{sidebarElement.icon}
										</ListItemIcon>
										<ListItemText primary={t(sidebarElement.item)} />
									</ListItem>
								</CustomAccordionSummary>
								<CustomAccordionDetails>
									{sidebarElement.subItems.map((subItem) => {
										return (
											<ListItem
												sx={getColors(subItem.path)}
												key={subItem.id}
												disablePadding>
												<ListItemButton
													disableRipple
													onClick={() => {
														dispatch(closeSidebar());
														navigate(subItem.path);
													}}
													key={t(subItem.name)}
													sx={{ gap: 11, paddingLeft: 23 }}>
													<ListItemIcon
														sx={{ minWidth: 0, visibility: "hidden" }}>
														{sidebarElement.icon}
													</ListItemIcon>
													<ListItemText primary={t(subItem.name)} />
												</ListItemButton>
											</ListItem>
										);
									})}
								</CustomAccordionDetails>
							</CustomAccordion>
						</div>
					) : (
						<ListItem
							sx={getColors(sidebarElement.path)}
							key={sidebarElement.id}
							disablePadding>
							<ListItemButton
								disableRipple
								onClick={() => {
									dispatch(closeSidebar());
									navigate(sidebarElement.path);
								}}
								sx={{ gap: 11, paddingLeft: 23 }}>
								<ListItemIcon
									sx={{
										minWidth: 0,
										color:
											location.pathname.includes(sidebarElement.path) &&
											theme.palette.primary.main
									}}>
									{sidebarElement.icon}
								</ListItemIcon>
								<ListItemText primary={t(sidebarElement.item)} />
							</ListItemButton>
						</ListItem>
					)
				)}
			</List>
		</div>
	);

	return (
		<Stack component="nav">
			<CssBaseline />
			<CustomDrawer
				sx={{
					display: { xs: "none", lg: "block" }
				}}
				variant="permanent">
				{drawer}
			</CustomDrawer>
			<CustomDrawer
				open={isSidebarOpen}
				sx={{
					display: { xs: "block", lg: "none" }
				}}
				variant="temporary">
				{drawer}
			</CustomDrawer>
			<Box
				component="main"
				sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}>
				<Toolbar />
			</Box>
		</Stack>
	);
};
