import { Autocomplete, Chip, FormControl } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import ErrorMessage from "components/formComponents/ErrorMessage";

import { CustomTextField } from "./style";

export default function FormInputTag({ name, endAdornment }) {
	const { control, watch } = useFormContext();

	const value = watch(name);

	return (
		<FormControl fullWidth>
			<Controller
				name={name}
				control={control}
				render={({ field, fieldState: { error } }) => (
					<>
						<Autocomplete
							freeSolo
							multiple
							filterSelectedOptions
							options={[]}
							getOptionLabel={(option) => option}
							renderTags={(value, getTagProps) =>
								value.map((option, index) => {
									return (
										index < 10 && (
											<Chip
												key={index}
												variant="contained"
												label={option}
												{...getTagProps({ index })}
											/>
										)
									);
								})
							}
							value={value || []}
							onChange={(e, newValue) => {
								field.onChange(newValue);
							}}
							renderInput={({
								InputProps: { endAdornment: adornment, ...InputProps },
								...params
							}) => {
								return (
									<CustomTextField
										onBlur={(e) => {
											field.onBlur(e);
										}}
										value={value}
										size="small"
										error={!!error}
										InputProps={{
											...InputProps,
											endAdornment: endAdornment
												? endAdornment(adornment)
												: adornment
										}}
										{...params}
									/>
								);
							}}
						/>
						<ErrorMessage>{error?.message}</ErrorMessage>
					</>
				)}
			/>
		</FormControl>
	);
}
