import { API_IMPORT_INFO } from "services/endpoints";
import { TAG_SHIPPING } from "services/tags";

export default (build) =>
	build.mutation({
		query: ({ importExcel }) => {
			return {
				url: API_IMPORT_INFO,
				method: "POST",
				headers: {
					"content-type": "multipart/form-data"
				},
				data: {
					file: importExcel[0]
				}
			};
		},
		invalidatesTags: [TAG_SHIPPING]
	});
