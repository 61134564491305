import alertImg from "assets/images/shared/reject.png";
import closeIcon from "assets/images/shared/close-icon.png";
import {
	CustomDialogWrapper,
	PrimaryDialogMessage,
	PrimaryDialogSubMessage
} from "./style";
import { Box, Stack } from "@mui/material";
import { Button, PRIMARY } from "components/layout/Button";
import { FormInputText } from "components/formComponents";
import { useTranslation } from "react-i18next";

const PrimaryUserMessageDialog = ({
	isDialogOpen,
	setDialogOpen,
	message,
	subMessage,
	firstBtn,
	secondBtn,
	firstAction,
	isLoading,
	control,
	isDirty
}) => {
	const { t } = useTranslation();

	return (
		<CustomDialogWrapper open={isDialogOpen}>
			<Stack p={20}>
				<Box textAlign="right">
					<img
						src={closeIcon}
						alt="closeIcon"
						style={{ cursor: "pointer" }}
						onClick={() => setDialogOpen(false)}
					/>
				</Box>
				<Box textAlign="center">
					<img src={alertImg} alt="alert image" />
				</Box>
				<Stack pb={12} gap={8}>
					<PrimaryDialogMessage color="black">{message}</PrimaryDialogMessage>
					<PrimaryDialogSubMessage>{subMessage}</PrimaryDialogSubMessage>
				</Stack>

				<FormInputText
					name="userMessageInput"
					isTextarea
					placeholder={t("brand.rejectReasonPlaceholder")}
					control={control}
				/>

				<Stack gap={10} pt={12}>
					<Button
						type="submit"
						disabled={!isDirty}
						isLoading={isLoading}
						color={PRIMARY}
						onClick={firstAction}>
						{firstBtn}
					</Button>
					{secondBtn && <Button>{secondBtn}</Button>}
				</Stack>
			</Stack>
		</CustomDialogWrapper>
	);
};

export default PrimaryUserMessageDialog;
