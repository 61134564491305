import { API_DELIVERY_INFO } from "services/endpoints";
import { TAG_SHIPPING } from "services/tags";

export default (build) =>
	build.mutation({
		query: ({ shippingId, deliveryType }) => {
			return {
				url: API_DELIVERY_INFO,
				method: "POST",
				data: {
					shipping_info_id: shippingId,
					delivery_type: deliveryType === "selfPickup" ? "person" : "delivery",
					manager: "Test Manager"
				}
			};
		},
		invalidatesTags: [TAG_SHIPPING]
	});
