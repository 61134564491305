import { API_CATEGORY } from "services/endpoints";
import { TAG_CATEGORY } from "services/tags";

export default (build) =>
	build.mutation({
		query: ({ id, name, parentCategoryId: parent_category_id }) => {
			return {
				url: `${API_CATEGORY}/${id}`,
				method: "PATCH",
				data: {
					name,
					parent_category_id
				}
			};
		},
		invalidatesTags: [TAG_CATEGORY]
	});
