import { useState } from "react";
import { useFormContext } from "react-hook-form";

import { StackRow } from "..";
import { SearchInputWithButton } from "./SearchInputWithButton";

export default function KeywordOnlyFilter({ searchPlaceholder, width }) {
	const { setValue } = useFormContext();

	const [searchValue, setSearchValue] = useState("");

	const handleSearchSubmit = () => {
		setValue("search", searchValue);
	};

	const handleClearSearch = () => {
		setValue("search", "");
		setSearchValue("");
	};

	return (
		<StackRow mb={20} gap={12}>
			<SearchInputWithButton
				width={width}
				searchValue={searchValue}
				setSearchValue={setSearchValue}
				handleClearSearch={handleClearSearch}
				handleSearchSubmit={handleSearchSubmit}
				placeholder={searchPlaceholder}
			/>
		</StackRow>
	);
}
