import { IMaskInput } from "react-imask";

export function CurrencyInput(props) {
	const { inputRef, value, onChange, ...inputProps } = props;

	return (
		<IMaskInput
			{...inputProps}
			value={String(value)}
			mask={Number}
			radix="."
			thousandsSeparator=","
			inputRef={inputRef}
			onAccept={(newValue) => {
				onChange({
					target: {
						name: props.name,
						value:
							newValue === "" ? "" : parseFloat(newValue.replaceAll(",", ""))
					}
				});
			}}
			overwrite
			onChange={() => {}}
		/>
	);
}
