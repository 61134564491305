import { Grid, Stack, Typography } from "@mui/material";
import { FormInputText } from "components/formComponents";
import FormInputSelect from "components/formComponents/FormInputSelect";
import { PreviewBox } from "components/formComponents/style";
import { Button, SECONDARY } from "components/layout/Button";
import { StackRow } from "components/shared";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import theme from "theme/theme";
import { InputLabel } from "../style";
import ErrorMessage from "components/formComponents/ErrorMessage";
import FormInputDatePicker from "components/formComponents/FormInputDatePicker";
import { DATE_FORMAT_ONE, format } from "utils/formatDate";

const OPTIONS = [
	{
		label: "A Bank",
		value: "aBank"
	},
	{
		label: "B Bank",
		value: "bBank"
	},
	{
		label: "AYA Bank",
		value: "ayaBank"
	}
];
// Temporary Data

const AdditionalInfo = () => {
	const { t } = useTranslation();

	const {
		control,
		register,
		setValue,
		watch,
		formState: { errors }
	} = useFormContext();
	const watchBankBookCopy = watch("bankBookCopy");
	const watchFinancialStatement = watch("financialStatement");

	return (
		<>
			<Stack pb={32} gap={20}>
				<StackRow>
					<Typography fontWeight={700} variant="text18" pr={10}>
						{t("signUp.moreInfo.companyDetails")}
					</Typography>
					<Typography
						fontWeight={700}
						variant="text18"
						color={theme.palette.text.secondary}>
						{t("signUp.moreInfo.companyDetailsExplained")}
					</Typography>
				</StackRow>

				<Grid container alignItems="center" wrap="nowrap">
					<Grid item xs={1} mr={14}>
						<InputLabel> {t("signUp.moreInfo.companyStartDate")}</InputLabel>
					</Grid>

					<Grid item container wrap="nowrap" xs={5.5} pr={14}>
						<Grid item xs={8} pr={14}>
							<FormInputDatePicker
								name="companyStartDate"
								control={control}
								onChange={(date) => {
									setValue("companyStartDate", format(date, DATE_FORMAT_ONE));
								}}
							/>
						</Grid>
					</Grid>
				</Grid>

				{/* 3 year turnover */}
				<Grid container alignItems="center">
					<Grid item xs={1} mr={14}>
						<InputLabel>{t("signUp.moreInfo.turnover")}</InputLabel>
					</Grid>

					<Grid item container xs gap={20}>
						<Grid item xs>
							<FormInputText
								name="year1"
								control={control}
								type="number"
								placeholder={t("signUp.moreInfo.turnoverExample1")}
							/>
						</Grid>

						<Grid item xs>
							<FormInputText
								name="year2"
								control={control}
								type="number"
								placeholder={t("signUp.moreInfo.turnoverExample2")}
							/>
						</Grid>

						<Grid item xs>
							<FormInputText
								name="year3"
								control={control}
								type="number"
								placeholder={t("signUp.moreInfo.turnoverExample3")}
							/>
						</Grid>
					</Grid>
				</Grid>

				<Grid container alignItems="flex-start">
					<Grid item xs={1} mr={14}>
						<InputLabel>{t("signUp.moreInfo.aboutUs")}</InputLabel>
					</Grid>

					<Grid item xs>
						<FormInputText
							isTextarea
							name="aboutUs"
							control={control}
							type="text"
							placeholder={t("signUp.moreInfo.aboutUsPlaceholder")}
						/>
					</Grid>
				</Grid>

				<Grid container alignItems="center">
					<Grid item xs={1} mr={14} visibility="hidden">
						<InputLabel>{t("signUp.moreInfo.aboutUs")}</InputLabel>
					</Grid>
					<Grid item xs={4} pr={14}>
						<PreviewBox
							component="label"
							htmlFor="financialStatement"
							sx={{
								borderColor:
									errors.financialStatement && theme.palette.error.main,
								display: "block"
							}}>
							<input
								type="file"
								id="financialStatement"
								accept="image/*,application/pdf"
								hidden
								{...register("financialStatement")}
							/>
							{(watchFinancialStatement && watchFinancialStatement[0]?.name) ||
								t("fileName")}
						</PreviewBox>
						{errors.financialStatement && (
							<ErrorMessage>{errors?.financialStatement.message}</ErrorMessage>
						)}
					</Grid>
					<Button
						color={SECONDARY}
						htmlFor="financialStatement"
						component="label">
						{t("Upload File")}
					</Button>
				</Grid>
			</Stack>
			<Stack pb={32} gap={20}>
				<StackRow>
					<Typography fontWeight={700} variant="text18" pr={10}>
						{t("signUp.moreInfo.fundAccountInfo")}
					</Typography>
					<Typography
						fontWeight={700}
						variant="text18"
						color={theme.palette.text.secondary}>
						{t("signUp.moreInfo.fundAccountInfoExplained")}
					</Typography>
				</StackRow>

				{/* Account Number */}
				<Grid container alignItems="center" wrap="nowrap">
					<Grid item xs={1} mr={23}>
						<InputLabel>{t("signUp.moreInfo.accountNumber")}</InputLabel>
					</Grid>

					<Grid item container wrap="nowrap">
						<Grid item pr={14}>
							<FormInputSelect
								width={213}
								height={40}
								variant="text15"
								fontWeight={500}
								control={control}
								name="bankName"
								setValue={setValue}
								options={OPTIONS}
								placeholder={t("signUp.moreInfo.bankChoice")}
							/>
						</Grid>
						<Grid item xs>
							<FormInputText
								name="bankAccountNumber"
								control={control}
								placeholder={t("signUp.moreInfo.accountNumberPlaceholder")}
							/>
						</Grid>
					</Grid>
				</Grid>

				{/* Email */}
				<Grid container alignItems="center">
					<Grid item xs={1} mr={14}>
						<InputLabel>{t("login.email")}</InputLabel>
					</Grid>

					<Grid item xs>
						<FormInputText
							name="taxInvoiceEmail"
							control={control}
							placeholder={t("signUp.moreInfo.emailPlaceholder")}
						/>
					</Grid>
				</Grid>

				{/* BankBookCopy */}
				<Grid container alignItems="center">
					<Grid item xs={1} mr={14}>
						<InputLabel>{t("signUp.moreInfo.bankBookCopy")}</InputLabel>
					</Grid>
					<Grid item xs={4} pr={14}>
						<PreviewBox
							component="label"
							htmlFor="bankBookCopy"
							sx={{
								borderColor: errors.bankBookCopy && theme.palette.error.main,
								display: "block"
							}}>
							<input
								type="file"
								id="bankBookCopy"
								accept="image/*,application/pdf"
								hidden
								{...register("bankBookCopy")}
							/>
							{(watchBankBookCopy && watchBankBookCopy[0]?.name) ||
								t("fileName")}
						</PreviewBox>
						{errors.bankBookCopy && (
							<ErrorMessage>{errors?.bankBookCopy.message}</ErrorMessage>
						)}
					</Grid>
					<Button color={SECONDARY} htmlFor="bankBookCopy" component="label">
						{t("Upload File")}
					</Button>
				</Grid>
			</Stack>
		</>
	);
};

export default AdditionalInfo;
