import { Typography, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { formatYearMonthDay } from "utils/formatDate";
import { PRIMARY } from "components/layout/Button";
import { StackRow } from "components/shared";

export function FilterStatus({ total, dateFilterStart, dateFilterEnd }) {
	const { t } = useTranslation();

	return (
		<StackRow
			maxWidth="max-content"
			mb={20}
			gap={10}
			justifyContent="space-between">
			<Typography>{t("shipping.fundingListHits")}</Typography>

			<Typography color={PRIMARY}>
				{total} {t("cases")}
			</Typography>

			{dateFilterStart && dateFilterEnd && (
				<>
					<Divider orientation="vertical" variant="middle" flexItem />
					<Typography color="GrayText">
						{formatYearMonthDay(dateFilterStart)} ~{" "}
						{formatYearMonthDay(dateFilterEnd)}
					</Typography>
				</>
			)}
		</StackRow>
	);
}

export function FilterStatusWithHookForm(props) {
	const { watch } = useFormContext();

	const [dateFilterStart, dateFilterEnd] = watch([
		"dateFilterStart",
		"dateFilterEnd"
	]);

	return (
		<FilterStatus
			{...props}
			dateFilterEnd={dateFilterEnd}
			dateFilterStart={dateFilterStart}
		/>
	);
}
