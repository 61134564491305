import { generatePath, useNavigate } from "react-router-dom";

import DataTable from "components/table/DataTable";
import { ROUTE_OWNER_DETAILS } from "data/routeVariables";

import useColumns from "./useColumns";
import { saveOwnerData } from "redux/modules";
import { useDispatch } from "react-redux";
import { ownerApi } from "services/modules/owner";

export default function useBody(filters, page) {
	const result = ownerApi.useGetOwnerListQuery({
		currentPage: page,
		...filters
	});

	return [
		result,
		() => {
			const columns = useColumns();
			const dispatch = useDispatch();
			const navigate = useNavigate();

			const rows = result?.data?.response?.data || [];

			const goToOwnerDetails = (rowData) => {
				const ownerDetailsLink = generatePath(ROUTE_OWNER_DETAILS, {
					ownerId: rowData.row.id
				});
				dispatch(saveOwnerData({ ...rowData.row }));
				navigate(ownerDetailsLink);
			};

			const onRowsSelection = (ids) => {
				if (ids.length === 0) return;

				const selectedRows = ids.map((id) => rows.find((row) => row.id === id));
				dispatch(saveOwnerData(selectedRows[0]));
			};

			return (
				<DataTable
					columns={columns}
					rows={rows}
					isLoading={result.isFetching}
					onRowClick={goToOwnerDetails}
					isRenderCheckBox={true}
					onRowsSelection={onRowsSelection}
				/>
			);
		}
	];
}
