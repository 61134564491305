export const translationEn = {
	fileName: "File Name",
	KRW: "Won",
	otpSendingError: "OTP sending error!",
	errorSendingEmail: "Error Sending Email",
	verificationSuccess: "Verification Success",
	invalidOtp: "Invalid OTP!",
	cannotVerifyOtp: "Cannot Verify OTP",
	DaysAgo: "days ago",
	today: "Today",
	cases: "cases",
	common: {
		fundingCode: "Funding Code",
		fundingName: "Funding Name",
		partnerCode: "Partner Code",
		partnerMission: "Partner Mission",
		productAmount: "Product Amount",
		investmentQuantity: "Investment Quantity",
		totalInvestmentAmount: "Total Investment Amount",
		investmentDate: "Investment Date"
	},
	alertMessage: {
		somethingWentWrong: "Oops! Something went wrong",
		noListData: "No results were found",
		noRequestListing: "No requests are made yet",
		auth: {
			loginSuccessful: "Login Successful",
			loginError: "Please try again later",
			logoutSuccessful: "Logout Successful",
			logoutUnsuccessful: "Logout Unsuccessful",
			signUpSuccessful: "Account created successfully",
			signUpError: "There was an error creating the account"
		},
		category: {
			postFail: "Unable to post",
			postSuccess: "Item successfully added",
			editSuccess: "Item successfully edited",
			fetchFail: "Unable to fetch listings",
			deleteSuccess: "Item Successfully deleted",
			deleteFail: "Unable to delete item",
			error: "Error",
			noData: "No Data"
		},
		funding: {
			raiseLimitSuccess: "Success! Please wait for confirmation",
			raiseLimitError: "Failed to raise limit.",
			preloadFail: "Data preloading Failed"
		},
		brand: {
			requestSuccess: "Success! please wait for confirmation",
			requestFail: "Brand request is not available right now.",
			createSuccess: "Brand creation successful!",
			createFail: "Brand creation failed",
			editSuccess: "Brand edit successful",
			editFail: "Brand edit failed",
			brandRejected: "Brand rejected",
			deleteSuccess: "Brand deletion successful!",
			deleteFail: "Brand deletion failed"
		},
		partner: {
			messageSendSuccess: "Message successfully sent",
			messageSendFail: "Message didn't send.Try again"
		}
	},

	login: {
		header: "Login to your account",
		email: "Email",
		password: "Login",
		fillPassword: "Password",
		id: "ID",
		joinPartner: "Join Partner",
		forgotPassword: "Forgot Password",
		eightOrMore:
			"8 or more characters including numbers, English, and special symbols"
	},

	signUp: {
		becomePartner: "Become a partner",
		essentialInfo: " | Essential information",
		accountInfo: "Account Information",
		companyInfo: "Company Information",
		terms: "Terms of Service",
		email: "Email",
		enterCode: "Please enter your verification code",
		sendVerification: "Send verification code",
		certificationNumber: "Certification Number",
		password: "Password",
		eightOrMore:
			"8 or more characters including numbers, English, and special symbols",
		rePassword: "Re-enter password",
		businessName: "Business Name",
		businessNumber: "Business Number",
		name: "Name",
		contact: "Contact",
		pleaseEnter: "Please enter",
		mutual: "Mutual",
		enterBusinessName: "Please enter your business name",
		enterMutualName: "Please enter mutual name",
		representative: "Representative",
		enterRepresentativeName: "Please enter representative name",
		representativeContact: "Representative contact",
		manager: "Manager",
		contactName: "Contact name",
		enterContactName: "Please enter the contact person's name",
		contactPerson: "Contact person",
		contactNumber: "Contact number",
		companyPhoneNumber: "Company phone number",
		companyAddress: "Company address",
		detailedAddress: "Detailed Address",
		done: "Completion",
		findPostalCode: "Find postal code",
		enterAddress: "Please enter your address",
		enterDetailAddress: "Please enter your detailed address",
		taxEmail: "Tax invoice email",
		enterTaxEmail: "Please enter your email address for tax invoice",
		bankAccountNumber: "Bank account number",
		enterBankAccountNumber: "Please enter your bank account number only",
		passportCopy: "Copy of passbook",
		attached: "Only PDF, JPG, PNG can be attached",
		attachment: "Attachment",
		companyProfile: "Company profile",
		onlyPDFAttached: "Only PDF can be attached",
		businessRegistration: "Business Registration",
		serviceAgreement: "Service agreement",
		termAndCondition: "Owner's Terms and Conditions service subscription",
		disagree: "I do not agree",
		agree: "I agree",
		supplierResponsibilities: "Supplier Responsibilities",
		cancel: "Cancel",
		signUp: "Sign up",
		emailWillBeSent: "A sign up email will be sent to your interest contact",
		findYourId: "Find your ID",
		copyOfBankbook: "Copy of Bankbook",
		financialStatement: "Financial Statement",
		moreInfo: {
			title: "Become a partner",
			subTitle: "| More info (optional)",
			companyDetails: "Company Details",
			companyDetailsExplained: "| Information presented to the user.",
			companyStartDate: "Date of company establishment",
			turnover: "3 year turnover",
			turnoverExample1: "2022",
			turnoverExample2: "2021",
			turnoverExample3: "2020",
			aboutUs: "About Us",
			aboutUsPlaceholder: "Please enter a company profile",
			fundAccountInfo: "Account Information",
			fundAccountInfoExplained:
				"| Required information for funding registration.",
			accountNumber: "Account number",
			bankChoice: "Choose a bank",
			accountNumberPlaceholder:
				"Please enter only numbers for your bank account",
			emailPlaceholder: "Please enter an email to receive your tax invoice",
			bankBookCopy: "Copy of bankbook"
		}
	},

	navbar: {
		faq: "FAQ",
		notice: "Notice",
		help: "Help",
		logout: "Logout"
	},

	sidebar: {
		dashboard: "Dashboard",
		funding: "Funding Management",
		trade: "Trade Management",
		inventoryOrder: "Inventory/Order Management",
		deliveryReceipt: "Delivery/Receipt Management",
		inventory: "Inventory Management",
		shipmentRequest: "Shipping Request (Shipment request)",
		shippingRequest: "Shipping Request",
		shipping: "Shipping Management",
		warehouse: "Warehouse Management",
		customer: "Customer Management",
		recommendation: "Recommendation Management",
		investment: "Investment Review Management",
		timeline: "Timeline Management",
		profit: "Profit Calculation",
		businessInfo: "Business Information",
		category: "Category",
		brands: "Brands",
		partner: "Partners",
		requestedBrands: "Requested Brands",
		brandList: "Brand List",
		subCategory: "Sub Category",
		subSubCategory: "Sub-Sub Category",
		setting: "Setting",
		inquiry: "1:1 Inquiry",
		owner: "Owner",
		settlement: "Settlement Management",
		review: "Review Management",
		reviewArticle: "Review Article",
		reviewReport: "Report",
		advertising: "Ads Management",
		bannerManagement: "Pro Banner Management",
		couponManagement: "Manage Coupons",
		mileageManagement: "Mileage Management",
		operation: "Operation",
		adminSetting: "Admin Settings",
		brandingManagement: "Branding Management",
		faqManagement: "FAQ Management",
		qnaManagement: "Manage Q/A"
	},

	filter: {
		searchPlaceholder: "Search by product code, product name, brand name",
		selectPlaceholder: "Select Period",
		searchBtn: "Search"
	},

	tableFilter: {
		title: "Inventory",
		myOrder: "My order",
		customerRequest: "Customer Request",
		all: "All products",
		shippingReq: "Shipping Request",
		shipping: "Shipping",
		delivered: "Delivered"
	},

	validationMsg: {
		validEmail: "Provide a valid email address",
		emailRequired: "Email is required",
		pwRequired: "Password is required",
		imgRequired: "Image is required",
		categoryRequired: "Category name is required",
		appRequired: "Please add application to upload",
		fieldRequired: "This field is required",
		endDateError: "This date can't be before started date",
		missingCategory: "Please Add Category",
		mustBePositive: "Input must be a positive number",
		requiredSource: "Please Enter Source",
		requiredURL: "Please Enter URL",
		insertFundingNameFirst: "Please insert funding name first",
		insertImage: "* Please insert at least one image",
		atLeast1000: "At least 1000",
		postalCodeDigits: "Postal code should be 5 digits"
	},

	signUpValidationMsg: {
		emailRequired: "Email is required",
		mustBeEmail: "Email must be a valid email address",
		pwRequired: "Password is required",
		unmatchPasswords: "Passwords must match",
		certificationNumber: "Certification number is required",
		businessRegistration: "Registration number is required",
		mutual: "Mutual number is required",
		representativeName: "Representative Name is required",
		representativeContact: "Representative Contact is required",
		representativeCountryCode: "Phone Number is required",
		contactName: "Contact Name is required",
		contactCountryCode: "Phone Number is required",
		companyPostCode: "Company address is required",
		companyAddress: "Company Address is required",
		companyDetailedAddress: "Detailed Company Address is required",
		taxEmail: "Email must be a valid email address",
		bankAccountNumber: "Bank account number is required",
		passportCopy: "Please select a file",
		serviceAgreement: "Service Agreement is required",
		supplierResponsibilities: "Supplier Responsibilities is required"
	},

	table: {
		actions: "Actions",
		status: "Status"
	},

	toolTips: {
		toEdit: "To Edit",
		toComment: "To Comment",
		investmentReviewManagement: "Investment Review Management",
		secondaryDocumentRegistration: "Secondary document registration",
		copy: "Copy",
		delete: "Delete"
	},

	funding: {
		agreeToTOC: "Agree to terms and conditions",
		summary: "Summary",
		searchBoxPlaceholder:
			"Search for funding code, funding name, brand name, partner company code, and partner company name",
		fundingStartDate: "Funding Start Date",
		allChangesSaved: "All changes have been saved.",
		info: "Funding Information",
		productInfo: "Product Information",
		create: "Create",
		emptyPage: {
			create: "Start registering a new funding",
			description1: "Register your funding",
			description2: "Recruit Owners",
			noSearchResult1: "No search result",
			noSearchResult2: "Try again"
		},
		stepIndicator: {
			step1: {
				newFundingRegistration: "New Funding Registration",
				title: "Accept the terms and conditions (required)",
				details:
					"Please check the terms and conditions required for funding registration."
			},
			step2: {
				title: "Funding details (required)",
				details:
					"Please enter information about funding recruitment / product / sales period."
			},
			step3: {
				title: "Additional Information (Secondary Additional Registration)",
				details:
					"Please submit additional documents required for funding and settlement."
			}
		},
		createProduct: {
			registerNew: "Register for new Fund",
			editFundInfo: "Edit Funding Information",
			terms: "Terms and Conditions",
			responsibilities: "Partner Responsibilities",
			fundingFee: "Funding Fee"
		},
		createProduct2: {
			basicInfo: {
				title: "Basic Information",
				fundingName: "Funding Name",
				productName: "Product Name",
				brand: "Brand",
				brandSearch: "Brand Search",
				registrationReq: "Request Brand"
			},
			investmentInfo: {
				title: "Investment Information",
				amount: "Amount",
				maxAmountRaised: "Maximum Amount Raised",
				raise: "Raise",
				quantity: "Quantity",
				unit: "Unit",
				settlement: "Settlement",
				settlementMainInfo: "Decision after consultation",
				settlementSubInfo:
					"(This is the amount that must be paid to the owner when one product is sold.)",
				A: "Settlement unit price (A)",
				B: "Shipping cost (B)",
				C: "Management fee (C)",
				ABC: "(A+B+C)",
				ABCdetails:
					"This is the amount that must be paid to the owner when one product is sold.",
				period: "Period",
				totalSettlementAmount: "Total Settlement Amount",
				singleQuantity: "Single quantity",
				multiQuantity: "Multiple quantity",
				domestic: "Domestic",
				oversea: "Oversea",
				expected: "Expected",
				repurchasePrice: "Repurchase price",
				repurchaseRate: "Repurchase rate",
				agree: "Agree terms of service is required",
				termsDetails:
					"If the product is not sold within the sales deadline, 80% of the unit price of the product will be repurchased at the price."
			},
			productInfo: {
				title: "Product Information",
				productImg: "Product Image",
				productImgNotice:
					"Please upload more than one image. 1000x1000 format, 3MB, recommended image, jpg, jpeg",
				description: "Description",
				category: "Category",
				origin: "Origin",
				tagForSearch: "Tag for search",
				tagPlaceholder: "Add Tag",
				tagHelperText:
					"After entering a tag, add a comma (,) or enter. (up to 10)",
				registerAsImg: "Register as image",
				registerAsDescription: "Register as description"
			},
			salesSchedule: {
				title: "Sale Schedule",
				deliDate: "Expected delivery date",
				deliDateNotice: "We are going to launch a product.",
				salesStart: "Sales Start Date",
				salesStartNotice:
					"Automatic sales will start two weeks after the expected arrival date.",
				saleDeadline: "Sale Deadline",
				saleDeadlineNotice: "No more than 6 months from the start date of sale."
			},
			relatedInfo: {
				title: "Related Information",
				subTitle: "Please enter news related to the product.",
				info1: "Information 1",
				info2: "Information 2",
				info3: "Information 3",
				info4: "Information 4",
				source: "Source",
				url: "URL"
			},
			brandRequestDialog: {
				title: "Request Brand",
				message: "Please request the name of brand you would like to fund",
				brandNameKor: "Brand name (Korean)",
				brandNameEng: "Brand name (English)"
			},
			raiseLimitDialog: {
				title: "Raise Insurance Limit",
				message: "",
				raiseLimit: "Amount"
			}
		},
		createProduct3: {
			title: "Documents required to activate sales",
			description:
				"After the funding application is completed, the required documents must be submitted within 7 days. If the required documents are not registered within 7 days, the funding amount will be charged to the guarantee insurance. If there is a change in the schedule, please contact Circlemon 1:1 in the inquiry form.",
			helperText: "Attachment: PDF. Only PNG and JPG are available.",
			remittanceConfirmation: "Remittance Confirmation",
			creditLetter: "Credit Letter",
			waybill: "Waybill",
			imported: "Imported",
			transactionStatement: "Transaction Statement"
		},
		productList: {
			fundingCode: "$t(common.fundingCode)",
			fundingName: "Funding Name",
			fundingStatus: "Funding Status",
			fundingUnit: "Funding Unit",
			investmentEnd: "Sales Term",
			investmentStart: "Recruitment Term",
			modificationDate: "Modification Date",
			productUnitPrice: "Product Unit Price",
			registrationAndModificationDate: "Registration Date/Modification Date",
			registrationAndApprovedDate: "Registration Date/Approved Date",
			saleTerm: "Sales Term",
			settlementStatus: "Settlements Status",
			targetAmount: "Target Amount",
			targetAndRecruitmentAmount: "Target Amount / Recruitment Amount",
			targetAndSalesQuantity: "Target Quantity / Sales Quantity",
			wearingStatus: "Stocking Status"
		},
		alertMessages: {
			postDocumentSuccess: "Documents are posted",
			postDocumentFail: "Fail to post documents"
		},
		notice: "(Notice)",
		registerProduct: "Funding registration",
		fullDownload: "Full download",
		selectDownload: "Select download",
		approvalRequest: "Approval Request",
		recruitmentCompleted: "Recruitment completed",
		waitingForSale: "Waiting for sale",
		title: "Funding management",
		subTitle: "Please select the funding state you want to manage.",
		deselect: "Deselect",
		fullSelection: "Full Selection",
		fundingList: "Funding List",
		beforeWearing: "Before Stocking",
		payingOff: "Paying Off",
		all: "All",
		writing: "Writing",
		waitingForApproval: "Waiting for approval",
		recruitmentPlanned: "Recruitment planned",
		recruiting: "Recruiting",
		recruitmentFailure: "Recruitment Failure",
		sale: "Sale",
		stopShipping: "Stop Shipping",
		settlementCompleted: "Settlement Completed",
		orderStatus: "Order Status",
		cancelStatus: "Cancellation Status",
		statuses: {
			all: "All Funding List",
			creating: "Writing",
			pending: "Pending Approval",
			approved: "Recruiting",
			recruiting: "Recruiting",
			recruiting_complete: "Recruitment Complete",
			recruiting_fail: "Recruitment Failed",
			rejected: "Rejected",
			deadline: "Deadline",
			step_one_creating: "Step 1 Creating",
			step_two_creating: "Step 2 Creating",
			recruiting_success: "Recruiting Success",
			scheduled: "Scheduled",
			secondary_document: "Secondary Document",
			sale: "Sale",
			sold_out: "Sold Out",
			in_trade: "In trade",
			codes: {
				funding01: "Writing",
				funding02: "Pending Approval",
				funding03: "Scheduled to Open",
				funding04: "Recruiting",
				funding05: "Rejected",
				funding06: "Due Deadline",
				funding07: "Recruitment Successful",
				funding08: "Recruitment Fail",
				funding09: "Recruitment Complete",
				funding10: "Second Document",
				funding11: "Sale",
				funding12: "Sold Out",
				funding13: "Second Document Pending"
			}
		},
		dateFilter: {
			fundingStartDate: "Funding Start Date",
			investStartDate: "Invest Start Date",
			investEndDate: "Invest End Date",
			saleStartDate: "Sale Start Date",
			saleDeadlineDate: "Sale Deadline Date"
		}
	},

	recommendationManagement: {
		recommendationList: {
			recommendationList: "Recommendation List"
		}
	},

	owner: {
		ownerDetails: "User Details",
		memberList: "Member List",
		userCode: "User code",
		nickname: "Nickname",
		name: "Name",
		age: "Age",
		gender: "Gender",
		email: "Email",
		lastLogin: "Last Login",
		subscription: "Subscription",
		marketingConsent: "Marketing Consent",
		totalLogin: "Cumulative Login",
		totalInvestments: "Total Investments",
		totalTrades: "Total Trades",
		overallReturnRate: "Overall Return Rate",
		marketReturnRate: "Market Return Rate",
		tradeReturn: "Trade Return",
		loginToday: "Login Today",
		days7Login: "7 days Login",
		days28Login: "28th Login",
		withdrawalMember: "Withdrawal member",
		suspenseActivity: "Suspension of Activity",
		president: "President",
		ceo: "CEO",
		president1: "President",
		ratingSetting: "Rating Setting",
		subscriptionStatus: "Subscription Status",
		totalMembersToday: "Total Members Today",
		today: "Today",
		last7Days: "Last 7 Days",
		last28Days: "Last 28 Days",
		accountNumber: "Account Number",
		cellPhone: "Phone",
		holdingAmount: "Holding Amount",
		mileage: "Mileage",
		investment: "Investment",
		trade: "Trade",
		ownership: "Ownership",
		accountDetails: "Account Details",
		oneOneRatioInquiry: "1:1 Inquiry",
		csManagement: "CS Management",
		gradeManagement: "Grade Management",
		mileageHistory: "Mileage History",
		suspensionOfActivity: "Suspension Of Activity",
		gradeChange: "Grade Change",
		mileagePayment: "Mileage Payment",
		sendMessage: "Send Message",
		rating: "Rating",
		moreInfo: "Details",
		promotionCriteria: "Promotion Criteria",
		chargeAmount: "Charge Amount",
		criteria1:
			"Cumulative measurement for one year from the date of rating change",
		criteria2: "Upgrade when two criteria are met",
		active: "active",
		inactive: "inactive"
	},

	shipping: {
		header: "Shipping Management",
		downloadRequestForm: "Download Request Form",
		registerAddress: "Register Shipping Address",
		onSale: "On sale",
		soldOut: "Sold out",
		exportCompleted: "Export completed",
		all: "All",
		fullFundingList: "All",
		available: "Available",
		paid: "Paid",
		deliveryRequest: "Delivery request",
		confirmRequest: "Confirm request",
		shipping: "Shipping",
		completed: "Completed",
		unitPrice: "Unit Price",
		shippingFee: "Shipping Fees",
		adminFee: "Admin Fees",
		payNotice:
			"After selecting the quantity of products to request delivery, check the settlement amount.",
		quantity: "Quantity",
		totalSettlementAmount: "Total Settlement Amount",
		payBtn: "Confirm Payment",
		cancelSelectedShipping: "Cancel Selected Shipping Request",
		fundingListHits: "Funding list hits",
		cases: "cases",
		tableHeaders: {
			division: "Division",
			fundingCode: "$t(common.fundingCode)",
			fundingName: "Funding Name",
			shippingStatus: "Shipping Status",
			shippingCode: "Shipping Code",
			recipient: "Recipient",
			address: "Address",
			phone: "Phone Number",
			invoiceEntryDate: "Invoice Entry Date",
			invoiceNumber: "Invoice Number",
			courier: "Courier",
			productCode: "Product Code",
			deliveryStatus: "Delivery Status",
			partnerCode: "Partner Code",
			partnerName: "Partner Name"
		},
		summary: {
			inventoryStatus: "Inventory Status",
			numOfCompanies: "Number of Companies",
			subject: "Subject",
			productQuantity: "Product Quantity",
			availableForSale: "Available For Sale",
			completePayment: "Completed Payment",
			allProducts: "All products",
			deliveryRequest: "Delivery Request",
			confirmRequest: "Confirm Request",
			shipping: "shipping",
			deliveryCompleted: "Delivery Completed"
		},
		card: {
			sale: "Sale",
			soldOut: "Sold out",
			endOfSale: "End of sale",
			productList: "Product List",
			deliveryStatus: "Delivery Status",
			orderPayment: "Order payment",
			shipping: "Shipping notes",
			settlementUnitPrice: "Settlement Unit Price",
			salePeriod: "Sale Period"
		},
		exportRequest: {
			header: "Export Request",
			exportOptions: "Export Options",
			selfPickup: "Self Pick Up",
			deliver: "Delivery(Quick) C.O.D",
			manager: "manager",
			address: "Address",
			phone: "Phone Number",
			contact1: "Contact 1",
			contact2: "Contact 2",
			findPostalCode: "Find Postal Code",
			shippingNotes: "Shipping Notes"
		},
		shippingInfo: {
			header: "Shipping information | Register at once",
			instruction: "Please upload an Excel file with shipping information.",
			shippingRequestForm: "Shipping Request Form",
			enterShippingInfo: "Shipping Information",
			downloadShippingForm: "Download Shipping Form",
			fileUpload: "File Upload"
		},
		requestComplete: {
			header: "Shipping Information",
			context:
				"Your delivery request has been completed. You can check the delivery status of the requested product in Delivery Management.",
			manageShipment: "Manage Shipments",
			viewRequestHistory: "View Request History"
		},
		individualProduct: {
			header: "Shipping information | Enter individual products",
			productList: "Product List",
			receiver: "Recipient",
			add: "+ add",
			enterName: "Enter your name",
			enterNumber: "Enter numbers only"
		},
		importMethod: {
			message: "Please select a method for entering shipping information",
			firstBtn: "Register at once as an excel file",
			secondBtn: "Enter individual products"
		}
	},

	inventoryManagement: {
		onSale: "On sale",
		salesCompleted: "Sales completed",
		fullFunding: "Full Funding",
		wearingInspection: "Stocking Inspection",
		summary: {
			inventoryStatus: "Inventory Status",
			numOfCompanies: "Number of Companies",
			item: "Item",
			commodityQuantity: "Commodity Quantity",
			availableForSale: "Available for sale",
			paymentCompleted: "Payment completed",
			wearingQuantity: "Stocking Quantity",
			exportQuantity: "Export Quantity",
			shippingDelivery: "Shipping Delivery"
		},
		tableHeaders: {
			fundingCode: "$t(common.fundingCode)",
			fundingName: "Funding Name",
			partnerCompanyCode: "Partner Company Code",
			partnerMission: "Partner Mission",
			salesTerm: "Sales Term",
			fluctuation: "Fluctuation",
			afterFluctuation: "After fluctuation",
			content: "Content",
			changeDate: "Change Date"
		}
	},

	category: {
		header: "Category",
		tableHeader: "Category Name",
		createCategory: "Create Category",
		editCategory: "Edit Category",
		deleteCategory: "Delete Category",
		deleteMessage: "Are you sure you want to delete is category?"
	},

	subCategory: {
		header: "Sub-Category",
		tableHeader: "Sub-Category Name"
	},

	subSubCategory: {
		header: "Sub-Sub-Category",
		tableHeader: "Sub-Sub-Category Name"
	},

	brand: {
		header: "Brand",
		koreanName: "Korea Name",
		englishName: "English Name",
		editBrand: "Edit Brand",
		deleteBrand: "Delete Brand",
		deleteMessage: "Are you sure you want to delete this brand?",
		rejectReasonPlaceholder: "Please provide reason of rejection",
		createBrand: {
			title: "Create Brand",
			button: "Create Brand",
			message: "",
			brandNameKor: "Brand name (Korean)",
			brandNameEng: "Brand name (English)",
			description: "Description",
			photo: "Photo",
			rejectDialogMessage: "Are you sure you want to reject this brand?",
			deleteDialogMessage: "Are you sure you want to delete this brand?"
		}
	},

	partner: {
		header: "Partner management",
		partnerList: "Partner List",
		division: "Division",
		code: "Code",
		companyName: "Company Name",
		businessNumber: "Business Number",
		address: "Address",
		zipCode: "Zip Code",
		manager: "Manager",
		contactPerson: "Contact Person",
		contactEmail: "Contact Email",
		insuranceAmount: "Insurance Amount",
		insuranceExpireDate: "Insurance Expire Date",
		insuranceNumber: "Insurance Number",
		numOfInvestments: "Cumulative Funding",
		since: "Since",
		approvalDate: "Approval Date",
		searchBoxPlaceholder:
			"Enter the partner company code, partner company name, representative name, manager name, contact number, and email",
		accountHolder: "Account holder",
		accountNumber: "Account number",
		bank: "Bank",
		sendMessage: "Send message",
		toList: "To List",
		postalCode: "Postal Code",
		summary: {
			activityStatus: "Activity Status",
			allPartners: "All Partners",
			subscriptionApproval: "Approval Requested",
			subscriptionApproved: "Approved",
			subscriptionPending: "Pending",
			inActive: "InActive",
			insuranceExpired: "Insurance Expired",
			partnerStatus: "Partner Status"
		},
		message: {
			subject: "Subject",
			content: "Content",
			subjectPlaceholder: "Please Insert Message Subject",
			contentPlaceholder: "Please Insert Message Content",
			sendTo: "Send to",
			message: "Message",
			mail: "Mail",
			kakao: "KakaoTalk",
			writeMessage: "Write a message",
			recipient: "Recipient"
		},
		profile: {
			header: "Profile",
			recently: "Recently",
			companyName: "Kanadara Co., Ltd.",
			companyIntroduction: "Company Introduction",
			dateOfEstablishment: "Date of Establishment",
			approveSubscription: "Approve Subscription",
			stopActivity: "Stop Activity",
			lastLogin: "Last Login",
			subscriptionDate: "Since",
			subscriptionApproveDate: "Subscription Approved",
			noDescription: "Company description is not added yet"
		},
		dialog: {
			successMessage: "Owner, congratulations on joining the partnership.",
			codeSentMessage:
				"The verification code has been sent to your email. Please check your email."
		}
	},

	setting: {
		header: "Setting",
		uploadApplication: "Upload Application",
		downloadApplication: "Download Application"
	},

	receipt: {
		wearingStatus: "Stocking Status",
		applicationDate: "Application Date",
		fundingCode: "$t(common.fundingCode)",
		fundingName: "Funding Name",
		salesStartDate: "Sales Start Date",
		partnerCompanyCode: "Partner Company Code",
		partnerMission: "Partner Mission",
		fundingRecruitmentQuantity: "Funding Recruitment Quantity",
		incomingApplicationQuantity: "Incoming Application Quantity",
		stockQuantity: "Stock Quantity",
		wearingFinishedQuantity: "Stocking Finished Quantity",
		companionGoods: "Companion Goods",
		stockQuantityAfterReceipt: "Stock quantity after receipt"
	},
	nSearchResults: "{{count}} search results",
	promptCancelNShippingRequest:
		"Would you like to cancel the shipping request for the {{count}} selected items?",
	shipping_: "shipping"
};
