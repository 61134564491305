import { Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { BasePlate } from "components/layout";
import { StackRow } from "components/shared";
import { Stats, StatsName } from "pages/partner/style";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const SummaryBox = ({ summary = {} }) => {
	const { t } = useTranslation();

	const {
		availableForSale,
		completePayment,
		item,
		partnerCompany,
		productQuantity
	} = summary;

	const summaryData = [
		[
			{
				statName: t("Partner Company"),
				stat: partnerCompany || "0"
			},
			{
				statName: t("Item"),
				stat: item || "0"
			}
		],
		[
			{
				statName: t("Product Quantity"),
				stat: productQuantity || "0"
			},
			{
				statName: t("Available for sale"),
				stat: availableForSale || "0"
			},
			{
				statName: t("Complete payment"),
				stat: completePayment || "0"
			}
		]
	];

	const CustomStack = (props) => {
		const { statName, stat, ...rest } = props;
		return (
			<Stack alignItems="center" gap={14} {...rest}>
				<StatsName sx={{ whiteSpace: "nowrap" }}>{statName}</StatsName>
				<Stats>{stat}</Stats>
			</Stack>
		);
	};

	return (
		<Stack mb={30}>
			<BasePlate sx={{ paddingY: 24, paddingX: 20, overflowX: "auto" }}>
				<StackRow gap={40} alignItems="center">
					<Typography
						sx={{ whiteSpace: "nowrap" }}
						variant="text17"
						fontWeight={700}>
						{t("Summary")}
					</Typography>
					{summaryData.map((summaryGroup, index) => (
						<Fragment key={`summaryGroup-${index}`}>
							<Divider orientation="vertical" variant="middle" flexItem />
							{summaryGroup.map(({ statName, stat }) => {
								return (
									<CustomStack key={statName} statName={statName} stat={stat} />
								);
							})}
						</Fragment>
					))}
				</StackRow>
			</BasePlate>
		</Stack>
	);
};

export default SummaryBox;
