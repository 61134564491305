import { api } from "services/api";
import login from "./login";
import logout from "./logout";
import signup from "./signup";

export const userApi = api.injectEndpoints({
	endpoints: (build) => ({
		login: login(build),
		logout: logout(build),
		signup: signup(build)
	}),
	overrideExisting: false
});

export const { useLoginMutation, useLogoutMutation, useSignupMutation } =
	userApi;
