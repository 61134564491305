import { API_FUNDING_ADMIN_LIST } from "services/endpoints";
import { TAG_FUNDING_ADMIN } from "services/tags";

export default (build) =>
	build.query({
		query: ({
			currentPage,
			perPage = 10,
			dateFilterType,
			dateFilterStart,
			dateFilterEnd,
			search,
			statusFilter
		}) => {
			const params = {
				page: currentPage,
				per_page: perPage,
				date_filter_type: dateFilterType,
				date_filter_start: dateFilterStart,
				date_filter_end: dateFilterEnd,
				q: search,
				filter: statusFilter
			};

			return {
				url: API_FUNDING_ADMIN_LIST,
				params,
				method: "GET"
			};
		},

		providesTags: [TAG_FUNDING_ADMIN]
	});
