import { API_LOGOUT } from "services/endpoints";

export default (build) =>
	build.mutation({
		query: () => {
			return {
				url: API_LOGOUT,
				method: "POST"
			};
		}
	});
