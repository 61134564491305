import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import { StackRow } from "components/shared";
import LabelText from "components/shared/LabelText";
import { Button, SECONDARY } from "components/layout/Button";
import theme from "theme/theme";
import { Blank } from "components/shared/blank";

const DataText = ({ children, ...rest }) => {
	return (
		<Typography fontWeight={400} fontSize={13} {...rest}>
			{children}
		</Typography>
	);
};

const PartnerBar = ({ data }) => {
	const { t } = useTranslation();
	const [isHovered, setHovered] = useState(false);
	const [isSelected, setSelected] = useState(false);

	return (
		<StackRow
			alignItems="center"
			gap={20}
			onMouseOver={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
			sx={{
				cursor: "pointer",
				border: "2px solid",
				p: 8,
				borderRadius: 2,
				borderColor:
					isHovered || isSelected
						? theme.palette.primary.main
						: theme.palette.secondary.light
			}}>
			<Grid alignItems="center" container gap={20}>
				<Grid item>
					<Blank minWidth={40} height={40} />
				</Grid>
				<Grid item xs={1.5}>
					<LabelText fontSize={15}>{data.team.code || "?"}</LabelText>
				</Grid>
				<Grid item xs>
					<Typography fontWeight={700} fontSize={15}>
						주식회사 가나다라
					</Typography>
				</Grid>
				<Grid item xs>
					<DataText>
						{t("Representative Name")} : {data.team.representativeName || "?"}
					</DataText>
				</Grid>
				<Grid item xs>
					<DataText>
						{t("Representative Contact")} :{" "}
						{data.team.representativeContact || "?"}
					</DataText>
				</Grid>
				<Grid item xs>
					<DataText>
						{t("Contact Person")} : {data.team.contactName || "?"}
					</DataText>
				</Grid>
				<Grid item xs>
					<DataText>
						{t("Contact Number")} : {data.team.contactNumber || "?"}
					</DataText>
				</Grid>
			</Grid>
			<Button
				onClick={() => setSelected(true)}
				sx={{ visibility: !isHovered && "hidden" }}
				color={SECONDARY}>
				{t("Select")}
			</Button>
		</StackRow>
	);
};

export default PartnerBar;
