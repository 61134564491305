import { API_FUNDING_STATUES } from "services/endpoints";
import { TAG_FUNDING } from "services/tags";

export default (build) => {
	return build.query({
		query: () => {
			return {
				url: API_FUNDING_STATUES,
				method: "GET"
			};
		},
		transformResponse(rawResponse) {
			const statuses = rawResponse.response?.data || [];
			const totalCount = statuses.reduce((init, { count }) => init + count, 0);

			return [
				{
					status: "all",
					display: "All",
					count: totalCount
				}
			].concat(statuses);
		},
		providesTags: [TAG_FUNDING]
	});
};

export const STATUS_APPROVAL = "approved";

export const STATUS_PENDING = "pending";

export const STATUS_WRITING = "writing";

export const STATUS_SECOND_DOCUMENT_CREATED = "second_document_pending";
