import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import HelpIcon from "@mui/icons-material/Help";
import { useState } from "react";

import { StackRow } from "components/shared";
import backIcon from "assets/images/back.png";
import { primaryBorder, primaryShadow } from "data/styleVariables";
import spreadsheetWhiteIcon from "assets/images/spreadsheet-white.png";
import spreadsheetGrayIcon from "assets/images/spreadsheet-gray.png";

export const PRIMARY = "primary";
export const SECONDARY = "secondary";
export const WHITE = "white";
export const GRAY = "gray";
export const WEAK_BLACK = "weakBlack";
export const BLACK = "black";
export const WEAK_GRAY = "weakGray";
export const ERROR = "error";

export const Button = styled(
	({ selected, color, disabled = false, isLoading, sx, ...rest }) => {
		return (
			<LoadingButton
				loading={isLoading}
				variant="contained"
				color={!disabled ? (selected ? PRIMARY : color ? color : WHITE) : GRAY}
				disabled={disabled}
				sx={sx}
				{...rest}
			/>
		);
	}
)({
	borderRadius: 6,
	border: primaryBorder,
	boxShadow: primaryShadow,
	fontSize: 15,
	textTransform: "none",
	whiteSpace: "nowrap",
	minWidth: "max-content"
});

export const BackButton = ({ onClick, btnText }) => {
	return (
		<StackRow
			gap={10}
			sx={{ opacity: 0.6, cursor: "pointer", userSelect: "none" }}
			alignItems="baseline"
			pb={10}
			onClick={onClick}>
			<img src={backIcon} />
			<Typography fontSize={20}>{btnText}</Typography>
		</StackRow>
	);
};

export const ImplicitLoadingButton = ({ onClick, ...props }) => {
	const [loading, setLoading] = useState(false);

	return (
		<Button
			isLoading={loading}
			onClick={async () => {
				try {
					setLoading(true);
					return await onClick();
				} finally {
					setLoading(false);
				}
			}}
			{...props}
		/>
	);
};

export const FullDownloadButton = ({ onClick, buttonText }) => {
	const { t } = useTranslation();

	return (
		<ImplicitLoadingButton
			color={SECONDARY}
			onClick={onClick}
			startIcon={
				<img src={spreadsheetWhiteIcon} alt="spreadsheet icon white" />
			}>
			{t(buttonText || "funding.fullDownload")}
		</ImplicitLoadingButton>
	);
};

export const SelectedDownloadButton = ({ onClick, buttonText }) => {
	const { t } = useTranslation();

	return (
		<ImplicitLoadingButton
			onClick={onClick}
			startIcon={<img src={spreadsheetGrayIcon} alt="spreadsheet icon gray" />}>
			{t(buttonText || "funding.selectDownload")}
		</ImplicitLoadingButton>
	);
};

export function SelectedOrAllDownloadButtons({
	handleFullDownload = () => {},
	handleSelectedDownload = () => {},
	wrapperProps
}) {
	return (
		<StackRow gap={13} {...wrapperProps}>
			<FullDownloadButton onClick={handleFullDownload} />
			<SelectedDownloadButton onClick={handleSelectedDownload} />
		</StackRow>
	);
}

export function HelpWithIcon() {
	const { t } = useTranslation();

	return (
		<StackRow gap={5} alignItems="center">
			<Typography color="text.tertiary">{t("navbar.help")}</Typography>
			<HelpIcon
				sx={{ height: "19px", width: "19px", color: "text.tertiary" }}
			/>
		</StackRow>
	);
}
