import { API_PARTNER_APPROVAL } from "services/endpoints";
import { TAG_PARTNER } from "services/tags";

export default (build) =>
	build.mutation({
		query: ({
			teamId,
			insuranceAmount,
			insuranceNumber,
			insuranceStartDate,
			insuranceExpireDate,
			status
		}) => {
			return {
				url: API_PARTNER_APPROVAL,
				method: "POST",
				data: {
					team_id: teamId,
					/* cSpell:disable */
					warrenty_insurance_amount: insuranceAmount || "",
					warrenty_insurance_number: insuranceNumber || "",
					warrenty_insurance_start: insuranceStartDate || "",
					warrenty_insurance_end: insuranceExpireDate || "",
					/* cSpell:enable */
					status: status
				}
			};
		},
		invalidatesTags: [TAG_PARTNER]
	});
