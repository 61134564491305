import { Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import ErrorMessage from "./ErrorMessage";
import { Stack } from "@mui/material";
import { parse } from "utils/formatDate";

export const FormInputDate = ({
	name,
	control,
	styleProps,
	textFieldProps,
	useParse = true
}) => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => {
				const value = parse(field.value);

				return (
					<Stack>
						<TextField
							type="date"
							size="small"
							error={!!error}
							variant="outlined"
							InputProps={{ style: { ...styleProps } }}
							{...field}
							{...textFieldProps}
							value={useParse ? value : field.value || ""}
						/>
						{error?.message && <ErrorMessage>{error.message}</ErrorMessage>}
					</Stack>
				);
			}}
		/>
	);
};
