export const toTitleCase = (str) => {
	var [first] = str;
	first = first.toUpperCase();

	return `${first}${str.slice(1)}`;
};

export const camelToSnakeCase = (key) => {
	return key
		.split(/(?=[A-Z])/)
		.join("_")
		.toLowerCase();
};

export const camelToSnakeCasedObjectKeys = (iterable = {}) => {
	return Object.keys(iterable).reduce((init, key) => {
		init[camelToSnakeCase(key)] = iterable[key];

		return init;
	}, {});
};

export const capitalizeFirstLetter = (letter) => {
	const str1 = letter.slice(1);
	const result = letter.charAt(0).toUpperCase() + str1;
	return result;
};

export function snakeToTitleCase(str) {
	return str.replace(/_/g, " ").replace(/(?:^|\s)\w/g, function (match) {
		return match.toUpperCase();
	});
}
