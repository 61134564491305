import { api } from "services/api";
import {
	API_OWNER,
	API_OWNER_ACTIVITY_STATUS,
	API_OWNER_INVESTMENT,
	API_OWNER_SUBSCRIPTION_STATUS
} from "services/endpoints";
import { TAG_OWNER } from "services/tags";

export const ownerApi = api.injectEndpoints({
	endpoints(build) {
		return {
			getOwnerList: build.query({
				query: ({
					currentPage,
					perPage = 10,
					dateFilterType,
					dateFilterStart,
					dateFilterEnd,
					search
				}) => {
					return {
						url: API_OWNER,
						params: {
							page: currentPage,
							per_page: perPage,
							q: search,
							filterType: dateFilterType,
							start_date: dateFilterStart,
							end_date: dateFilterEnd
						},
						method: "GET"
					};
				},
				providesTags: [TAG_OWNER]
			}),

			getOwnerInvestmentList: build.query({
				query: ({ currentPage, perPage = 10, ownerId, fundingId }) => {
					return {
						url: API_OWNER_INVESTMENT,
						params: {
							page: currentPage,
							per_page: perPage,
							owner_id: ownerId,
							funding_ids: fundingId
						},
						method: "GET"
					};
				},
				providesTags: [TAG_OWNER]
			}),

			getOwnerActivityStatuses: build.query({
				query: () => {
					return {
						url: API_OWNER_ACTIVITY_STATUS,
						method: "GET"
					};
				},
				providesTags: [TAG_OWNER]
			}),

			getOwnerSubscriptionStatuses: build.query({
				query: () => {
					return {
						url: API_OWNER_SUBSCRIPTION_STATUS,
						method: "GET"
					};
				},
				providesTags: [TAG_OWNER]
			})
		};
	}
});
