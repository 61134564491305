import Postcode from "@actbase/react-daum-postcode";
import { Modal } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";

const PostCodeBox = styled(Box)(() => ({
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400
}));

export const PostCode = (props) => {
	const { onSelected, isPostCodeModalOpen, setPostCodeModalOpen } = props;
	return (
		<Modal
			open={isPostCodeModalOpen}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			onClose={() => setPostCodeModalOpen(false)}>
			<PostCodeBox>
				<Postcode
					jsOptions={{ animation: true }}
					onSelected={(data) => {
						onSelected(data);
						setPostCodeModalOpen(false);
					}}
				/>
			</PostCodeBox>
		</Modal>
	);
};
