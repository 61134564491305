import { API_DOC_DOWNLOAD } from "services/endpoints";
import { TAG_PARTNER } from "services/tags";

export default (build) =>
	build.query({
		query: ({ partnerId, docType }) => {
			return {
				url: API_DOC_DOWNLOAD,
				method: "GET",
				params: {
					partner_id: partnerId,
					doc_type: docType
				}
			};
		},
		providesTags: [TAG_PARTNER]
	});
