import { API_ORDER_PAYMENT } from "services/endpoints";
import { TAG_SHIPPING } from "services/tags";

export default (build) =>
	build.mutation({
		query: ({ shippingInfoId, currentCount, totalSettlementAmount }) => {
			return {
				url: API_ORDER_PAYMENT,
				method: "POST",
				data: {
					shipping_info_id: shippingInfoId,
					note: "Note Note Note",
					order_quantity: currentCount,
					total_settlement_amount: totalSettlementAmount
				}
			};
		},
		invalidatesTags: [TAG_SHIPPING]
	});
