import { Stack, Typography } from "@mui/material";
import { BasePlate } from "components/layout";
import {
	BackButton,
	Button,
	PRIMARY,
	SECONDARY
} from "components/layout/Button";
import KeywordOnlyFilter from "components/shared/list/keywordOnlyFilter";
import useFilter from "components/shared/list/useFilter";
import { MuiPagination } from "components/table/Pagination";
import { ROUTE_PARTNER_LIST } from "data/routeVariables";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetPartnersQuery } from "services/modules/partner";
import { useNumberSearchParams } from "utils/useNumbered";
import PartnerBar from "./PartnerBar";
import { StackRow } from "components/shared";
import { useTodo } from "utils/useTodo";
import BackDropLoader from "components/shared/BackDropLoader";

const ChoosePartner = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const todo = useTodo();

	const [filters, methods] = useFilter();

	const page = useNumberSearchParams();

	const { data } = useGetPartnersQuery({
		currentPage: page,
		...filters
	});

	const result = data?.response?.data || [];
	const meta = data?.response?.meta || [];

	return (
		<FormProvider {...methods}>
			<BackButton
				onClick={() => navigate(ROUTE_PARTNER_LIST)}
				btnText={t("To List")}
			/>
			<Typography component="h2" variant="h2" pb={24} lineHeight={1}>
				{t("Funding Details")}
			</Typography>

			<KeywordOnlyFilter
				width={500}
				searchPlaceholder={t("Search by company code and company name")}
			/>

			<BasePlate>
				{result.length === 0 ? (
					<BackDropLoader open={true} />
				) : (
					<Stack sx={{ gap: 10, px: 21, py: 18 }}>
						{result.map((data) => {
							return <PartnerBar key={data.id} data={data} />;
						})}
					</Stack>
				)}
			</BasePlate>
			{result.length !== 0 && <MuiPagination page={page} meta={meta} />}

			<StackRow justifyContent="flex-end" gap={15}>
				<Button sx={{ px: 62, py: 8 }} color={SECONDARY} onClick={todo}>
					{t("Cancellation")}
				</Button>
				<Button sx={{ px: 62, py: 8 }} color={PRIMARY} onClick={todo}>
					{t("Next")}
				</Button>
			</StackRow>
		</FormProvider>
	);
};

export default ChoosePartner;
