import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { closeDeleteBrandDialog, selectBrand, showAlert } from "redux/modules";
import { useDeleteBrandMutation } from "services/modules/brand";
import PrimaryAlertDialog from "../PrimaryAlertDialog";

const BrandDeleteDialog = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { brandId, isDeleteDialogOn } = useSelector(selectBrand);

	const [
		deleteBrand,
		{ isLoading: deleting, isSuccess: deleteSuccess, isError: deleteError }
	] = useDeleteBrandMutation();

	const triggerDeleteBrand = () => {
		deleteBrand(brandId);
	};

	useEffect(() => {
		deleteError &&
			dispatch(
				showAlert({
					severity: "error",
					message: t("alertMessage.brand.deleteFail")
				})
			);

		deleteSuccess &&
			dispatch(
				showAlert({
					severity: "success",
					message: t("alertMessage.brand.deleteSuccess")
				})
			);

		dispatch(closeDeleteBrandDialog());
	}, [deleteSuccess, deleteError]);

	return (
		<PrimaryAlertDialog
			isDialogOpen={isDeleteDialogOn}
			closeDialogFromState={closeDeleteBrandDialog}
			message={t("brand.createBrand.deleteDialogMessage")}
			firstBtn={t("Confirm")}
			firstAction={triggerDeleteBrand}
			isLoading={deleting}
		/>
	);
};

export default BrandDeleteDialog;
