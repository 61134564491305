import { Stack } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ImageUploading from "react-images-uploading";

import { Button, WEAK_GRAY } from "components/layout/Button";
import ErrorMessage from "components/formComponents/ErrorMessage";

import { ImageWrapper, UploadBtn } from "./style";

const photoSize = 95;

export const ArticleImgUpload = ({ name, images, control, names }) => {
	const { t } = useTranslation();
	const { trigger } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<ImageUploading
					value={images}
					onChange={(e) => {
						const img = e[0]?.data_url;
						field.onChange(img);
						trigger(names);
					}}
					dataURLKey="data_url"
					acceptType={["jpg", "png", "jpeg"]}>
					{({ imageList, onImageUpload, onImageRemove }) => {
						return (
							<Stack direction="row">
								{imageList.length === 0 ? (
									<Stack spacing={2} direction="column" maxWidth="100px">
										<Button
											color={WEAK_GRAY}
											sx={{
												width: photoSize,
												display: "flex",
												height: photoSize
											}}
											onClick={onImageUpload}>
											+
										</Button>
										<ErrorMessage>{error?.message}</ErrorMessage>
									</Stack>
								) : (
									imageList.map((image, idx) => (
										<ImageWrapper
											key={`image-${idx}`}
											width={photoSize}
											height={photoSize}>
											<img
												{...field}
												name={name}
												height="100%"
												width="100%"
												src={image.data_url || field.value}
												onClick={onImageUpload}
											/>
											<UploadBtn
												color="primary"
												onClick={() => {
													onImageRemove(idx);
												}}>
												{t("Delete")}
											</UploadBtn>
										</ImageWrapper>
									))
								)}
							</Stack>
						);
					}}
				</ImageUploading>
			)}
		/>
	);
};
