import { API_DOCUMENTS } from "services/endpoints";
import { TAG_PRODUCT, TAG_FUNDING, TAG_FUNDING_ADMIN } from "services/tags";
import { camelToSnakeCase } from "utils/string";

export default (build) =>
	build.mutation({
		query: ({ hasDocuments, ...values }) => {
			const data = Object.entries(values).reduce((init, [key, value]) => {
				init[camelToSnakeCase(key)] =
					value instanceof FileList ? value.item(0) : value;

				return init;
			}, {});

			const request = {
				data,
				url: API_DOCUMENTS,
				headers: { "content-type": "multipart/form-data" }
			};

			if (hasDocuments) {
				request.params = { funding_id: data.funding_id };
				request.method = "PUT";
			} else {
				request.method = "POST";
			}

			return request;
		},
		invalidatesTags: [TAG_PRODUCT, TAG_FUNDING, TAG_FUNDING_ADMIN]
	});
