import { api } from "services/api";
import brandCreate from "./brandCreate";
import brandEdit from "./brandEdit";
import deleteBrand from "./deleteBrand";
import getBrandRequestList from "./getBrandRequestList";
import getBrands from "./getBrands";
import requestBrand from "./requestBrand";
import requestedBrandStatus from "./requestedBrandStatus";

export const brandApi = api.injectEndpoints({
	endpoints: (build) => ({
		getBrands: getBrands(build),
		brandCreate: brandCreate(build),
		brandEdit: brandEdit(build),
		getBrandRequestList: getBrandRequestList(build),
		requestBrand: requestBrand(build),
		requestedBrandStatus: requestedBrandStatus(build),
		deleteBrand: deleteBrand(build)
	}),
	overrideExisting: false
});

export const {
	useGetBrandsQuery,
	useBrandCreateMutation,
	useBrandEditMutation,
	useGetBrandRequestListQuery,
	useRequestBrandMutation,
	useRequestedBrandStatusMutation,
	useDeleteBrandMutation
} = brandApi;
