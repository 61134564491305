import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { FormInputText } from "components/formComponents";
import { PreviewBox } from "components/formComponents/style";
import { Button, GRAY, PRIMARY, SECONDARY } from "components/layout/Button";
import { StackRow } from "components/shared";
import { termAndConditions } from "data/legalTerms";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InputLabel, ScrollableBox } from "../style";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorMessage from "components/formComponents/ErrorMessage";
import { PostCode } from "components/shared/PostCode";
import { useState } from "react";
import {
	useSendOTPRegisterMutation,
	useVerifyOTPMutation
} from "services/modules/partner";
import { useDispatch } from "react-redux";
import { showAlert } from "redux/modules";
import { useEffect } from "react";
import PrimaryAlertDialog from "components/dialogs/PrimaryAlertDialog";
import theme from "theme/theme";

const JoinPartners = ({ termsAccepted, setTermsAccepted, setOTPVerified }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [isPostCodeModalOpen, setPostCodeModalOpen] = useState(false);
	const [isCodeSentMsgDialogOpen, setCodeSentMsgDialogOpen] = useState(false);

	const {
		control,
		register,
		setValue,
		watch,
		formState: { errors }
	} = useFormContext();

	const verificationCodeValue = watch("verificationCode");
	const newEmailValue = watch("newEmail");
	const watchCompanyProfile = watch("companyProfile");
	const watchBusinessLicense = watch("businessLicense");

	const [
		sendOTP,
		{ data: sendOTPData, isLoading: sendingEmail, error: sendEmailError }
	] = useSendOTPRegisterMutation();

	const otpStatus = sendOTPData?.response.status;

	const [
		verifyOTP,
		{ data: verifyOTPData, isLoading: verifying, error: verifyError }
	] = useVerifyOTPMutation();

	const verificationStatus = verifyOTPData?.response.status;

	const sendOTPEmail = () => {
		sendOTP({ newEmail: newEmailValue });
	};

	const verifyOTPFunction = () => {
		verifyOTP({ newEmail: newEmailValue, otp: verificationCodeValue });
	};

	useEffect(() => {
		if (otpStatus) {
			setCodeSentMsgDialogOpen(true);
		} else if (sendOTPData && !otpStatus) {
			dispatch(
				showAlert({
					severity: "error",
					message: t("otpSendingError")
				})
			);
		}
		if (sendEmailError) {
			dispatch(
				showAlert({
					severity: "error",
					message:
						sendEmailError.status === 500
							? t("errorSendingEmail")
							: sendEmailError?.data.message
				})
			);
		}
	}, [otpStatus, sendEmailError]);

	useEffect(() => {
		if (verificationStatus) {
			setOTPVerified(true);
			dispatch(
				showAlert({
					severity: "success",
					message: t("verificationSuccess")
				})
			);
		} else if (verifyOTPData && !verificationStatus) {
			dispatch(
				showAlert({
					severity: "error",
					message: t("invalidOtp")
				})
			);
		}
		if (verifyError) {
			dispatch(
				showAlert({
					severity: "error",
					message:
						verifyError.status === 500
							? t("cannotVerifyOtp")
							: t(verifyError.data.message)
				})
			);
		}
	}, [verificationStatus, verifyError]);

	const renderTerms = (termsToRender) => {
		return termsToRender.map((term) => {
			return (
				<Box key={term.id} pb={22}>
					<Stack direction="row">
						<Typography fontSize={16} fontWeight={700} pb={8}>
							{term.id}.
						</Typography>
						<Typography fontSize={16} fontWeight={700}>
							{term.title}
						</Typography>
					</Stack>
					<Typography fontSize={16} fontWeight={500}>
						{term.content}
					</Typography>
				</Box>
			);
		});
	};

	const TermsOfService = () => {
		return (
			<Box sx={{ py: 32 }}>
				<Stack pb={32}>
					<Typography fontWeight={700} variant="text18" pb={20}>
						{t("signUp.terms")}
					</Typography>
					<ScrollableBox>
						{renderTerms(termAndConditions)}
						{/* Contents are dummy */}
					</ScrollableBox>
					<StackRow justifyContent="flex-end">
						<Button
							onClick={() =>
								setTermsAccepted({
									...termsAccepted,
									term1: !termsAccepted.term1
								})
							}
							color={termsAccepted.term1 === true ? PRIMARY : GRAY}>
							<StackRow gap={10}>
								<CheckCircleOutlineIcon sx={{ width: 14 }} />
								{t("Agree")}
							</StackRow>
						</Button>
					</StackRow>
				</Stack>
				<Stack>
					<Typography fontWeight={700} variant="text18" pb={20}>
						{t("signUp.terms")}
					</Typography>
					<ScrollableBox>
						{renderTerms(termAndConditions)}
						{/* Contents are dummy */}
					</ScrollableBox>
					<StackRow justifyContent="flex-end">
						<Button
							onClick={() =>
								setTermsAccepted({
									...termsAccepted,
									term2: !termsAccepted.term2
								})
							}
							color={termsAccepted.term2 === true ? PRIMARY : GRAY}>
							<StackRow gap={10}>
								<CheckCircleOutlineIcon sx={{ width: 14 }} />
								{t("Agree")}
							</StackRow>
						</Button>
					</StackRow>
				</Stack>
			</Box>
		);
	};

	return (
		<>
			{/* ACCOUNT INFORMATION */}
			<Stack pb={32} gap={20}>
				<Typography fontWeight={700} variant="text18">
					{t("signUp.accountInfo")}
				</Typography>

				{/* Email */}
				<Grid container alignItems="center" wrap="nowrap">
					<Grid item xs={1} mr={14}>
						<InputLabel>{t("login.email")}</InputLabel>
					</Grid>

					<Grid item container wrap="nowrap" xs={5.5} pr={14}>
						<Grid item xs={8} pr={14}>
							<FormInputText
								name="newEmail"
								readOnly={verificationStatus}
								control={control}
								placeholder="abcd@abcd.abcd"
								autoFocusOn={true}
							/>
						</Grid>
						<Grid item xs>
							<Button
								color={SECONDARY}
								onClick={sendOTPEmail}
								isLoading={sendingEmail}>
								{t("Send authorization number")}
							</Button>
						</Grid>
					</Grid>

					<Grid item container wrap="nowrap" xs={5.5}>
						<Grid item xs={10.3} pr={14}>
							<FormInputText
								name="verificationCode"
								type="number"
								noLengthIndicator
								maxLength={6}
								control={control}
								readOnly={verificationStatus}
								placeholder={t("signUp.enterCode")}
							/>
						</Grid>
						<Grid item xs>
							<Button
								color={PRIMARY}
								onClick={verifyOTPFunction}
								isLoading={verifying}
								disabled={verificationCodeValue?.length == 0 ? true : false}>
								{t("Confirm")}
							</Button>
						</Grid>
					</Grid>
				</Grid>

				{/* Password */}
				<Grid container alignItems="center">
					<Grid item xs={1} mr={14}>
						<InputLabel>{t("signUp.password")}</InputLabel>
					</Grid>

					<Grid item xs={5} pr={40}>
						<FormInputText
							name="newPassword"
							control={control}
							type="password"
							placeholder={t("signUp.eightOrMore")}
						/>
					</Grid>

					<Grid item xs={1}>
						<InputLabel>{t("signUp.password")}</InputLabel>
					</Grid>

					<Grid item xs>
						<FormInputText
							name="passwordConfirmation"
							control={control}
							type="password"
							placeholder={t("signUp.eightOrMore")}
						/>
					</Grid>
				</Grid>
			</Stack>

			<Divider />

			{/* COMPANY INFORMATION */}
			<Box sx={{ py: 32 }}>
				<Typography fontWeight={700} variant="text18">
					{t("signUp.companyInfo")}
				</Typography>

				{/* BusinessName and Number */}
				<Grid container alignItems="center" pt={20}>
					<Grid item xs={1} mr={14}>
						<InputLabel>{t("signUp.businessName")}</InputLabel>
					</Grid>

					<Grid item xs={4} pr={40}>
						<FormInputText
							name="businessName"
							control={control}
							type="text"
							placeholder={t("signUp.eightOrMore")}
						/>
					</Grid>

					<Grid item xs={1}>
						<InputLabel>{t("signUp.businessNumber")}</InputLabel>
					</Grid>

					<Grid item xs>
						<StackRow
							justifyContent="space-around"
							alignItems="center"
							gap={16}>
							<FormInputText
								name="businessRegistration"
								noLengthIndicator
								control={control}
								type="number"
								placeholder="000"
								maxLength={3}
							/>
							-
							<FormInputText
								name="businessPhoneNumber"
								noLengthIndicator
								control={control}
								type="number"
								placeholder="0000"
								maxLength={4}
							/>
							-
							<FormInputText
								name="businessExtension"
								noLengthIndicator
								control={control}
								type="number"
								placeholder="0000"
								maxLength={4}
							/>
						</StackRow>
					</Grid>
				</Grid>

				{/* Representative Information */}
				<Grid container alignItems="center" pt={20}>
					<Grid item xs={1} mr={14}>
						<StackRow justifyContent="space-between">
							<InputLabel>{t("signUp.representative")}</InputLabel>
							<InputLabel>|</InputLabel>
							<InputLabel>{t("signUp.name")}</InputLabel>
						</StackRow>
					</Grid>

					<Grid item xs={2} pr={20}>
						<FormInputText
							name="representativeName"
							control={control}
							type="text"
							placeholder={t("signUp.pleaseEnter")}
						/>
					</Grid>

					<Grid textAlign="center" item xs={1}>
						<InputLabel>Email</InputLabel>
						{/* "Email" is not translated in design */}
					</Grid>

					<Grid item xs={2} pr={20}>
						<FormInputText
							name="representativeEmail"
							control={control}
							type="text"
							placeholder={t("signUp.pleaseEnter")}
						/>
					</Grid>

					<Grid textAlign="center" item xs={1}>
						<InputLabel>{t("signUp.contact")}</InputLabel>
					</Grid>

					<Grid item xs>
						<StackRow
							justifyContent="space-around"
							alignItems="center"
							gap={16}>
							<FormInputText
								name="representativeCountryCode"
								noLengthIndicator
								control={control}
								type="number"
								placeholder="000"
								maxLength={3}
							/>
							-
							<FormInputText
								name="representativePhoneNumber"
								noLengthIndicator
								control={control}
								type="number"
								placeholder="0000"
								maxLength={4}
							/>
							-
							<FormInputText
								name="representativeExtension"
								noLengthIndicator
								control={control}
								type="number"
								placeholder="0000"
								maxLength={4}
							/>
						</StackRow>
					</Grid>
				</Grid>

				{/* Manager Information */}
				<Grid container alignItems="center" pt={20}>
					<Grid item xs={1} mr={14}>
						<StackRow justifyContent="space-between">
							<InputLabel>{t("signUp.manager")}</InputLabel>
							<InputLabel>|</InputLabel>
							<InputLabel>{t("signUp.name")}</InputLabel>
						</StackRow>
					</Grid>

					<Grid item xs={2} pr={20}>
						<FormInputText
							name="managerName"
							control={control}
							type="text"
							placeholder={t("signUp.pleaseEnter")}
						/>
					</Grid>

					<Grid textAlign="center" item xs={1}>
						<InputLabel>Email</InputLabel>
					</Grid>

					<Grid item xs={2} pr={20}>
						<FormInputText
							name="managerEmail"
							control={control}
							type="text"
							placeholder={t("signUp.pleaseEnter")}
						/>
					</Grid>

					<Grid textAlign="center" item xs={1}>
						<InputLabel>{t("signUp.contact")}</InputLabel>
					</Grid>

					<Grid item xs>
						<StackRow
							justifyContent="space-around"
							alignItems="center"
							gap={16}>
							<FormInputText
								name="managerCountryCode"
								noLengthIndicator
								control={control}
								type="number"
								placeholder="000"
								maxLength={3}
							/>
							-
							<FormInputText
								name="managerPhoneNumber"
								noLengthIndicator
								control={control}
								type="number"
								placeholder="0000"
								maxLength={4}
							/>
							-
							<FormInputText
								name="managerExtension"
								noLengthIndicator
								control={control}
								type="number"
								placeholder="0000"
								maxLength={4}
							/>
						</StackRow>
					</Grid>
				</Grid>

				{/* Business businessLicense */}
				<Grid container alignItems="center" pt={20}>
					<Grid item xs={1} mr={14}>
						<InputLabel>{t("signUp.businessRegistration")}</InputLabel>
					</Grid>

					<Grid item xs={4} pr={14}>
						<PreviewBox
							component="label"
							htmlFor="businessLicense"
							sx={{
								borderColor: errors.businessLicense && theme.palette.error.main,
								display: "block"
							}}>
							<input
								type="file"
								id="businessLicense"
								accept="image/*,application/pdf"
								hidden
								{...register("businessLicense")}
							/>
							{(watchBusinessLicense && watchBusinessLicense[0]?.name) ||
								t("fileName")}
						</PreviewBox>
						{errors.businessLicense && (
							<ErrorMessage>{errors?.businessLicense.message}</ErrorMessage>
						)}
					</Grid>

					<Button color={SECONDARY} htmlFor="businessLicense" component="label">
						{t("Upload File")}
					</Button>
				</Grid>

				{/* Company Profile  */}
				<Grid container alignItems="center" pt={20}>
					<Grid item xs={1} mr={14}>
						<InputLabel>{t("signUp.companyProfile")}</InputLabel>
					</Grid>

					<Grid item xs={4} pr={14}>
						<PreviewBox
							component="label"
							htmlFor="companyProfile"
							sx={{
								borderColor: errors.companyProfile && theme.palette.error.main,
								display: "block"
							}}>
							<input
								type="file"
								id="companyProfile"
								accept="image/*,application/pdf"
								hidden
								{...register("companyProfile")}
							/>
							{(watchCompanyProfile && watchCompanyProfile[0]?.name) ||
								t("fileName")}
						</PreviewBox>
						{errors.companyProfile && (
							<ErrorMessage>{errors?.companyProfile.message}</ErrorMessage>
						)}
					</Grid>

					<Button color={SECONDARY} htmlFor="companyProfile" component="label">
						{t("Upload File")}
					</Button>
				</Grid>

				{/* Company Address */}
				<Grid container alignItems="center" pt={20}>
					<Grid item xs={1} mr={14}>
						<InputLabel>{t("signUp.companyAddress")}</InputLabel>
					</Grid>

					<Grid item xs={3} pr={14}>
						<FormInputText
							readOnly
							name="postalCode"
							control={control}
							type="number"
							placeholder={t("signUp.enterAddress")}
							errorMessage={errors.postalCode}
						/>
					</Grid>

					<Grid item xs={2}>
						<Button
							color={SECONDARY}
							onClick={() => setPostCodeModalOpen(true)}>
							{t("Find Postal Code")}
						</Button>
					</Grid>

					<Grid textAlign="center" item xs={1}>
						<InputLabel>{t("signUp.contact")}</InputLabel>
					</Grid>

					<Grid item xs>
						<StackRow
							justifyContent="space-around"
							alignItems="center"
							gap={16}>
							<FormInputText
								name="contactCountryCode"
								control={control}
								noLengthIndicator
								type="number"
								placeholder="000"
								maxLength={3}
							/>
							-
							<FormInputText
								name="contactPhoneNumber"
								control={control}
								noLengthIndicator
								type="number"
								placeholder="0000"
								maxLength={4}
							/>
							-
							<FormInputText
								name="contactExtension"
								control={control}
								noLengthIndicator
								type="number"
								placeholder="0000"
								maxLength={4}
							/>
						</StackRow>
					</Grid>
				</Grid>

				{/* Address && Detailed Address */}
				<Grid container alignItems="center" pt={20} wrap="nowrap">
					<Grid item xs={1} visibility="hidden" mr={14}>
						<InputLabel>{t("signUp.companyProfile")}</InputLabel>
					</Grid>

					<Grid item xs={5.4} pr={20}>
						<FormInputText
							name="companyAddress"
							readOnly
							control={control}
							type="text"
							placeholder={t("signUp.enterAddress")}
						/>
					</Grid>
					<Grid item xs={5.43}>
						<FormInputText
							name="companyDetailedAddress"
							control={control}
							type="text"
							placeholder={t("signUp.enterDetailAddress")}
						/>
					</Grid>
				</Grid>

				<PostCode
					isPostCodeModalOpen={isPostCodeModalOpen}
					setPostCodeModalOpen={setPostCodeModalOpen}
					onSelected={(result) => {
						setValue("postalCode", result.zonecode);
						setValue("companyAddress", result.address);
					}}></PostCode>
			</Box>

			<Divider />
			<TermsOfService />
			<PrimaryAlertDialog
				isReason
				isDialogOpen={isCodeSentMsgDialogOpen}
				setDialogOpen={setCodeSentMsgDialogOpen}
				message={t("partner.dialog.codeSentMessage")}
				firstBtn={t("Confirm")}
				firstAction={() => setCodeSentMsgDialogOpen(false)}
			/>
		</>
	);
};

export default JoinPartners;
