import { Controller, useFormContext } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import { InputAdornment, styled, Typography } from "@mui/material";

import ErrorMessage from "../ErrorMessage";

export const RelatedInfoTextField = ({
	name,
	control,
	type,
	placeholder,
	textAlignCenter = 0,
	limit,
	width,
	currentWordCount,
	isTextarea = false,
	disabled,
	names
}) => {
	const { trigger } = useFormContext();
	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<>
					<CustomTextField
						{...field}
						multiline={isTextarea && true}
						placeholder={placeholder}
						onChange={(e) => {
							field.onChange(e);
						}}
						onBlur={(e) => {
							field.onBlur(e);
							trigger(names);
						}}
						value={field.value || ""}
						minRows={4}
						type={type}
						size="small"
						error={!!error}
						fullWidth
						disabled={disabled}
						width={width}
						placement={textAlignCenter}
						variant="outlined"
						InputProps={{
							inputProps: { min: 0, maxLength: limit },
							endAdornment: limit && (
								<InputAdornment position="end">
									<Typography component="span" color="primary">
										{currentWordCount}
									</Typography>
									<Typography component="span">{`/${limit}`}</Typography>
								</InputAdornment>
							)
						}}
					/>
					{disabled ? null : error ? (
						<ErrorMessage>{error.message}</ErrorMessage>
					) : null}
				</>
			)}
		/>
	);
};

const CustomTextField = styled(TextField)(({ placement }) => ({
	"& .MuiInputBase-input": {
		textAlign: placement ? "center" : "left"
	},
	"& .MuiFormHelperText-contained": {
		width: "500px",
		margin: 0
	}
}));
