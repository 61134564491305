import { Avatar, Divider, Grid, Stack, Typography } from "@mui/material";
import { StackRow } from "components/shared";
import { useTranslation } from "react-i18next";
import DualTitle from "components/shared/DualTitle";
import theme from "theme/theme";
import { InputLabel } from "components/auth/style";
import { DataText } from "./style";
import { Button, SECONDARY } from "components/layout/Button";
import { useState } from "react";
import MessagePartnerDialog from "components/dialogs/partner/MessagePartnerDialog";

export const DataDisplay = ({ attribute, data = "-" }) => {
	return (
		<StackRow gap={14} alignItems="center">
			<InputLabel noWrap minWidth={80}>
				{attribute}
			</InputLabel>
			<DataText>{data}</DataText>
		</StackRow>
	);
};

const MainInfo = ({ partnerDetails, showAddress }) => {
	const [isMessagePartnerOpen, setMessagePartnerOpen] = useState(false);

	const userInteractionData = false;
	// DUMMY CONDITIONAL FOR USER LOGIN DATA

	const { t } = useTranslation();
	const {
		code,
		businessNumber,
		companyAddress,
		companyAddressDetail,
		postalCode,
		representativeName,
		representativeContact,
		representativeEmail,
		contactEmail,
		contactName,
		contactNumber
	} = partnerDetails?.team || {};

	return (
		<Stack>
			<StackRow
				pb={16}
				gap={13}
				alignItems="center"
				justifyContent="space-between">
				<StackRow gap={16} alignItems="center">
					<Avatar
						alt="profile"
						src={partnerDetails?.photos ? partnerDetails?.photos[0]?.path : ""}
						sx={{ width: 32, height: 32 }}>
						{partnerDetails?.name?.charAt(0)}
					</Avatar>
					<DualTitle
						mainTitle={partnerDetails?.name}
						subTitle={code}
						mainVariant={{ fontWeight: 500, fontSize: 18 }}
						subVariant={{
							fontWeight: 500,
							fontSize: 16,
							color: theme.palette.text.secondary
						}}
					/>
				</StackRow>
				{showAddress && (
					<Button color={SECONDARY} onClick={() => setMessagePartnerOpen(true)}>
						{t("partner.sendMessage")}
					</Button>
				)}
			</StackRow>

			<Grid container gap={60}>
				{showAddress && (
					<Grid item xs>
						<Stack gap={10}>
							<DataDisplay
								attribute={t("partner.businessNumber")}
								data={businessNumber || ""}
							/>
							<Typography>{`(${t("partner.postalCode")}:${postalCode || ""}) ${
								companyAddress || ""
							} ${companyAddressDetail || ""}`}</Typography>
						</Stack>
					</Grid>
				)}
				<Grid item xs>
					<Stack gap={10}>
						<DataDisplay
							attribute={t("Representative Name")}
							data={representativeName || ""}
						/>
						<DataDisplay
							attribute={t("Representative Contact")}
							data={representativeContact || ""}
						/>
						<DataDisplay
							attribute={t("Representative Email")}
							data={representativeEmail || ""}
						/>
					</Stack>
				</Grid>
				<Grid item xs>
					<Stack gap={10}>
						<DataDisplay
							attribute={t("Contact Person")}
							data={contactName || ""}
						/>
						<DataDisplay
							attribute={t("partner.contactPerson")}
							data={contactNumber || ""}
						/>
						<DataDisplay
							attribute={t("partner.contactEmail")}
							data={contactEmail || ""}
						/>
					</Stack>
				</Grid>
			</Grid>
			<MessagePartnerDialog
				isDialogOpen={isMessagePartnerOpen}
				setDialogOpen={setMessagePartnerOpen}
			/>
			{userInteractionData && (
				<StackRow
					mt={22}
					gap={12}
					divider={<Divider orientation="vertical" variant="middle" flexItem />}
					sx={{
						color: "gray.tertiary",
						textAlign: "right",
						whiteSpace: "nowrap"
					}}>
					<Typography variant="text12" fontWeight={400}>
						{t("partner.profile.lastLogin")} :
					</Typography>
					<Typography variant="text12" fontWeight={400}>
						{t("partner.profile.subscriptionDate")} :
					</Typography>
					<Typography variant="text12" fontWeight={400}>
						{t("partner.profile.subscriptionApproveDate")} :
					</Typography>
				</StackRow>
			)}
		</Stack>
	);
};

export default MainInfo;
