import { Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import theme from "theme/theme";
import { formatDateTime } from "utils/formatDate";
import {
	DescriptionContainer,
	RecommendationAvatar,
	RecommendationContainer,
	RightBorder
} from "./style";

const RecommendationTable = ({ data }) => {
	return (
		<Grid container spacing={12}>
			{data.map((item) => {
				return (
					<Grid item key={item.id} xs={6}>
						<RecommendationContainer>
							<Stack direction="row" alignItems="center">
								<Stack
									direction="row"
									justifyContent="space-between"
									alignContent="center"
									sx={{ width: "100%" }}>
									<Stack direction="row" alignItems="center">
										<RecommendationAvatar />
										<Typography
											variant="text13"
											fontWeight="bold"
											color="text.primary">
											{item.user.name}
										</Typography>
									</Stack>
									<Stack direction="row" alignItems="center">
										<Typography variant="text12" color="text.secondary">
											{formatDateTime(item.createdAt)}
										</Typography>
									</Stack>
								</Stack>
							</Stack>
							<DescriptionContainer>
								<Typography variant="text13" textAlign="left">
									{item.content}
								</Typography>
							</DescriptionContainer>
							<Stack direction="row" justifyContent="space-between" pt={10}>
								<Stack direction="row" alignItems="center">
									<RightBorder>
										<Typography
											variant="text13"
											color={theme.palette.gray.tertiary}
											textAlign="left">
											{item.product.id}
										</Typography>
									</RightBorder>
									<Typography
										variant="text13"
										color={theme.palette.gray.tertiary}
										textAlign="left"
										pl={10}>
										{item.product.brand.name}
									</Typography>
								</Stack>
							</Stack>
						</RecommendationContainer>
					</Grid>
				);
			})}
		</Grid>
	);
};

export default RecommendationTable;
