import { Container, Stack } from "@mui/material";
import { ContentStyle, RootStyle } from "./style";
import { Navbar } from "components/layout";
import { SignUpIdForm } from "components/auth/SignUpIdForm";

const SignUpId = () => {
	return (
		<RootStyle>
			<Navbar isLogin={true} />
			<Container maxWidth="sm">
				<ContentStyle justifyContent="center">
					<Stack direction="column" alignItems="center" sx={{ mb: 80 }}>
						<img
							src={require("assets/images/logo.png")}
							alt="logo"
							width={200}
						/>
					</Stack>
					<SignUpIdForm />
				</ContentStyle>
			</Container>
		</RootStyle>
	);
};

export default SignUpId;
