import { API_PRELOAD } from "services/endpoints";

export default (build) =>
	build.query({
		query: () => {
			return {
				url: API_PRELOAD,
				method: "GET"
			};
		},
		providesTags: []
	});
