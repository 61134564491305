export const TAG_RECOMMENDATION = "Recommendation";
export const TAG_PRODUCT = "Product";
export const TAG_CATEGORY = "Category";
export const TAG_BRAND = "Brand";
export const TAG_USER = "User";
export const TAG_FUNDING = "Funding";
export const TAG_FUNDING_ADMIN = "FundingAdmin";
export const TAG_PARTNER = "Partner";
export const TAG_RECEIPT = "Receipt";
export const TAG_SHIPPING = "Shipping";
export const TAG_OWNER = "Owner";
export const TAG_STOCKING = "Stocking";
