import { Box } from "@mui/material";

import { Navbar, SideNavbar } from ".";

export const Layout = () => {
	return (
		<Box>
			<Navbar />
			<SideNavbar />
		</Box>
	);
};
