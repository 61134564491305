import { Avatar, Paper, styled } from "@mui/material";
import { Box } from "@mui/system";

export const RecommendationContainer = styled(Paper)({
	padding: 16,
	width: "100%"
});

export const RecommendationAvatar = styled(Avatar)({
	marginRight: 13
});

export const DescriptionContainer = styled(Box)(({ theme }) => ({
	paddingBlock: 10,
	borderBottom: `0.5px solid ${theme.palette.gray.main}`
}));

export const RightBorder = styled(Box)(({ theme }) => ({
	paddingRight: 10,
	borderRight: `0.5px solid ${theme.palette.gray.main}`
}));
