import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@mui/material";
import PrimaryUserMessageDialog from "components/dialogs/PrimaryUserMessageDialog";
import { BrandImgUpload } from "components/fileUpload/BrandImgUpload";
import { FormInputText } from "components/formComponents";
import { BasePlate } from "components/layout";
import { Button, PRIMARY, SECONDARY } from "components/layout/Button";
import { StackRow } from "components/shared";
import {
	ROUTE_BRAND_LIST,
	ROUTE_REQUESTED_BRAND_LIST
} from "data/routeVariables";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showAlert } from "redux/modules";
import { selectBrand } from "redux/modules/brand";
import {
	useBrandCreateMutation,
	useBrandEditMutation,
	useRequestedBrandStatusMutation
} from "services/modules/brand";
import { brandCreateValidationSchema } from "utils/validationSchema";
import { primaryAlertUserMessageValidation } from "utils/validationSchema";

const requestStatus = {
	APPROVE: "approve",
	PENDING: "pending",
	REJECT: "reject"
};

const brandAssignTypeConst = {
	CREATE: "create",
	EDIT: "edit"
};

const CreateBrand = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [rejectDialog, setRejectDialog] = useState(false);

	const {
		brandId,
		type: brandAssignType,
		koreanName,
		name,
		description,
		photos
	} = useSelector(selectBrand);

	const [
		brandCreate,
		{
			isError: createError,
			isSuccess: createSuccess,
			isLoading: brandCreateLoading
		}
	] = useBrandCreateMutation();

	const [
		brandEdit,
		{ isError: editError, isSuccess: editSuccess, isLoading: brandEditLoading }
	] = useBrandEditMutation();

	const [
		requestedBrandStatus,
		{
			data: brandStatusUpdateResponse,
			isError: statusChangeError,
			isSuccess: statusChangeSuccess,
			isLoading: statusChangeLoading
		}
	] = useRequestedBrandStatusMutation();

	const rejectStatusResponse =
		brandStatusUpdateResponse?.response?.data?.status;

	const { handleSubmit, control, reset, ...methods } = useForm({
		defaultValues: {
			id: brandId,
			koreanName: koreanName || "",
			name: name || "",
			description: description || "",
			photo: ""
		},
		mode: "onBlur",
		resolver: yupResolver(brandCreateValidationSchema(t))
	});

	const {
		control: userMessageControl,
		formState: { isDirty },
		watch
	} = useForm({
		defaultValues: {
			userMessageInput: ""
		},
		mode: "onBlur",
		resolver: yupResolver(primaryAlertUserMessageValidation(t))
	});

	const userMessageInput = watch("userMessageInput");

	const rejectBrandRequest = () => {
		requestedBrandStatus({
			brandId: brandId,
			status: requestStatus.REJECT,
			reason: userMessageInput
		});
		statusChangeSuccess || statusChangeError
			? setRejectDialog(false)
			: setRejectDialog(true);
	};

	const onSubmit = (values) => {
		if (brandAssignType === brandAssignTypeConst.EDIT) {
			brandEdit(values);
		} else {
			brandCreate({
				requestBrandId: brandId,
				...values
			});
		}
	};

	const handleCancel = () => {
		reset({
			id: "",
			koreanName: "",
			name: "",
			description: ""
		});
		navigate(-1);
	};

	useEffect(() => {
		rejectStatusResponse === "rejected" &&
			dispatch(
				showAlert({
					severity: "success",
					message: t("alertMessage.brand.brandRejected")
				})
			);

		createError &&
			dispatch(
				showAlert({
					severity: "error",
					message: t("alertMessage.brand.createFail")
				})
			);

		createSuccess &&
			dispatch(
				showAlert({
					severity: "success",
					message: t("alertMessage.brand.createSuccess")
				})
			);

		editError &&
			dispatch(
				showAlert({
					severity: "error",
					message: t("alertMessage.brand.editFail")
				})
			);

		editSuccess &&
			dispatch(
				showAlert({
					severity: "success",
					message: t("alertMessage.brand.editSuccess")
				})
			);

		statusChangeError &&
			dispatch(
				showAlert({
					severity: "error",
					message: t("alertMessage.somethingWentWrong")
				})
			);

		statusChangeSuccess &&
			rejectStatusResponse === "rejected" &&
			navigate(ROUTE_REQUESTED_BRAND_LIST);
		createSuccess && navigate(ROUTE_BRAND_LIST);
		editSuccess && navigate(ROUTE_BRAND_LIST);
	}, [
		createSuccess,
		createError,
		statusChangeSuccess,
		statusChangeError,
		editError,
		editSuccess
	]);

	return (
		<>
			<Typography fontSize={26} mb={19} fontWeight={500}>
				{brandAssignType === brandAssignTypeConst.EDIT
					? t("brand.editBrand")
					: t("brand.createBrand.title")}
			</Typography>
			<FormProvider {...methods}>
				<form>
					<BasePlate sx={{ paddingY: 30, paddingX: 36 }}>
						<Grid container mb={24} alignItems="start">
							<Grid item xs={3}>
								<Typography pt={10}>
									{t("brand.createBrand.brandNameKor")}
								</Typography>
							</Grid>
							<Grid item xs>
								<FormInputText
									name="koreanName"
									control={control}
									type="text"
								/>
							</Grid>
						</Grid>

						<Grid container mb={24} alignItems="start">
							<Grid item xs={3}>
								<Typography pt={10}>
									{t("brand.createBrand.brandNameEng")}
								</Typography>
							</Grid>
							<Grid item xs>
								<FormInputText name="name" control={control} type="text" />
							</Grid>
						</Grid>

						<Grid container mb={24} alignItems="start">
							<Grid item xs={3}>
								<Typography pt={10}>
									{t("brand.createBrand.description")}
								</Typography>
							</Grid>
							<Grid item xs>
								<FormInputText
									name="description"
									isTextarea
									type="text"
									control={control}
								/>
							</Grid>
						</Grid>

						<Grid container mb={24} alignItems="start">
							<Grid item xs={3}>
								<Typography pt={10}>{t("brand.createBrand.photo")}</Typography>
							</Grid>
							<Grid container item xs>
								<BrandImgUpload
									name="photo"
									control={control}
									photos={photos}
								/>
							</Grid>
						</Grid>
					</BasePlate>
					<StackRow justifyContent="flex-end" gap={10}>
						<Button onClick={handleCancel}>{t("Cancel")}</Button>
						{brandAssignType === brandAssignTypeConst.CREATE && brandId && (
							<Button color={SECONDARY} onClick={() => setRejectDialog(true)}>
								{t("Reject")}
							</Button>
						)}

						<Button
							isLoading={brandCreateLoading || brandEditLoading}
							color={PRIMARY}
							onClick={handleSubmit(onSubmit)}>
							{brandAssignType === brandAssignTypeConst.EDIT
								? t("Update")
								: brandId
								? t("Approve")
								: t("Create")}
						</Button>
					</StackRow>
				</form>
			</FormProvider>
			<PrimaryUserMessageDialog
				isDialogOpen={rejectDialog}
				setDialogOpen={setRejectDialog}
				message={t("brand.createBrand.rejectDialogMessage")}
				firstBtn={t("Confirm")}
				firstAction={rejectBrandRequest}
				isLoading={statusChangeLoading}
				control={userMessageControl}
				isDirty={isDirty}
			/>
		</>
	);
};

export default CreateBrand;
export { requestStatus };
