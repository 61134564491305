import { MenuItem, Select, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import theme from "theme/theme";
import ErrorMessage from "./ErrorMessage";

const FormInputSelect = ({
	width,
	height,
	fontWeight,
	variant,
	name,
	options,
	selectedValue,
	setSelectedValue,
	placeholder,
	control,
	setValue,
	disabled,
	hideBorder = false
}) => {
	const generateOptions = () => {
		return options.map((option, index) => {
			return (
				<MenuItem key={`${index}_${option.value}`} value={option.value}>
					<Typography fontWeight={fontWeight} variant={variant}>
						{option.label}
					</Typography>
				</MenuItem>
			);
		});
	};

	const handleChange = (event) => {
		setValue && setValue(name, event.target.value);
		setSelectedValue && setSelectedValue(event.target.value);
	};

	const hideBorderProps = {
		variant: "standard",
		disableUnderline: true
	};

	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { value }, fieldState: { error } }) => (
				<>
					<Select
						sx={{
							width: width,
							height: height,
							padding: 0,
							border: hideBorder ? "" : "1px solid lightgray",
							backgroundColor: theme.palette.white
						}}
						disabled={disabled}
						value={selectedValue || value || ""}
						error={!!error}
						onChange={handleChange}
						displayEmpty
						{...(hideBorder && hideBorderProps)}>
						{placeholder && (
							<MenuItem value="">
								<Typography
									fontWeight={fontWeight}
									variant={variant}
									color={theme.palette.text.placeholder}>
									{placeholder}
								</Typography>
							</MenuItem>
						)}
						{generateOptions()}
					</Select>
					<ErrorMessage>{error?.message}</ErrorMessage>
				</>
			)}
		/>
	);
};

export default FormInputSelect;
