import { Button, DialogActions } from "@mui/material";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog, selectDialog, showAlert } from "redux/modules";
import DialogContainer from "./DialogContainer";
import {
	useCreateCategoryMutation,
	useEditCategoryMutation
} from "services/modules/category";
import { CustomDialogContentText } from "./style";
import { FormInputText } from "components/formComponents";
import { useForm } from "react-hook-form";

const CategoryDialog = ({ isDialogOpen }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [createCategory, { error: createError }] = useCreateCategoryMutation();
	const [editCategory, { error: editError }] = useEditCategoryMutation();
	const { handleSubmit, control } = useForm();

	const { payload: editProps } = useSelector(selectDialog);

	const {
		name = "",
		id = -1,
		parent_category: parentCategory
	} = editProps || {};

	const { id: parentCategoryId } = parentCategory || {};

	const isCreate = !name;

	const onSubmit = ({ name }) => {
		const payload = {
			name,
			id,
			parentCategoryId
		};

		if (isCreate) {
			createCategory(payload);
			dispatch(
				showAlert({
					severity: "success",
					message: t("alertMessage.category.postSuccess")
				})
			);

			createError &&
				dispatch(
					showAlert({
						severity: "error",
						message: t("alertMessage.category.postFail")
					})
				);
		} else {
			editCategory(payload);
			dispatch(
				showAlert({
					severity: "success",
					message: t("alertMessage.category.editSuccess")
				})
			);

			editError &&
				dispatch(
					showAlert({
						severity: "error",
						message: t("alertMessage.category.postFail")
					})
				);
		}

		handleClose();
	};

	const handleClose = () => {
		dispatch(closeDialog());
	};

	return (
		<DialogContainer
			isDialogOpen={isDialogOpen}
			dialogTitle={
				isCreate ? t("category.createCategory") : t("category.editCategory")
			}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Stack>
					<Stack gap={10}>
						<CustomDialogContentText>
							{t("category.header")}
						</CustomDialogContentText>
						<FormInputText name="name" control={control} type="text" />
					</Stack>
				</Stack>
				<DialogActions
					sx={{ justifyContent: "right", paddingY: 30, paddingX: 30 }}>
					<Button variant="outlined" onClick={handleClose}>
						{t("Cancel")}
					</Button>
					<Button variant="contained" type="submit">
						{isCreate ? t("Create") : t("Save")}
					</Button>
				</DialogActions>
			</form>
		</DialogContainer>
	);
};

export default CategoryDialog;
