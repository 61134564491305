import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { translationEn } from "./locale/en/en";
import { translationKor } from "./locale/kor/kor";

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: true,
		fallbackLng: "en",
		// lng: process.env.REACT_APP_DEFAULT_TRANSLATION,
		// Temporarily disabled for Toggle Language Change
		interpolation: {
			escapeValue: false
		},
		resources: {
			en: {
				translation: translationEn
			},
			kor: {
				translation: translationKor
			}
		}
	});

export default i18n;
