import {
	Divider,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	Stack,
	Tooltip,
	Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormInputText } from "components/formComponents";
import { ImageWrapper } from "components/fileUpload/style";
import { FlatPlate } from "components/layout";
import { StackRow } from "components/shared";
import dummyImg from "assets/images/kal-visuals-square.jpg";
import theme from "theme/theme";
import { Button, PRIMARY, SECONDARY } from "components/layout/Button";
import { exportDeliveryInfoSchema } from "utils/validationSchema";
import RequiredLabelText from "components/shared/RequiredLabelText";
import { PostCode } from "components/shared/PostCode";
import { useDeliveryInfoMutation } from "services/modules/shipping/request";
import { usePromiseHandler } from "utils/usePromiseHandler";
import ShippingDataContext from "pages/inventory/shippingRequest/shippingDataContext";
import useCopyString from "utils/useCopyString";

import PrimaryDialog from "../PrimaryDialog";

const ExportRequestDialog = ({
	isExportRequestOpen,
	setExportRequestOpen,
	setRequestCompleteOpen
}) => {
	const { t } = useTranslation();
	const promiseHandler = usePromiseHandler();
	const [selectedValue, setSelectedValue] = useState("selfPickup");
	const [isPostCodeModalOpen, setPostCodeModalOpen] = useState(false);

	const [isCopied, copyString] = useCopyString();

	const localAddress = {
		address: "경기도 하남시 000 000 412-1",
		phone: "031-0000-0000"
	};

	const handleCopy = () => {
		copyString(
			`address => ${localAddress.address} , phone => ${localAddress.phone}`
		);
	};

	const shippingData = useContext(ShippingDataContext);

	const {
		id: shippingId,
		funding,
		shippingRequestOrderPayment
	} = shippingData || {};

	const { control, handleSubmit, setValue, reset } = useForm({
		defaultValues: {
			manager: shippingData?.recipient,
			contact1: shippingData?.contactNumber_1,
			contact2: shippingData?.contactNumber_2,
			address: shippingData?.address,
			detailedAddress: shippingData?.addressDetail
		},
		mode: "onBlur",
		resolver:
			selectedValue !== "selfPickup" && yupResolver(exportDeliveryInfoSchema(t))
	});

	const [sendDeliveryInfo, { isLoading }] = useDeliveryInfoMutation();

	const handleChange = (event) => {
		setSelectedValue(event.target.value);
	};

	const onSubmit = (values) => {
		if (selectedValue === "selfPickup") {
			promiseHandler(
				sendDeliveryInfo({
					shippingId,
					deliveryType: "selfPickup"
				}).unwrap(),
				"Delivery Info Sent",
				"Could not send delivery info"
			).then(() => {
				setExportRequestOpen(false);
				setRequestCompleteOpen(true);
			});
		} else {
			promiseHandler(
				sendDeliveryInfo({ shippingId, ...values }).unwrap(),
				"Delivery Info Sent",
				"Could not send delivery info"
			).then(() => {
				setExportRequestOpen(false);
				setRequestCompleteOpen(true);
			});
		}
	};

	return (
		<PrimaryDialog
			header={t("shipping.exportRequest.header")}
			reset={reset}
			setDialogOpen={setExportRequestOpen}
			isDialogOpen={isExportRequestOpen}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<FlatPlate sx={{ p: 24 }}>
					<StackRow>
						<ImageWrapper minWidth={168} height={168}>
							<img height="100%" width="100%" src={dummyImg} />
						</ImageWrapper>
						<Stack gap={12} pl={24} color={theme.palette.gray.tertiary}>
							<Typography>{t("shipping.tableHeaders.fundingName")}</Typography>
							<Typography>{t("shipping.tableHeaders.fundingCode")}</Typography>
							<Typography>{t("shipping.available")}</Typography>
							<Typography>{t("table.status")}</Typography>
							<Typography>{t("shipping.card.settlementUnitPrice")}</Typography>
						</Stack>
						<Stack gap={12} pl={12}>
							<Typography>{funding?.name}</Typography>
							<Typography>{funding?.code}</Typography>
							<Typography>{funding?.quantity}</Typography>
							{/*TODO / To integrate data */}
							<Typography>
								{`${t("shipping.unitPrice")} ${
									funding?.unitPrice || 0
								} |  | ${t("shipping.adminFee")} 3000`}
							</Typography>
							<Typography>{`${
								shippingRequestOrderPayment?.totalSettlementAmount ||
								funding?.unitPrice ||
								0
							}${t("KRW")}`}</Typography>
							{/*TODO / To integrate data */}
						</Stack>
					</StackRow>
				</FlatPlate>
				<FlatPlate sx={{ p: 24 }}>
					<StackRow alignItems="center" gap={40}>
						<Typography color={theme.palette.gray.tertiary}>
							{t("shipping.exportRequest.exportOptions")}
						</Typography>
						<RadioGroup value={selectedValue} onChange={handleChange}>
							<StackRow gap={20}>
								<FormControlLabel
									value="selfPickup"
									control={<Radio color={SECONDARY} />}
									label={t("shipping.exportRequest.selfPickup")}
								/>
								<FormControlLabel
									color="secondary"
									value="delivery"
									control={<Radio color={SECONDARY} />}
									label={t("shipping.exportRequest.deliver")}
								/>
							</StackRow>
						</RadioGroup>
					</StackRow>

					{selectedValue === "selfPickup" ? (
						<StackRow
							alignItems="center"
							justifyContent="space-between"
							pb={140}>
							<StackRow>
								<Typography pr={10} color={theme.palette.gray.tertiary}>
									{t("shipping.exportRequest.address")}
								</Typography>
								<Typography pr={23}>{localAddress.address}</Typography>
								<Typography pr={10} color={theme.palette.gray.tertiary}>
									{t("shipping.exportRequest.phone")}
								</Typography>
								<Typography>{localAddress.phone}</Typography>
							</StackRow>
							<Tooltip title={isCopied ? "Copied" : ""} placement="top">
								<Button onClick={handleCopy}>{t("Copy")}</Button>
							</Tooltip>
						</StackRow>
					) : (
						<Grid container gap={12} my={36}>
							{/* Recipient */}
							<Grid container alignItems="flex-start">
								<Grid pt={10} item xs={1.8}>
									<RequiredLabelText color={theme.palette.gray.tertiary}>
										{t("Recipient")}
									</RequiredLabelText>
								</Grid>
								<Grid container item xs alignItems="flex-start" gap={20}>
									<Grid item xs>
										<FormInputText
											control={control}
											name="manager"
											placeholder={t("shipping.individualProduct.enterName")}
										/>
									</Grid>
									<Typography visibility="hidden">{t("Contact 2")}</Typography>
									<Grid item xs visibility="hidden">
										<FormInputText type="number" name="invisibleField" />
									</Grid>
								</Grid>
							</Grid>

							{/* Contact 1 & 2 */}
							<Grid container alignItems="flex-start">
								<Grid pt={10} item xs={1.8}>
									<RequiredLabelText color={theme.palette.gray.tertiary}>
										{t("Contact 1")}
									</RequiredLabelText>
								</Grid>
								<Grid container item xs alignItems="flex-start" gap={20}>
									<Grid item xs>
										<FormInputText
											type="number"
											control={control}
											name="contact1"
											placeholder={t("shipping.individualProduct.enterNumber")}
										/>
									</Grid>
									<Typography pt={10} color={theme.palette.gray.tertiary}>
										{t("Contact 2")}
									</Typography>
									<Grid item xs>
										<FormInputText
											type="number"
											control={control}
											name="contact2"
											placeholder={t("shipping.individualProduct.enterNumber")}
										/>
									</Grid>
								</Grid>
							</Grid>

							{/* Address */}
							<Grid container alignItems="flex-start">
								<Grid pt={10} item xs={1.8}>
									<RequiredLabelText color={theme.palette.gray.tertiary}>
										{t("shipping.exportRequest.address")}
									</RequiredLabelText>
								</Grid>
								<Grid container gap={20} item xs alignItems="flex-start">
									<Grid item xs>
										<FormInputText
											width={300}
											control={control}
											name="address"
										/>
									</Grid>
									<Button
										color={SECONDARY}
										onClick={() => setPostCodeModalOpen(true)}>
										{t("shipping.exportRequest.findPostalCode")}
									</Button>
									<PostCode
										isPostCodeModalOpen={isPostCodeModalOpen}
										setPostCodeModalOpen={setPostCodeModalOpen}
										onSelected={(result) => {
											setValue("address", result.zonecode);
											setValue("detailedAddress", result.address);
										}}></PostCode>
								</Grid>
							</Grid>

							{/* Address Detail*/}
							<Grid container>
								<Grid item xs={1.8}>
									<Typography visibility="hidden">
										{t("shipping.individualProduct.recipient")}
									</Typography>
								</Grid>
								<Grid item xs>
									<FormInputText
										control={control}
										name="detailedAddress"
										placeholder={t("Enter detailed address")}
									/>
								</Grid>
							</Grid>
						</Grid>
					)}

					<Divider />
					<StackRow
						justifyContent="flex-end"
						alignItems="center"
						gap={20}
						pt={24}>
						<Typography color={theme.palette.gray.tertiary}>
							{t("shipping.totalSettlementAmount")}
						</Typography>
						<Typography fontSize={25} fontWeight={600}>
							{`${
								shippingRequestOrderPayment?.totalSettlementAmount ||
								funding?.unitPrice ||
								0
							}${t("KRW")}`}
						</Typography>
					</StackRow>
				</FlatPlate>
				<StackRow justifyContent="flex-end">
					<Button type="submit" isLoading={isLoading} color={PRIMARY}>
						{t("Confirm")}
					</Button>
				</StackRow>
			</form>
		</PrimaryDialog>
	);
};

export default ExportRequestDialog;
