import * as Yup from "yup";

export const PartnerRegisterSchema = (t) =>
	Yup.object().shape({
		newEmail: Yup.string()
			.email(t("signUpValidationMsg.mustBeEmail"))
			.required(t("signUpValidationMsg.emailRequired")),
		newPassword: Yup.string().required(t("signUpValidationMsg.pwRequired")),
		passwordConfirmation: Yup.string()
			.required(t("signUpValidationMsg.pwRequired"))
			.oneOf(
				[Yup.ref("newPassword")],
				t("signUpValidationMsg.unmatchPasswords")
			),
		businessName: Yup.string().required(t("validationMsg.fieldRequired")),
		businessRegistration: Yup.number()
			.typeError(t("signUpValidationMsg.businessRegistration"))
			.required(t("signUpValidationMsg.businessRegistration")),
		businessPhoneNumber: Yup.number()
			.typeError(t("signUpValidationMsg.businessRegistration"))
			.required(t("signUpValidationMsg.businessRegistration")),
		businessExtension: Yup.number()
			.typeError(t("signUpValidationMsg.businessRegistration"))
			.required(t("signUpValidationMsg.businessRegistration")),
		representativeName: Yup.string().required(t("validationMsg.fieldRequired")),
		representativeEmail: Yup.string()
			.email(t("signUpValidationMsg.mustBeEmail"))
			.required(t("validationMsg.fieldRequired")),
		representativeCountryCode: Yup.number()
			.typeError(t("validationMsg.fieldRequired"))
			.required(t("validationMsg.fieldRequired")),
		representativePhoneNumber: Yup.number()
			.typeError(t("validationMsg.fieldRequired"))
			.required(t("validationMsg.fieldRequired")),
		representativeExtension: Yup.number()
			.typeError(t("validationMsg.fieldRequired"))
			.required(t("validationMsg.fieldRequired")),
		managerName: Yup.string().required(t("validationMsg.fieldRequired")),
		managerEmail: Yup.string()
			.email(t("signUpValidationMsg.mustBeEmail"))
			.required(t("validationMsg.fieldRequired")),
		managerCountryCode: Yup.number()
			.typeError(t("validationMsg.fieldRequired"))
			.required(t("validationMsg.fieldRequired")),
		managerPhoneNumber: Yup.number()
			.typeError(t("validationMsg.fieldRequired"))
			.required(t("validationMsg.fieldRequired")),
		managerExtension: Yup.number()
			.typeError(t("validationMsg.fieldRequired"))
			.required(t("validationMsg.fieldRequired")),
		postalCode: Yup.string()
			.required(t("validationMsg.fieldRequired"))
			.test(
				"length",
				t("validationMsg.postalCodeDigits"),
				(value) => value?.toString().length === 5
			),
		companyAddress: Yup.string().required(t("validationMsg.fieldRequired")),
		companyDetailedAddress: Yup.string().required(
			t("validationMsg.fieldRequired")
		),
		contactCountryCode: Yup.number()
			.typeError(t("validationMsg.fieldRequired"))
			.required(t("validationMsg.fieldRequired")),
		contactPhoneNumber: Yup.number()
			.typeError(t("validationMsg.fieldRequired"))
			.required(t("validationMsg.fieldRequired")),
		contactExtension: Yup.number()
			.typeError(t("validationMsg.fieldRequired"))
			.required(t("validationMsg.fieldRequired")),
		businessLicense: Yup.mixed().test(
			"required",
			t("validationMsg.fieldRequired"),
			(value) => {
				return value?.length;
			}
		),
		companyProfile: Yup.mixed().test(
			"required",
			t("validationMsg.fieldRequired"),
			(value) => {
				return value?.length;
			}
		)
	});

export const AdditionalInfoSchema = (t) =>
	Yup.object().shape({
		companyStartDate: Yup.string().required(t("validationMsg.fieldRequired")),
		year1: Yup.number()
			.typeError(t("validationMsg.fieldRequired"))
			.required(t("validationMsg.fieldRequired")),
		year2: Yup.number()
			.typeError(t("validationMsg.fieldRequired"))
			.required(t("validationMsg.fieldRequired")),
		year3: Yup.number()
			.typeError(t("validationMsg.fieldRequired"))
			.required(t("validationMsg.fieldRequired")),
		aboutUs: Yup.string().required(t("validationMsg.fieldRequired")),
		financialStatement: Yup.mixed().test(
			"required",
			t("validationMsg.fieldRequired"),
			(value) => {
				return value?.length;
			}
		),
		bankName: Yup.string().required(t("validationMsg.fieldRequired")),
		bankAccountNumber: Yup.string().required(t("validationMsg.fieldRequired")),
		taxInvoiceEmail: Yup.string()
			.email(t("signUpValidationMsg.mustBeEmail"))
			.required(t("validationMsg.fieldRequired")),
		bankBookCopy: Yup.mixed().test(
			"required",
			t("validationMsg.fieldRequired"),
			(value) => {
				return value?.length;
			}
		)
	});
