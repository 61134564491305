import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	on: false,
	severity: "error",
	message: "",
	errorType: 0,
	delay: 3000 // 3 seconds
};

const alertSlice = createSlice({
	name: "alert",
	initialState,
	reducers: {
		showAlert: (state, action) => {
			state.on = true;
			state.severity = action.payload.severity;
			state.message = action.payload.message;
			state.delay = action.payload.delay || initialState.delay;

			const errorType = action.payload.errorType || 0;

			switch (errorType) {
				case 0:
					state.severity = action.payload.severity;
					state.message = action.payload.message;
					break;

				case 429:
					state.severity = "error";
					state.message = "Too many login attempts";
					break;

				case 422 || 404:
					state.severity = "error";
					state.message = "Invalid Credentials";
					break;

				default:
					state.severity = "error";
					state.message = "Unable to Login";
					break;
			}
		},

		hideAlert: (state) => {
			state.on = false;
		}
	}
});

export const { showAlert, hideAlert } = alertSlice.actions;
export const alertReducer = alertSlice.reducer;
