const {
	styled,
	Paper,
	Typography,
	Tooltip,
	Box,
	tooltipClasses
} = require("@mui/material");
const { DataGrid } = require("@mui/x-data-grid");

export const TableWrapper = styled(Paper)({
	borderRadius: 9,
	display: "flex",
	height: "100%",
	/* For future changes */
	// paddingLeft: 20,
	// paddingRight: 20,
	// paddingTop: 16,
	// paddingBottom: 16
	padding: 0
});

export const SummaryWrapper = styled(Paper)({
	borderRadius: 9,
	display: "flex",
	height: "100%",
	paddingTop: 24,
	paddingBottom: 22,
	paddingLeft: 46,
	paddingRight: 34,
	marginBottom: 34,
	justifyContent: "space-around"
});

export const ColumnWrapper = styled(Box)({
	flexDirection: "column",
	textAlign: "center"
});

export const NotificationSpot = styled(Box)(({ theme }) => ({
	width: "6px",
	height: "6px",
	borderRadius: "50%",
	backgroundColor: theme.palette.primary.main,
	position: "absolute",
	left: 55,
	top: 0
}));

export const NotificationSaleSpot = styled(Box)(({ theme }) => ({
	width: "6px",
	height: "6px",
	borderRadius: "50%",
	backgroundColor: theme.palette.primary.main,
	position: "absolute",
	left: 43,
	top: 0
}));

export const PositionRelativeBox = styled(Box)({
	position: "relative"
});

export const VerticalLine = styled(Box)(({ theme }) => ({
	borderLeft: `1px solid ${theme.palette.gray.tertiary}`
}));

export const MuiDataGrid = styled(DataGrid)(({ theme }) => ({
	border: "none",
	"& .MuiDataGrid-columnSeparator": {
		display: "none"
	},
	"& .MuiDataGrid-cell": {
		borderBottom: "0.5px solid",
		borderColor: theme.palette.gray.rowCellGray
	},
	/* Future use */
	// "& .MuiDataGrid-cellContent": {
	// 	minWidth: "max-content",
	// 	whiteSpace: "normal"
	// },
	"& .MuiDataGrid-columnHeaderTitleContainer": {
		justifyContent: "left"
	},
	"& .MuiDataGrid-cell:focus-within": {
		outline: "none"
	},
	"& .MuiDataGrid-columnHeaderTitle": {
		fontWeight: "700",
		fontSize: 13
	}
}));

export const PrimaryTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ customcolor, custombg, theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: custombg || theme.palette.primary.main,
		color: customcolor || theme.palette.white,
		boxShadow: theme.shadows[1],
		fontSize: 10
	}
}));

export const List = styled("ul")({
	listStyle: "none",
	padding: 0,
	margin: 0,
	display: "flex",
	justifyContent: "center"
});

export const ListItemBtn = styled(Typography)(({ theme }) => ({
	cursor: "pointer",
	fontSize: 17,
	color: theme.palette.gray.main,
	padding: 5
}));

export const NavBtn = styled("button")(() => ({
	border: "none",
	cursor: "pointer",
	background: "none",
	padding: 5
}));
