import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { showAlert } from "redux/modules";
import { FlatPlate } from "components/layout";
import { Button, PRIMARY, SECONDARY } from "components/layout/Button";
import { StackRow } from "components/shared";
import spreadsheetWhiteIcon from "assets/images/spreadsheet-white.png";
import theme from "theme/theme";
import { PreviewBox } from "components/formComponents/style";
import {
	useImportShippingInfoMutation,
	useLazyDownloadTemplateQuery
} from "services/modules/shipping/request";
import { downloadWithWindow } from "utils/downloadFile";

import PrimaryDialog from "../PrimaryDialog";

const ShippingInformationDialog = ({
	setShippingInfoOpen,
	isShippingInfoOpen
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const {
		register,
		formState: { errors, isDirty },
		handleSubmit,
		reset
	} = useForm({
		defaultValues: {
			importExcel: ""
		}
	});

	const [trigger, { isLoading: downloadingTemplate }] =
		useLazyDownloadTemplateQuery();

	async function onDownloadTemplate() {
		return trigger().then(({ data }) => {
			return downloadWithWindow(data.response.url);
		});
	}

	const [importExcelFile, { isError, isSuccess, isLoading, error }] =
		useImportShippingInfoMutation();

	const onSubmit = (values) => {
		importExcelFile(values);
	};

	useEffect(() => {
		isError &&
			error?.status === 422 &&
			dispatch(
				showAlert({
					severity: "error",
					message: error?.data?.message
				})
			);

		isError &&
			error?.status !== 422 &&
			dispatch(
				showAlert({
					severity: "error",
					message: "Upload Failed"
				})
			);

		isSuccess &&
			dispatch(
				showAlert({
					severity: "success",
					message: "Upload Success"
				})
			);

		isSuccess && setShippingInfoOpen(false);
	}, [isError, isSuccess]);

	return (
		<PrimaryDialog
			header={t("shipping.shippingInfo.header")}
			setDialogOpen={setShippingInfoOpen}
			isDialogOpen={isShippingInfoOpen}
			reset={reset}>
			<form>
				<FlatPlate sx={{ p: 24, pb: 260 }}>
					<Typography pb={20}>
						{t("shipping.shippingInfo.instruction")}
					</Typography>
					<Grid container alignItems="center" pb={20}>
						<Grid
							item
							noWrap
							component={Typography}
							xs={2}
							fontSize={15}
							fontWeight={500}
							color={theme.palette.gray.tertiary}>
							{t("shipping.shippingInfo.shippingRequestForm")}
						</Grid>
						<Grid item xs>
							<Button
								isLoading={downloadingTemplate}
								onClick={onDownloadTemplate}
								color={SECONDARY}
								startIcon={
									<img
										src={spreadsheetWhiteIcon}
										alt="spreadsheet icon white"
									/>
								}>
								{t("shipping.shippingInfo.downloadShippingForm")}
							</Button>
						</Grid>
					</Grid>
					<Grid container alignItems="center" pb={20}>
						<Grid
							item
							noWrap
							component={Typography}
							xs={2}
							fontSize={15}
							fontWeight={500}
							color={theme.palette.gray.tertiary}>
							{t("shipping.shippingInfo.enterShippingInfo")}
						</Grid>
						<Grid item xs>
							<Button
								htmlFor="importExcel"
								sx={{ paddingLeft: 38, paddingRight: 38 }}
								component="label"
								color={PRIMARY}
								startIcon={
									<img
										src={spreadsheetWhiteIcon}
										alt="spreadsheet icon white"
									/>
								}>
								{t("shipping.shippingInfo.fileUpload")}
								<input hidden type="file" />
							</Button>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item visibility="hidden" xs={2}>
							배송 정보 입력
							{/* Invisible placeholder for stable UI purposes */}
						</Grid>
						<Grid item xs>
							<PreviewBox>
								<input
									type="file"
									id="importExcel"
									accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
									{...register("importExcel", {
										required: t("validationMsg.fieldRequired")
									})}
								/>
							</PreviewBox>
						</Grid>
					</Grid>
				</FlatPlate>

				<StackRow justifyContent="flex-end">
					<Button
						isLoading={isLoading}
						disabled={!isDirty || errors?.importExcel?.message ? true : false}
						onClick={handleSubmit(onSubmit)}
						color={PRIMARY}>
						{t("Upload")}
					</Button>
				</StackRow>
			</form>
		</PrimaryDialog>
	);
};

export default ShippingInformationDialog;
