import { forwardRef } from "react";
import { styled } from "@mui/material";

import { CustomNumberField } from "./style";

function FocusedOutlinedComponent(props, ref) {
	return <CustomNumberField ref={ref} focused variant="outlined" {...props} />;
}

export const OutlinedTextInput = styled(forwardRef(FocusedOutlinedComponent))(
	({ focused }) => {
		return {
			"& .MuiInput-underline:after": {
				borderBottomColor: focused ? "black" : "grey"
			},
			"& .MuiOutlinedInput-root": {
				"&.Mui-focused fieldset": {
					borderColor: focused ? "black" : "grey"
				}
			}
		};
	}
);
