import { API_CATEGORY } from "services/endpoints";
import { TAG_CATEGORY } from "services/tags";

export default (build) =>
	build.query({
		query: ({ id }) => {
			return {
				url: `${API_CATEGORY}/${id}`,
				method: "GET"
			};
		},
		providesTags: [TAG_CATEGORY]
	});
