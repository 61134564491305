import { API_RECOMMENDATION } from "services/endpoints";
import { TAG_RECOMMENDATION } from "services/tags";

export default (build) =>
	build.query({
		query: ({ currentPage, perPage = 10 }) => {
			return {
				url: API_RECOMMENDATION,
				params: {
					page: currentPage,
					per_page: perPage
				},
				method: "GET"
			};
		},
		providesTags: [TAG_RECOMMENDATION]
	});
