import { Box } from "@mui/material";

import { absoluteCenter } from "components/shared";
import { useUser } from "redux/modules/useUser";

const Dashboard = () => {
	const { isSuperAdmin } = useUser();

	return (
		<Box sx={absoluteCenter}>
			{isSuperAdmin ? "Super Admin Dashboard" : "Partner Admin Dashboard"}
		</Box>
	);
};

export default Dashboard;
