import * as Yup from "yup";

export function isNumber(t, value, ctx) {
	if (!ctx.schema.isType(value)) {
		return ctx.createError({
			message: t("This field should be a number")
		});
	}

	return true;
}

export const numberOrUndefined = (value) => (isNaN(value) ? undefined : value);

export const LoginSchema = (t) =>
	Yup.object().shape({
		email: Yup.string()
			.email(t("validationMsg.validEmail"))
			.required(t("validationMsg.emailRequired")),
		password: Yup.string()
			.min(8, t("login.eightOrMore"))
			.required(t("validationMsg.pwRequired"))
	});

export const CategoryValidationSchema = (t) =>
	Yup.object().shape({
		name: Yup.string().required(t("validationMsg.categoryRequired"))
	});

export const appUploadValidationSchema = (t) =>
	Yup.object().shape({
		application: Yup.string().required(t("validationMsg.appRequired"))
	});

export const productCreateStep2ValidationSchema = (t) =>
	Yup.object().shape({
		fundingName: Yup.string().required(t("validationMsg.fieldRequired")),
		name: Yup.string().required(t("validationMsg.fieldRequired")),
		brand: Yup.string().required(t("validationMsg.fieldRequired")),
		quantity: numberType(t),
		unitPrice: numberType(t),
		investStart: Yup.string().required(t("validationMsg.fieldRequired")),
		investEnd: Yup.string().required(t("validationMsg.fieldRequired")),
		targetQuantity: numberType(t),
		domesticPrice: numberType(t),
		overseaPrice: numberType(t),
		expectedProfit: numberType(t),
		tagList: Yup.array().min(1, t("validationMsg.fieldRequired")),
		countryId: Yup.string().required(t("validationMsg.fieldRequired")),
		description: Yup.string().required(t("validationMsg.fieldRequired")),
		saleStartDate: Yup.string().required(t("validationMsg.fieldRequired")),
		saleDeadline: Yup.string().required(t("validationMsg.fieldRequired")),
		expectedDeliveryDate: Yup.string().required(
			t("validationMsg.fieldRequired")
		),
		category: Yup.string().required(t("validationMsg.categoryRequired")),
		category1: Yup.string().required(t("validationMsg.categoryRequired")),
		category2: Yup.string().required(t("validationMsg.categoryRequired")),
		productImgNotice: Yup.mixed().required(t("validationMsg.insertImage"))
	});
// validations interfering with api call are commented out for functionality

export const brandRequestValidationSchema = (t) =>
	Yup.object({
		koreanName: Yup.string().required(t("validationMsg.fieldRequired")),
		name: Yup.string().required(t("validationMsg.fieldRequired"))
	});

export const brandCreateValidationSchema = (t) =>
	Yup.object({
		koreanName: Yup.string().required(t("validationMsg.fieldRequired")),
		name: Yup.string().required(t("validationMsg.fieldRequired")),
		description: Yup.string().required(t("validationMsg.fieldRequired")),
		photo: Yup.array().nullable().required(t("validationMsg.insertImage"))
	});

export const primaryAlertUserMessageValidation = (t) =>
	Yup.object({
		userMessageInput: Yup.string().required(t("validationMsg.fieldRequired"))
	});

export const raiseLimitValidationSchema = (t) =>
	Yup.object({
		raisedAmount: Yup.number()
			.integer()
			.positive(t("validationMsg.mustBePositive"))
			.transform(numberOrUndefined)
			.required(t("validationMsg.fieldRequired"))
	});

export const partnerMessageValidation = (t) =>
	Yup.object({
		title: Yup.string().max(40).required(t("validationMsg.fieldRequired")),
		content: Yup.string().max(500).required(t("validationMsg.fieldRequired"))
	});

export const exportDeliveryInfoSchema = (t) =>
	Yup.object({
		manager: Yup.string().required(t("validationMsg.fieldRequired")),
		contact1: Yup.string().required(t("validationMsg.fieldRequired")),
		contact2: Yup.string().required(t("validationMsg.fieldRequired")),
		address: Yup.string().required(t("validationMsg.fieldRequired")),
		detailedAddress: Yup.string().required(t("validationMsg.fieldRequired"))
	});

export const numberType = (t) =>
	Yup.number()
		.typeError(t("validationMsg.mustBePositive"))
		.integer(t("Only round numbers are supported"))
		.test((...args) => isNumber(t, ...args))
		.required(t("validationMsg.fieldRequired"));

export const productCreateStepTwo = (t) => {
	return Yup.object({
		brandId: Yup.string().required(t("validationMsg.fieldRequired")),
		domesticPrice: numberType(t),
		expectedDeliveryDate: Yup.string().required(
			t("validationMsg.fieldRequired")
		),
		expectedProfit: numberType(t),
		fundingName: Yup.string()
			.max(255)
			.required(t("validationMsg.fieldRequired")),
		investEnd: Yup.string().required(t("validationMsg.fieldRequired")),
		investmentPrice: Yup.number(),
		investStart: Yup.string().required(t("validationMsg.fieldRequired")),
		name: Yup.string().max(255).required(t("validationMsg.fieldRequired")),
		overseaPrice: numberType(t),
		quantity: numberType(t),
		raisedAmount: numberType(t),
		recruitmentPeriod: Yup.string().required(t("validationMsg.fieldRequired")),
		serviceExposure: Yup.string().required(t("validationMsg.fieldRequired")),
		saleDeadline: Yup.string().required(t("validationMsg.fieldRequired")),
		saleStartDate: Yup.string().required(t("validationMsg.fieldRequired")),
		targetQuantity: numberType(t),
		unitPrice: numberType(t),
		toc: Yup.object()
			.shape({
				termsAccepted: Yup.bool(),
				partnersResponsibilities: Yup.bool()
			})
			.test((value, ctx) => {
				if (
					!("termsAccepted" in value) ||
					!("partnersResponsibilities" in value)
				) {
					return ctx.createError({
						message: t("validationMsg.fieldRequired")
					});
				}

				if (!value.termsAccepted || !value.partnersResponsibilities) {
					return ctx.createError({
						message: t("All fields should be selected")
					});
				}

				return true;
			})
			.required(t("validationMsg.fieldRequired")),
		minRecruitmentQty: numberType(t)
	});
};

export const productCreateStepThree = (t) => {
	const allOrNone = t("All or none in the group must be filled");
	const required = t("validationMsg.fieldRequired");

	return Yup.object({
		countryId: Yup.string().required(required),
		categoryList: Yup.array()
			.of(Yup.mixed())
			.transform((val) => val.filter((v) => v))
			.length(2, t("Both parent and child categories are required"))
			.required(required),
		defaultPhotoId: Yup.number().integer(),
		deletedPhotos: Yup.array().of(Yup.string()),
		deletedRelatedInfoIds: Yup.array().of(Yup.string()),
		description: Yup.string().max(1000).required(required),
		photoId: Yup.number().integer(),
		photos: Yup.array()
			.of(
				Yup.object({
					photo: Yup.string(),
					status: Yup.number().integer()
				})
			)
			.test(function (val, ctx) {
				if (val.length === 0) {
					return ctx.createError({ message: required });
				}
				return true;
			})
			.required(required),
		relatedInfoId: Yup.number(),
		relatedInfos: Yup.array().of(
			Yup.object({
				photo: Yup.string().test((val, ctx) => {
					if (!val && (ctx.parent.url || ctx.parent.source)) {
						return ctx.createError({
							message: allOrNone
						});
					}

					return true;
				}),
				source: Yup.string().test((val, ctx) => {
					if (!val && (ctx.parent.photo || ctx.parent.url)) {
						return ctx.createError({
							message: allOrNone
						});
					}

					return true;
				}),
				url: Yup.string()
					.url(t("validationMsg.requiredURL"))
					.test((val, ctx) => {
						if (!val && (ctx.parent.photo || ctx.parent.source)) {
							return ctx.createError({
								message: allOrNone
							});
						}

						return true;
					})
			})
		),
		tagList: Yup.array()
			.of(Yup.string())
			.test(function (val, ctx) {
				if (val.length === 0) {
					return ctx.createError({ message: required });
				}

				return true;
			})
			.required(required)
			.max(10)
	});
};

export const individualProductValidationSchema = (t) => {
	return Yup.object().shape({
		product: Yup.array().of(
			Yup.object().shape({
				recipient: Yup.string().required(t("validationMsg.fieldRequired")),
				address: Yup.string().required(t("validationMsg.fieldRequired")),
				address_detail: Yup.string().required(t("validationMsg.fieldRequired")),
				contact_number_1: Yup.string().required(
					t("validationMsg.fieldRequired")
				),
				contact_number_2: Yup.string().required(
					t("validationMsg.fieldRequired")
				),
				shipping_note: Yup.string().required(t("validationMsg.fieldRequired"))
			})
		)
	});
};

export const requestReceiptInfoSchema = (t) => {
	return Yup.object().shape({
		stockingDate: Yup.string().required(t("validationMsg.fieldRequired")),
		quantity: Yup.number()
			.required(t("validationMsg.fieldRequired"))
			.positive(t("validationMsg.mustBePositive")),
		stockingTime: Yup.string().required(t("validationMsg.fieldRequired")),
		manager: Yup.string().required(t(t("validationMsg.fieldRequired"))),
		contact: Yup.string().required(t(t("validationMsg.fieldRequired")))
	});
};

// use this is future
// Yup.setLocale({
// 	string: {
// 		url: () => "validationMsg.invalidType"
// 	}
// });
