import axios from "axios";
import { authenticationStatusChange, resetAuth } from "redux/modules";
import { wrapResponse } from "./utils";

export const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_OWNERYA_ADMIN_API,
	headers: {
		accept: "application/json"
	}
});

axiosInstance.defaults.withCredentials = true;

export const baseQuery =
	(wrap = true) =>
	async (requestOpts, { dispatch }) => {
		try {
			const result = await axiosInstance({
				...requestOpts
			});

			const data = wrap
				? wrapResponse(result.data, requestOpts)
				: {
						response: result.data,
						req: requestOpts
				  };

			return { data };
		} catch (axiosError) {
			const err = axiosError;
			if (err.response?.status === 403 || err.response?.status === 401) {
				dispatch(authenticationStatusChange(false));
				dispatch(resetAuth());
			}
			return {
				error: { status: err.response?.status, data: err.response?.data }
			};
		}
	};

export const customBaseQuery = baseQuery();
