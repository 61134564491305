import { Backdrop, CircularProgress } from "@mui/material";

const BackDropLoader = ({ open }) => {
	return (
		<Backdrop
			sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={open}>
			<CircularProgress color="primary" />
		</Backdrop>
	);
};

export default BackDropLoader;
