import { api } from "services/api";
import getRecommendations from "./getRecommendations";

export const recommendationApi = api.injectEndpoints({
	endpoints: (build) => ({
		getRecommendations: getRecommendations(build)
	}),
	overrideExisting: false
});

export const { useGetRecommendationsQuery } = recommendationApi;
