import { API_SIGNUP } from "services/endpoints";

export default (build) =>
	build.mutation({
		query: ({
			newEmail,
			newPassword,
			businessName,
			businessRegistration,
			businessPhoneNumber,
			businessExtension,
			representativeName,
			representativeEmail,
			representativeCountryCode,
			representativePhoneNumber,
			representativeExtension,
			managerName,
			managerEmail,
			managerCountryCode,
			managerPhoneNumber,
			managerExtension,
			postalCode,
			companyAddress,
			companyDetailedAddress,
			contactCountryCode,
			contactPhoneNumber,
			contactExtension,
			businessLicense,
			companyProfile,

			companyStartDate,
			year1,
			year2,
			year3,
			aboutUs,
			financialStatement,
			bankName,
			bankAccountNumber,
			taxInvoiceEmail,
			bankBookCopy
		}) => {
			return {
				url: API_SIGNUP,
				method: "POST",
				headers: {
					accept: "application/json",
					"content-type": "multipart/form-data"
				},
				data: {
					email: newEmail,
					password: newPassword,
					name: businessName,
					business_number: `${businessRegistration}${businessPhoneNumber}${businessExtension}`,
					representative_name: representativeName,
					representative_email: representativeEmail,
					representative_contact: `${representativeCountryCode}${representativePhoneNumber}${representativeExtension}`,
					contact_name: managerName,
					contact_email: managerEmail,
					contact_number: `${managerCountryCode}${managerPhoneNumber}${managerExtension}`,
					business_license: businessLicense[0],
					company_profile: companyProfile[0],
					postal_code: postalCode,
					company_address: companyAddress,
					company_address_detail: companyDetailedAddress,
					company_phone_number: `${contactCountryCode}${contactPhoneNumber}${contactExtension}`,

					established_at: companyStartDate,
					year_one_turn_over: year1,
					year_two_turn_over: year2,
					year_three_turn_over: year3,
					about_us: aboutUs,
					financial_statement: financialStatement[0],
					bank_name: bankName,
					bank_account_number: bankAccountNumber,
					tax_invoice_email: taxInvoiceEmail,
					copy_of_passport: bankBookCopy[0]
				}
			};
		}
	});
