import React from "react";

const PartnerIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M20 20H4C2.346 20 1 18.654 1 17V7C1 5.346 2.346 4 4 4H20C21.654 4 23 5.346 23 7V17C23 18.654 21.654 20 20 20ZM4 5.5C3.449 5.5 2.5 6.449 2.5 7V17C2.5 17.551 3.449 18.5 4 18.5H20C20.551 18.5 21.5 17.551 21.5 17V7C21.5 6.449 20.551 5.5 20 5.5H4ZM10 15H6C5.73478 15 5.48043 14.8946 5.29289 14.7071C5.10536 14.5196 5 14.2652 5 14C5 13.7348 5.10536 13.4804 5.29289 13.2929C5.48043 13.1054 5.73478 13 6 13H10C10.2652 13 10.5196 13.1054 10.7071 13.2929C10.8946 13.4804 11 13.7348 11 14C11 14.2652 10.8946 14.5196 10.7071 14.7071C10.5196 14.8946 10.2652 15 10 15ZM10 11H6C5.73478 11 5.48043 10.8946 5.29289 10.7071C5.10536 10.5196 5 10.2652 5 10C5 9.73478 5.10536 9.48043 5.29289 9.29289C5.48043 9.10536 5.73478 9 6 9H10C10.2652 9 10.5196 9.10536 10.7071 9.29289C10.8946 9.48043 11 9.73478 11 10C11 10.2652 10.8946 10.5196 10.7071 10.7071C10.5196 10.8946 10.2652 11 10 11Z"
				fill="black"
			/>
			<path
				d="M16 12.5C17.1046 12.5 18 11.6046 18 10.5C18 9.39543 17.1046 8.5 16 8.5C14.8954 8.5 14 9.39543 14 10.5C14 11.6046 14.8954 12.5 16 12.5Z"
				fill="black"
			/>
			<path
				d="M16 13.3564C14.438 13.3564 13.5 14.0714 13.5 14.7854C13.5 15.1424 14.438 15.5004 16 15.5004C17.466 15.5004 18.5 15.1434 18.5 14.7854C18.5 14.0714 17.52 13.3564 16 13.3564Z"
				fill="black"
			/>
		</svg>
	);
};

export default PartnerIcon;
