import { Dialog, DialogContentText, styled } from "@mui/material";

export const CustomDialogContentText = styled(DialogContentText)({
	fontSize: 14
});

export const CustomDialogWrapper = styled(Dialog)({
	"& .MuiDialog-container": {
		"& .MuiPaper-root": {
			maxWidth: "100%",
			borderRadius: 10
		}
	}
});

export const PrimaryDialogMessage = styled(DialogContentText)({
	fontSize: 18,
	fontWeight: 600,
	textAlign: "center"
});

export const PrimaryDialogSubMessage = styled(DialogContentText)({
	fontSize: 14,
	fontWeight: 500,
	textAlign: "center"
});
