import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	orderData: {},
	isOrderPaymentDialogOn: false,
	shippingInfoId: ""
};

const shippingOrderSlice = createSlice({
	name: "orderPayment",
	initialState,
	reducers: {
		saveOrderPaymentPayload: (state, { payload }) => {
			state.orderData = payload.orderData;
		},
		openOrderPaymentDialog: (state, { payload }) => {
			state.isOrderPaymentDialogOn = true;
			state.shippingInfoId = payload.shippingInfoId;
			state.orderData = payload.shippingInfoData;
		},
		closeOrderPaymentDialog: (state) => {
			state.isOrderPaymentDialogOn = false;
			state.shippingInfoId = "";
			state.orderData = {};
		}
	}
});

export const selectOrderPayment = (state) => state.orderPayment || {};

export const {
	saveOrderPaymentPayload,
	openOrderPaymentDialog,
	closeOrderPaymentDialog
} = shippingOrderSlice.actions;

export const orderPaymentReducer = shippingOrderSlice.reducer;
