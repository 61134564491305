import { Controller } from "react-hook-form";
import { InputAdornment, Stack, Typography } from "@mui/material";

import ErrorMessage from "./ErrorMessage";
import { CustomNumberField } from "./style";
import { CurrencyInput } from "./CurrencyInput";

export const FormInputText = ({
	name,
	control,
	type,
	placeholder,
	textAlignCenter = 0,
	width,
	currentWordCount,
	isTextarea = false,
	disabled,
	maxLength,
	readOnly,
	errorMessage = "",
	autoFocusOn,
	endAdornment,
	isCurrencyType,
	onBlur,
	withTextIndicator,
	noLengthIndicator,
	...restProps
}) => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { value, ...rest }, fieldState }) => {
				return (
					<Stack {...restProps}>
						<CustomNumberField
							{...rest}
							onBlur={onBlur}
							multiline={isTextarea && true}
							placeholder={placeholder}
							value={value || ""}
							minRows={4}
							type={type}
							size="small"
							error={
								errorMessage || value ? !!errorMessage : !!fieldState.error
							}
							fullWidth
							disabled={disabled}
							placement={textAlignCenter}
							variant="outlined"
							onInput={(e) => {
								if (maxLength) {
									e.target.value = e.target.value.substring(0, maxLength);
								}
							}}
							InputProps={{
								inputComponent: isCurrencyType && CurrencyInput,
								inputProps: { min: 0, style: { width: width } },
								readOnly: readOnly,
								endAdornment: maxLength ? (
									<InputAdornment position="end">
										<Typography
											component="span"
											color={withTextIndicator ? "" : "primary"}>
											{currentWordCount}
										</Typography>
										<Typography component="span">
											{!noLengthIndicator && `/${maxLength}`}
										</Typography>
										{withTextIndicator && (
											<Typography component="span">자</Typography>
										)}
										{/* Temp solution for UI */}
									</InputAdornment>
								) : (
									endAdornment && (
										<InputAdornment position="end">
											<Typography component="span">{endAdornment}</Typography>
										</InputAdornment>
									)
								)
							}}
							autoFocus={autoFocusOn}
						/>
						{disabled
							? null
							: fieldState.error && (
									<ErrorMessage>{fieldState.error.message}</ErrorMessage>
							  )}
					</Stack>
				);
			}}
		/>
	);
};
