import { generatePath, useNavigate } from "react-router-dom";

import DataTable from "components/table/DataTable";
import { ROUTE_PARTNER_DETAILS } from "data/routeVariables";

import useColumns from "./useColumns";
import { savePartnerData } from "redux/modules";
import { useDispatch } from "react-redux";
import { useGetPartnersQuery } from "services/modules/partner";

export default function useBody(filters, page) {
	const result = useGetPartnersQuery({
		currentPage: page,
		...filters
	});

	return [
		result,
		() => {
			const columns = useColumns();
			const dispatch = useDispatch();
			const navigate = useNavigate();

			const rows = result?.data?.response?.data || [];

			const goToPartnerDetails = (rowData) => {
				const partnerDetailsLink = generatePath(ROUTE_PARTNER_DETAILS, {
					partnerId: rowData.row.id
				});

				dispatch(savePartnerData({ ...rowData?.row?.team }));
				navigate(partnerDetailsLink);
			};

			const onRowsSelection = (ids) => {
				if (ids.length === 0) return;

				const selectedRows = ids.map((id) => rows.find((row) => row.id === id));
				dispatch(savePartnerData(selectedRows[0]));
			};

			return (
				<DataTable
					columns={columns}
					rows={rows}
					isLoading={result.isFetching}
					onRowClick={goToPartnerDetails}
					isRenderCheckBox={true}
					onRowsSelection={onRowsSelection}
				/>
			);
		}
	];
}
