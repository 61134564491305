import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/system";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import theme from "./theme/theme";
import { store, persistor } from "./redux/store";

import "./index.css";
import "./i18n";

export const AppMainWrapper = ({ children }) => (
	<ThemeProvider theme={theme}>
		<BrowserRouter>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					{children}
				</PersistGate>
			</Provider>
		</BrowserRouter>
	</ThemeProvider>
);

createRoot(document.getElementById("root")).render(
	<AppMainWrapper>
		<App />
	</AppMainWrapper>
);
