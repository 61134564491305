import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	on: false,
	categoryName: "",
	id: 0
};

const categorySlice = createSlice({
	name: "category",
	initialState,
	reducers: {
		setCurrentCategory: (state, { payload }) => {
			state.currentCategory = payload;
		},
		openDeleteCategoryDialog: (state, { payload }) => {
			state.on = true;
			state.id = payload.id;
		},
		closeDeleteCategoryDialog: (state) => {
			state.on = false;
			state.name = "";
		}
	}
});

export const selectCategory = (state) => state.category || {};

export const {
	setCurrentCategory,
	openDeleteCategoryDialog,
	closeDeleteCategoryDialog
} = categorySlice.actions;

export const categoryReducer = categorySlice.reducer;
