import { Avatar, Divider, Grid, Stack, Typography } from "@mui/material";
import { BasePlate } from "components/layout";
import { Button, SECONDARY } from "components/layout/Button";
import { StackRow } from "components/shared";
import { DataDisplay } from "pages/partner/MainInfo";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import numberToThousandComma from "utils/numberToThousandComma";

const OwnerDetailsSummary = ({ ownerData }) => {
	const { t } = useTranslation();

	const [isDetailsOpen, setDetailsOpen] = useState(false);

	return (
		<BasePlate sx={{ p: 25 }}>
			<StackRow justifyContent="space-between">
				<StackRow alignItems="center" gap={12}>
					<Avatar
						alt="profile"
						src={ownerData?.photos.path}
						sx={{ width: 40, height: 40 }}
					/>
					<Grid
						container
						direction={{ md: "column", lg: "row" }}
						gap={12}
						sx={{ opacity: 0.6 }}
						divider={
							<Divider orientation="vertical" variant="middle" flexItem />
						}>
						<Typography>{ownerData?.name}</Typography>
						<Typography>{ownerData?.code}</Typography>

						<Typography>{`${t("owner.rating")} :`}</Typography>
						<StackRow
							alignItems="center"
							onClick={() => setDetailsOpen(!isDetailsOpen)}>
							<Typography>{t("owner.moreInfo")}</Typography>
							{isDetailsOpen ? (
								<KeyboardArrowDownIcon />
							) : (
								<KeyboardArrowUpIcon />
							)}
						</StackRow>
					</Grid>
				</StackRow>
				<StackRow gap={12} alignItems="center">
					<Button sx={{ paddingX: 33, paddingY: 12 }}>
						{t("owner.suspensionOfActivity")}
					</Button>
					<Button sx={{ paddingX: 33, paddingY: 12 }}>
						{t("owner.gradeChange")}
					</Button>
					<Button sx={{ paddingX: 33, paddingY: 12 }}>
						{t("owner.mileagePayment")}
					</Button>
					<Button color={SECONDARY} sx={{ paddingX: 33, paddingY: 12 }}>
						{t("owner.sendMessage")}
					</Button>
				</StackRow>
			</StackRow>
			{isDetailsOpen && (
				<Stack>
					<Grid container gap={60} pt={20}>
						<Grid item xs>
							<Stack gap={10}>
								<DataDisplay
									attribute={t("owner.email")}
									data={ownerData?.email}
								/>
								<DataDisplay
									attribute={t("owner.cellPhone")}
									data={ownerData?.phoneNumber}
								/>
							</Stack>
						</Grid>

						<Grid item xs>
							<Stack gap={10}>
								<DataDisplay
									attribute={t("owner.accountNumber")}
									data={ownerData?.bankAccount}
								/>
								<DataDisplay
									attribute={t("owner.holdingAmount")}
									data={`${
										numberToThousandComma(ownerData?.holdingAmount) || 0
									} ${t("KRW")}`}
								/>
								<DataDisplay
									attribute={t("owner.mileage")}
									data={ownerData?.mileage || 0}
								/>
							</Stack>
						</Grid>
						<Grid item xs>
							<Stack gap={10}>
								<DataDisplay
									attribute={t("owner.totalLogin")}
									data={ownerData?.cumulativeLoginNumber}
								/>
								<DataDisplay
									attribute={t("owner.totalInvestments")}
									data={ownerData?.cumulativeInvestmentNumber}
								/>
								<DataDisplay
									attribute={t("owner.totalTrades")}
									data={ownerData?.cumulativeTradeNumber}
								/>
							</Stack>
						</Grid>
						<Grid item xs>
							<Stack gap={10}>
								<DataDisplay
									attribute={t("owner.overallReturnRate")}
									data={ownerData?.totalReturn}
								/>
								<DataDisplay
									attribute={t("owner.marketReturnRate")}
									data={ownerData?.marketReturn}
								/>
								<DataDisplay
									attribute={t("owner.tradeReturn")}
									data={ownerData?.tradeReturn}
								/>
							</Stack>
						</Grid>
					</Grid>
					<StackRow
						pt={22}
						gap={24}
						divider={
							<Divider orientation="vertical" variant="middle" flexItem />
						}>
						<Typography variant="text15" sx={{ opacity: 0.6 }}>
							{t("Last login")} : ?
						</Typography>
						<Typography variant="text15" sx={{ opacity: 0.6 }}>
							{t("Since")} : ?
						</Typography>
						<Typography variant="text15" sx={{ opacity: 0.6 }}>
							{t("Marketing Consent")} : ?
						</Typography>
					</StackRow>
				</Stack>
			)}
		</BasePlate>
	);
};

export default OwnerDetailsSummary;
