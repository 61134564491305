import { useState } from "react";
import { Typography, Grid, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import FormInputSelect from "components/formComponents/FormInputSelect";
import FormInputDatePicker from "components/formComponents/FormInputDatePicker";

import { SearchInputWithButton } from "./SearchInputWithButton";
import { StackRow } from "..";
import { DATE_FORMAT_ONE, format } from "utils/formatDate";

export default function ListFilter() {
	const { t } = useTranslation();

	const { control, setValue, watch } = useFormContext();

	const [start, end] = watch(["dateFilterStart", "dateFilterEnd"]);

	const [searchValue, setSearchValue] = useState("");

	const handleSearchSubmit = () => {
		searchValue && setValue("search", searchValue);
	};

	const handleClearSearch = () => {
		setValue("search", "");
		setSearchValue("");
	};

	return (
		<>
			<Grid container alignItems="center" gap={15} mb={20}>
				<Grid item>
					<FormInputSelect
						name="dateFilterType"
						width={150}
						height={44}
						variant="text15"
						fontWeight={500}
						control={control}
						options={[
							{
								label: t("funding.dateFilter.fundingStartDate"),
								value: "created_at"
							},
							{
								label: t("funding.dateFilter.investStartDate"),
								value: "invest_start"
							},
							{
								label: t("funding.dateFilter.investEndDate"),
								value: "invest_end"
							},
							{
								label: t("funding.dateFilter.saleStartDate"),
								value: "sale_start"
							},
							{
								label: t("funding.dateFilter.saleDeadlineDate"),
								value: "sale_deadline"
							}
						]}
						setValue={setValue}
					/>
				</Grid>
				<Grid item>
					<FormInputDatePicker
						name="dateFilterStart"
						control={control}
						onChange={(date) => {
							setValue("dateFilterStart", format(date, DATE_FORMAT_ONE));
						}}
					/>
				</Grid>
				<Typography component="h2" variant="h2">
					~
				</Typography>
				<Grid item>
					<FormInputDatePicker
						name="dateFilterEnd"
						control={control}
						onChange={(date) => {
							setValue("dateFilterEnd", format(date, DATE_FORMAT_ONE));
						}}
					/>
				</Grid>

				{start && end && (
					<Tooltip
						title="Reset date"
						onClick={() => {
							setValue("dateFilterStart", "");
							setValue("dateFilterEnd", "");
						}}>
						<CloseIcon sx={{ cursor: "pointer" }} />
					</Tooltip>
				)}

				<Grid item xs>
					<StackRow gap={5}>
						<SearchInputWithButton
							autoFocus={false}
							searchValue={searchValue}
							setSearchValue={setSearchValue}
							handleSearchSubmit={handleSearchSubmit}
							handleClearSearch={handleClearSearch}
						/>
					</StackRow>
				</Grid>
			</Grid>
		</>
	);
}
