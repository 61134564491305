import closeIcon from "assets/images/shared/close-icon.png";
import { Box, Dialog, Typography } from "@mui/material";
import { StackRow } from "components/shared";
import theme from "theme/theme";
import { useDispatch } from "react-redux";

const PrimaryDialog = ({
	width = 800,
	header,
	subTitle,
	isDialogOpen,
	setDialogOpen,
	reset,
	clearErrors,
	closeDialogFromState,
	children,
	renderStatus,
	renderHeaderDate
}) => {
	const dispatch = useDispatch();

	return (
		<Dialog
			PaperProps={{
				style: {
					position: "relative",
					width,
					borderRadius: 20,
					backgroundColor: theme.palette.gray.dialogBg
				}
			}}
			open={isDialogOpen}>
			<Box sx={{ p: 30 }}>
				<StackRow justifyContent="space-between" alignItems="center" pb={24}>
					<StackRow alignItems="center" gap={10}>
						<Typography component="h2" variant="h2">
							{header}
						</Typography>
						{renderStatus && renderStatus}
					</StackRow>

					<StackRow>
						{renderHeaderDate}
						<img
							src={closeIcon}
							alt="closeIcon"
							style={{ cursor: "pointer" }}
							onClick={() => {
								setDialogOpen && setDialogOpen(false);
								closeDialogFromState && dispatch(closeDialogFromState());
								reset && reset();
								clearErrors && clearErrors();
							}}
						/>
					</StackRow>
				</StackRow>
				<Typography fontSize={17} fontWeight={500} pb={12}>
					{subTitle}
				</Typography>
				{children}
			</Box>
		</Dialog>
	);
};

export default PrimaryDialog;
