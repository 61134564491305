import { API_SEND_OTP } from "services/endpoints";

export default (build) =>
	build.mutation({
		query: ({ newEmail: email }) => {
			return {
				url: API_SEND_OTP,
				method: "POST",
				data: {
					email
				}
			};
		},
		invalidatesTags: []
	});
