import { API_DOWNLOAD_TEMPLATE } from "services/endpoints";
import { TAG_SHIPPING } from "services/tags";

export default (build) =>
	build.query({
		query: () => {
			return {
				url: API_DOWNLOAD_TEMPLATE,
				method: "GET"
			};
		},
		providesTags: [TAG_SHIPPING]
	});
