import { Stack } from "@mui/material";

export const StackRow = (props) => {
	const { children, ...rest } = props;

	return (
		<Stack direction="row" {...rest}>
			{children}
		</Stack>
	);
};
