import { createTheme } from "@material-ui/core";
import { pink } from "@mui/material/colors";

const theme = createTheme({
	palette: {
		type: "light",
		text: {
			primary: "#191919",
			secondary: "#818181",
			tertiary: "#585858",
			main: "#e95a31",
			error: "#FF0000",
			black: "#000000",
			white: "#FFFFFF",
			placeholder: "#969696",
			required: "#f44336",
			success: "#22bb33",
			gray: "#6F6F6F"
		},
		primary: {
			mainBg: "#EDEDED",
			main: "#e95a31",
			light: "#e95a31",
			sidebar: "#e95a3133"
		},
		secondary: {
			main: "#191919",
			light: "#C8C8C8"
		},
		gray: {
			main: "#BCBCBC",
			secondary: "#D9D9D9",
			tertiary: "#818181",
			profileGray: "#363636",
			rowCellGray: "#0000001f",
			dialogBg: "#F0F0F0",
			textBoxBg: "#E8E8E8"
		},
		button: {
			light: "#F4774C",
			gray: "#BCBCBC",
			white: "#ffffff",
			error: "#FF0000"
		},
		border: {
			focus: "#F4774C"
		},
		white: "#fff",
		error: {
			main: "#FF0000"
		},
		weakBlack: {
			main: "#191919"
		},
		weakGray: {
			main: "#EDEDED"
		}
	},
	status: {
		danger: pink
	},
	components: {
		SecondaryBtn: {
			styleOverrides: {
				root: {
					backgroundColor: "black"
				}
			}
		}
	},
	typography: {
		errorMsg: {
			fontSize: "10px",
			color: "red"
		},
		h2: {
			fontSize: "26px",
			fontWeight: 500
		},
		text10: {
			fontSize: "10px"
		},
		text12: {
			fontSize: "12px"
		},
		text13: {
			fontSize: "13px"
		},
		text14: {
			fontSize: "14px"
		},
		text17: {
			fontSize: "17px"
		},
		text15: {
			fontSize: "15px"
		},
		tableHeader: {
			fontSize: "13px"
		},
		700: {
			fontWeight: 700
		}
	},

	spacing: 1
});

export default theme;
