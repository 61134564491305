const { styled, Box, Stack } = require("@mui/material");

export const RootStyle = styled(Box)({
	backgroundColor: "white",
	height: "100vh",
	display: "grid"
});

export const ContentStyle = styled(Stack)(() => ({
	maxWidth: 600,
	margin: "auto",
	background: "white"
}));

export const SignUpContentStyle = styled(Stack)(() => ({
	maxWidth: 800,
	margin: "auto",
	background: "white"
}));

export const SignUpRootStyle = styled(Box)({
	backgroundColor: "white",
	height: "100%",
	display: "grid"
});
