import { API_MESSAGE_PARTNER } from "services/endpoints";
import { TAG_PARTNER } from "services/tags";

export default (build) =>
	build.mutation({
		query: ({ title, content, partnerId }) => {
			return {
				url: API_MESSAGE_PARTNER,
				method: "POST",
				data: {
					title,
					content,
					partner_id: partnerId
				}
			};
		},
		invalidatesTags: [TAG_PARTNER]
	});
