import {
	API_FUNDING,
	API_FUNDING_STEP_ONE,
	API_FUNDING_STEP_TWO
} from "services/endpoints";
import { TAG_FUNDING, TAG_PRODUCT } from "services/tags";
import { camelToSnakeCase } from "utils/string";

export const stepTwo = (build) => {
	return build.mutation({
		query(input) {
			const data = Object.keys(input).reduce((init, key) => {
				const snake_cased = camelToSnakeCase(key);

				init[snake_cased] = input[key];

				return init;
			}, {});

			return {
				url: API_FUNDING_STEP_ONE,
				method: "POST",
				data
			};
		},

		transformResponse({ response: { data } }) {
			return data;
		},

		invalidatesTags: [TAG_FUNDING]
	});
};

export const stepThree = (build) => {
	return build.mutation({
		query(input) {
			const data = Object.keys(input).reduce((init, key) => {
				const snake_cased = camelToSnakeCase(key);

				if (key === "relatedInfos") {
					const relatedInfos = input.relatedInfos.filter(
						(info) => info.photo && info.source && info.url
					);

					init[snake_cased] = relatedInfos;
				} else if (key === "categoryList") {
					const categoryList = input.categoryList.filter((info) => info);

					init[snake_cased] = categoryList;
				} else {
					init[snake_cased] = input[key];
				}

				return init;
			}, {});

			return {
				url: API_FUNDING_STEP_TWO,
				method: "POST",
				data
			};
		},

		invalidatesTags: [TAG_FUNDING]
	});
};

export default (build) =>
	build.mutation({
		query: ({
			name,
			fundingName,
			quantity,
			description,
			investmentPrice,
			unitPrice,
			investStart,
			investEnd,
			targetQuantity,
			domesticPrice,
			overseaPrice,
			expectedProfit,
			countryId,
			expectedDeliveryDate,
			saleStartDate,
			saleDeadline,
			tagList,
			funding,
			categoryList,
			productImage,
			defaultPhotoId
		}) => {
			const fundingArray = [];
			const {
				info1Photo,
				info1Source,
				info1URL,
				info2Photo,
				info2Source,
				info2URL,
				info3Photo,
				info3Source,
				info3URL
			} = funding?.createProduct2?.relatedInfo || {};
			if (info1Photo) {
				fundingArray.push([
					{ photo: info1Photo.data_url },
					{ source: info1Source },
					{ url: info1URL }
				]);
			}
			if (info2Photo) {
				fundingArray.push([
					{ photo: info2Photo.data_url },
					{ source: info2Source },
					{ url: info2URL }
				]);
			}
			if (info3Photo) {
				fundingArray.push([
					{ photo: info3Photo.data_url },
					{ source: info3Source },
					{ url: info3URL }
				]);
			}
			return {
				url: API_FUNDING,
				method: "POST",
				data: {
					name: name,
					funding_name: fundingName,
					funding_code: "A0135234564",
					// Todo
					brand_id: 6,
					// Todo
					investment_price: investmentPrice,
					quantity: quantity,
					description: description,
					unit_price: unitPrice,
					invest_start: investStart,
					invest_end: investEnd,
					domestic_price: domesticPrice,
					oversea_price: overseaPrice,
					expected_profit: expectedProfit,
					country_id: countryId,
					expected_delivery_date: expectedDeliveryDate,
					sale_start_date: saleStartDate,
					sale_deadline: saleDeadline,
					minimum_investors: 0,
					maximum_investors: 10,
					target_quantity: targetQuantity ? targetQuantity : 1,
					category_list: categoryList,
					tag_list: tagList,
					related_info: fundingArray,
					photos: productImage,
					default_photo_id: defaultPhotoId
				}
			};
		},
		invalidatesTags: [TAG_PRODUCT]
	});
