import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	type: "",
	brandId: "",
	koreanName: "",
	name: "",
	description: "",
	isDeleteDialogOn: false
};

const brandSlice = createSlice({
	name: "brand",
	initialState,
	reducers: {
		assignToBrandPayload: (state, { payload }) => {
			state.type = payload.type;
			state.brandId = payload.id;
			state.koreanName = payload.koreanName;
			state.name = payload.name;
			state.description = payload.description;
			state.photos = payload.photos;
		},
		openDeleteBrandDialog: (state, { payload }) => {
			state.isDeleteDialogOn = true;
			state.brandId = payload.id;
		},
		closeDeleteBrandDialog: (state) => {
			state.isDeleteDialogOn = false;
			state.brandId = "";
		}
	}
});

export const selectBrand = (state) => state.brand || {};

export const {
	assignToBrandPayload,
	openDeleteBrandDialog,
	closeDeleteBrandDialog
} = brandSlice.actions;

export const brandReducer = brandSlice.reducer;
