import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
	DATE_FORMAT_NINE,
	DATE_FORMAT_ONE,
	DATE_FORMAT_TWO,
	pipeParseFormat
} from "utils/formatDate";
import { OutlinedTextInput } from "./OutlinedTextInput";

import { dateFieldStyles } from "./style";

const FormInputDatePicker = ({
	name,
	outlined,
	placeholder,
	inputFormat = DATE_FORMAT_NINE,
	...props
}) => {
	const {
		control,
		trigger,
		formState: { errors }
	} = useFormContext();

	const { t } = useTranslation();

	const classes = dateFieldStyles();

	const Input = outlined ? OutlinedTextInput : TextField;

	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onChange, value, ...field } }) => {
				return (
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DatePicker
							{...field}
							inputFormat={inputFormat}
							onChange={(value) => {
								onChange(value);
								trigger(name);
							}}
							renderInput={(params) => {
								return (
									<Input
										value={
											value
												? pipeParseFormat(
														value,
														DATE_FORMAT_ONE,
														DATE_FORMAT_TWO
												  )
												: ""
										}
										{...params}
										placeholder={placeholder || t("Select Date")}
										error={!!errors[name]}
										inputProps={{
											size: "small",
											sx: { py: 0 }
										}}
									/>
								);
							}}
							className={classes.root}
							{...props}
						/>
					</LocalizationProvider>
				);
			}}
		/>
	);
};

export default FormInputDatePicker;
