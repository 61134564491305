import { Divider, Stack, Typography } from "@mui/material";
import { BasePlate } from "components/layout";
import { StackRow } from "components/shared";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import upArrow from "assets/images/upArrow.svg";
import downArrow from "assets/images/downArrow.svg";
import { CustomStack } from "./style";
import { ownerApi } from "services/modules/owner";
import VerticalBarGraph from "@chartiful/react-vertical-bar-graph";
import theme from "theme/theme";

const SubscriptionStatusBox = () => {
	const { t } = useTranslation();

	const { data } = ownerApi.useGetOwnerSubscriptionStatusesQuery();

	const countByDate = data?.response || {};

	const loginDates = ["12.15", "12.20", "12.30"];
	// Data are dummy

	const XAxisIndicatorText = ({ children }) => (
		<Typography fontWeight={400} fontSize={13} sx={{ opacity: 0.6 }}>
			{children}
		</Typography>
	);

	const config1 = {
		hasXAxisBackgroundLines: false,
		hasXAxisLabels: false,
		hasYAxisLabels: false,
		hasYAxisBackgroundLines: false,
		yAxisLabelStyle: {
			fontFamily: "system-ui, sans-serif"
		},
		xAxisLabelStyle: {
			position: "right",
			prefix: "",
			fontFamily: "system-ui, sans-serif"
		},
		xAxisBackgroundLineStyle: {
			strokeWidth: 2
		}
	};

	const summaryData = [
		[
			{
				statName: t("owner.totalMembersToday"),
				stat: data?.response.totalUserCounts || 0
			},
			{
				statName: t("owner.today"),
				stat: countByDate.today || 0,
				indicator: upArrow
			},
			{
				statName: t("owner.last7Days"),
				stat: countByDate.thisWeek || 0,
				indicator: downArrow
			},
			{
				statName: t("owner.last28Days"),
				stat: countByDate.thisMonth || 0,
				indicator: "-"
			}
		]
	];

	return (
		<Stack mb={36}>
			<BasePlate sx={{ paddingY: 24, paddingX: 20, overflowX: "auto" }}>
				<StackRow
					gap={40}
					alignItems="center"
					divider={
						<Divider orientation="vertical" variant="middle" flexItem />
					}>
					<Typography
						sx={{ whiteSpace: "nowrap" }}
						variant="text17"
						fontWeight={700}>
						{t("Subscription Status")}
					</Typography>
					{summaryData.map((summaryGroup, index) => (
						<Fragment key={`summaryGroup-${index}`}>
							{summaryGroup.map(({ statName, stat, indicator }) => {
								return (
									<CustomStack
										key={statName}
										statName={statName}
										stat={stat}
										indicator={indicator}
									/>
								);
							})}
						</Fragment>
					))}
					<Stack pr={20}>
						<VerticalBarGraph
							data={[
								20, 45, 28, 80, 100, 43, 50, 49, 23, 24, 30, 59, 13, 34, 24
							]}
							// Data are dummy
							labels={["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"]}
							width={500}
							height={70}
							barRadius={3}
							barWidthPercentage={0.15}
							barColor={theme.palette.primary.main}
							baseConfig={config1}
							style={{ borderBottom: "1px solid black" }}
						/>

						<StackRow justifyContent="space-between">
							{loginDates.map((date, index) => {
								if (index === loginDates.length - 1) {
									return (
										<StackRow gap={5} key={index}>
											<XAxisIndicatorText>{date}</XAxisIndicatorText>
											<XAxisIndicatorText>{t("today")}</XAxisIndicatorText>
										</StackRow>
									);
								}
								return (
									<XAxisIndicatorText key={index}>{date}</XAxisIndicatorText>
								);
							})}
						</StackRow>
					</Stack>
				</StackRow>
			</BasePlate>
		</Stack>
	);
};

export default SubscriptionStatusBox;
