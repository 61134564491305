import { useFormContext } from "react-hook-form";

import { errorBorder } from "data/styleVariables";
import ErrorMessage from "components/formComponents/ErrorMessage";

import { CustomNumberField } from "../style";

export const PasswordField = ({ name, placeholder, type }) => {
	const {
		watch,
		formState: { errors },
		getValues,
		setValue,
		trigger
	} = useFormContext();

	watch();

	const inputValue = getValues(name);

	return (
		<>
			<CustomNumberField
				placeholder={placeholder}
				type={type}
				minRows={4}
				value={inputValue}
				onChange={(event) => {
					setValue(name, event.currentTarget.value);
				}}
				onBlur={() => {
					trigger(name);
				}}
				InputProps={{
					inputProps: {
						min: type === "number" ? 0 : undefined,
						style: {
							paddingBlock: 12,
							paddingInline: 20
						}
					}
				}}
				fullWidth
				variant="outlined"
				name={name}
				sx={{ border: errors[name] && errorBorder }}
			/>
			{errors[name] && <ErrorMessage>{errors[name].message}</ErrorMessage>}
		</>
	);
};
