import {
	Grid,
	Divider,
	Typography,
	LinearProgress,
	linearProgressClasses
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { BasePlate } from "components/layout";
import { StackRow } from "components/shared";
import numberToThousandComma from "utils/numberToThousandComma";

const Bar = ({ value, color }) => {
	return (
		<LinearProgress
			color={color}
			sx={{
				borderRadius: 5,
				height: 15,
				variant: "determinate",
				backgroundColor: "transparent",
				[`& .${linearProgressClasses.bar}`]: {
					borderRadius: 5
				}
			}}
			variant="determinate"
			value={value}
		/>
	);
};

function Item({ color, time, cases, total, value }) {
	const { t } = useTranslation();

	const ago = time
		? time === 1
			? `1 ${t("day ago")}`
			: `${time} ${t("days ago")}`
		: t("today");

	return (
		<Grid container>
			<Grid item xs>
				<Typography color="text.secondary">{ago}</Typography>
			</Grid>
			<Grid item xs>
				<Typography color="text.primary" fontWeight={700}>
					{`${cases} ${t("cases")}`}
				</Typography>
			</Grid>
			<Grid item xs>
				<Bar value={value} color={color} />
			</Grid>
			<Grid item xs>
				<Typography color="text.primary" fontWeight={700}>
					{numberToThousandComma(total)}
				</Typography>
			</Grid>
		</Grid>
	);
}

function Row({ heading, color }) {
	return (
		<Grid item xs={6}>
			<BasePlate sx={{ paddingY: 24, paddingX: 20 }}>
				<StackRow
					gap={20}
					divider={<Divider orientation="vertical" flexItem />}>
					<Typography alignSelf="center" variant="text17" fontWeight={700}>
						{heading}
					</Typography>

					<StackRow flexGrow={1} direction="column" gap={2}>
						<Item
							color={color}
							time={3}
							cases={Math.floor(1000 * Math.random())}
							value={Math.floor(100 * Math.random())}
							total={Math.floor(10000000 * Math.random())}
						/>
						<Item
							color={color}
							time={2}
							cases={Math.floor(1000 * Math.random())}
							value={Math.floor(100 * Math.random())}
							total={Math.floor(10000000 * Math.random())}
						/>
						<Item
							color={color}
							time={1}
							cases={Math.floor(1000 * Math.random())}
							value={Math.floor(100 * Math.random())}
							total={Math.floor(10000000 * Math.random())}
						/>
						<Item
							color={color}
							cases={Math.floor(1000 * Math.random())}
							value={Math.floor(100 * Math.random())}
							total={Math.floor(10000000 * Math.random())}
						/>
					</StackRow>
				</StackRow>
			</BasePlate>
		</Grid>
	);
}

export default function OrderStatus() {
	const { t } = useTranslation();

	return (
		<Grid container spacing={20}>
			<Row heading={t("funding.orderStatus")} color="secondary" />
			<Row heading={t("funding.cancelStatus")} color="primary" />
		</Grid>
	);
}
