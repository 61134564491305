import { Box, Typography } from "@mui/material";
import { StackRow } from ".";

const RequiredLabelText = ({ children, ...props }) => {
	return (
		<Box {...props}>
			<StackRow>
				{children}
				<Typography color="primary">*</Typography>
			</StackRow>
		</Box>
	);
};

export default RequiredLabelText;
