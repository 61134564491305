// Authentication
export const API_LOGIN = "login";
export const API_LOGOUT = "logout";
export const API_SIGNUP = "register";

// Category
export const API_CATEGORY = "category";

// Funding
export const API_FUNDING = "funding";
export const API_FUNDING_SUMMARY = `${API_FUNDING}/summary`;
export const API_FUNDING_STATUES = `${API_FUNDING}/status-summary`;
export const API_RAISE_LIMIT_REQUEST = `${API_FUNDING}/raise-limit/request`;
export const API_ADMIN_RAISE_LIMIT_REQUEST = `${API_FUNDING}/raise-limit`;
export const API_DOCUMENTS = `${API_FUNDING}/documents`;
export const API_PRELOAD = `${API_FUNDING}/preload`;
export const API_FUNDING_STEP_ONE = `${API_FUNDING}/step-one`;
export const API_FUNDING_STEP_TWO = `${API_FUNDING}/step-two`;
export const API_FUNDING_EDIT_PRELOAD = `${API_FUNDING}/edit-preload`;
export const API_FUNDING_MESSAGE = `${API_FUNDING}/message`;

// Funding Admin
export const API_FUNDING_ADMIN = `${API_FUNDING}/admin`;
export const API_FUNDING_ADMIN_DETAIL = `${API_FUNDING_ADMIN}/detail`;
export const API_FUNDING_ADMIN_LIST = `${API_FUNDING}/all`;
export const API_FUNDING_ADMIN_STEP_ONE = `${API_FUNDING_ADMIN}/step-one`;
export const API_FUNDING_ADMIN_STEP_TWO = `${API_FUNDING_ADMIN}/step-two`;
export const API_FUNDING_ADMIN_STATUS_SUMMARY = `${API_FUNDING_ADMIN}/status-summary`;
export const API_FUNDING_ADMIN_REJECT_FUNDING = `${API_FUNDING_ADMIN}/reject-funding`;
export const API_FUNDING_ADMIN_SECONDARY_DOCUMENT_APPROVAL = `${API_FUNDING_ADMIN}/secondary-document-approval`;

// FundingAdmin/order
export const API_FUNDING_ORDER_HISTORY = `${API_FUNDING}/order-history`;
export const API_FUNDING_ORDER_EXPORT = `${API_FUNDING}/export-orders`;

// FundingAdmin/settlement
export const API_FUNDING_SETTLEMENT_DETAILS = `${API_FUNDING}/settle-details`;
export const API_FUNDING_SETTLEMENT_EXPORT = `${API_FUNDING}/export-settlement-requests`;

// Product
export const API_PRODUCT = "product";
export const API_PRODUCT_EXCEL = `${API_PRODUCT}/export`;
export const API_FUNDING_EXCEL = `${API_FUNDING}/export`;

// Brand
export const API_BRAND = "brand";
export const API_BRAND_REQUEST = `${API_FUNDING}/${API_BRAND}/request`;
export const API_REQUESTED_BRAND_LIST = "requested-brands";

// Review
export const API_REVIEW = "review";
export const API_RECOMMENDATION = "recommendation";

// Partner
export const API_PARTNER = "partner";
export const API_MESSAGE_PARTNER = `${API_PARTNER}/message`;
export const API_VERIFY_OTP = `${API_PARTNER}/verify-otp`;
export const API_SEND_OTP = `${API_PARTNER}/send-otp`;
export const API_SEND_OTP_REGISTER = `${API_PARTNER}/send-otp-register`;
export const API_RESET_PASSWORD = `${API_PARTNER}/forget-password`;
export const API_DOWNLOAD_REGISTRATION_DOCUMENTS = `${API_PARTNER}/registration-doc-download`;
export const API_DOC_DOWNLOAD = `${API_PARTNER}/doc-download`;
export const API_DOWNLOAD_SECONDARY_REGISTRATION_DOCUMENTS = `${API_PARTNER}/secondary-registration-doc-download`;

// Owner
export const API_OWNER = "owner";
export const API_OWNER_INVESTMENT = `${API_OWNER}/investments`;
export const API_OWNER_SUBSCRIPTION_STATUS = `${API_OWNER}/subscription-status`;
export const API_OWNER_ACTIVITY_STATUS = `${API_OWNER}/activity-status`;

// Team
export const API_TEAM = "team";
export const API_PARTNER_APPROVAL = `${API_TEAM}/approval`;
export const API_PARTNER_REJECT = `${API_TEAM}/reject-approval`;

// Shipping
export const API_SHIPPING_INFO = "shipping-info";
export const API_SHIPPING_UPDATE_STATUS = `${API_SHIPPING_INFO}/update-status`;
export const API_SHIPPING_COURIERS = `${API_SHIPPING_INFO}/couriers`;
export const API_ORDER_PAYMENT = `${API_SHIPPING_INFO}/order-payment`;
export const API_DELIVERY_INFO = `${API_SHIPPING_INFO}/delivery-info`;
export const API_IMPORT_INFO = `${API_SHIPPING_INFO}/import`;
export const API_DOWNLOAD_TEMPLATE = `${API_SHIPPING_INFO}/template`;
export const API_SHIPPING_REQUEST_SUMMARY = `${API_SHIPPING_INFO}/summary`;
export const API_SHIPPING_REQUEST_TAB_FILTER_SUMMARY = `${API_SHIPPING_INFO}/status-summary`;
export const API_BATCH_IMPORT = `${API_SHIPPING_INFO}/batch`;

// Receipt
export const API_RECEIPT = "receipt";

// inventory/warehouse
export const API_WAREHOUSE = "warehouse";
export const API_WAREHOUSE_ADMIN = "stocking";
export const API_WAREHOUSE_STATUS_SUMMARY = `${API_WAREHOUSE_ADMIN}/summary`;
export const API_WAREHOUSE_EXPORT = `${API_WAREHOUSE_ADMIN}/export`;
