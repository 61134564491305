import { FlatPlate } from "components/layout";
import { StackRow } from "components/shared";
import { useTranslation } from "react-i18next";
import { Button, SECONDARY } from "components/layout/Button";
import PrimaryDialog from "../PrimaryDialog";
import { Grid, List, ListItem, Typography } from "@mui/material";
import { FormInputText } from "components/formComponents";
import { useForm } from "react-hook-form";
import theme from "theme/theme";

export const RatingSettingDialog = ({ isDialogOpen, setDialogOpen }) => {
	const { t } = useTranslation();

	const { control } = useForm();
	// DUMMY FIELDS AND FIELD NAMES ARE USED
	return (
		<PrimaryDialog
			width={800}
			header={t("owner.ratingSetting")}
			setDialogOpen={setDialogOpen}
			isDialogOpen={isDialogOpen}>
			<FlatPlate sx={{ p: 24 }}>
				<Grid gap={20} container color={theme.palette.text.secondary}>
					<Grid container gap={20}>
						<Grid component={Typography} item xs>
							{t("owner.accountNumber")}
						</Grid>
						<Grid textAlign="center" component={Typography} item xs>
							{t("owner.totalLogin")}
						</Grid>
						<Grid textAlign="center" component={Typography} item xs>
							{t("owner.chargeAmount")}
						</Grid>
						<Grid textAlign="center" component={Typography} item xs>
							{t("owner.totalInvestments")}
						</Grid>
						<Grid textAlign="center" component={Typography} item xs>
							{t("owner.totalTrades")}
						</Grid>
					</Grid>
					<Grid container gap={20} alignItems="center">
						<Grid component={Typography} item xs>
							{t("owner.president")}
						</Grid>
						<Grid component={Typography} item xs>
							<FormInputText control={control} name="input1" />
						</Grid>
						<Grid component={Typography} item xs>
							<FormInputText control={control} name="input2" />
						</Grid>
						<Grid component={Typography} item xs>
							<FormInputText control={control} name="input3" />
						</Grid>
						<Grid component={Typography} item xs>
							<FormInputText control={control} name="input4" />
						</Grid>
					</Grid>
					<Grid container gap={20} alignItems="center">
						<Grid component={Typography} item xs>
							{t("owner.ceo")}
						</Grid>
						<Grid component={Typography} item xs>
							<FormInputText control={control} name="input1" />
						</Grid>
						<Grid component={Typography} item xs>
							<FormInputText control={control} name="input2" />
						</Grid>
						<Grid component={Typography} item xs>
							<FormInputText control={control} name="input3" />
						</Grid>
						<Grid component={Typography} item xs>
							<FormInputText control={control} name="input4" />
						</Grid>
					</Grid>
					<Grid container gap={20} alignItems="center">
						<Grid component={Typography} item xs>
							{t("owner.president1")}
						</Grid>
						<Grid component={Typography} item xs>
							<FormInputText control={control} name="input1" />
						</Grid>
						<Grid component={Typography} item xs>
							<FormInputText control={control} name="input2" />
						</Grid>
						<Grid component={Typography} item xs>
							<FormInputText control={control} name="input3" />
						</Grid>
						<Grid component={Typography} item xs>
							<FormInputText control={control} name="input4" />
						</Grid>
					</Grid>
					<Grid container gap={20} alignItems="flex-start">
						<Grid component={Typography} item xs={2.4}>
							{t("owner.promotionCriteria")}
						</Grid>
						<Grid item>
							<List
								sx={{
									fontSize: 15,
									fontWeight: 500,
									listStyleType: "disc",
									padding: 0,
									"& .MuiListItem-root": {
										display: "list-item",
										padding: 0
									}
								}}>
								<ListItem>{t("owner.criteria1")}</ListItem>
								<ListItem>{t("owner.criteria2")}</ListItem>
							</List>
						</Grid>
					</Grid>
				</Grid>
			</FlatPlate>
			<StackRow justifyContent="flex-end" gap={10}>
				<Button sx={{ width: 126 }} color={SECONDARY}>
					{t("Save")}
				</Button>
			</StackRow>
		</PrimaryDialog>
	);
};
