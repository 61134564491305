// This place is where devs can experiment with their components.

// To test import your components here and go to: http://localhost:3000/storybook

import { Typography } from "@mui/material";
import { BasePlate } from "components/layout";

export default function StoryBook() {
	return (
		<BasePlate sx={{ width: "100vh", height: "100vh", p: 20 }}>
			<Typography align="center" variant="h1">
				Hello, devs!
			</Typography>
		</BasePlate>
	);
}
