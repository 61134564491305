import { useTranslation } from "react-i18next";

import FormInputSelect from "components/formComponents/FormInputSelect";
import numberToThousandComma from "utils/numberToThousandComma";
import { TopBottom, SlashDivider } from "pages/funding/utils";
import { formatYearMonthDay } from "utils/formatDate";

export default function columns({ control, setValue }) {
	const { t } = useTranslation();

	return [
		{
			field: "status",
			renderCell: ({ row: { statusDetail } }) =>
				t(`funding.statuses.codes.${statusDetail.statusCode}`),
			minWidth: 250,
			renderHeader: () => (
				<div>
					<FormInputSelect
						name="statusFilter"
						variant="text15"
						fontWeight={500}
						control={control}
						options={[
							{ label: t("tableFilter.all"), value: "all" },
							{ label: t("funding.statuses.sale"), value: "sale" },
							{ label: t("funding.statuses.in_trade"), value: "in_trade" }
						]}
						setValue={setValue}
						hideBorder
					/>
				</div>
			)
		},
		{
			field: "code",
			minWidth: 150,
			headerName: t("funding.productList.fundingCode")
		},
		{
			field: "name",
			minWidth: 200,
			headerName: t("funding.productList.fundingName")
		},
		{
			field: "partnerCode",
			headerName: t("shipping.tableHeaders.partnerCode"),
			renderCell: ({ row }) => row.team.code
		},
		{
			field: "partnerName",
			minWidth: 200,
			headerName: t("shipping.tableHeaders.partnerName"),
			renderCell: ({ row }) => row.team.name
		},
		{
			field: "registrationAnApprovedDate",
			headerName: t("funding.productList.registrationAndApprovedDate"),
			minWidth: 250,
			renderCell: ({ row: { createdAt, updatedAt, status } }) => (
				<TopBottom
					top={formatYearMonthDay(createdAt)}
					bottom={status === "approved" ? formatYearMonthDay(updatedAt) : "-"}
				/>
			)
		},
		{
			field: "unitPrice",
			headerName: t("funding.productList.productUnitPrice"),
			minWidth: 200,
			renderCell: ({ formattedValue }) =>
				numberToThousandComma(Number(formattedValue))
		},
		{
			field: "targetAndRecruitmentAmount",
			headerName: t("funding.productList.targetAndRecruitmentAmount"),
			minWidth: 280,
			renderCell: ({ row: { investmentPrice, expectedProfit } }) => {
				return (
					<SlashDivider
						left={numberToThousandComma(Number(expectedProfit))}
						right={numberToThousandComma(Number(investmentPrice))}
					/>
				);
			}
		},
		{
			field: "targetAndSalesQuantity",
			headerName: t("funding.productList.targetAndSalesQuantity"),
			minWidth: 250,
			renderCell: ({ row: { targetQuantity, quantity } }) => (
				<SlashDivider left={targetQuantity} right={quantity} />
			)
		},
		{
			field: "investStartEnd",
			headerName: t("funding.productList.investmentStart"),
			minWidth: 200,
			renderCell: ({ row: { investStart, investEnd } }) => (
				<TopBottom
					top={formatYearMonthDay(investStart)}
					bottom={formatYearMonthDay(investEnd)}
					divider="~"
				/>
			)
		},
		{
			field: "salesStartEnd",
			headerName: t("funding.productList.investmentEnd"),
			renderCell: ({ row: { saleStartDate, saleDeadline } }) => (
				<TopBottom
					top={formatYearMonthDay(saleStartDate)}
					bottom={formatYearMonthDay(saleDeadline)}
					divider="~"
				/>
			)
		}
	].map((column) => ({
		headerAlign: "center",
		align: "center",
		minWidth: 120,
		...column
	}));
}
