import { customBaseQuery } from "./customBaseQuery";
import { createApi } from "@reduxjs/toolkit/query/react";
import {
	TAG_BRAND,
	TAG_CATEGORY,
	TAG_PARTNER,
	TAG_PRODUCT,
	TAG_RECEIPT,
	TAG_RECOMMENDATION,
	TAG_USER,
	TAG_SHIPPING,
	TAG_OWNER,
	TAG_FUNDING_ADMIN,
	TAG_STOCKING
} from "./tags";

export const api = createApi({
	baseQuery: customBaseQuery,
	tagTypes: [
		TAG_USER,
		TAG_CATEGORY,
		TAG_BRAND,
		TAG_PRODUCT,
		TAG_RECOMMENDATION,
		TAG_PARTNER,
		TAG_RECEIPT,
		TAG_SHIPPING,
		TAG_OWNER,
		TAG_FUNDING_ADMIN,
		TAG_STOCKING
	],
	endpoints: () => ({})
});
