import { Box, Stack, Typography } from "@mui/material";
import { FlatPlate } from "components/layout";
import { Button, SECONDARY } from "components/layout/Button";
import { StackRow } from "components/shared";
import { useTranslation } from "react-i18next";
import PrimaryDialog from "../PrimaryDialog";
import correctTick from "assets/images/correct-tick.png";
import { useNavigate } from "react-router-dom";
import { ROUTE_SHIPPING, ROUTE_SHIPPING_REQUEST } from "data/routeVariables";

const RequestCompleteDialog = ({
	isRequestCompleteOpen,
	setRequestCompleteOpen
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<PrimaryDialog
			header={t("shipping.requestComplete.header")}
			setDialogOpen={setRequestCompleteOpen}
			isDialogOpen={isRequestCompleteOpen}>
			<FlatPlate sx={{ py: 150 }}>
				<Stack alignItems="center">
					<Box sx={{ width: 54 }}>
						<img src={correctTick} alt="correct" />
					</Box>
					<Typography
						textAlign="center"
						width={400}
						py={20}
						fontSize={18}
						fontWeight={700}>
						{t("shipping.requestComplete.context")}
					</Typography>
					<StackRow gap={12}>
						<Button onClick={() => navigate(ROUTE_SHIPPING)}>
							{t("shipping.requestComplete.manageShipment")}
						</Button>
						<Button
							onClick={() => {
								navigate(ROUTE_SHIPPING_REQUEST);
								setRequestCompleteOpen(false);
							}}>
							{t("shipping.requestComplete.viewRequestHistory")}
						</Button>
					</StackRow>
				</Stack>
			</FlatPlate>

			<StackRow justifyContent="flex-end">
				<Button
					onClick={() => setRequestCompleteOpen(false)}
					sx={{ paddingRight: 50, paddingLeft: 50 }}
					color={SECONDARY}>
					{t("Confirm")}
				</Button>
			</StackRow>
		</PrimaryDialog>
	);
};

export default RequestCompleteDialog;
