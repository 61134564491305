import { lazy, Suspense } from "react";

import { Loading } from "components/shared/loading";
import { useUser } from "redux/modules/useUser";

export default function WarehouseList() {
	const { isSuperAdmin } = useUser();

	const Component = lazy(() =>
		isSuperAdmin ? import("./admin") : import("./partner")
	);

	return (
		<Suspense fallback={<Loading />}>
			<Component />
		</Suspense>
	);
}
