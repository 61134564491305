import { createSlice } from "@reduxjs/toolkit";

import { api } from "services/api";

const SUPER_ADMIN = "s_admin";
const PARTNER_ADMIN = "admin";

const initialState = {
	isLoggedIn: false,
	currentUser: null,
	isPartner: true,
	isSuperAdmin: false
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		authenticationStatusChange: (state, { payload }) => {
			if (payload) {
				const user = payload?.response?.data;
				const role = user?.role;

				return {
					...state,
					isLoggedIn: true,
					currentUser: user,
					isPartner: role === PARTNER_ADMIN,
					isSuperAdmin: role === SUPER_ADMIN
				};
			}
			return initialState;
		},
		resetAuth: () => api.util.resetApiState()
	}
});

export const selectAuth = (state) => state.auth || {};

export const { authenticationStatusChange, resetAuth } = authSlice.actions;

export const authReducer = authSlice.reducer;
