import { Stack } from "@mui/material";
import ErrorMessage from "components/formComponents/ErrorMessage";
import { Button, WEAK_GRAY } from "components/layout/Button";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ImageUploading from "react-images-uploading";
import { ImageWrapper, UploadBtn } from "./style";

export const BrandImgUpload = ({ name, control, photos: prefillPhoto }) => {
	const {
		setError,
		resetField,
		formState: { errors }
	} = useFormContext();
	const { t } = useTranslation();
	const photoSize = 140;
	const [image, setImage] = useState(prefillPhoto);

	useEffect(() => {
		image?.length === 0 &&
			setError(name, {
				type: "required",
				message: t("validationMsg.insertImage")
			});
	}, [image]);

	const onChange = (imageList) => {
		setImage(imageList);
	};

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<ImageUploading
					value={image}
					onChange={(e) => {
						onChange(e);
						field.onChange(e);
					}}
					dataURLKey="data_url"
					acceptType={["jpg", "png", "jpeg"]}>
					{({ imageList, onImageUpload, onImageRemove }) => (
						<Stack direction="row" flexWrap="wrap" gap={16}>
							{imageList?.length === 0 ? (
								<Stack>
									<Button
										color={WEAK_GRAY}
										sx={{ width: photoSize, height: photoSize }}
										onClick={() => {
											onImageUpload();
										}}>
										+
									</Button>

									{errors && (
										<ErrorMessage>{errors[name]?.message}</ErrorMessage>
									)}
								</Stack>
							) : (
								imageList?.map(({ data_url: dataUrl, path }, index) => (
									<ImageWrapper
										width={photoSize}
										height={photoSize}
										key={index}>
										<img
											{...field}
											name={name}
											height="100%"
											width="100%"
											src={dataUrl ? dataUrl : path}
											onClick={onImageUpload}
										/>
										<UploadBtn
											color="primary"
											onClick={() => {
												onImageRemove(index);
												resetField(name);
											}}>
											{t("Delete")}
										</UploadBtn>
									</ImageWrapper>
								))
							)}
						</Stack>
					)}
				</ImageUploading>
			)}
		/>
	);
};
