import { useState } from "react";
import { Box, Breadcrumbs, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ROUTE_PRODUCT_CREATE, ROUTE_PRODUCT_EDIT } from "data/routeVariables";
import { PageTitle } from "components/shared";
import {
	Button,
	HelpWithIcon,
	PRIMARY,
	SECONDARY,
	SelectedOrAllDownloadButtons
} from "components/layout/Button";
import DataTable from "components/table/DataTable";
import { MuiPagination } from "components/table/Pagination";
import {
	useGetProductsQuery,
	useGetStatusesQuery
} from "services/modules/funding";
import { downloadFile } from "utils/downloadFile";
import { API_FUNDING_EXCEL } from "services/endpoints";
import noDataImg from "assets/images/shared/reason.png";
import { BasePlate } from "components/layout";
import theme from "theme/theme";
import { useSelectedDownload } from "utils/useDownload";

import { getColumns, normalizeRows } from "./utils";

function FundingEmptyView() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<>
			<Typography component="h2" variant="h2" pb={24}>
				{t("funding.title")}
			</Typography>

			<BasePlate sx={{ py: 250, textAlign: "center" }}>
				<img src={noDataImg} alt="No funding created" />

				<Typography>{t("funding.emptyPage.description1")}</Typography>

				<Typography>{t("funding.emptyPage.description2")}</Typography>

				<Box sx={{ p: 6 }} />

				<Button
					type="button"
					onClick={() => {
						navigate(ROUTE_PRODUCT_CREATE);
					}}
					sx={{
						backgroundColor: "button.light"
					}}
					color={SECONDARY}>
					{t("funding.emptyPage.create")}
				</Button>
			</BasePlate>
		</>
	);
}

const Funding = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams, setParams] = useSearchParams();
	const page = parseInt(searchParams.get("page") || 1);
	const activeFilter = searchParams.get("filter") || "all";
	const { data, isFetching } = useGetProductsQuery({
		currentPage: page,
		filter: activeFilter
	});
	const rows = normalizeRows(data);
	const meta = data?.response?.meta || { total: 0 };
	const [ids, setIds] = useState([]);
	const [hoverIndex, setHoverIndex] = useState(0);

	const { data: maybeAvailableStatuses } = useGetStatusesQuery();

	const dl = useSelectedDownload();

	const availableStatuses = maybeAvailableStatuses ?? [];

	if (!isFetching && meta.total === 0) {
		return <FundingEmptyView />;
	}

	return (
		<>
			<Stack direction="row" justifyContent="space-between" pb={22}>
				<PageTitle>{t("funding.title")}</PageTitle>
			</Stack>

			<Stack flexDirection="row" justifyContent="space-between">
				<Breadcrumbs separator="|">
					{availableStatuses.map((availableStatus) => (
						<Box key={availableStatus.status}>
							<Typography
								sx={{
									cursor: "pointer",
									color:
										// Note: `all` is a special case it doesn't exist on db
										// we have added it using `prependUiStatus` it is purely for UI
										// Taking that into consideration, if `activeFilter` === "" it means that `all` filter
										// is applied as we clear the filter onClick if status is `all`
										activeFilter === "" && availableStatus.status === "all"
											? theme.palette.common.black
											: availableStatus.status === activeFilter &&
											  theme.palette.common.black
								}}
								key={availableStatus.status}
								onClick={() => {
									if (availableStatus.status === "all") {
										return setParams();
									}

									searchParams.set("filter", availableStatus.status);
									setParams({ filter: availableStatus.status });
								}}>
								{`${t(
									availableStatus.statusCode
										? `funding.statuses.codes.${availableStatus.statusCode}`
										: `funding.statuses.${availableStatus.status}`
								)} ${availableStatus.count}`}
							</Typography>
						</Box>
					))}
				</Breadcrumbs>

				<HelpWithIcon />
			</Stack>

			<Box sx={{ p: 6 }} />

			<DataTable
				disableColumnMenu
				columns={getColumns(t, hoverIndex)}
				rows={rows}
				isLoading={isFetching}
				isRenderCheckBox={true}
				onRowsSelection={setIds}
				componentsProps={{
					row: {
						onMouseEnter: (e) => {
							const rowId = Number(e.currentTarget.dataset.id);
							setHoverIndex(rowId);
						},
						onMouseLeave: () => {
							setHoverIndex(0);
						}
					}
				}}
				onRowClick={({ row }) => {
					navigate(`${ROUTE_PRODUCT_EDIT}/${row.id}/step/1`);
				}}
			/>

			<MuiPagination page={page} meta={meta} />

			<Stack direction="row" sx={{ mt: 20 }}>
				<SelectedOrAllDownloadButtons
					handleFullDownload={() =>
						dl(
							undefined,
							downloadFile(API_FUNDING_EXCEL, "Recommendation_Table")
						)
					}
					handleSelectedDownload={() =>
						dl(
							ids,
							downloadFile(API_FUNDING_EXCEL, "Recommendation_Table", {
								funding_ids: ids
							})
						)
					}
				/>
				<Button
					color={PRIMARY}
					sx={{
						marginLeft: "auto",
						paddingInline: 47
					}}
					onClick={() => navigate(ROUTE_PRODUCT_CREATE)}>
					{t("funding.registerProduct")}
				</Button>
			</Stack>
		</>
	);
};

export default Funding;
