import { API_FUNDING_SUMMARY } from "services/endpoints";
import { TAG_FUNDING } from "services/tags";

export default (build) =>
	build.query({
		query: () => {
			return {
				url: API_FUNDING_SUMMARY,
				method: "GET"
			};
		},
		providesTags: [TAG_FUNDING]
	});
