import { useSearchParams, useParams } from "react-router-dom";

export function useNumberSearchParams(type = "page", fallback = 1) {
	const [searchParams] = useSearchParams();

	const value = parseInt(searchParams.get(type) || fallback);

	return value;
}

export function useNumberParams(field = "stepNumber", fallback = 1) {
	const params = useParams();

	const value = parseInt(params[field] || fallback);

	return value;
}
