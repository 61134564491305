import React from "react";
import {
	KBarPortal,
	KBarPositioner,
	KBarAnimator,
	KBarSearch,
	KBarResults,
	useMatches,
	KBarProvider
} from "kbar";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { privateRoutes } from "routes";
import theme from "theme/theme";
import { useLogout } from "utils/useLogout";

import { capitalizeFirstLetter } from "utils/string";

const makeAction = (item, navigate) => {
	return {
		id: item.id,
		name: item.name
			.match(/[A-Z]?[a-z]+/g)
			.map(capitalizeFirstLetter)
			.join(" "),
		subtitle: item.path,
		section: "Navigation",
		perform: () => navigate(item.path)
	};
};

export default function KBarCommand() {
	const navigate = useNavigate();
	const { i18n } = useTranslation();

	const logout = useLogout();

	const actions = [
		{
			id: "switch-lang",
			name: "Switch language",
			section: "Actions"
		},
		...Object.keys(i18n.store.data).map((lang) => {
			return {
				id: lang,
				parent: "switch-lang",
				keywords: lang,
				name: capitalizeFirstLetter(lang),
				perform: () => i18n.changeLanguage(lang)
			};
		}),
		{
			id: "logout",
			name: "Logout",
			section: "Actions",
			perform: logout
		}
	].concat(
		privateRoutes
			.map((route) => {
				if (Array.isArray(route.path)) {
					return route.path.map((path) =>
						makeAction({ ...route, path }, navigate)
					);
				}

				return makeAction(route, navigate);
			})
			.flat()
	);

	return (
		<KBarProvider actions={actions}>
			<KBarPortal>
				<KBarPositioner>
					<KBarAnimator style={animatorStyle}>
						<KBarSearch
							defaultPlaceholder="Search for available routes or actions..."
							style={searchStyle}
						/>
						<RenderResults />
						<Typography textAlign="center" p={20}>
							Press ⮠ to select, ⮁ to navigate and Esc to close
						</Typography>
					</KBarAnimator>
				</KBarPositioner>
			</KBarPortal>
		</KBarProvider>
	);
}

function RenderResults() {
	const { results } = useMatches();

	return (
		<KBarResults
			items={results}
			onRender={({ item, active }) =>
				typeof item === "string" ? (
					<div style={groupNameStyle}>{item}</div>
				) : (
					<ResultItem action={item} active={active} />
				)
			}
		/>
	);
}

const ResultItem = React.forwardRef(function ResultItem(
	{ action, active },
	ref
) {
	return (
		<div
			ref={ref}
			style={{
				padding: "12px 16px",
				background: active ? theme.palette.primary.sidebar : "transparent",
				borderLeft: `2px solid ${active ? "red" : "transparent"}`,
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				cursor: "pointer"
			}}>
			<div
				style={{
					display: "flex",
					gap: "8px",
					alignItems: "center",
					fontSize: 14
				}}>
				<div style={{ display: "flex", flexDirection: "column" }}>
					<span>{action.name}</span>
					<span style={{ fontSize: 12 }}>{action.subtitle}</span>
				</div>
			</div>
		</div>
	);
});

const searchStyle = {
	padding: "12px 16px",
	fontSize: "16px",
	width: "100%",
	boxSizing: "border-box",
	outline: "none",
	border: "none",
	background: "rgb(252 252 252)",
	color: "rgb(28 28 29)"
};

const animatorStyle = {
	maxWidth: "600px",
	width: "100%",
	background: "rgb(252 252 252)",
	color: "rgb(28 28 29)",
	borderRadius: "8px",
	overflow: "hidden",
	boxShadow: "0px 6px 20px rgb(0 0 0 / 20%)"
};

const groupNameStyle = {
	padding: "8px 16px",
	fontSize: "10px",
	textTransform: "uppercase",
	opacity: 0.5
};
