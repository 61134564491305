import { createSlice } from "@reduxjs/toolkit";

const sidebarSlice = createSlice({
	name: "sidebar",
	initialState: { isSidebarOpen: false },
	reducers: {
		openSidebar: (state) => {
			state.isSidebarOpen = true;
		},
		closeSidebar: (state) => {
			state.isSidebarOpen = false;
		},
		toggleSidebar: (state) => {
			state.isSidebarOpen = !state.isSidebarOpen;
		}
	}
});

export const selectSidebar = (state) => state.sidebar || {};

export const { openSidebar, closeSidebar, toggleSidebar } =
	sidebarSlice.actions;

export const sidebarReducer = sidebarSlice.reducer;
