export const translationKor = {
	fileName: "파일명",
	KRW: "원",
	otpSendingError: "전송 오류!",
	errorSendingEmail: "이메일 전송 오류",
	verificationSuccess: "검증 성공",
	invalidOtp: "잘못된 코드!",
	cannotVerifyOtp: "코드를 확인할 수 없음",
	DaysAgo: "1일전",
	today: "오늘",
	cases: "건",
	common: {
		fundingCode: "펀딩코드",
		fundingName: "펀딩명",
		partnerCode: "파트너코드",
		partnerMission: "파트너코드",
		productAmount: "상품 금액",
		investmentQuantity: "투자 수량",
		totalInvestmentAmount: "총 투자 금액",
		investmentDate: "투자 날짜"
	},
	alertMessage: {
		somethingWentWrong: "아차! 문제가 발생했습니다",
		noListData: "검색 결과가 없습니다 다시 시도해보세요",
		noRequestListing: "아직 요청이 없습니다",
		auth: {
			loginSuccessful: "로그인이 정상적으로 진행되었습니다",
			loginError: "나중에 다시 시도 해주십시오",
			logoutSuccessful: "로그아웃이 정상적으로 진행되었습니다",
			logoutUnsuccessful: "로그아웃이 실패하였습니다",
			signUpSuccessful: "계정이 생성되었습니다.",
			signUpError: "계정을 만드는 동안 오류가 발생했습니다."
		},
		category: {
			postFail: "게시할 수 없음",
			postSuccess: "성공적으로 추가되었습니다",
			editSuccess: "성공적으로 편집되었습니다",
			fetchFail: "가져올 수 없음",
			deleteSuccess: "성공적으로 삭제됨",
			deleteFail: "삭제할 수 없음",
			noData: "데이터 없음"
		},
		funding: {
			raiseLimitSuccess: "성공! 확인을 기다려 주십시오.",
			raiseLimitError: "한도를 올리지 못했습니다.",
			preloadFail: "데이터 사전 로드 실패"
		},
		brand: {
			requestSuccess: "성공! 확인을 기다려 주십시오.",
			requestFail: "지금은 브랜드 요청을 사용할 수 없습니다.",
			createSuccess: "브랜드 생성 성공!",
			createFail: "브랜드 생성 실패",
			editSuccess: "브랜드 편집 성공!",
			editFail: "브랜드 수정 실패",
			brandRejected: "브랜드 거부됨",
			deleteSuccess: "브랜드 삭제 성공!",
			deleteFail: "브랜드 삭제 실패"
		},
		partner: {
			messageSendSuccess: "메시지가 성공적으로 전송되었습니다.",
			messageSendFail: "메시지가 전송되지 않았습니다. 다시 시도하세요."
		}
	},

	partner: {
		header: "파트너사 관리",
		partnerList: "파트너 목록",
		division: "구분",
		code: "파트너사코드",
		companyName: "회사명",
		businessNumber: "사업자번호",
		address: "주소",
		zipCode: "우편번호",
		contactPerson: "담당자 연락처",
		contactEmail: "담당자 email",
		insuranceAmount: "보증보험금액",
		insuranceExpireDate: "보증보험 만기일",
		insuranceNumber: "보증보험  번호",
		numOfInvestments: "누적 펀딩수",
		since: "가입일",
		approvalDate: "가입승인일",
		searchBoxPlaceholder:
			"파트너사코드, 파트너사명, 대표자명, 담당자명, 연락처, 이메일 입력",
		accountHolder: "계정 소유자",
		accountNumber: "계좌 번호",
		bank: "은행",
		sendMessage: "메시지 보내기",
		toList: "목록으로",
		postalCode: "우",
		summary: {
			activityStatus: "활동 현황",
			allPartners: "전체 파트너사",
			subscriptionApproval: "가입승인요청",
			subscriptionApproved: "가입승인",
			subscriptionPending: "가입보류",
			inActive: "활동정지",
			insuranceExpired: "보증보험 만료",
			partnerStatus: "파트너사 현황"
		},
		message: {
			subject: "제목",
			content: "내용",
			subjectPlaceholder: "메시지 제목을 입력하세요.",
			contentPlaceholder: "메시지 내용을 삽입하십시오",
			sendTo: "수단",
			message: "문자",
			mail: "메일",
			kakao: "카카오톡",
			writeMessage: "메시지 작성",
			recipient: "받는 사람"
		},
		profile: {
			header: "회원정보",
			recently: "최근 회사 매출",
			companyName: "주식회사 가나다라",
			companyIntroduction: "회사소개",
			dateOfEstablishment: "설립일",
			approveSubscription: "가입승인",
			stopActivity: "활동정지",
			lastLogin: "마지막 로그인",
			subscriptionDate: "가입일",
			subscriptionApproveDate: "가입승인일",
			noDescription: "회사 설명이 아직 추가되지 않았습니다."
		},
		dialog: {
			successMessage: "오너야 파트너사 가입을 축하합니다.",
			codeSentMessage:
				"인증번호를 이메일로 발송 했습니다. 이메일을 확인해주세요."
		}
	},

	login: {
		header: "계정 로그인",
		email: "이메일",
		password: "로그인",
		fillPassword: "비밀번호",
		id: "아이디",
		joinPartner: "파트너사 가입",
		forgotPassword: "비밀번호 찾기",
		eightOrMore: "숫자, 영문, 특수기호 포함 8글자 이상"
	},

	signUp: {
		becomePartner: "파트너사 가입",
		essentialInfo: "|  필수 정보",
		accountInfo: "계정 정보",
		companyInfo: "회사 정보",
		terms: "이용 약관",
		email: "이메일",
		enterCode: "인증 번호를 입력해주세요",
		sendVerification: "인증번호전송",
		certificationNumber: "인증번호",
		password: "비밀번호",
		eightOrMore: "숫자, 영문, 특수기호 포함 8글자 이상",
		rePassword: "비밀번호 재입력",
		name: "이름",
		contact: "연락처",
		pleaseEnter: "입력해주세요",
		businessName: "비즈니스 이름",
		businessNumber: "사업자번호",
		mutual: "상호",
		enterBusinessName: "상호명을 입력해 주세요",
		enterMutualName: "상호명을 입력해 주세요",
		representative: "대표자",
		enterRepresentativeName: "대표자 이름을 입력해 주세요",
		representativeContact: "대표자 연락처",
		manager: "담당자",
		contactName: "담당자 이름",
		enterContactName: "담당자 이름을 입력해 주세요",
		contactPerson: "담장자 연락처",
		contactNumber: "연락처",
		companyPhoneNumber: "회사 전화번호",
		companyAddress: "회사주소",
		detailedAddress: "상세주소",
		confirm: "확인",
		done: "완료",
		findPostalCode: "우편번호찾기",
		enterAddress: "주소를 입력해 주세요",
		enterDetailAddress: "상세 주소를 입력해 주세요",
		taxEmail: "세금계산서메일",
		enterTaxEmail: "세금계산서용 이메일을 입력해주세요",
		bankAccountNumber: "은행계좌번호",
		enterBankAccountNumber: "은행계좌를 숫자만 입력해 주세요",
		passportCopy: "통장사본",
		attached: "PDF, JPG, PNG 만 첨부 가능",
		attachment: "파일첨부",
		companyProfile: "회사소개서",
		onlyPDFAttached: "PDF 만 첨부 가능",
		businessRegistration: "사업자 등록증",
		serviceAgreement: "서비스동의",
		termAndCondition: "오너야 이용 약관 서비스 가입",
		disagree: "동의하지 않습니다",
		agree: "동의합니다",
		supplierResponsibilities: "협력사의 책임",
		cancel: "취소",
		signUp: "가입하기",
		emailWillBeSent: "대표자 연락처로 가입 이메일리 발송 됩니다.",
		findYourId: "가입 ID 찾기",
		copyOfBankbook: "통장사본",
		financialStatement: "제무재표",
		moreInfo: {
			title: "파트너사 가입",
			subTitle: "|  추가 정보 (선택)",
			companyDetails: "회사 상세 정보",
			companyDetailsExplained: "|  사용자에게 소개되는 정보입니다.",
			companyStartDate: "회사 설립일",
			turnover: "3년 매출액",
			turnoverExample1: "2022년",
			turnoverExample2: "2021년",
			turnoverExample3: "2020년",
			aboutUs: "회사 소개",
			aboutUsPlaceholder: "회사 소개를 입력해주세요",
			fundAccountInfo: "계좌 정보",
			fundAccountInfoExplained: "|  펀딩 등록에 필요한 정보입니다.",
			accountNumber: "계좌번호",
			bankChoice: "은행 선택",
			accountNumberPlaceholder: "은행 계좌를 숫자만 입력해주세요",
			emailPlaceholder: "세금 계산서를 받을 이메일을 입력해주세요",
			bankBookCopy: "통장 사본"
		}
	},

	navbar: {
		faq: "알림",
		notice: "공지사항",
		help: "도움말",
		logout: "로그 아웃"
	},

	sidebar: {
		memberList: "회원 목록",
		dashboard: "대시보드",
		funding: "펀딩관리",
		trade: "트레이드 관리",
		inventoryOrder: "재고/주문 관리",
		deliveryReceipt: "배송/입고 관리",
		inventory: "재고 관리",
		shipmentRequest: "배송 요청",
		shippingRequest: "배송 요청",
		shipping: "배송 관리",
		warehouse: "입고 관리",
		customer: "고객관리",
		recommendation: "추천글 관리",
		investment: "투자후기 관리",
		timeline: "타임라인 관리",
		profit: "정산관리",
		businessInfo: "비즈니스 정보",
		category: "카테고리",
		brands: "브렌드관리",
		partner: "파트너",
		requestedBrands: "요청된 브랜드",
		brandList: "브랜드 목록",
		subCategory: "하위 카테고리",
		subSubCategory: "최하위 카테고리",
		setting: "환경",
		inquiry: "1:1 문의 관리",
		owner: "오너 관리",
		settlement: "정산 관리",
		review: "리뷰 관리",
		reviewArticle: "리뷰 기사",
		reviewReport: "신고",
		advertising: "광고 관리",
		bannerManagement: "프로배너 관리",
		couponManagement: "쿠폰 관리",
		mileageManagement: "마일리지 관리",
		operation: "운영",
		adminSetting: "관리자 설정",
		brandingManagement: "브랜딩 관리",
		faqManagement: "FAQ 관리",
		qnaManagement: "QnA 관리"
	},

	filter: {
		searchPlaceholder: "상품코드, 브랜드명, 상품명 검색",
		selectPlaceholder: "기간 선택",
		searchBtn: "검색"
	},

	tableFilter: {
		title: "재고 목록",
		myOrder: "내주문",
		customerRequest: "고객 요청",
		all: "전체",
		shippingReq: "배송 요청",
		shipping: "배송중",
		delivered: "배송 완료"
	},

	validationMsg: {
		validEmail: "유효한 이메일 주소를 입력해주세요",
		emailRequired: "이메일이 필요합니다",
		pwRequired: "비밀번호가 필요합니다",
		imgRequired: "이미지가 필요합니다",
		categoryRequired: "카테고리 이름은 필수 항목입니다.",
		appRequired: "업로드할 애플리케이션을 추가하세요.",
		fieldRequired: "이 필드는 필수 항목입니다.",
		endDateError: "종료 날짜는 시작 날짜 이전일 수 없습니다.",
		missingCategory: "카테고리를 추가해주세요",
		mustBePositive: "입력은 양수여야 합니다.",
		requiredSource: "Source을 입력하세요",
		requiredURL: "URL을 입력하세요",
		insertFundingNameFirst: "펀딩 이름을 먼저 입력하세요.",
		insertImage: "* 이미지를 하나 이상 삽입하세요.",
		atLeast1000: "적어도 1000",
		postalCodeDigits: "우편 번호는 5 자리 숫자 여야합니다",
		invalidType: "유효하지 않은 유형"
	},

	signUpValidationMsg: {
		emailRequired: "이메일이 필요합니다",
		mustBeEmail: "유효한 이메일 주소를 입력해주세요",
		pwRequired: "비밀번호가 필요합니다",
		unmatchPasswords: "비밀번호가 일치해야합니다",
		certificationNumber: "인증 번호는 필수 항목입니다",
		businessRegistration: "등록 번호는 필수 항목입니다",
		mutual: "상호 번호는 필수 항목입니다",
		representativeName: "대표 이름은 필수 항목입니다",
		representativeContact: "담당자 연락처가 필요합니다",
		representativeCountryCode: "전화번호는 필수 항목입니다",
		contactName: "연락처 이름은 필수 항목입니다",
		contactCountryCode: "전화번호는 필수 항목입니다",
		companyPostCode: "주소를 입력해주세요",
		companyAddress: "회사 주소는 필수 항목입니다",
		companyDetailedAddress: "자세한 회사 주소는 필수 항목입니다.",
		taxEmail: "이메일이 필요합니다",
		bankAccountNumber: "은행 계좌 번호는 필수 항목입니다",
		passportCopy: "파일을 선택하세요",
		serviceAgreement: "서비스 계약이 필요합니다",
		supplierResponsibilities: "공급업체 책임이 필요합니다"
	},

	table: {
		actions: "액션",
		status: "상태"
	},

	toolTips: {
		toEdit: "수정하기",
		toComment: "논평",
		investmentReviewManagement: "투자후기 관리",
		secondaryDocumentRegistration: "차 서류 등록",
		copy: "복사",
		delete: "삭제"
	},

	funding: {
		agreeToTOC: "약관 동의",
		searchBoxPlaceholder:
			"펀딩 코드, 펀딩명, 브랜드명, 파트너사 코드, 파트너사명 검색",
		summary: "요약",
		fundingStartDate: "펀딩 시작일",
		allChangesSaved: "모든 변경사항이 저장되었습니다.",
		info: "펀딩 정보",
		productInfo: "상품 정보",
		create: "새 펀딩 등록",
		emptyPage: {
			create: "새 펀딩 등록 시작",
			description1: "펀딩을 등록해",
			description2: "오너를 모집해보세요",
			noSearchResult1: "검색 결과가 없습니다",
			noSearchResult2: "다시 시도해보세요"
		},
		stepIndicator: {
			step1: {
				newFundingRegistration: "새로운 펀딩 등록",
				title: "약관 동의 (필수)",
				details: "펀딩 등록에 필요한 약관을 확인해주세요."
			},
			step2: {
				title: "펀딩 상세 정보 (필수)",
				details: "펀딩모집 / 상품 / 판매 기간에 대한 정보를 입력해주세요."
			},
			step3: {
				title: "추가 정보 (2차 추가 등록)",
				details: "펀딩 및 정산에 필요한 추가 서류를 제출해주세요."
			}
		},
		createProduct: {
			registerNew: "새로운 펀딩 등록하기",
			editFundInfo: "펀딩 정보 수정",
			terms: "이용 약관",
			responsibilities: "파트너사의 책임",
			fundingFee: "펀딩 수수료"
		},
		createProduct2: {
			basicInfo: {
				title: "기본 정보",
				fundingName: "펀딩명",
				productName: "상품명",
				brand: "브랜드",
				brandSearch: "브랜드 검색",
				registrationReq: "브랜드 등록 요청"
			},
			investmentInfo: {
				title: "모집 정보",
				amount: "모집 금액",
				maxAmountRaised: "최대 모집금액",
				raise: "한도 높이기",
				quantity: "상품 수량",
				unit: "상품 단가",
				settlement: "정산 금액",
				settlementMainInfo: "협의 후 결정",
				settlementSubInfo:
					"(상품 1개가 팔릴 때 오너야에 지급해야하는 금액입니다.)",
				A: "정산 단가(A)",
				B: "배송비(B)",
				C: "관리비(C)",
				ABC: "(A+B+C)",
				ABCdetails: "상품 1개가 팔릴 때 오너야에 지급해야하는 금액입니다.",
				period: "모집 기간",
				singleQuantity: "단일 수량",
				multiQuantity: "여러 수량",
				domestic: "국내 판매가",
				oversea: "해외 판매가",
				expected: "예정 판매가",
				repurchasePrice: "재매입 가격",
				repurchaseRate: "재매입 비율",
				agree: "서비스 약관에 동의해야 합니다",
				termsDetails:
					"판매 마감일 내에 상품이 판매되지 않을 경우, 상품 단가의 80% 가격으로 재매입합니다."
			},
			productInfo: {
				title: "상품 정보",
				productImg: "상품 이미지",
				productImgNotice:
					"최소 1개 이상의 이미지를 올려주세요. 권장 이미지 사이즈 1000x1000, 용량 3MB, 형식 png, jpg, jpeg",
				description: "상품 스토리",
				category: "카테고리",
				origin: "원산지",
				tagForSearch: "검색용 태그",
				tagPlaceholder: "태그 추가",
				tagHelperText:
					"태그 입력 후 콤마(,) 또는 enter를 입력하여 추가해주세요. (최대 10개)",
				registerAsImg: "이미지로 등록하기",
				registerAsDescription: "Register as description"
			},
			salesSchedule: {
				title: "판매 일정",
				deliDate: "입고 예정일",
				deliDateNotice: "상품을 매입할 날짜를 입력해주세요.",
				salesStart: "판매 시작일",
				salesStartNotice: "입고 예정일로부터 2주 후 자동 판매 시작됩니다.",
				saleDeadline: "판매 마감일",
				saleDeadlineNotice: "판매 시작일로부터 최대 6개월을 넘어갈 수 없습니다."
			},
			relatedInfo: {
				title: "관련정보",
				subTitle: "선택 입력 , 상품에 관련된 뉴스를 입력해주세요.",
				info1: "정보 1",
				info2: "정보 2",
				info3: "정보 3",
				info4: "정보 4",
				source: "출처",
				url: "URL"
			},
			brandRequestDialog: {
				title: "브랜드 요청",
				message: "자금을 지원하고 싶은 브랜드 이름을 요청하세요.",
				brandNameKor: "상표명(상표명)",
				brandNameEng: "상표명(상표명)"
			},
			raiseLimitDialog: {
				title: "Raise Insurance Limit",
				message: "",
				raiseLimit: "모금할 금액"
			}
		},
		createProduct3: {
			title: "판매 활성화에 필요한 서류",
			description:
				"펀딩 모집이 완료되면 7일 이내에 구비 서류를 제출 해야 합니다. 7일 이내에 구비서류가 등록이 안될 경우 보증보험에 펀딩금액을 청구하게 됩니다. 일정 변동시 오너야 문의 하기에서 1:1  문의 해주세요.",
			helperText: "첨부파일 : PDF. PNG, JPG 만 가능해요.",
			remittanceConfirmation: "송금 확인증",
			creditLetter: "신용장(LC) 전문",
			waybill: "운송장(BL)",
			imported: "수입면장",
			transactionStatement: "거래명세서(국내)"
		},
		productList: {
			fundingCode: "$t(common.fundingCode)",
			fundingName: "펀딩명",
			fundingStatus: "펀딩 상태",
			fundingUnit: "펀딩 단가",
			investmentEnd: "판매 기간",
			investmentStart: "모집 기간",
			modificationDate: "수정일",
			productUnitPrice: "상품 단가/수량",
			registrationAndModificationDate: "등록일/수정일",
			registrationAndApprovedDate: "등록일/승인일",
			saleTerm: "판매 기간",
			settlementStatus: "정산 상태",
			targetAmount: "펀딩 단가",
			targetAndRecruitmentAmount: "목표 금액 / 모집 금액",
			targetAndSalesQuantity: "목표 수량 / 판매 수량",
			wearingStatus: "입고 상태"
		},
		alertMessages: {
			postDocumentSuccess: "문서가 게시됨",
			postDocumentFail: "문서 게시 실패"
		},
		notice: "(필수)",
		approvalRequest: "승인요청",
		registerProduct: "제품 등록",
		fullDownload: "전체 다운로드",
		selectDownload: "선택 다운로드",
		recruitmentCompleted: "모집완료",
		waitingForSale: "판매대기중",
		title: "펀딩관리",
		subTitle: "관리하려고 하는 펀딩 상태를 선택해주세요.",
		deselect: "선택 해제",
		fullSelection: "전체 선택",
		fundingList: "펀딩 목록",
		beforeWearing: "입고 전",
		payingOff: "정산 중",
		all: "전체",
		writing: "작성중",
		waitingForApproval: "승인 대기",
		recruitmentPlanned: "모집 예정",
		recruiting: "모집중",
		recruitmentFailure: "모집 실패",
		sale: "판매중",
		stopShipping: "출고 정지",
		settlementCompleted: "정산 완료",
		orderStatus: "주문 현황",
		cancelStatus: "취소 현황",
		statuses: {
			all: "펀딩 목록 전체",
			creating: "작성중",
			pending: "승인대기중",
			approved: "모병",
			recruiting: "모병",
			completed: "완전한",
			recruiting_complete: "모집 완료",
			recruiting_fail: "모집 실패",
			rejected: "거부됨",
			deadline: "마감일",
			step_one_creating: "1 단계 만들기",
			step_two_creating: "2 단계 만들기",
			recruiting_success: "모집 성공",
			scheduled: "예정",
			secondary_document: "보조 문서",
			sale: "판매",
			sold_out: "매진",
			in_trade: "트레이드중",
			codes: {
				funding01: "작성중",
				funding02: "승인 대기 중",
				funding03: "오픈예정",
				funding04: "모집",
				funding05: "거부됨",
				funding06: "기한 마감",
				funding07: "모집성공",
				funding08: "모집 실패",
				funding09: "모집 완료",
				funding10: "두 번째 문서",
				funding11: "세일",
				funding12: "매진",
				funding13: "두 번째 문서 보류 중"
			}
		},
		dateFilter: {
			fundingStartDate: "펀딩 시작일",
			investStartDate: "투자 시작 날짜",
			investEndDate: "투자 종료 날짜",
			saleStartDate: "판매 시작 날짜",
			saleDeadlineDate: "판매 마감일"
		}
	},

	recommendationManagement: {
		recommendationList: {
			recommendationList: "추천글 목록"
		}
	},

	owner: {
		ownerDetails: "사용자 상세 정보",
		userCode: "사용자 코드",
		nickname: "닉네임",
		name: "이름",
		age: "나이",
		gender: "성별",
		email: "이메일",
		lastLogin: "마지막 로그인",
		subscription: "가입일",
		marketingConsent: "마케팅 동의",
		totalLogin: "누적 로그인",
		totalInvestments: "누적 투자수",
		totalTrades: "누적 트레이드수",
		overallReturnRate: "전체 수익율",
		marketReturnRate: "마켓 수익율",
		tradeReturn: "트레이드 수익율",
		loginToday: "오늘 로그인",
		days7Login: "7일 로그인",
		days28Login: "28일 로그인",
		withdrawalMember: "탈퇴회원",
		suspenseActivity: "활동 정지",
		president: "사장님",
		ceo: "대표님",
		president1: "회장님",
		ratingSetting: "등급 설정",
		subscriptionStatus: "전체 회원수",
		totalMembersToday: "전체 회원수",
		today: "오늘",
		last7Days: "최근 7일",
		last28Days: "최근 28일",
		accountNumber: "계좌번호",
		cellPhone: "휴대전화",
		holdingAmount: "보유금액",
		mileage: "마일리지",
		investment: "투자 ",
		trade: "트레이드",
		ownership: "소유권",
		accountDetails: "계좌내역",
		oneOneRatioInquiry: "1:1문의",
		csManagement: "CS관리",
		gradeManagement: "등급 관리",
		mileageHistory: "마일리지 내역",
		suspensionOfActivity: "활동 정지",
		gradeChange: "등급 변경",
		mileagePayment: "마일리지 결제",
		sendMessage: "메시지 보내기",
		rating: "등급",
		moreInfo: "상세정보",
		promotionCriteria: "승급 기준",
		chargeAmount: "충전 금액",
		criteria1: "등급이 바뀐 날 기준 1년간 누적 측정",
		criteria2: "2개의 기준 충족 시 승급",
		active: "활동",
		inactive: "비활성"
	},

	shipping: {
		header: "배송 관리",
		downloadRequestForm: "배송 요청 양식 다운로드",
		registerAddress: "배송지 한번에 등록",
		onSale: "판매중",
		soldOut: "판매완료",
		exportCompleted: "반출완료",
		all: "전체",
		fullFundingList: "펀딩 목록 전체",
		available: "판매 가능",
		paid: "결제 완료",
		deliveryRequest: "배송 요청",
		confirmRequest: "요청 확인",
		shipping: "배송중",
		completed: "배송 완료",
		unitPrice: "상품 단가",
		shippingFee: "배송비",
		adminFee: " 관리비",
		payNotice: "배송 요청 할 상품 수량 선택 후, 정산 금액을 확인해주세요.",
		quantity: "상품 수량",
		totalSettlementAmount: "총 정산 금액",
		payBtn: "결제하기",
		cancelSelectedShipping: "선택 배송 요청 취소",
		fundingListHits: "펀딩 목록 조회",
		cases: "건",
		tableHeaders: {
			division: "구분",
			fundingCode: "$t(common.fundingCode)",
			fundingName: "펀딩명",
			shippingStatus: "배송 상태",
			shippingCode: "배송코드",
			recipient: "수령인",
			address: "주소지",
			phone: "전화번호",
			invoiceEntryDate: "송장 입력일",
			invoiceNumber: "송장 번호",
			courier: "택배사",
			productCode: "상품코드",
			deliveryStatus: "배송 상태",
			partnerCode: "파트너사코드",
			partnerName: "파트너사명"
		},
		summary: {
			inventoryStatus: "재고현황",
			numOfCompanies: "업체수",
			subject: "품목",
			productQuantity: "상품 수량",
			availableForSale: "판매 가능",
			completePayment: "결제 완료",
			allProducts: "상품 전체",
			deliveryRequest: "배송 요청",
			confirmRequest: "요청 확인",
			shipping: "배송중",
			deliveryCompleted: "배송 완료"
		},
		card: {
			sale: "판매중",
			soldOut: "판매 완료",
			endOfSale: "판매 기간 종료",
			productList: "상품 목록",
			deliveryStatus: "배송 상태",
			orderPayment: "주문 결제",
			shippingRequest: "배송 요청",
			settlementUnitPrice: "정산 단가",
			salePeriod: "판매 기간"
		},
		exportRequest: {
			header: "반출 요청",
			exportOptions: "반출 방식",
			selfPickup: "직접 방문",
			deliver: "택배(퀵) 착불",
			manager: "담당자",
			address: "주소지",
			phone: "전화번호",
			contact1: "연락처1",
			contact2: "연락처2",
			findPostalCode: "우편번호 찾기",
			shippingNotes: "배송 메모"
		},
		shippingInfo: {
			header: "배송정보  |  ",
			instruction: "배송 정보가 입력된 엑셀파일을 업로드 해주세요.",
			shippingRequestForm: "배송 요청 양식",
			enterShippingInfo: "배송 정보 입력",
			downloadShippingForm: "배송 양식 다운로드",
			fileUpload: "파일 업로드"
		},
		requestComplete: {
			header: "한번에 등록하기",
			context:
				"배송 요청이 완료되었습니다. 요청한 상품은 배송관리에서 배송 상태를 확인하실 수 있습니다.",
			manageShipment: "배송 관리하기",
			viewRequestHistory: "요청 내역 보기"
		},
		individualProduct: {
			header: "배송정보  |  상품 개별 입력하기",
			productList: "상품 목록",
			receiver: "받으시는분",
			add: "+ 추가",
			enterName: "이름 입력",
			enterNumber: "숫자만 입력"
		},
		importMethod: {
			message: "배송 정보를 입력할 방법을 선택해주세요",
			firstBtn: "엑셀파일로 한번에 등록하기",
			secondBtn: "상품 개별 입력하기"
		}
	},

	inventoryManagement: {
		onSale: "판매중",
		salesCompleted: "판매 완료",
		fullFunding: "전체 펀딩",
		wearingInspection: "입고 검수",
		summary: {
			inventoryStatus: "재고현황",
			numOfCompanies: "업체수",
			item: "품목",
			commodityQuantity: "상품 수량",
			availableForSale: "판매 가능",
			paymentCompleted: "결제 완료",
			wearingQuantity: "입고 수량",
			exportQuantity: "반출 수량",
			shippingDelivery: "배송 출고"
		},
		tableHeaders: {
			fundingCode: "$t(common.fundingCode)",
			fundingName: "펀딩명",
			partnerCompanyCode: "파트너사코드",
			partnerMission: "파트너사명",
			salesTerm: "판매 기간",
			fluctuation: "변동전",
			afterFluctuation: "변동후",
			content: "내용",
			changeDate: "변경일"
		}
	},

	category: {
		header: "카테고리",
		tableHeader: "카테고리",
		createCategory: "카테고리 생성",
		editCategory: "카테고리 편집",
		deleteCategory: "카테고리 삭제",
		deleteMessage: "카테고리를 삭제하시겠습니까?"
	},

	subCategory: {
		header: "하위 카테고리",
		tableHeader: "하위 카테고리"
	},

	subSubCategory: {
		header: "최하위 카테고리",
		tableHeader: "최하위 카테고리"
	},

	brand: {
		header: "상표",
		koreanName: "한국 이름",
		englishName: "영어 이름",
		editBrand: "브랜드 편집",
		deleteBrand: "브랜드 삭제",
		deleteMessage: "이 브랜드를 삭제하시겠습니까?",
		rejectReasonPlaceholder: "거부 사유를 입력하세요.",
		createBrand: {
			title: "브랜드 만들기",
			button: "브랜드 만들기",
			message: "",
			brandNameKor: "브랜드명(한글)",
			brandNameEng: "브랜드명(영문)",
			description: "설명",
			photo: "사진",
			rejectDialogMessage: "이 브랜드를 거부하시겠습니까?",
			deleteDialogMessage: "이 브랜드를 삭제하시겠습니까?"
		}
	},

	setting: {
		header: "환경",
		uploadApplication: "신청서 업로드",
		downloadApplication: "애플리케이션 다운로드"
	},

	receipt: {
		wearingStatus: "입고 현황",
		applicationDate: "신청일",
		fundingCode: "$t(common.fundingCode)",
		fundingName: "펀딩명",
		salesStartDate: "판매 시작일",
		partnerCompanyCode: "파트너사코드",
		partnerMission: "파트너사명",
		fundingRecruitmentQuantity: "펀딩 모집 수량",
		incomingApplicationQuantity: "입고 신청 수량",
		stockQuantity: "재고 수량",
		wearingFinishedQuantity: "입고 완료 수량",
		companionGoods: "반려 상품",
		stockQuantityAfterReceipt: "입고후 재고 수량"
	},
	"Change your warehouse information": "입고지 정보 변경",
	"Warehouse Information": "입고지 정보",
	Address: "주소",
	"000 000 412-1, Hanam-si, Gyeonggi-do": "경기도 하남시 000 000 412-1",
	"Phone Number": "전화번호",
	"031-0000-0000": "031-0000-0000",
	"Change your address": "입고지 주소 변경",
	"Save changes": "변경 저장",
	"Contact Us": "연락처",
	"166 Pangyo Station-ro, Sampyeong-dong, Bundang-gu, Seongnam-si, Gyeonggi-do":
		"경기도 성남시 분당구 삼평동 판교역로 166",
	"Find your Zip Code": "우편번호 찾기",
	"OOO Apartment No. 1102, 203-dong": "OOO아파트 203동 1102호",
	"Only 1 order per person": "1인당 1개만 주문 가능",
	"Can order n per person": "1인당 n개 주문 가능",
	"Recruitment Start Date": "모집 시작일",
	"Service Exposure": "서비스 노출",
	"Recruitment Period": "모집 기간",
	"Settlement information": "정산 정보",
	"This field should be a number": "이 필드는 숫자여야 합니다",
	"Up to 200,000,000 won (200 million won)": "최대 200,000,000원 (2억원)",
	"1 week before application start date": "모집 시작일로부터 1주일 전",
	"2 weeks from the application start date": "모집 시작일로부터 2주일",
	"Within 6 weeks of the recruitment start date": "모집 시작일 기준 6주 이내",
	"2 weeks from the expected date of delivery": "입고 예정일로부터 2주",
	"6 months from the start of sale": "판매 시작일로부터 6개월",
	"settlement price +5%": "정산가격 +5%",
	"confirmed after review": "검토 후 확정",
	"This is the repurchase rate for the total amount of funding":
		"펀딩 전체 수량에 대한 재매입 비율 입니다",
	"You have completed your funding registration request.":
		"펀딩 등록 요청을 완료하였습니다.",
	"We'll let you know via notification when funding is approved.":
		"펀딩이 승인되면 알림을 통해 알려드리겠습니다.",
	"Funding has been declined.": "펀딩이 반려 되었습니다.",
	"Reason for rejection": "반려 사유",
	"Not implemented yet": "아직 구현되지 않음",
	"Select Date": "날짜 선택",
	"Final Settlement Unit Price": "최종 정산 단가",
	"Failed to fetch resources": "리소스를 가져오지 못했습니다.",
	"All fields should be selected": "모든 필드를 선택해야 합니다.",
	"Approval request date": "승인 요청일",
	"Initial writing date": "최초 작성일",
	"Last modified date": "마지막 수정일",
	"Recruitment Status": "모집 현황",
	"Attainment Rate": "달성율",
	"Cumulative number of participants": "누적 참여인원",
	"Cumulative Recruitment Amount": "누적 모집금액",
	"Review Status": "리뷰 현황",
	Community: "커뮤니티",
	"Recommended Posts": "추천글",
	"Investment Reviews": "투자후기",
	"Inventory Status": "재고 현황",
	Full: "전체",
	"Finished stocking": "입고 완료",
	"Delivery completed": "배송 완료",
	"Other documents": "기타서류",
	"Please attach the file": "파일을 첨부해주세요",
	Change: "변경",
	"Nothing to save": "저장할 것이 없습니다",
	"All or none in the group must be filled":
		"그룹의 전부 또는 하나도 채워야 합니다.",
	"Go back": "돌아가다",
	"Download successfully": "성공적으로 다운로드",
	"Download unsuccessful": "다운로드 실패",
	Companion: "반려",
	"Last save": "마지막 저장",
	Approval: "승인",
	"Settlement Unit Price": "정산 단가 설정",
	"Set Repurchase Price": "재매입가 설정",
	"Settlement unit price + 5%": "정산 단가 + 5%",
	"Dividend Setting": "배당금 설정",
	Owner: "오너",
	Partner: "파트너사",
	"It's the owner": "오너야",
	"Member List": "회원 목록",
	"Owner Management": "오너 관리",
	"Minimum Recruitment Quantity": "최저 모집 수량",
	"More information": "상세정보",
	"Send message": "메시지 보내기",
	"Partner information": "대표자 이름",
	"Subscription approval completion date": "가입 승인 완료일",
	"Order List": "주문 목록",
	"Settlement Request Details": "정산 요청 내역",
	"Funding Information": "펀딩 정보",
	"View Terms": "약관 보기",
	"Partner Management": "파트너사 관리",
	"Partner List": "파트너사 목록",
	complete: "완벽한",
	request: "입고요청",
	confirm: "확인",
	"Shipping Management": "배송 관리",
	"Search for funding code, funding name, brand name, partner company code, and partner company name":
		"파트너사코드, 파트너사명, 대표자명, 담당자명, 연락처, 이메일 입력",
	"Enter user code, nickname, name, email, and contact information":
		"사용자코드, 닉네임, 이름, 이메일, 연락처 입력",
	"Please inform the partner of the reason for the rejection in detail.":
		"파트너사에게 반려 사유를 상세히 알려주세요.",
	"Send message to partners": "파트너사에 메시지 보내기",
	"We ask for confirmation.": "확인 요청 드립니다.",
	"Please enter the information you want to check.":
		"확인하실 내용을 입력해주세요.",
	"Notification means": "알림 수단",
	"Funding rejection": "펀딩 반려",
	Send: "전송하기",
	Reject: "반려하기",
	"Shipping Request": "배송 요청",
	"Funding List All": "펀딩 목록 전체",
	"On Sale": "판매중",
	"Sold Out": "판매완료",
	"Export Complete": "반출완료",
	All: "전체",
	"List is empty": "목록이 비어 있습니다.",
	"Shipping Requests are not made yet":
		"배송 요청이 아직 이루어지지 않았습니다",
	"Partner company name": "파트너사 회사명",
	"Partner Company": "파트너사",
	Summary: "요약",
	Item: "품목",
	"Product Quantity": "상품 수량",
	"Available for sale": "판매 가능",
	"Complete payment": "결제 완료",
	"days ago": "일전",
	"day ago": "일전",
	"No file uploaded": "업로드된 파일 없음",
	"Upload Failed": "업로드 실패",
	"Enter detailed address": "상세 주소 입력",
	"Shipping notes": "배송요청사항",
	"Complete request": "배송 요청 완료",
	"Upload Success": "업로드 성공",
	Recipient: "받으시는분",
	"Contact 1": "연락처1",
	"Contact 2": "연락처2",
	Notes: "배송요청사항",
	"Order Code": "주문코드",
	"User Code": "사용자코드",
	"Order Amount": "주문 금액",
	Quantity: "수량",
	Charge: "수수료",
	"Used Mileage": "사용 마일리지",
	"Order Date Time": "주문 날짜/시간",
	"Settlement Type": "정산 유형",
	"Member Code": "회원 코드",
	Amount: "금액",
	"Enter Address": "주소지입력",
	Order: "주문",
	Cancelled: "취소",
	"Order Date/Time": "주문 날짜/시간",
	"Please select at least one or more items":
		"하나 이상의 항목을 선택하십시오.",
	Downloading: "다운로드 중",
	"Delivery Info Sent": "배달 정보 전송됨",
	"Could not send delivery info": "배송 정보를 보낼 수 없습니다",
	"Failed to copy text": "텍스트를 복사하지 못했습니다.",
	Back: "돌아가기",
	"To List": "목록으로",
	"Receipt approval": "입고 승인",
	finished: "입고 완료",
	all: "전체",
	"Receipt completion date": "입고 완료일",
	"Receipt completed quantity": "입고 완료 수량",
	"Before stocking": "입고전",
	nSearchResults: "검색 결과 {{count}}명",
	"Shipping request information": "배송 요청 정보",
	"Cancel shipping request": "배송 요청 취소",
	"Invoice information": "송장 정보",
	promptCancelNShippingRequest:
		"선택하신 {{count}}건의 상품을 배송요청 취소하시겠습니까?",
	"Cancel request": "취소 요청",
	"Success!": "성공!",
	"Delivery company": "택배사",
	"N/A": "사용할 수 없음",
	"Please allow pop-up to download": "다운로드를 위해 팝업을 허용해주세요",
	"Receipt Request Information": "입고 요청 정보",
	"Stocking Date": "입고일",
	"Enter Quantity": "입고 수량",
	"Stocking time": "입고 시간",
	Manager: "담당자",
	Contact: "연락처",
	"Stocking method": "입고 방식",
	"Stock Directly": "직접 입고",
	"Courier(quick) warehousing": "택배(퀵) 입고",
	"Receipt Approval": "입고 승인",
	"Expected quantity to receive": "입고예정수량",
	"Receipt Quantity (A)": "입고수량 (A)",
	"Receipt Quantity (B)": "반품수량 (B)",
	"Inception Quantity (A+B)": "검수수량 (A+B)",
	"Receipt Result": "입고결과",
	Print: "출력",
	"Stocking Finished": "입고 완료",
	"Warehouse Manager": "입고담당자",
	"(Name / Signature)": "(이름 / 서명)",
	"Receipt Inspection Information": "입고 검수 정보",
	"Stocking Request": "입고 요청",
	"Time Selection": "시간 선택",
	"Warehousing Application": "입고 신청",
	Cancellation: "취소",
	"Cancel Receipt Request": "입고 요청 취소",
	"Desired date of wearing": "입고 희망일",
	"Desired wearing time": "입고 희망 시간",
	"Quantity requested": "신청 수량",
	"Total stock quantity": "전체 재고 수량",
	"Confirm Select Shipping Request": "선택 배송 요청 확인",
	"Search by company code and company name": "업체코드, 회사명 을 검색하세요.",
	Select: "선택",
	"Representative Name": "대표자 이름",
	"Representative Contact": "대표자 연락처",
	"Representative Email": "대표자 email",
	"Contact Person": "담당자 이름",
	"Contact Number": "담당자 연락처",
	"Maximum file size limit (20MB) exceeded": "최대 파일 크기 제한(20MB) 초과",
	"Funding Details": "펀딩 상세 정보",
	Next: "다음",
	"Register Invoice": "송장 등록",
	"Delete shipping information": "배송 정보 삭제",
	shipping_: "배송",
	Previous: "이전",
	Search: "검색",
	Cancel: "취소",
	Create: "추가",
	Submit: "제출하다",
	Save: "저장",
	Delete: "삭제",
	Upload: "업로드",
	Download: "다운로드",
	Agree: "동의합니다",
	Update: "업데이트",
	Message: "메시지",
	Help: "도움말",
	Copy: "복사",
	"Change Password": "비밀번호 변경",
	"Upload File": "파일 불러오기",
	"Send authorization number": "인증번호 전송",
	"Find Postal Code": "우편번호 찾기",
	"Todo next": "다음에하기",
	"Sign Up Complete": "가입 완료",
	required: "필수의",
	optional: "선택 과목",
	"Warehouse application": "입고 신청",
	"Failed!": "실패한",
	pending: "승인대기중",
	approve: "입고승인",
	approved: "$t(approve)",
	completed: "입고완료",
	Complete: "$t(complete)",
	rejected: "거부됨",
	Confirm: "$t(confirm)",
	"Activity Status": "활동 현황",
	"Subscription Status": "가입 현황",
	"Register shipping invoices in bulk": "배송 송장 대량 등록",
	Approve: "$t(approve)",
	Approved: "$t(approve)",
	Writing: "작성중",
	Finished: "$t(finished)",
	Request: "$t(request)",
	Rejected: "$t(rejected)",
	Pending: "승인 대기 중",
	Scheduled: "오픈예정",
	Recruitment: "모집",
	Denied: "거부됨",
	Deadline: "기한 마감",
	"Recruiting Success": "모집성공",
	"Recruiting Fail": "모집 실패",
	"Recruiting Complete": "모집 완료",
	"Secondary Document": "두 번째 문서",
	Sale: "세일",
	"Secondary Document Pending": "두 번째 문서 보류 중",
	"Please select funding": "펀딩을 선택해주세요",
	"Email is already used": "이미 사용 중인 이메일",
	"Only round numbers are supported": "반올림 숫자만 지원됩니다",
	"Last login": "마지막 로그인",
	Since: "가입일",
	"Marketing Consent": "마케팅동의",
	"Request for Approval": "승인 요청",
	"Completion of subscription": "가입완료",
	Preview: "시사",
	"Both parent and child categories are required":
		"상위 및 하위 범주가 모두 필요합니다",
	User: "사용자",
	Default: "디폴트값",
	"Set as default": "기본값으로 설정",
	Check: "$t(confirm)",
	"Enter name": "$t(shipping.individualProduct.enterName)",
	"Enter numbers only": "$t(shipping.individualProduct.enterNumber)",
	"File not uploaded": "파일이 업로드되지 않음"
};
