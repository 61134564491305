import { Typography } from "@mui/material";
import theme from "theme/theme";

const LabelText = ({ children, ...props }) => {
	return (
		<Typography
			sx={{ whiteSpace: "normal" }}
			color={theme.palette.gray.tertiary}
			{...props}>
			{children}
		</Typography>
	);
};

export default LabelText;
