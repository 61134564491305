import { API_CATEGORY } from "services/endpoints";
import { TAG_CATEGORY } from "services/tags";

export default (build) =>
	build.mutation({
		query: (id) => {
			return {
				url: `${API_CATEGORY}/${id}`,
				method: "DELETE"
			};
		},
		invalidatesTags: [TAG_CATEGORY]
	});
