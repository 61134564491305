import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { showAlert } from "redux/modules";

import { absoluteCenter } from "./index";

export function Loading() {
	return <CircularProgress sx={absoluteCenter} />;
}

export function withResponseHandler(Component) {
	return function wrapped(props) {
		const { t } = useTranslation();

		const message = t("Failed to fetch resources");

		const {
			response: {
				isError,
				isLoading,
				isFetching,
				isSuccess,
				data,
				error = {
					data: { message }
				}
			},
			...otherProps
		} = props;

		const dispatch = useDispatch();

		useEffect(() => {
			if (isError) {
				dispatch(
					showAlert({
						severity: "error",
						message: error.data.message
					})
				);
			}
		}, [isError]);

		if (isFetching || isLoading) {
			return <Loading />;
		}

		if (isSuccess && data) {
			return <Component response={data} {...otherProps} />;
		} else {
			return <Box sx={absoluteCenter}>{message}</Box>;
		}
	};
}
