import { API_BRAND } from "services/endpoints";
import { TAG_BRAND } from "services/tags";

export default (build) =>
	build.query({
		query: ({ query, currentPage, limit = true }) => {
			const params = {
				order: "desc",
				page: currentPage,
				q: query
			};

			if (limit) {
				params.per_page = 10;
			}

			return {
				url: API_BRAND,
				params,
				method: "GET"
			};
		},
		providesTags: [TAG_BRAND]
	});
