import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { hideAlert } from "redux/modules";

export const SnackbarAlert = () => {
	const dispatch = useDispatch();
	const alert = useSelector((state) => state.alert);

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		dispatch(hideAlert());
	};

	return alert.on ? (
		<Snackbar
			anchorOrigin={{
				horizontal: "center",
				vertical: "top"
			}}
			open={alert.on}
			autoHideDuration={alert.delay}
			onClose={handleClose}>
			<Alert
				severity={alert.severity}
				variant="filled"
				sx={{ width: "100%", color: "white" }}>
				{alert.message}
			</Alert>
		</Snackbar>
	) : null;
};
