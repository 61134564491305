import styled from "@emotion/styled";
import { Paper } from "@mui/material";

export const BasePlate = styled(Paper)({
	boxShadow: "0px 1px 15px rgba(0,0,0,0.05)",
	borderRadius: 9,
	marginBottom: 24
});

export const FlatPlate = styled(Paper)({
	boxShadow: "none",
	marginBottom: 24
});
