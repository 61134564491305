import { Box, styled } from "@mui/material";
import { Button } from "components/layout/Button";

export const ImageWrapper = styled(Box)(({ width, height }) => ({
	width,
	height,
	position: "relative",
	border: "1px solid lightGray",
	borderRadius: 6,
	overflow: "hidden"
}));

export const UploadBtn = styled(Button)({
	fontSize: 10,
	position: "absolute",
	right: 5,
	top: 5,
	padding: 2
});

export const SetThumbnailBtn = styled(Button)(({ theme, bg }) => ({
	position: "absolute",
	backgroundColor: bg,
	color: theme.palette.text.white,
	left: 5,
	top: 5,
	padding: 2,
	fontSize: 10
}));
