import { useEffect } from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";

import Login from "pages/auth/Login";
import NotFound from "pages/404/NotFound";
import { privateRoutes } from "routes";
import { Layout, SnackbarAlert } from "components/layout";
import {
	DEV_STORYBOOK,
	ROUTE_FORGOT_PASSWORD,
	ROUTE_LOGIN,
	ROUTE_NOTFOUND,
	ROUTE_REGISTER_PARTNER,
	ROUTE_SIGNUP_ID
} from "data/routeVariables";
import ForgotPassword from "pages/auth/ForgotPassword";
import SignUpId from "pages/auth/SignUpId";
import PartnerRegister from "pages/auth/PartnerRegister";
import { useUser } from "redux/modules/useUser";
import CommandPalette from "utils/commandPalette";

const OutletWrapper = styled(Box)(({ theme }) => ({
	marginLeft: 220,
	marginTop: 10,
	paddingInline: 40,
	paddingBlock: 60,
	[theme.breakpoints.down("md")]: {
		marginLeft: 0
	}
}));

const PrivateRoutes = ({ isLoggedIn }) => {
	return isLoggedIn ? (
		<OutletWrapper>
			<Outlet />
		</OutletWrapper>
	) : (
		<Navigate to={ROUTE_LOGIN} />
	);
};

function App() {
	const navigate = useNavigate();
	const { isLoggedIn, isSuperAdmin } = useUser();

	let blackList = [];
	if (isSuperAdmin) {
		blackList = ["funding"];
	} else {
		blackList = [
			"brandList",
			"brandRequestList",
			"category",
			"childCategory",
			"partnerList",
			"partnerMessage",
			"partnerDetails",
			"fundingAdmin",
			"shippingRequestAdmin"
		];
	}

	if (process.env.NODE_ENV !== "development") {
		blackList.push(DEV_STORYBOOK.id);
	}

	const permittedPrivateRoutes = privateRoutes.filter(
		(element) => !blackList.includes(element.id)
	);

	useEffect(() => {
		!isLoggedIn && navigate(ROUTE_LOGIN);
	}, [isLoggedIn]);

	return (
		<div className="App">
			{process.env.NODE_ENV === "development" && isLoggedIn ? (
				<CommandPalette />
			) : null}
			{/* Protected Routes */}
			{isLoggedIn && <Layout />}
			<Routes>
				<Route element={<PrivateRoutes isLoggedIn={isLoggedIn} />}>
					{isLoggedIn &&
						permittedPrivateRoutes.map((route) => {
							// support for multiple routes to a single components
							if (Array.isArray(route.path)) {
								return route.path.map((path) => {
									return (
										<Route
											key={`${route.id}/${path}`}
											path={path}
											element={route.element}
										/>
									);
								});
							}

							return (
								<Route
									key={route.id}
									path={route.path}
									element={route.element}
								/>
							);
						})}
				</Route>
				{!isLoggedIn && (
					<>
						<Route path={ROUTE_LOGIN} element={<Login />} />
						<Route
							path={ROUTE_REGISTER_PARTNER}
							element={<PartnerRegister />}
						/>
						<Route path={ROUTE_FORGOT_PASSWORD} element={<ForgotPassword />} />
						<Route path={ROUTE_SIGNUP_ID} element={<SignUpId />} />
					</>
				)}
				<Route path={ROUTE_NOTFOUND} element={<NotFound />} />
			</Routes>
			<SnackbarAlert />
		</div>
	);
}

export default App;
