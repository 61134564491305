import { API_SHIPPING_INFO } from "services/endpoints";
import { TAG_SHIPPING } from "services/tags";

export default (build) =>
	build.query({
		query: ({
			currentPage,
			perPage = 10,
			dateFilterType,
			dateFilterStart,
			dateFilterEnd,
			search,
			deliveryStatus,
			tabFilter
		}) => {
			return {
				url: API_SHIPPING_INFO,
				params: {
					page: currentPage,
					per_page: perPage,
					delivery_status: deliveryStatus,
					sale_status: tabFilter,
					q: search,
					filterType: dateFilterType,
					start_date: dateFilterStart,
					end_date: dateFilterEnd
				},
				method: "GET"
			};
		},
		transformResponse: ({ response: { data, meta } }) => ({
			result: data,
			meta
		}),
		providesTags: [TAG_SHIPPING]
	});
