import { Divider, Stack, Typography } from "@mui/material";
import { BasePlate } from "components/layout";
import { StackRow } from "components/shared";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import upArrow from "assets/images/upArrow.svg";
import downArrow from "assets/images/downArrow.svg";
import { Button } from "components/layout/Button";
import { CustomStack } from "./style";
import { RatingSettingDialog } from "components/dialogs/owner/RatingSettingDialog";
import { ownerApi } from "services/modules/owner";

const ActivityStatusBox = () => {
	const { t } = useTranslation();
	const [isRatingDialogOpen, setRatingDialogOpen] = useState(false);

	const { data } = ownerApi.useGetOwnerActivityStatusesQuery();

	const unactiveCounts = data?.response.unactiveUserCounts || [];

	const countByDate = data?.response.userCountsByDate || {};

	const countByLevel = data?.response.userCountsByLevel || {};

	const summaryData = [
		[
			{
				statName: t("owner.loginToday"),
				stat: countByDate.today || 0,
				indicator: upArrow
			},
			{
				statName: t("owner.days7Login"),
				stat: countByDate.thisWeek || 0,
				indicator: downArrow
			},
			{
				statName: t("owner.days28Login"),
				stat: countByDate.thisMonth || 0,
				indicator: "-"
			}
		],
		[
			{
				statName: t("owner.withdrawalMember"),
				stat: unactiveCounts[0]?.suspendedCount || 0
			},
			{
				statName: t("owner.suspenseActivity"),
				stat: unactiveCounts[0]?.withdrawnCount || 0
			}
		],
		[
			{
				statName: t("owner.president"),
				stat: countByLevel.boss || 0
			},
			{
				statName: t("owner.ceo"),
				stat: countByLevel.ceo || 0
			},
			{
				statName: t("owner.president1"),
				stat: countByLevel.president || 0
			}
		]
	];

	return (
		<Stack mb={20}>
			<BasePlate sx={{ paddingY: 24, paddingX: 20, overflowX: "auto" }}>
				<StackRow alignItems="center" justifyContent="space-between">
					<StackRow gap={40} alignItems="center">
						<Typography
							sx={{ whiteSpace: "nowrap" }}
							variant="text17"
							fontWeight={700}>
							{t("Activity Status")}
						</Typography>
						{summaryData.map((summaryGroup, index) => (
							<Fragment key={`summaryGroup-${index}`}>
								<Divider orientation="vertical" variant="middle" flexItem />
								{summaryGroup.map(({ statName, stat, indicator }, idx) => {
									return (
										<CustomStack
											key={`${idx}-${statName}`}
											statName={statName}
											stat={stat}
											indicator={indicator}
										/>
									);
								})}
							</Fragment>
						))}
					</StackRow>
					<Button
						sx={{ marginLeft: 40 }}
						onClick={() => setRatingDialogOpen(true)}>
						{t("owner.ratingSetting")}
					</Button>
				</StackRow>
			</BasePlate>
			<RatingSettingDialog
				isDialogOpen={isRatingDialogOpen}
				setDialogOpen={setRatingDialogOpen}
			/>
		</Stack>
	);
};

export default ActivityStatusBox;
