const {
	styled,
	Drawer,
	AccordionDetails,
	AccordionSummary,
	Accordion
} = require("@mui/material");
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const drawerWidth = 220;

export const CustomDrawer = styled((props) => (
	<Drawer anchor="left" {...props} />
))(() => ({
	width: drawerWidth,
	flexShrink: 0,
	"& .MuiDrawer-paper": {
		width: drawerWidth,
		boxSizing: "border-box"
	}
}));

export const ProfileShowcaseWrapper = styled("div")(() => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	margin: 38,
	gap: 15
}));

export const CustomAccordion = styled((props) => (
	<Accordion disableGutters elevation={0} square {...props} />
))(() => ({
	border: "none",
	"&:before": {
		display: "none"
	},
	"& .MuiAccordionSummary-root": {
		padding: "0px"
	}
}));

export const CustomAccordionSummary = styled((props) => (
	<AccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(() => ({
	margin: 0,
	position: "relative",
	"& .MuiAccordionSummary-content": {
		margin: 0
	},
	"& .MuiAccordionSummary-expandIconWrapper": {
		marginRight: 10
	}
}));

export const CustomAccordionDetails = styled(AccordionDetails)(() => ({
	padding: "0px",
	textAlign: "center"
}));
