import React from "react";
import { useTranslation } from "react-i18next";
import PrimaryAlertDialog from "../PrimaryAlertDialog";

const ChooseImportMethodDialog = ({
	isImportMethodDialogOpen,
	setImportMethodDialogOpen,
	setProductDialogOpen,
	setShippingInfoOpen
}) => {
	const { t } = useTranslation();

	const registerFromExcel = () => {
		setImportMethodDialogOpen(false);
		setProductDialogOpen(false);
		setShippingInfoOpen(true);
	};

	const registerFromUI = () => {
		setImportMethodDialogOpen(false);
		setProductDialogOpen(true);
		setShippingInfoOpen(false);
	};

	return (
		<PrimaryAlertDialog
			isDialogOpen={isImportMethodDialogOpen}
			setDialogOpen={setImportMethodDialogOpen}
			message={t("shipping.importMethod.message")}
			firstBtn={t("shipping.importMethod.firstBtn")}
			secondBtn={t("shipping.importMethod.secondBtn")}
			firstAction={registerFromExcel}
			secondAction={registerFromUI}
		/>
	);
};

export default ChooseImportMethodDialog;
