import { API_RESET_PASSWORD } from "services/endpoints";

export default (build) =>
	build.mutation({
		query: ({ email, otpCode, newPassword, passwordConfirmation, token }) => {
			return {
				url: API_RESET_PASSWORD,
				method: "POST",
				data: {
					email: email,
					otp_code: otpCode,
					password: newPassword,
					password_confirmation: passwordConfirmation,
					token
				}
			};
		},
		invalidatesTags: []
	});
