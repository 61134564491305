import { API_FUNDING } from "services/endpoints";
import { TAG_FUNDING } from "services/tags";

export default (build) =>
	build.query({
		query: ({ currentPage, perPage = 10, filter }) => {
			const params = {
				page: currentPage,
				per_page: perPage,
				filter
			};

			// `all` doesn't exist on db, it's for UI only
			if (filter === "all" || filter === "") {
				delete params.filter;
			}

			return {
				url: API_FUNDING,
				params,
				method: "GET"
			};
		},
		providesTags: [TAG_FUNDING]
	});
