import { Autocomplete, Box, styled, TextField } from "@mui/material";
import { primaryShadow } from "data/styleVariables";
import CoreTextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";

export const CustomAutoComplete = styled(Autocomplete)({
	"& > div > div ": {
		alignItems: "flex-start"
	}
});

export const CustomTextField = styled(TextField)(({ width }) => ({
	width,
	border: "1px solid lightGray",
	borderRadius: 6,
	outline: "none",
	boxShadow: primaryShadow
}));

export const SearchTextField = styled(TextField)(({ width }) => ({
	width,
	border: "1px solid lightGray",
	borderRadius: 6,
	outline: "none",
	boxShadow: primaryShadow,
	".MuiOutlinedInput-input": {
		padding: 0
	}
}));

export const PreviewBox = styled(Box)(({ theme }) => ({
	paddingBlock: 10,
	paddingInline: 14,
	border: "1px solid lightgray",
	borderRadius: 6,
	outline: "none",
	boxShadow: primaryShadow,
	color: theme.palette.text.placeholder
}));

export const CustomNumberField = styled(CoreTextField)(({ placement }) => ({
	"& .MuiInputBase-input": {
		textAlign: placement ? "center" : "left"
	},
	"& .MuiFormHelperText-contained": {
		width: "500px",
		margin: 0
	},
	"& input[type=number]": {
		MozAppearance: "textfield"
	},
	"& input[type=number]::-webkit-outer-spin-button": {
		WebkitAppearance: "none",
		margin: 0
	},
	"& input[type=number]::-webkit-inner-spin-button": {
		WebkitAppearance: "none",
		margin: 0
	}
}));

export const dateFieldStyles = makeStyles((theme) => ({
	root: {
		border: "1px solid lightgray",
		boxShadow: primaryShadow,
		borderRadius: "4px",
		backgroundColor: "white",
		"& .MuiInputBase-input": {
			padding: theme.spacing(1),
			height: 26,
			minWidth: "unset",
			"&:focus": {
				backgroundColor: "white"
			}
		},
		"& .MuiInputAdornment-positionEnd": {
			marginLeft: 0
		},
		"& .MuiInputBase-input.Mui-disabled": {
			WebkitTextFillColor: "black"
		}
	}
}));
