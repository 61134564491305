import { Container, Stack } from "@mui/material";
import { ContentStyle, RootStyle } from "./style";
import { Navbar } from "components/layout";
import { ResetPasswordForm } from "components/auth";

const ForgotPassword = () => {
	return (
		<RootStyle>
			<Navbar isLogin={true} />
			<Container maxWidth="sm">
				<ContentStyle justifyContent="center">
					<Stack direction="column" alignItems="center" sx={{ mb: 80 }}>
						<img
							src={require("assets/images/logo.png")}
							alt="logo"
							width={200}
						/>
					</Stack>

					<ResetPasswordForm />
				</ContentStyle>
			</Container>
		</RootStyle>
	);
};

export default ForgotPassword;
