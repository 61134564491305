import { useEffect } from "react";
import { Container, Stack } from "@mui/material";
import { LoginForm } from "components/auth";
import { useDispatch } from "react-redux";
import { resetAuth } from "redux/modules";
import { ContentStyle, RootStyle } from "./style";
import { Navbar } from "components/layout";

/* Need Future Reference */
// let easing = [0.6, -0.05, 0.01, 0.99];
// const fadeInUp = {
// 	initial: {
// 		y: 60,
// 		opacity: 0,
// 		transition: { duration: 0.6, ease: easing }
// 	},
// 	animate: {
// 		y: 0,
// 		opacity: 1,
// 		transition: {
// 			duration: 0.6,
// 			ease: easing
// 		}
// 	}
// };
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
	opacity: 1,
	y: 0,
	transition: {
		duration: 0.6,
		ease: easing,
		delay: 0.16
	}
};

const Login = () => {
	const dispatch = useDispatch();

	/* To clear previously logged in auth data */
	useEffect(() => {
		dispatch(resetAuth());
	}, []);

	return (
		<RootStyle>
			<Navbar isLogin={true} />
			<Container maxWidth="sm">
				<ContentStyle justifyContent="center">
					<Stack
						direction="column"
						alignItems="center"
						initial={{ opacity: 0, y: 40 }}
						animate={animate}
						sx={{ mb: 80 }}>
						<img
							src={require("assets/images/logo.png")}
							alt="logo"
							width={200}
						/>
					</Stack>

					<LoginForm />
					{/* Need Future Reference */}
					{/* <Typography
						component={motion.p}
						{...fadeInUp}
						variant="body2"
						align="center"
						sx={{ mt: 3 }}>
						Don’t have an account?{" "}
						<Link variant="subtitle2" component={RouterLink} to="/signup">
							Sign up
						</Link>
					</Typography> */}
				</ContentStyle>
			</Container>
		</RootStyle>
	);
};

export default Login;
