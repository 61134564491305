import { Breadcrumbs as MuiBreadcrumbs, Link } from "@mui/material";

const Breadcrumbs = ({ breadCrumbItems }) => {
	return (
		<MuiBreadcrumbs aria-label="breadcrumb" sx={{ mb: 12 }}>
			{breadCrumbItems.map(({ name, path }) => {
				return (
					<Link key={name} underline="none" href={path}>
						{name}
					</Link>
				);
			})}
		</MuiBreadcrumbs>
	);
};

export default Breadcrumbs;
