import { API_BRAND } from "services/endpoints";
import { TAG_BRAND } from "services/tags";

export default (build) =>
	build.mutation({
		query: (id) => {
			return {
				url: `${API_BRAND}/${id}`,
				method: "DELETE"
			};
		},
		invalidatesTags: [TAG_BRAND]
	});
