import { ImageWrapper } from "components/fileUpload/style";
import { StackRow } from "components/shared";
import dummyImg from "assets/images/kal-visuals-square.jpg";
import { BasePlate } from "components/layout";
import { Chip, Divider, Stack, Typography } from "@mui/material";
import { Button, PRIMARY, SECONDARY } from "components/layout/Button";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { openOrderPaymentDialog } from "redux/modules/shippingOrderPayment";
import {
	STATUS_EXPORT_COMPLETE,
	STATUS_ONSALE,
	STATUS_SOLDOUT
} from "pages/inventory/shippingRequest/status";
import IndividualProductDialog from "components/dialogs/shipping/IndividualProductDialog";
import ChooseImportMethodDialog from "components/dialogs/shipping/ChooseImportMethodDialog";
import ShippingDataContext from "../shippingDataContext";
import ShippingInformationDialog from "components/dialogs/shipping/ShippingInformationDialog";
import RequestCompleteDialog from "components/dialogs/shipping/RequestCompleteDialog";
import ExportRequestDialog from "components/dialogs/shipping/ExportRequestDialog";

const FundingCard = ({ status, shippingInfoData }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [isHovered, setHovered] = useState(false);

	const [isProductDialogOpen, setProductDialogOpen] = useState(false);
	const [isImportMethodDialogOpen, setImportMethodDialogOpen] = useState(false);
	const [isShippingInfoOpen, setShippingInfoOpen] = useState(false);
	const [isRequestCompleteOpen, setRequestCompleteOpen] = useState(false);
	const [isExportRequestOpen, setExportRequestOpen] = useState(false);

	const {
		funding,
		team,
		id: shippingInfoId,
		shippingRequestOrderPayment,
		saleStatusCode
	} = shippingInfoData || {};

	return (
		<BasePlate
			sx={{ p: 20, minHeight: 140, mb: 20 }}
			onMouseOver={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}>
			<Stack
				direction={{ md: "row", sm: "column" }}
				alignItems={{ md: "center", sm: "flex-start" }}>
				<StackRow alignItems="center" gap={30} pr={30}>
					<ImageWrapper minWidth={100} height={100}>
						<img height="100%" width="100%" src={dummyImg} />
					</ImageWrapper>

					<Stack gap={10}>
						<StackRow gap={7}>
							<Chip
								label={t(status)}
								color={status === "On Sale" ? PRIMARY : SECONDARY}
								sx={{ height: 21 }}
							/>
							<Typography
								variant="text14"
								fontWeight={700}
								color="gray.tertiary">
								{team?.name}
							</Typography>
							<Typography
								variant="text14"
								fontWeight={700}
								color="gray.tertiary">
								{funding?.code}
							</Typography>
						</StackRow>
						<Typography maxWidth={200} fontSize={18} fontWeight={700}>
							{funding?.name}
						</Typography>
						<Typography variant="text14" fontWeight={400} noWrap>
							{`${t("shipping.card.salePeriod")} ${
								funding?.saleStartDate || "00-00-00"
							} ~ ${funding?.saleDeadline || "00-00-00"}`}
						</Typography>
					</Stack>

					<Divider orientation="vertical" variant="middle" flexItem />
					{/* Removing this divider means wrapping the above two DIVs in one more StackRow */}

					<Stack gap={18}>
						<Stack>
							<Typography variant="text15" fontWeight={700}>
								{t("shipping.card.productList")}
							</Typography>
							<StackRow
								columnGap={10}
								color="gray.tertiary"
								variant="text15"
								fontWeight={400}
								divider={
									<Divider orientation="vertical" variant="middle" flexItem />
								}>
								<Typography>{`${t("shipping.fullFundingList")} 0`}</Typography>
								<Typography>{`${t("shipping.available")} 0`}</Typography>
								<Typography>{`${t("shipping.paid")} 0`}</Typography>
							</StackRow>
						</Stack>

						<Stack>
							<Typography variant="text15" fontWeight={700}>
								{t("shipping.card.deliveryStatus")}
							</Typography>
							<StackRow
								columnGap={10}
								color="gray.tertiary"
								variant="text15"
								fontWeight={400}
								divider={
									<Divider orientation="vertical" variant="middle" flexItem />
								}>
								<Typography>{`${t("shipping.deliveryRequest")} 0`}</Typography>
								<Typography>{`${t("shipping.confirmRequest")} 0`}</Typography>
								<Typography>{`${t("shipping.shipping")} 0`}</Typography>
								<Typography>{`${t("shipping.completed")} 0`}</Typography>
							</StackRow>
						</Stack>
					</Stack>
				</StackRow>

				<Divider orientation="vertical" variant="middle" flexItem />

				<StackRow pl={30} pt={{ sm: 20 }} alignItems="center">
					<Stack gap={8} alignSelf={{ md: "end", sm: "start" }} minWidth={160}>
						<Typography variant="text15" fontWeight={400}>
							{t("shipping.card.settlementUnitPrice")}
						</Typography>
						<Typography fontSize={23} fontWeight={700}>
							{shippingRequestOrderPayment?.totalSettlementAmount ||
								funding?.unitPrice ||
								0}
						</Typography>
					</Stack>

					<Stack
						direction={{ md: "column", sm: "row" }}
						sx={{ visibility: !isHovered && "hidden" }}
						gap={8}>
						{(saleStatusCode !== STATUS_EXPORT_COMPLETE ||
							saleStatusCode === STATUS_ONSALE) && (
							<>
								{(saleStatusCode !== STATUS_SOLDOUT ||
									saleStatusCode === STATUS_ONSALE) && (
									<Button
										color={SECONDARY}
										onClick={() => {
											dispatch(
												openOrderPaymentDialog({
													shippingInfoId,
													shippingInfoData
												})
											);
										}}>
										{t("shipping.card.orderPayment")}
									</Button>
								)}
								<Button
									color={PRIMARY}
									onClick={() => setImportMethodDialogOpen(true)}>
									{t("sidebar.shippingRequest")}
								</Button>
							</>
						)}
						{saleStatusCode === STATUS_SOLDOUT && (
							<Button
								color={PRIMARY}
								onClick={() => setExportRequestOpen(true)}>
								{t("shipping.exportRequest.header")}
							</Button>
						)}
					</Stack>
				</StackRow>
			</Stack>
			<ShippingDataContext.Provider value={shippingInfoData}>
				<IndividualProductDialog
					width={800}
					setProductDialogOpen={setProductDialogOpen}
					isProductDialogOpen={isProductDialogOpen}
				/>
				<ChooseImportMethodDialog
					isImportMethodDialogOpen={isImportMethodDialogOpen}
					setImportMethodDialogOpen={setImportMethodDialogOpen}
					setProductDialogOpen={setProductDialogOpen}
					setShippingInfoOpen={setShippingInfoOpen}
				/>
				<ShippingInformationDialog
					width={700}
					isShippingInfoOpen={isShippingInfoOpen}
					setShippingInfoOpen={setShippingInfoOpen}
				/>
				<ExportRequestDialog
					isExportRequestOpen={isExportRequestOpen}
					setExportRequestOpen={setExportRequestOpen}
					setRequestCompleteOpen={setRequestCompleteOpen}
					isRequestCompleteOpen={isRequestCompleteOpen}
				/>
				<RequestCompleteDialog
					width={800}
					setRequestCompleteOpen={setRequestCompleteOpen}
					isRequestCompleteOpen={isRequestCompleteOpen}
				/>
			</ShippingDataContext.Provider>
		</BasePlate>
	);
};

export default FundingCard;
