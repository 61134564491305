import theme from "theme/theme";
import { ImageWrapper } from "components/fileUpload/style";

export function Blank(props) {
	return (
		<ImageWrapper
			{...props}
			sx={{ backgroundColor: theme.palette.secondary.light }}
		/>
	);
}
