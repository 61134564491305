import { API_REQUESTED_BRAND_LIST } from "services/endpoints";
import { TAG_BRAND } from "services/tags";

export default (build) =>
	build.mutation({
		query: ({ brandId, status, reason }) => {
			return {
				url: API_REQUESTED_BRAND_LIST,
				method: "POST",
				data: {
					request_brand_id: brandId,
					request_brand_status: status,
					reason
				}
			};
		},
		invalidatesTags: [TAG_BRAND]
	});
