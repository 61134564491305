import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import RecommendationTable from "components/layout/RecommendationTable/RecommendationTable";
import { MuiPagination } from "components/table/Pagination";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useGetRecommendationsQuery } from "services/modules/recommendation";
import { isArray } from "utils/isArray";

const RecommendationManagement = () => {
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const page = parseInt(searchParams.get("page") || 1);
	const { data } = useGetRecommendationsQuery({
		currentPage: page
	});
	const responseData = data?.response?.data || [];
	const meta = data?.response?.meta || [];

	return (
		<>
			<Typography variant="text17" fontWeight="bold" component="h2" pb={20}>
				{t("recommendationManagement.recommendationList.recommendationList")}(
				{meta?.total || 0})
			</Typography>
			{isArray(responseData) && responseData?.length > 0 ? (
				<>
					<RecommendationTable data={responseData} />
					<MuiPagination page={page} meta={meta} />
				</>
			) : (
				<Stack
					sx={{ width: "100%", height: "50vh" }}
					alignItems="center"
					justifyContent="center">
					<Typography variant="text17" fontWeight="bold" component="h2">
						{t("alertMessage.category.noData")}({meta?.total || 0})
					</Typography>
				</Stack>
			)}
		</>
	);
};

export default RecommendationManagement;
