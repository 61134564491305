import { Divider, Typography, CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";
import { BasePlate } from "components/layout";
import { StackRow } from "components/shared";
import { Stats, StatsName } from "pages/partner/style";
import { useTranslation } from "react-i18next";
import { useAdminFundingSummaryQuery } from "services/modules/fundingAdmin";

const CustomStack = ({ statName, stat }) => {
	return (
		<Stack alignItems="center" gap={14}>
			<StatsName sx={{ whiteSpace: "nowrap" }}>{statName}</StatsName>
			<Stats>{stat}</Stats>
		</Stack>
	);
};

const Summary = () => {
	const { t } = useTranslation();
	const { data, isLoading } = useAdminFundingSummaryQuery();

	const summaryData = data?.response?.data || [];

	return (
		<Stack mb={10}>
			<BasePlate
				sx={{
					paddingY: 24,
					paddingX: 20,
					overflowX: "auto"
				}}>
				{isLoading ? (
					<CircularProgress sx={{ display: "block", margin: "auto" }} />
				) : (
					<StackRow gap={50} alignItems="center">
						<Typography variant="text17" fontWeight={700}>
							{t("funding.summary")}
						</Typography>
						<Divider orientation="vertical" variant="middle" flexItem />
						{summaryData.map(({ count, statusCode }) => {
							return (
								<CustomStack
									key={statusCode}
									statName={t(`funding.statuses.codes.${statusCode}`)}
									stat={count}
								/>
							);
						})}
					</StackRow>
				)}
			</BasePlate>
		</Stack>
	);
};

export default Summary;
