import { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ArticleImgUpload } from "components/fileUpload/ArticleImgUpload";
import { InputLabel } from "pages/funding/style";
import { StackRow } from "components/shared";
import { inputLabelWidth } from "pages/funding/components/styles";

import { RelatedInfoTextField } from "./custom/RelatedInfoTextField";

export const ArticleInputGroup = ({ articleName, index }) => {
	const { t } = useTranslation();
	const { control, watch, getValues, setValue } = useFormContext();

	watch(`relatedInfos[${index}]`);

	const { photo, id, source, url } = getValues(`relatedInfos[${index}]`) || {};

	useEffect(() => {
		if (!photo && !source && !url) {
			setValue(
				"deleted_related_info_ids",
				(getValues("deleted_related_info_ids") || []).concat(id)
			);
		}

		if (photo && source && url) {
			const deletedRelatedInfos = getValues("deleted_related_info_ids");

			setValue(
				"deleted_related_info_ids",
				deletedRelatedInfos.filter((deleted) => deleted !== id)
			);
		}
	}, [id, photo, source, url]);

	const names = [
		`relatedInfos[${index}].photo`,
		`relatedInfos[${index}].source`,
		`relatedInfos[${index}].url`
	];

	return (
		<StackRow columnGap={20} flexWrap="wrap">
			<StackRow>
				<InputLabel width={inputLabelWidth}>{t(articleName)}</InputLabel>
				<ArticleImgUpload
					width="100%"
					name={names[0]}
					images={photo ? [photo] : []}
					control={control}
					names={names}
				/>
			</StackRow>
			<Stack flex={1} flexBasis={250}>
				<StackRow flex={1} columnGap={14}>
					<Stack flexBasis={40}>
						<Typography fontSize={15} fontWeight={500}>
							{t("funding.createProduct2.relatedInfo.source")}
						</Typography>
					</Stack>
					<Stack flex={1}>
						<RelatedInfoTextField
							name={names[1]}
							control={control}
							names={names}
						/>
					</Stack>
				</StackRow>
				<StackRow flex={1} columnGap={14}>
					<Stack flexBasis={40}>
						<Typography fontSize={15} fontWeight={500}>
							{t("funding.createProduct2.relatedInfo.url")}
						</Typography>
					</Stack>
					<Stack flex={1}>
						<RelatedInfoTextField
							name={names[2]}
							control={control}
							names={names}
						/>
					</Stack>
				</StackRow>
			</Stack>
		</StackRow>
	);
};
