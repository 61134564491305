import { DialogContent, DialogTitle } from "@mui/material";

import { CustomDialogWrapper } from "./style";

const DialogContainer = ({
	children,
	isDialogOpen,
	dialogTitle,
	dialogMessage,
	setDialogOpen
}) => {
	return (
		<CustomDialogWrapper
			open={isDialogOpen}
			sx={{ textAlign: "left", paddingX: 30, paddingTop: 20 }}
			onClose={() => {
				setDialogOpen && setDialogOpen(false);
			}}>
			<DialogTitle fontWeight="bold">{dialogTitle}</DialogTitle>
			{dialogMessage && <DialogContent>{dialogMessage}</DialogContent>}
			{children && <DialogContent>{children}</DialogContent>}
		</CustomDialogWrapper>
	);
};

export default DialogContainer;
