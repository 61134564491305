import { styled, Typography } from "@mui/material";

export const StatsName = styled((props) => (
	<Typography fontSize={15} fontWeight={500} {...props} />
))();

export const Stats = styled((props) => (
	<Typography fontSize={23} fontWeight={500} {...props} />
))();

export const DataText = styled(Typography)({
	fontWeight: 500,
	fontSize: 17
});
