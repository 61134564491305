import { API_SHIPPING_INFO } from "services/endpoints";
import { TAG_SHIPPING } from "services/tags";

export default (build) => {
	return build.query({
		query: () => {
			return {
				url: API_SHIPPING_INFO,
				method: "GET"
			};
		},
		transformResponse(rawResponse) {
			const statuses = rawResponse.response?.data || [];
			const totalCount = statuses.reduce((init, { count }) => init + count, 0);

			return {
				...rawResponse,
				response: [
					{
						status: "all",
						display: "All",
						count: totalCount
					}
				].concat(statuses)
			};
		},
		providesTags: [TAG_SHIPPING]
	});
};
