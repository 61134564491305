import { lazy, Suspense, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { productApi } from "services/modules/funding";
import { fundingAdminApi } from "services/modules/fundingAdmin";
import { Loading, withResponseHandler } from "components/shared/loading";
import { ErrorBoundary } from "components/shared/errorBoundary";
import { useUser } from "redux/modules/useUser";
import { absoluteCenter } from "components/shared";
import { ROUTE_NOTFOUND } from "data/routeVariables";

const AVAILABLE_PARTNERADMIN_STEPS = 3;
const AVAILABLE_SUPERADMIN_STEPS = 5;

function WithGuard(maxSteps, Component) {
	return function WithGuardInner(props) {
		const params = useParams();
		const navigate = useNavigate();

		const { fundingId, stepNumber } = params;

		const step = parseInt(stepNumber);

		useEffect(() => {
			if (!fundingId || isNaN(step) || step > maxSteps || step < 1) {
				navigate(ROUTE_NOTFOUND);
			}
		}, [fundingId, step]);

		return <Component {...props} {...params} />;
	};
}

const PartnerAdminEdit = WithGuard(
	AVAILABLE_PARTNERADMIN_STEPS,
	({ stepNumber, ...props }) => {
		const Component = lazy(() => import(`./steps/step${stepNumber}`));

		return (
			<Suspense fallback={<Loading />}>
				<Component {...props} />
			</Suspense>
		);
	}
);

const SuperAdminEdit = WithGuard(
	AVAILABLE_SUPERADMIN_STEPS,
	({ stepNumber, ...props }) => {
		const Component = lazy(() =>
			import(`pages/fundingAdmin/edit/steps/step${stepNumber}`)
		);

		return (
			<Suspense fallback={<Loading />}>
				<Component {...props} />
			</Suspense>
		);
	}
);

export default function EditFunding() {
	const { fundingId } = useParams();
	const { isSuperAdmin } = useUser();

	const Component = withResponseHandler(
		isSuperAdmin ? SuperAdminEdit : PartnerAdminEdit
	);

	const resourceGetter = isSuperAdmin
		? fundingAdminApi.useAdminFundingDetailQuery
		: productApi.useGetProductQuery;

	return (
		<ErrorBoundary sx={absoluteCenter}>
			<Component
				response={resourceGetter(fundingId, {
					refetchOnMountOrArgChange: true
				})}
			/>
		</ErrorBoundary>
	);
}
