import { styled, Switch as MuiSwitch } from "@mui/material";

export const SlideSwitch = styled((props) => (
	<MuiSwitch
		focusVisibleClassName=".Mui-focusVisible"
		disableRipple
		color="primary"
		{...props}
	/>
))(({ theme }) => ({
	width: 42,
	height: 26,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 2,
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(16px)",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.white,
				opacity: 1,
				border: "1px solid lightgray"
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				backgroundColor: theme.palette.white,
				opacity: 0.5
			}
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff"
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color:
				theme.palette.mode === "light"
					? theme.palette.grey[100]
					: theme.palette.grey[600]
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3
		}
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 22,
		height: 22,
		backgroundColor: theme.palette.primary.main
	},
	"& .MuiSwitch-track": {
		borderRadius: 21,
		backgroundColor: theme.palette.white,
		border: "1px solid lightgray",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500
		})
	}
}));
