import { Box, styled, TextField, Typography } from "@mui/material";
import { primaryBorder, primaryShadow } from "data/styleVariables";
import theme from "theme/theme";

export const CustomTextField = styled(TextField)({
	"& .MuiOutlinedInput-notchedOutline": {
		borderColor: "gray"
	}
});

export const RightBorder = styled(Box)(({ theme }) => ({
	paddingRight: 16,
	borderRight: `1px solid ${theme.palette.text.black}`
}));

export const InputLabel = styled(Typography)({
	fontWeight: 500,
	fontSize: 15,
	color: theme.palette.text.primary,
	opacity: 0.6
});

export const ScrollableBox = styled((props) => (
	<Box px={28} py={25} mb={21} height={props.height || 228} {...props} />
))(() => ({
	border: primaryBorder,
	boxShadow: primaryShadow,
	borderRadius: 9,
	overflowY: "auto"
}));
