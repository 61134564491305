import reject from "assets/images/shared/reject.png";
import reason from "assets/images/shared/reason.png";
import success from "assets/images/shared/success.png";
import closeIcon from "assets/images/shared/close-icon.png";
import {
	CustomDialogWrapper,
	PrimaryDialogMessage,
	PrimaryDialogSubMessage
} from "./style";
import { Box, Stack } from "@mui/material";
import { Button, PRIMARY } from "components/layout/Button";
import { useDispatch } from "react-redux";

const PrimaryAlertDialog = ({
	isDialogOpen,
	setDialogOpen,
	closeDialogFromState,
	isSuccess,
	isReason,
	message,
	subMessage,
	firstBtn,
	secondBtn,
	firstAction,
	secondAction,
	isLoading
}) => {
	const dispatch = useDispatch();

	return (
		<CustomDialogWrapper open={isDialogOpen}>
			<Stack p={20}>
				<Box textAlign="right">
					<img
						src={closeIcon}
						alt="closeIcon"
						style={{ cursor: "pointer" }}
						onClick={() => {
							setDialogOpen && setDialogOpen(false);
							closeDialogFromState && dispatch(closeDialogFromState());
						}}
					/>
				</Box>
				<Box textAlign="center">
					<img
						src={isSuccess ? success : isReason ? reason : reject}
						alt="alert image"
					/>
				</Box>
				<Stack pb={12} gap={8}>
					<PrimaryDialogMessage color="black">{message}</PrimaryDialogMessage>
					<PrimaryDialogSubMessage>{subMessage}</PrimaryDialogSubMessage>
				</Stack>
				<Stack gap={10} pt={12}>
					<Button
						type="submit"
						isLoading={isLoading}
						color={PRIMARY}
						onClick={firstAction}>
						{firstBtn}
					</Button>
					{secondBtn && <Button onClick={secondAction}>{secondBtn}</Button>}
				</Stack>
			</Stack>
		</CustomDialogWrapper>
	);
};

export default PrimaryAlertDialog;
