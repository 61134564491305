import { api } from "services/api";
import {
	API_FUNDING_ADMIN_DETAIL,
	API_FUNDING_ADMIN_REJECT_FUNDING,
	API_FUNDING_ADMIN_SECONDARY_DOCUMENT_APPROVAL,
	API_FUNDING_MESSAGE
} from "services/endpoints";
import { TAG_FUNDING_ADMIN } from "services/tags";
import { camelToSnakeCasedObjectKeys } from "utils/string";

import { normalize } from "../funding/edit";
import adminFundingList from "./adminFundingList";
import adminFundingSummary from "./adminFundingSummary";

export const fundingAdminApi = api.injectEndpoints({
	endpoints: (build) => ({
		adminFundingList: adminFundingList(build),

		adminFundingSummary: adminFundingSummary(build),

		adminUpdateFunding: build.mutation({
			query({ url, ...data }) {
				data = normalize(camelToSnakeCasedObjectKeys(data));

				return {
					url: `${url}/${data.funding_id}`,
					data,
					method: "PUT"
				};
			},
			invalidatesTags: [TAG_FUNDING_ADMIN]
		}),

		adminUpdateFundingStatus: build.mutation({
			query({ url, ...data }) {
				data = normalize(camelToSnakeCasedObjectKeys(data));

				return {
					url: `${url}/${data.funding_id}`,
					data,
					method: "PUT"
				};
			},
			invalidatesTags: [TAG_FUNDING_ADMIN]
		}),

		adminFundingReject: build.mutation({
			query(data) {
				return {
					data,
					method: "POST",
					url: API_FUNDING_ADMIN_REJECT_FUNDING
				};
			},
			invalidatesTags: [TAG_FUNDING_ADMIN]
		}),

		adminFundingSendMessage: build.mutation({
			query(data) {
				return {
					data,
					method: "POST",
					url: API_FUNDING_MESSAGE
				};
			}
		}),

		adminFundingSecondaryDocumentApproval: build.mutation({
			query(data) {
				return {
					data,
					method: "POST",
					url: API_FUNDING_ADMIN_SECONDARY_DOCUMENT_APPROVAL
				};
			},
			invalidatesTags: [TAG_FUNDING_ADMIN]
		}),

		adminFundingDetail: build.query({
			query(fundingId) {
				return {
					method: "GET",
					url: `${API_FUNDING_ADMIN_DETAIL}/${fundingId}`
				};
			},

			providesTags: [TAG_FUNDING_ADMIN],

			transformResponse({ response: { data } }) {
				return {
					...data,
					brandId: data.product.brand.id,
					fundingName: data.name,
					name: data.product.name,
					relatedInfos: data.relatedInfos?.map((info) => ({
						photo: info.photos[0]?.path,
						source: info.source,
						url: info.url,
						id: info.id
					}))
				};
			}
		})
	}),
	overrideExisting: false
});

export const { useAdminFundingListQuery, useAdminFundingSummaryQuery } =
	fundingAdminApi;
