import { Stack } from "@mui/material";

import { absoluteCenter } from "components/shared";

const NotFound = () => {
	return (
		<Stack sx={absoluteCenter} justifyContent="center" alignItems="center">
			Not Found
		</Stack>
	);
};

export default NotFound;
