import { API_BRAND } from "services/endpoints";
import { TAG_BRAND } from "services/tags";

export default (build) =>
	build.mutation({
		query: ({ name, koreanName, description, photo, id }) => {
			return {
				url: `${API_BRAND}/${id}`,
				method: "PATCH",
				data: {
					name,
					korean_name: koreanName,
					description,
					photo: photo[0].data_url
				}
			};
		},
		invalidatesTags: [TAG_BRAND]
	});
