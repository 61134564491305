import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormProvider } from "react-hook-form";

import { ListTitle } from "components/shared";
import OwnerLogo from "assets/icons/sidebar/OwnerIcon";
import {
	Button,
	PRIMARY,
	SelectedOrAllDownloadButtons
} from "components/layout/Button";
import { StackRow } from "components/shared";
import { MuiPagination } from "components/table/Pagination";
import NoData from "components/table/NoData";
import { useNumberSearchParams } from "utils/useNumbered";
import useFilter from "components/shared/list/useFilter";
import KeywordOnlyFilter from "components/shared/list/keywordOnlyFilter";
import MainTitle from "components/shared/list/MainTitle";

import ActivityStatusBox from "../ActivityStatusBox";
import SubscriptionStatusBox from "../SubscriptionStatusBox";
import useBody from "./useBody";

const OwnerList = () => {
	const { t } = useTranslation();

	const page = useNumberSearchParams();

	const [filters, methods] = useFilter();

	const { watch } = methods;

	const [{ data, isFetching }, Body] = useBody(filters, page);

	const columnFilter = watch("columnFilter");
	const statusFilter = watch("statusFilter");

	const rows = data?.response?.data || [];

	const meta = data?.response?.meta || { total: 0 };

	const isSearchResultEmpty =
		!rows.length & !isFetching & !!Object.values(filters).length &&
		(statusFilter === "all" || columnFilter === "all");

	return (
		<FormProvider {...methods}>
			<MainTitle pageName={t("Owner Management")} logo={<OwnerLogo />} />

			<ActivityStatusBox />

			<SubscriptionStatusBox />

			<Stack pb={20}>
				<ListTitle>
					{t("Member List")}({meta?.total || 0})
				</ListTitle>
			</Stack>

			<KeywordOnlyFilter
				searchPlaceholder={t(
					"Enter user code, nickname, name, email, and contact information"
				)}
			/>

			<>
				{isSearchResultEmpty ? (
					<NoData message={t("alertMessage.noListData")} />
				) : (
					<>
						<Body />

						<StackRow
							pt={20}
							alignItems="center"
							justifyContent="space-between">
							<SelectedOrAllDownloadButtons />

							<Button color={PRIMARY}>{t("funding.create")}</Button>
						</StackRow>

						<MuiPagination page={page} meta={meta} />
					</>
				)}
			</>
		</FormProvider>
	);
};

export default OwnerList;
