import { API_BATCH_IMPORT } from "services/endpoints";
import { TAG_SHIPPING } from "services/tags";

export default (build) =>
	build.mutation({
		query: ({ product }) => {
			return {
				url: API_BATCH_IMPORT,
				method: "POST",
				data: {
					shippings: product
				}
			};
		},
		invalidatesTags: [TAG_SHIPPING]
	});
