import { ROUTE_PRODUCT_CREATE } from "data/routeVariables";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import noDataImg from "assets/images/shared/reject.png";
import noSearchDataImg from "assets/images/shared/reason.png";
import { Button, PRIMARY } from "components/layout/Button";
import { BasePlate } from "components/layout";

const ShippingListEmpty = ({ searchEmpty = false }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<>
			<BasePlate sx={{ py: 150, textAlign: "center" }}>
				<img
					src={searchEmpty ? noSearchDataImg : noDataImg}
					alt="No funding data"
				/>

				{searchEmpty ? (
					<>
						<Typography>{t("funding.emptyPage.noSearchResult1")}</Typography>
						<Typography>{t("funding.emptyPage.noSearchResult2")}</Typography>
					</>
				) : (
					<>
						<Typography>{t("List is empty")}</Typography>
						<Typography>{t("Shipping Requests are not made yet")}</Typography>
					</>
				)}

				<Box sx={{ p: 6 }} />
			</BasePlate>

			{searchEmpty ? (
				<Button
					color={PRIMARY}
					onClick={() => {
						navigate(ROUTE_PRODUCT_CREATE);
					}}
					sx={{
						display: "block",
						marginLeft: "auto"
					}}>
					{t("funding.create")}
				</Button>
			) : null}
		</>
	);
};

export default ShippingListEmpty;
