import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	partnerId: "",
	partnerListSearch: ""
};

const partnerSlice = createSlice({
	name: "partner",
	initialState,
	reducers: {
		savePartnerData: (state, { payload }) => {
			state.partnerId = payload.id;
		},
		setPartnerListSearch: (state, { payload }) => {
			state.partnerListSearch = payload;
		}
	}
});

export const selectPartner = (state) => state.partner || {};

export const { savePartnerData, setPartnerListSearch } = partnerSlice.actions;

export const partnerReducer = partnerSlice.reducer;
