import { Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import { BasePlate } from "components/layout";
import { StackRow } from "components/shared";
import {
	BackButton,
	Button,
	PRIMARY,
	SECONDARY
} from "components/layout/Button";
import { FormInputText } from "components/formComponents";
import {
	useGetPartnerDetailsQuery,
	usePartnerApprovalMutation,
	usePartnerRejectMutation
} from "services/modules/partner";
import DualTitle from "components/shared/DualTitle";
import theme from "theme/theme";
import { InputLabel } from "components/auth/style";
import { ROUTE_PARTNER_LIST } from "data/routeVariables";
import { PartnerDetailsSchema } from "utils/validation/partnerDetailsSchema";
import { Blank } from "components/shared/blank";
import PrimaryAlertDialog from "components/dialogs/PrimaryAlertDialog";

import PartnerFileDownloadButtons from "./PartnerFileDownloadButtons";
import { DataText } from "./style";
import MainInfo from "./MainInfo";
import FormInputDatePicker from "components/formComponents/FormInputDatePicker";
import {
	DATE_FORMAT_ONE,
	DATE_FORMAT_SEVEN,
	DATE_FORMAT_TWO,
	format,
	pipeParseFormat
} from "utils/formatDate";
import BackDropLoader from "components/shared/BackDropLoader";

const PartnerDetails = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { partnerId: partnerId } = useParams();
	const date = new Date();

	const STATUS_COMPLETE = "Completion of subscription";
	const STATUS_REJECT = "Reject";

	const [isSuccessDialogOpen, setSuccessDialogOpen] = useState(false);
	const [isFailedDialogOpen, setFailedDialogOpen] = useState(false);

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	const { data: partnerDetails, isFetching } = useGetPartnerDetailsQuery({
		partnerId
	});

	const [
		partnerApproval,
		{
			isSuccess: approveSuccess,
			isError: approveError,
			error: approveErrorData
		}
	] = usePartnerApprovalMutation();

	const [
		partnerReject,
		{ isSuccess: rejectSuccess, isError: rejectError, error: rejectErrorData }
	] = usePartnerRejectMutation();

	const partnerDetailsPayload = partnerDetails
		? partnerDetails?.response?.data
		: {};

	const {
		id: teamId,
		status,
		aboutUs,
		bankAccountNumber,
		name,
		guaranteeInsuranceNumber,
		guaranteeInsuranceAmount,
		guaranteeInsuranceEnd,
		guaranteeInsuranceStart
	} = partnerDetailsPayload?.team || {};

	const defaultFieldData = {
		insuranceAmount: guaranteeInsuranceAmount || "",
		insuranceNumber: guaranteeInsuranceNumber || "",
		insuranceExpireDate: guaranteeInsuranceEnd || "",
		insuranceStartDate: guaranteeInsuranceStart || ""
	};

	const methods = useForm({
		defaultValues: defaultFieldData,
		mode: "onBlur",
		resolver: yupResolver(PartnerDetailsSchema(t))
	});

	const { control, handleSubmit, getValues, reset, setValue } = methods;

	useEffect(() => {
		reset(defaultFieldData);
	}, [partnerDetails]);

	const approvePartner = (values) => {
		partnerApproval({ teamId: teamId, status: "approve", ...values });
	};

	const savePartnerData = () => {
		const values = getValues();

		partnerApproval({
			teamId: teamId,
			status:
				status === STATUS_COMPLETE
					? "approve"
					: status === STATUS_REJECT
					? "reject"
					: "pending",
			insuranceAmount: values.insuranceAmount || guaranteeInsuranceAmount,
			insuranceNumber: values.insuranceNumber || guaranteeInsuranceNumber,
			insuranceExpireDate: values.insuranceExpireDate || guaranteeInsuranceEnd,
			insuranceStartDate: values.insuranceStartDate || guaranteeInsuranceStart
		});
	};

	const stopActivity = () => {
		partnerReject({ teamId: teamId });
	};

	useEffect(() => {
		if (approveSuccess || rejectSuccess) {
			setFailedDialogOpen(false);
			setSuccessDialogOpen(true);
		}

		if (approveError || rejectError) {
			setSuccessDialogOpen(false);
			setFailedDialogOpen(true);
		}
	}, [approveSuccess, approveError, rejectSuccess, rejectError]);

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(approvePartner)}>
				<BackDropLoader open={isFetching} />
				<BackButton
					onClick={() => navigate(ROUTE_PARTNER_LIST)}
					btnText={t("To List")}
				/>

				<Typography component="h2" variant="h2" pb={24} lineHeight={1}>
					{t("partner.profile.header")}
				</Typography>

				<BasePlate sx={{ paddingX: 24, paddingY: 16 }}>
					<MainInfo partnerDetails={partnerDetailsPayload} showAddress />

					<StackRow
						pt={22}
						gap={24}
						divider={
							<Divider orientation="vertical" variant="middle" flexItem />
						}>
						<Typography variant="text15" sx={{ opacity: 0.6 }}>
							{t("Subscription approval completion date")} :
							{` ${
								pipeParseFormat(
									partnerDetailsPayload?.team?.enrollmentApprovalDate,
									DATE_FORMAT_ONE,
									DATE_FORMAT_TWO
								) || "-"
							}`}
						</Typography>
						<Typography variant="text15" sx={{ opacity: 0.6 }}>
							{t("Last login")} : {` ${partnerDetailsPayload.lastLogin || "-"}`}
						</Typography>
						<Typography variant="text15" sx={{ opacity: 0.6 }}>
							{t("Since")} :
							{` ${
								pipeParseFormat(
									partnerDetailsPayload?.team?.createdAt,
									DATE_FORMAT_SEVEN,
									DATE_FORMAT_TWO
								) || "-"
							}`}
						</Typography>
					</StackRow>

					<Divider sx={{ mt: 20 }} />

					<StackRow py={10} gap={20}>
						<StackRow gap={14} alignItems="center">
							<InputLabel>{`${t("partner.bank")}`}</InputLabel>
							<DataText>우리은행</DataText>
						</StackRow>

						<StackRow gap={14} alignItems="center">
							<InputLabel>{`${t("partner.accountNumber")}`}</InputLabel>
							<DataText>{bankAccountNumber}</DataText>
						</StackRow>

						<StackRow gap={14} alignItems="center">
							<InputLabel>{`${t("partner.accountHolder")}`}</InputLabel>
							<DataText>{name}</DataText>
						</StackRow>
					</StackRow>

					<Stack
						direction={{ lg: "row", md: "column" }}
						justifyContent="space-between">
						<StackRow alignItems="flex-start" pb={10} mr={10}>
							<InputLabel pr={14} sx={{ whiteSpace: "nowrap", pt: 10 }}>
								{`${t("partner.insuranceAmount")} `}
							</InputLabel>

							<FormInputText
								type="number"
								name="insuranceAmount"
								placeholder="0"
								control={control}
								value={guaranteeInsuranceAmount}
							/>
						</StackRow>

						<StackRow alignItems="flex-start" pb={10} mr={10}>
							<InputLabel pr={14} sx={{ whiteSpace: "nowrap", pt: 10 }}>
								{`${t("partner.insuranceNumber")}`}
							</InputLabel>

							<FormInputText
								type="number"
								name="insuranceNumber"
								control={control}
								placeholder="000-0000-00000"
							/>
						</StackRow>

						<StackRow alignItems="flex-start" pb={10} gap={14}>
							<InputLabel noWrap sx={{ whiteSpace: "nowrap", pt: 10 }}>
								{`${t("partner.insuranceExpireDate")}`}
							</InputLabel>

							<FormInputDatePicker
								name="insuranceStartDate"
								control={control}
								onChange={(date) => {
									setValue("insuranceStartDate", format(date, DATE_FORMAT_ONE));
								}}
							/>

							<Typography pt={10}>~</Typography>

							<FormInputDatePicker
								name="insuranceExpireDate"
								control={control}
								onChange={(date) => {
									setValue(
										"insuranceExpireDate",
										format(date, DATE_FORMAT_ONE)
									);
								}}
							/>
						</StackRow>
					</Stack>
				</BasePlate>

				<BasePlate sx={{ paddingX: 14, paddingY: 19 }}>
					<Stack>
						<DualTitle
							mainTitle={t("partner.profile.companyIntroduction")}
							subTitle="설립일"
							mainVariant={{ fontWeight: 500, fontSize: 18 }}
							subVariant={{
								fontWeight: 500,
								fontSize: 16,
								color: theme.palette.text.secondary
							}}
						/>
						<Typography pt={20} pb={14} variant="text12" fontWeight={400}>
							{aboutUs}
						</Typography>
						{/* Dummy company info for UI purpose */}
					</Stack>

					<Divider />

					<Stack pt={18} pb={8} sx={{ opacity: 0.6 }}>
						<Typography variant="text12" fontWeight={400}>
							{t("partner.profile.recently")}
						</Typography>
						<Typography variant="text12" fontWeight={400}>
							{date.getFullYear() - 1} :
						</Typography>
						<Typography variant="text12" fontWeight={400}>
							{date.getFullYear() - 2} :
						</Typography>
						<Typography variant="text12" fontWeight={400}>
							{date.getFullYear() - 3} :
						</Typography>
					</Stack>

					<Divider />

					<StackRow pt={10} gap={21}>
						<Blank width={63} height={63} />
						<Blank width={63} height={63} />
						<Blank width={63} height={63} />
					</StackRow>
				</BasePlate>

				<StackRow justifyContent="space-between">
					<PartnerFileDownloadButtons partnerId={partnerDetailsPayload.id} />
					<StackRow gap={11}>
						{status !== STATUS_REJECT && (
							<Button onClick={stopActivity} color={SECONDARY}>
								{t("partner.profile.stopActivity")}
							</Button>
						)}
						<Button color={PRIMARY} onClick={savePartnerData}>
							{t("Save")}
						</Button>
						{status !== STATUS_COMPLETE && (
							<Button type="submit" color={PRIMARY}>
								{t("partner.profile.approveSubscription")}
							</Button>
						)}
					</StackRow>
				</StackRow>
				<PrimaryAlertDialog
					isSuccess
					isDialogOpen={isSuccessDialogOpen}
					setDialogOpen={() => setSuccessDialogOpen(false)}
					message="Successful"
					subMessage={
						approveSuccess
							? "Successfully updated partner status"
							: "Successfully stopped partner activity"
					}
					firstBtn={t("Confirm")}
					firstAction={() => {
						setSuccessDialogOpen(false);
						navigate(ROUTE_PARTNER_LIST);
					}}
				/>
				{/* Alert Messages are dummy */}

				<PrimaryAlertDialog
					isDialogOpen={isFailedDialogOpen}
					setDialogOpen={() => setFailedDialogOpen(false)}
					message="Failed"
					subMessage={
						approveError
							? approveErrorData?.data.message
							: rejectErrorData?.data.message
					}
					firstBtn={t("Confirm")}
					firstAction={() => {
						setFailedDialogOpen(false);
					}}
				/>
				{/* Alert Messages are dummy */}
			</form>
		</FormProvider>
	);
};

export default PartnerDetails;
