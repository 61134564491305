import { API_VERIFY_OTP } from "services/endpoints";

export default (build) =>
	build.mutation({
		query: ({ newEmail, otp }) => {
			return {
				url: API_VERIFY_OTP,
				method: "POST",
				data: {
					email: newEmail,
					otp_code: otp
				}
			};
		},
		invalidatesTags: []
	});
