export const TradeIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M19.6154 3.00006H4.38462C3.61991 3.00006 3 3.61997 3 4.38468V12.6924C3 13.4571 3.61991 14.077 4.38462 14.077H19.6154C20.3801 14.077 21 13.4571 21 12.6924V4.38468C21 3.61997 20.3801 3.00006 19.6154 3.00006Z"
				stroke="#000001"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12 10.6154C13.1471 10.6154 14.0769 9.68552 14.0769 8.53847C14.0769 7.39142 13.1471 6.46155 12 6.46155C10.853 6.46155 9.9231 7.39142 9.9231 8.53847C9.9231 9.68552 10.853 10.6154 12 10.6154Z"
				stroke="#000001"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.38464 17.5386H19.6154"
				stroke="#000001"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.76929 21.0001H18.2308"
				stroke="#000001"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
