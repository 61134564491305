import {
	API_RAISE_LIMIT_REQUEST,
	API_ADMIN_RAISE_LIMIT_REQUEST
} from "services/endpoints";
import { TAG_FUNDING_ADMIN } from "services/tags";

export default (build) =>
	build.mutation({
		query: ({ raisedAmount, fundingName, partnerId }) => {
			const data = {
				raised_amount: raisedAmount,
				funding_name: fundingName
			};

			const request = {
				method: "POST",
				data
			};

			if (partnerId) {
				data.partner_id = partnerId;
				request.url = API_ADMIN_RAISE_LIMIT_REQUEST;
			} else {
				request.url = API_RAISE_LIMIT_REQUEST;
			}

			return request;
		},
		invalidatesTags: [TAG_FUNDING_ADMIN]
	});
