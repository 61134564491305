import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

import theme from "theme/theme";
import { Button, SECONDARY } from "components/layout/Button";
import { SearchTextField } from "components/formComponents/style";

export function SearchInputWithButton({
	searchValue,
	setSearchValue,
	handleSearchSubmit,
	handleClearSearch,
	width = null,
	...props
}) {
	const { t } = useTranslation();

	return (
		<>
			<SearchTextField
				placeholder={t(
					"Search for funding code, funding name, brand name, partner company code, and partner company name"
				)}
				fullWidth
				size="small"
				variant="outlined"
				value={searchValue}
				autoFocus
				onChange={(e) => setSearchValue(e.target.value)}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						e.preventDefault();

						handleSearchSubmit();
					}
				}}
				sx={{ width }}
				InputProps={{
					style: { padding: 10, backgroundColor: theme.palette.white },
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					),
					endAdornment: searchValue ? (
						<CloseIcon sx={{ cursor: "pointer" }} onClick={handleClearSearch} />
					) : null
				}}
				{...props}
			/>

			<Button color={SECONDARY} onClick={handleSearchSubmit}>
				{t("Search")}
			</Button>
		</>
	);
}
