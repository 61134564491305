import { Divider, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFieldArray, useForm } from "react-hook-form";
import { useContext, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import { FlatPlate } from "components/layout";
import { StackRow } from "components/shared";
import theme from "theme/theme";
import { Button, PRIMARY, SECONDARY } from "components/layout/Button";
import { FormInputText } from "components/formComponents";
import RequiredLabelText from "components/shared/RequiredLabelText";
import { PostCode } from "components/shared/PostCode";
import ShippingDataContext from "pages/inventory/shippingRequest/shippingDataContext";
import { useStoreProductBatchMutation } from "services/modules/shipping/request";
import { usePromiseHandler } from "utils/usePromiseHandler";
import { individualProductValidationSchema } from "utils/validationSchema";

import PrimaryDialog from "../PrimaryDialog";

const IndividualProductDialog = ({
	isProductDialogOpen,
	setProductDialogOpen
}) => {
	const { t } = useTranslation();
	const promiseHandler = usePromiseHandler();
	const [isPostCodeModalOpen, setPostCodeModalOpen] = useState(false);

	const shippingData = useContext(ShippingDataContext);

	const defaultValues = {
		shipping_code: shippingData?.funding.code,
		funding_id: shippingData?.funding.id,
		team_id: shippingData?.team.id,
		product_id: shippingData?.product.id,
		delivery_status: "request",
		recipient: "",
		address: "",
		address_detail: "",
		contact_number_1: "",
		contact_number_2: "",
		shipping_note: "",
		invoice_number: shippingData?.invoiceNumber,
		courier_id: shippingData?.courier.id
	};

	const { control, setValue, handleSubmit, getValues, reset, watch } = useForm({
		defaultValues: {
			product: [defaultValues]
		},
		mode: "onBlur",
		resolver: yupResolver(individualProductValidationSchema(t))
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: "product"
	});

	const copyAndAppendData = (index) => {
		const { product } = getValues();
		append(product[index]);
	};

	const [storeProductBatch, { isLoading }] = useStoreProductBatchMutation();

	const onSubmit = (values) => {
		promiseHandler(
			storeProductBatch(values).unwrap(),
			"Upload Success",
			"Upload Failed"
		).then(() => {
			setProductDialogOpen(false);
		});
	};

	return (
		<PrimaryDialog
			reset={reset}
			header={t("shipping.individualProduct.header")}
			setDialogOpen={setProductDialogOpen}
			isDialogOpen={isProductDialogOpen}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<FlatPlate sx={{ p: 24 }}>
					<Typography pb={16} fontWeight={700} fontSize={19}>
						{`${t("shipping.individualProduct.productList")} ${fields.length}`}
					</Typography>

					<Divider />

					{fields.map((item, index) => {
						return (
							<Stack gap={12} key={item.id} whiteSpace="nowrap">
								<Grid container alignItems="center" mb={12} mt={24}>
									<Grid item xs={1.8}>
										<Typography color={theme.palette.gray.tertiary}>
											{t("shipping.tableHeaders.fundingCode")}
										</Typography>
									</Grid>
									<Grid item xs>
										<StackRow alignItems="center" gap={10}>
											<Typography color={theme.palette.primary.main}>
												{index + 1}
											</Typography>
											<Typography>/</Typography>
											<Typography mr={10}>
												{shippingData?.funding.code}
											</Typography>
											<Button onClick={() => copyAndAppendData(index)}>
												{t("Copy")}
											</Button>
											{fields.length > 1 && (
												<Button color={SECONDARY} onClick={() => remove(index)}>
													{t("Delete")}
												</Button>
											)}
										</StackRow>
									</Grid>
								</Grid>

								{/* Recipient */}
								<Grid container alignItems="flex-start">
									<Grid pt={10} item xs={1.8}>
										<RequiredLabelText color={theme.palette.gray.tertiary}>
											{t("Recipient")}
										</RequiredLabelText>
									</Grid>
									<Grid container item xs alignItems="flex-start" gap={20}>
										<Grid item xs>
											<FormInputText
												control={control}
												name={`product[${index}].recipient`}
												placeholder={t("shipping.individualProduct.enterName")}
											/>
										</Grid>
										<Typography visibility="hidden">
											{t("Contact 2")}
										</Typography>
										<Grid item xs visibility="hidden">
											<FormInputText type="number" name="invisibleField" />
										</Grid>
									</Grid>
								</Grid>

								{/* Contact 1 & 2 */}
								<Grid container alignItems="flex-start">
									<Grid pt={10} item xs={1.8}>
										<RequiredLabelText color={theme.palette.gray.tertiary}>
											{t("Contact 1")}
										</RequiredLabelText>
									</Grid>
									<Grid container item xs alignItems="flex-start" gap={20}>
										<Grid item xs>
											<FormInputText
												type="number"
												control={control}
												name={`product[${index}].contact_number_1`}
												placeholder={t(
													"shipping.individualProduct.enterNumber"
												)}
											/>
										</Grid>
										<Typography pt={10} color={theme.palette.gray.tertiary}>
											{t("Contact 2")}
										</Typography>
										<Grid item xs>
											<FormInputText
												type="number"
												control={control}
												name={`product[${index}].contact_number_2`}
												placeholder={t(
													"shipping.individualProduct.enterNumber"
												)}
											/>
										</Grid>
									</Grid>
								</Grid>

								{/* Address */}
								<Grid container alignItems="flex-start">
									<Grid pt={10} item xs={1.8}>
										<RequiredLabelText color={theme.palette.gray.tertiary}>
											{t("shipping.exportRequest.address")}
										</RequiredLabelText>
									</Grid>
									<Grid container gap={20} item xs alignItems="flex-start">
										<Grid item xs>
											<FormInputText
												width={300}
												control={control}
												name={`product[${index}].address`}
											/>
										</Grid>
										<Button
											color={SECONDARY}
											onClick={() => setPostCodeModalOpen(true)}>
											{t("shipping.exportRequest.findPostalCode")}
										</Button>
										<PostCode
											isPostCodeModalOpen={isPostCodeModalOpen}
											setPostCodeModalOpen={setPostCodeModalOpen}
											onSelected={(result) => {
												setValue(`product[${index}].address`, result.zonecode);
												setValue(
													`product[${index}].address_detail`,
													result.address
												);
											}}></PostCode>
									</Grid>
								</Grid>

								{/* Address Detail*/}
								<Grid container>
									<Grid item xs={1.8}>
										<Typography visibility="hidden">
											{t("shipping.individualProduct.recipient")}
										</Typography>
									</Grid>
									<Grid item xs>
										<FormInputText
											control={control}
											name={`product[${index}].address_detail`}
											placeholder={t("Enter detailed address")}
										/>
									</Grid>
								</Grid>

								{/* Shipping Notes */}
								<Grid container alignItems="flex-start">
									<Grid pt={10} item xs={1.8}>
										<Typography color={theme.palette.gray.tertiary}>
											{t("Notes")}
										</Typography>
									</Grid>
									<Grid item xs>
										<FormInputText
											currentWordCount={
												watch(`product[${index}].shipping_note`)?.length
											}
											maxLength={40}
											control={control}
											withTextIndicator
											name={`product[${index}].shipping_note`}
										/>
									</Grid>
								</Grid>

								<Divider />
							</Stack>
						);
					})}

					<Button
						sx={{ marginTop: 20 }}
						color={SECONDARY}
						onClick={() => {
							append();
						}}>
						{t("shipping.individualProduct.add")}
					</Button>
				</FlatPlate>
				<StackRow justifyContent="flex-end">
					<Button isLoading={isLoading} type="submit" color={PRIMARY}>
						{t("Complete request")}
					</Button>
				</StackRow>
			</form>
		</PrimaryDialog>
	);
};

export default IndividualProductDialog;
