import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import NoData from "components/table/NoData";
import { useNumberSearchParams } from "utils/useNumbered";
import useFilter from "components/shared/list/useFilter";
import { SelectedOrAllDownloadButtons } from "components/layout/Button";

import useBody from "./useBody";

const OwnerInvestmentList = () => {
	const { t } = useTranslation();

	const page = useNumberSearchParams();

	const [filters, methods] = useFilter();

	const { watch } = methods;

	const [{ data, isFetching }, Body] = useBody(filters, page);

	const columnFilter = watch("columnFilter");
	const statusFilter = watch("statusFilter");

	const rows = data?.response?.data || [];

	const isSearchResultEmpty =
		!rows.length & !isFetching & !!Object.values(filters).length &&
		(statusFilter === "all" || columnFilter === "all");

	return (
		<>
			{isSearchResultEmpty ? (
				<NoData message={t("alertMessage.noListData")} />
			) : (
				<>
					<Body />

					<Box pt={20}>
						<SelectedOrAllDownloadButtons />
					</Box>
				</>
			)}
		</>
	);
};

export default OwnerInvestmentList;
