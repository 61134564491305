import { Stack } from "@mui/material";
import { StackRow } from "components/shared";
import { Stats, StatsName } from "pages/partner/style";

export const CustomStack = (props) => {
	const { statName, stat, indicator, ...rest } = props;
	return (
		<Stack alignItems="center" gap={14} {...rest}>
			<StatsName sx={{ whiteSpace: "nowrap" }}>{statName}</StatsName>
			<StackRow gap={3}>
				<Stats>{stat}</Stats>
				{indicator === "-" || indicator === "+" ? (
					<Stats>{indicator}</Stats>
				) : (
					<img src={indicator} />
				)}
			</StackRow>
		</Stack>
	);
};
