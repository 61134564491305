import { useTranslation } from "react-i18next";

export default function columns() {
	const { t } = useTranslation();

	return [
		{
			field: "fundingCode",
			headerName: t("common.fundingCode"),
			minWidth: 200
		},
		{
			field: "fundingName",
			headerName: t("common.fundingName"),
			minWidth: 200
		},
		{
			field: "partnerCode",
			headerName: t("common.partnerCode"),
			minWidth: 150
		},
		{
			field: "partnerMission",
			headerName: t("common.partnerMission"),
			minWidth: 300
		},
		{
			field: "productAmount",
			headerName: t("common.productAmount")
		},
		{
			field: "investmentQuantity",
			headerName: t("common.investmentQuantity"),
			minWidth: 200
		},
		{
			field: "totalInvestmentAmount",
			headerName: t("common.totalInvestmentAmount"),
			minWidth: 200
		},
		{
			field: "investmentDate",
			headerName: t("common.investmentDate"),
			minWidth: 200
		}
	].map((column) => ({
		headerAlign: "center",
		align: "center",
		minWidth: 120,
		...column
	}));
}
