import formatter from "date-fns/esm/format";

import parser from "date-fns/esm/parse";

export const TIME_FORMAT_ONE = "KK:mm:ss";

export const TIME_FORMAT_TWO = "KK:mm";

export const TIME_FORMAT_THREE = "kk:mm:ss";

export const DATE_FORMAT_ONE = "yyyy-LL-dd";

export const DATE_FORMAT_TWO = "yyyy.LL.dd";

export const DATE_FORMAT_THREE = "yyyy.LL.dd KK:mm";

export const DATE_FORMAT_FOUR = `${DATE_FORMAT_ONE} ${TIME_FORMAT_ONE}`;

export const DATE_FORMAT_FIVE = "yyyy-MM-dd";

export const DATE_FORMAT_SIX = `${DATE_FORMAT_TWO} ${TIME_FORMAT_ONE}`;

export const DATE_FORMAT_SEVEN = `${DATE_FORMAT_ONE} ${TIME_FORMAT_THREE}`;

export const DATE_FORMAT_EIGHT = `${DATE_FORMAT_TWO} ${TIME_FORMAT_TWO}`;

export const DATE_FORMAT_NINE = "yyyy/MM/dd";

function fmt(...params) {
	try {
		return formatter(...params);
	} catch {
		console.log("Unable to format:", ...params);
		return formatter(new Date(), DATE_FORMAT_ONE);
	}
}

export const pipeParseFormat = (input, inputFormat, outputFormat) => {
	return format(parse(input, inputFormat), outputFormat);
};

export const formatYearMonthDay = (date) => {
	try {
		return fmt(new Date(date), "yyyy.MM.dd");
	} catch (error) {
		return "Invalid date";
	}
};

export const formatDateTime = (date) => {
	return fmt(new Date(date), "yyyy:MM:dd hh:mm");
};

// Date object to string
export function format(date, format = DATE_FORMAT_ONE) {
	return fmt(date, format);
}

// string to Date object
export function parse(date, format = DATE_FORMAT_ONE, refDate = new Date()) {
	try {
		return parser(date, format, refDate);
	} catch (error) {
		return parser(new Date(), format, refDate);
	}
}
