import { useDispatch } from "react-redux";
import { showAlert } from "redux/modules";

export function useTodo() {
	const dispatch = useDispatch();

	return () => {
		dispatch(
			showAlert({
				severity: "info",
				message: "Not yet implemented."
			})
		);
	};
}
