import { styled, Typography } from "@mui/material";

export const SubTitle = (props) => {
	const { children, ...rest } = props;
	return (
		<SubTypo component="h2" variant="h2" {...rest}>
			{children}
		</SubTypo>
	);
};

export const SubTypo = styled(Typography)(({ theme }) => ({
	fontSize: 17,
	fontWeight: 400,
	color: theme.palette.gray.tertiary
}));
