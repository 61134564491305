import { useTranslation } from "react-i18next";
import { DATE_FORMAT_TWO, format } from "utils/formatDate";
import numberToThousandComma from "utils/numberToThousandComma";

export default function columns() {
	const { t } = useTranslation();

	return [
		{
			field: "status",
			headerName: t("partner.division"),
			minWidth: 200,
			valueGetter: ({ row: { team } }) => (team.status ? t(team.status) : "-")
		},
		{
			field: "code",
			headerName: t("partner.code"),
			valueGetter: ({ row: { team } }) => team.code || "-"
		},
		{
			field: "name",
			headerName: t("partner.companyName"),
			minWidth: 200
		},
		{
			field: "businessNumber",
			headerName: t("partner.businessNumber"),
			minWidth: 150,
			valueGetter: ({ row: { team } }) => team.businessNumber || "-"
		},
		{
			field: "address",
			headerName: t("partner.address"),
			minWidth: 300,
			valueGetter: ({ row: { team } }) => team.companyAddress || "-"
		},
		{
			field: "postalCode",
			headerName: t("partner.zipCode"),
			valueGetter: ({ row: { team } }) => team.postalCode || "-"
		},
		{
			field: "representative",
			headerName: t("Representative Name"),
			minWidth: 200,
			valueGetter: ({ row: { team } }) => team.representativeName || "-"
		},
		{
			field: "representativeContact",
			headerName: t("Representative Contact"),
			minWidth: 200,
			valueGetter: ({ row: { team } }) => team.representativeContact || "-"
		},
		{
			field: "representativeEmail",
			headerName: t("Representative Email"),
			minWidth: 200,
			valueGetter: ({ row: { team } }) => team.representativeEmail || "-"
		},
		{
			field: "contactPerson",
			headerName: t("partner.contactPerson"),
			minWidth: 200,
			valueGetter: ({ row: { team } }) => team.contactName || "-"
		},
		{
			field: "contactNumber",
			headerName: t("Contact Number"),
			minWidth: 150,
			valueGetter: ({ row: { team } }) => team.contactNumber || "-"
		},
		{
			field: "contactEmail",
			headerName: t("partner.contactEmail"),
			minWidth: 200,
			valueGetter: ({ row: { team } }) => team.contactEmail || "-"
		},
		{
			field: "guaranteeInsuranceAmount",
			headerName: t("partner.insuranceAmount"),
			minWidth: 200,
			valueGetter: ({ row: { team } }) => {
				const amount = team.guaranteeInsuranceAmount;
				return amount ? `${numberToThousandComma(amount)} ${t("KRW")}` : "-";
			}
		},
		{
			field: "guaranteeInsuranceExpirationDate",
			headerName: t("partner.insuranceExpireDate"),
			minWidth: 200,
			valueGetter: (params) =>
				params.row.team?.guaranteeInsuranceEnd
					? format(
							new Date(params.row.team?.guaranteeInsuranceEnd),
							DATE_FORMAT_TWO
					  )
					: "-"
		},
		{
			field: "cumulativeFunding",
			headerName: t("partner.numOfInvestments"),
			minWidth: 150,
			valueGetter: ({ row: { team } }) => team.cumulativeFunding || "-"
		},
		{
			field: "establishedAt",
			headerName: t("partner.since"),
			minWidth: 200,
			valueGetter: (params) =>
				params.row.team?.establishedAt
					? format(new Date(params.row.team?.establishedAt), DATE_FORMAT_TWO)
					: "-"
		},
		{
			field: "enrollmentApprovalDate",
			headerName: t("partner.approvalDate"),
			minWidth: 200,
			valueGetter: (params) =>
				params.row.team?.enrollmentApprovalDate
					? format(
							new Date(params.row.team?.enrollmentApprovalDate),
							DATE_FORMAT_TWO
					  )
					: "-"
		}
	].map((column) => ({
		headerAlign: "center",
		align: "center",
		minWidth: 120,
		...column
	}));
}
