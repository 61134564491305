import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";

import {
	ROUTE_CATEGORY,
	ROUTE_DASHBOARD,
	ROUTE_FUNDING,
	ROUTE_SETTING,
	ROUTE_BRAND_LIST,
	ROUTE_REQUESTED_BRAND_LIST,
	ROUTE_PARTNER_LIST,
	ROUTE_INVENTORY_WAREHOUSE,
	ROUTE_SHIPPING,
	ROUTE_SHIPPING_REQUEST,
	ROUTE_INVENTORY_MANAGEMENT,
	ROUTE_OWNER,
	ROUTE_TRADE_MANAGEMENT,
	ROUTE_SETTLEMENT_MANAGEMENT
} from "data/routeVariables";
import { DashboardIcon } from "assets/icons/sidebar/DashboardIcon";
import MarketIcon from "assets/icons/sidebar/MarketIcon";
import { TradeIcon } from "assets/icons/sidebar/TradeIcon";
import { ShippingIcon } from "assets/icons/sidebar/ShippingIcon";
import ReviewIcon from "assets/icons/sidebar/ReviewIcon";
import OwnerIcon from "assets/icons/sidebar/OwnerIcon";
import PartnerIcon from "assets/icons/sidebar/PartnerIcon";
import SettlementIcon from "assets/icons/sidebar/SettlementIcon";
import AdvertisingIcon from "assets/icons/sidebar/AdvertisingIcon";
import { InquiryIcon } from "assets/icons/sidebar/InquiryIcon";
import OperationIcon from "assets/icons/sidebar/OperationIcon";

export const INVENTORY_MENU_SUPER = "inventorySuper";
export const INVENTORY_MENU_PARTNER = "inventoryPartner";
export const BRANDS_MENU = "brands";
export const CATEGORY_MENU = "category";
export const PARTNER_MENU = "partner";
export const DASHBOARD_MENU = "dashboard";
export const FUNDING_MENU = "funding";
export const TRADE_MENU = "trade";
export const CUSTOMER_MENU = "customer";
export const PROFIT_MENU = "profit";
export const OWNER_MENU = "owner";
export const BUSINESS_INFO_MENU = "businessInfo";
export const SETTING_MENU = "setting";
export const SETTLEMENT_MENU = "settlement";
export const REVIEW_MENU = "review";
export const ADVERTISING_MENU = "advertising";
export const INQUIRY_MENU = "inquiry";
export const OPERATION_MENU = "operation";

export const allSidebarElements = [
	{
		id: DASHBOARD_MENU,
		icon: <DashboardIcon />,
		name: "Dashboard",
		item: ["sidebar.dashboard"],
		path: ROUTE_DASHBOARD
	},
	{
		id: FUNDING_MENU,
		icon: <MarketIcon />,
		name: "Funding Management",
		item: ["sidebar.funding"],
		path: ROUTE_FUNDING
	},
	{
		id: TRADE_MENU,
		icon: <TradeIcon />,
		name: "Trade Management",
		item: ["sidebar.trade"],
		path: ROUTE_TRADE_MANAGEMENT
	},
	{
		id: INVENTORY_MENU_SUPER,
		icon: <ShippingIcon />,
		name: "Inventory Management",
		item: ["sidebar.inventoryOrder"],
		subItems: [
			{ id: 1, name: "sidebar.warehouse", path: ROUTE_INVENTORY_WAREHOUSE },
			{ id: 2, name: "Shipping Request", path: ROUTE_SHIPPING_REQUEST },
			{ id: 3, name: "sidebar.shipping", path: ROUTE_SHIPPING },
			{ id: 4, name: "sidebar.inventory", path: ROUTE_INVENTORY_MANAGEMENT }
		]
	},
	{
		id: INVENTORY_MENU_PARTNER,
		icon: <ShippingIcon />,
		name: "Inventory Management",
		item: ["sidebar.deliveryReceipt"],
		subItems: [
			{ id: 1, name: "Shipping Request", path: ROUTE_SHIPPING_REQUEST },
			{ id: 2, name: "sidebar.shipping", path: ROUTE_SHIPPING },
			{ id: 3, name: "sidebar.warehouse", path: ROUTE_INVENTORY_WAREHOUSE }
		]
	},
	{
		id: REVIEW_MENU,
		icon: <ReviewIcon />,
		name: "Review Management",
		item: ["sidebar.review"],
		subItems: [
			{
				id: 1,
				name: "sidebar.reviewArticle",
				path: `${REVIEW_MENU}/report`
			},
			{
				id: 2,
				name: "sidebar.reviewReport",
				path: `${REVIEW_MENU}/article`
			}
		]
	},
	{
		id: INQUIRY_MENU,
		icon: <InquiryIcon />,
		name: "inquiry",
		item: ["sidebar.inquiry"],
		subItems: [
			{
				id: 1,
				name: "sidebar.owner",
				path: `${INQUIRY_MENU}/owner`
			},
			{
				id: 2,
				name: "sidebar.partner",
				path: `${INQUIRY_MENU}/partner`
			}
		]
	},
	{
		id: OWNER_MENU,
		icon: <OwnerIcon />,
		name: "Owner Management",
		item: ["sidebar.owner"],
		path: ROUTE_OWNER
	},
	{
		id: PARTNER_MENU,
		icon: <PartnerIcon />,
		name: "Partner",
		item: ["sidebar.partner"],
		path: ROUTE_PARTNER_LIST
	},
	{
		id: SETTLEMENT_MENU,
		icon: <SettlementIcon />,
		name: "Settlement",
		item: ["sidebar.settlement"],
		path: ROUTE_SETTLEMENT_MANAGEMENT
	},
	{
		id: ADVERTISING_MENU,
		icon: <AdvertisingIcon />,
		name: "Advertising",
		item: ["sidebar.advertising"],
		subItems: [
			{
				id: 1,
				name: "sidebar.bannerManagement",
				path: `${ADVERTISING_MENU}/banner-management`
			},
			{
				id: 2,
				name: "sidebar.couponManagement",
				path: `${ADVERTISING_MENU}/coupon-management`
			},
			{
				id: 3,
				name: "sidebar.mileageManagement",
				path: `${ADVERTISING_MENU}/mileage-management`
			}
		]
	},
	{
		id: OPERATION_MENU,
		icon: <OperationIcon />,
		name: "Operation",
		item: ["sidebar.operation"],
		subItems: [
			{
				id: 1,
				name: "sidebar.adminSetting",
				path: `${OPERATION_MENU}/admin-setting`
			},
			{
				id: 2,
				name: "sidebar.brandingManagement",
				path: `${OPERATION_MENU}/branding-management`
			},
			{
				id: 3,
				name: "sidebar.faqManagement",
				path: `${OPERATION_MENU}/faq-management`
			},
			{
				id: 4,
				name: "sidebar.qnaManagement",
				path: `${OPERATION_MENU}/qna-management`
			}
		]
	},
	{
		id: CATEGORY_MENU,
		icon: <CategoryOutlinedIcon />,
		name: "Category",
		item: ["sidebar.category"],
		path: ROUTE_CATEGORY
	},
	{
		id: BRANDS_MENU,
		icon: <SellOutlinedIcon />,
		name: "Brands",
		item: ["sidebar.brands"],
		subItems: [
			{
				id: 1,
				name: "sidebar.requestedBrands",
				path: ROUTE_REQUESTED_BRAND_LIST
			},
			{ id: 2, name: "sidebar.brandList", path: ROUTE_BRAND_LIST }
		]
	},

	{
		id: SETTING_MENU,
		icon: <SettingsOutlinedIcon />,
		name: "Setting",
		item: ["sidebar.setting"],
		path: ROUTE_SETTING
	}
];
