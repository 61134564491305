import { api } from "services/api";
import getPartnerDetails from "./getPartnerDetails";
import getPartners from "./getPartners";
import messagePartner from "./messagePartner";
import partnerApproval from "./partnerApproval";
import partnerReject from "./partnerReject";
import resetPassword from "./resetPassword";
import sendOTP from "./sendOTP";
import sendOTPRegister from "./sendOTPRegister";
import verifyOTP from "./verifyOTP";
import docDownload from "./docDownload";

export const partnerApi = api.injectEndpoints({
	endpoints: (build) => ({
		getPartners: getPartners(build),
		getPartnerDetails: getPartnerDetails(build),
		messagePartner: messagePartner(build),
		sendOTP: sendOTP(build),
		sendOTPRegister: sendOTPRegister(build),
		verifyOTP: verifyOTP(build),
		resetPassword: resetPassword(build),
		partnerApproval: partnerApproval(build),
		partnerReject: partnerReject(build),
		docDownload: docDownload(build)
	}),
	overrideExisting: false
});

export const {
	useGetPartnersQuery,
	useGetPartnerDetailsQuery,
	useMessagePartnerMutation,
	useSendOTPMutation,
	useSendOTPRegisterMutation,
	useVerifyOTPMutation,
	useResetPasswordMutation,
	usePartnerApprovalMutation,
	usePartnerRejectMutation,
	useLazyDocDownloadQuery
} = partnerApi;
