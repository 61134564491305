import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Stack } from "@mui/system";
import { useSearchParams } from "react-router-dom";
import { List, ListItemBtn, NavBtn } from "./style";

export const MuiPagination = ({ meta, page }) => {
	const { currentPage, lastPage, links } = meta;
	const [searchParams, setSearchParams] = useSearchParams();

	const setPage = (page) => {
		searchParams.set("page", page);
		setSearchParams(searchParams);
	};

	const handlePaginationClick = (e) => {
		const pageNumToChange = parseInt(e.target.innerHTML);
		setPage(pageNumToChange);
	};

	const handleNextClick = () => {
		setPage(page + 1);
	};

	const handlePreviousClick = () => {
		setPage(page - 1);
	};

	const linkBtns = links?.slice(1, -1);

	return (
		<Stack direction="row" justifyContent="center" my={36}>
			<NavBtn disabled={currentPage === 1} onClick={handlePreviousClick}>
				<ArrowLeft />
			</NavBtn>
			{links && (
				<List>
					{linkBtns?.map((link) => {
						return (
							<ListItemBtn
								onClick={handlePaginationClick}
								key={link.label}
								sx={
									link.active && {
										color: "black"
									}
								}>
								{link.label}
							</ListItemBtn>
						);
					})}
				</List>
			)}
			<NavBtn disabled={currentPage === lastPage} onClick={handleNextClick}>
				<ArrowRight />
			</NavBtn>
		</Stack>
	);
};
