import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	ownerId: "",
	ownerListSearch: "",
	ownerData: {}
};

const ownerSlice = createSlice({
	name: "owner",
	initialState,
	reducers: {
		saveOwnerData: (state, { payload }) => {
			state.ownerId = payload.id;
			state.ownerData = payload;
		},
		setOwnerListSearch: (state, { payload }) => {
			state.ownerListSearch = payload;
		}
	}
});

export const selectOwner = (state) => state.owner || {};

export const { saveOwnerData, setOwnerListSearch } = ownerSlice.actions;

export const ownerReducer = ownerSlice.reducer;
