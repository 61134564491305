import axios from "axios";

import { showToast } from "utils/showToast";

export const downloadFile = (url, fileName, params) => {
	return () => {
		return download(
			`${process.env.REACT_APP_OWNERYA_ADMIN_API}/${url}`,
			fileName,
			params
		);
	};
};

export function download(url, fileName, params = {}) {
	return axios
		.get(url, {
			responseType: "arraybuffer",
			withCredentials: true,
			params
		})
		.then(function ({ headers, data }) {
			const blob = new Blob([data], { type: headers["content-type"] });
			const link = document.createElement("a");
			const url = URL.createObjectURL(blob);
			link.href = url;
			link.download = fileName;
			link.click();
			URL.revokeObjectURL(url);
		});
}

export async function downloadWithWindow(url, showMessage = true) {
	const opened = window.open(url);

	if (showMessage) {
		showToast(
			opened ? "Download successfully" : "Download unsuccessful",
			opened ? "success" : "error"
		);
	}

	return opened
		? Promise.resolve()
		: Promise.reject({ data: { message: "Download unsuccessful" } });
}
