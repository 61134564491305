import { axiosInstance } from "services/customBaseQuery";
import { API_LOGIN } from "services/endpoints";

export default (build) =>
	build.mutation({
		query: ({ email, password }) => {
			return {
				url: API_LOGIN,
				method: "POST",
				data: {
					email,
					password
				}
			};
		},

		async onQueryStarted() {
			await axiosInstance({
				url: "/sanctum/csrf-cookie",
				baseURL: process.env.REACT_APP_OWNERYA_ADMIN_BASE_API
			});
		}
	});
