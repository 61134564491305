import { API_BRAND } from "services/endpoints";
import { TAG_BRAND } from "services/tags";

export default (build) =>
	build.mutation({
		query: ({ name, koreanName, description, photo, requestBrandId }) => {
			let bodyParams;
			if (requestBrandId !== "") {
				bodyParams = {
					name,
					request_brand_id: requestBrandId,
					request_brand_status: "approve",
					korean_name: koreanName,
					description,
					photo: photo[0].data_url
				};
			} else
				bodyParams = {
					name,
					korean_name: koreanName,
					description,
					photo: photo[0].data_url
				};

			return {
				url: API_BRAND,
				method: "POST",
				data: bodyParams
			};
		},
		invalidatesTags: [TAG_BRAND]
	});
