import * as Yup from "yup";

export const ResetPasswordSchema = (t) =>
	Yup.object({
		email: Yup.string()
			.email(t("signUpValidationMsg.mustBeEmail"))
			.required(t("signUpValidationMsg.emailRequired")),
		otpCode: Yup.string().required(t("validationMsg.fieldRequired")),
		newPassword: Yup.string().required(t("signUpValidationMsg.pwRequired")),
		passwordConfirmation: Yup.string()
			.required(t("signUpValidationMsg.pwRequired"))
			.oneOf(
				[Yup.ref("newPassword")],
				t("signUpValidationMsg.unmatchPasswords")
			)
	});
