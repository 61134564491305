import { api } from "services/api";
import category from "./category";
import createCategory from "./createCategory";
import deleteCategory from "./deleteCategory";
import editCategory from "./editCategory";
import categoryDetail from "./categoryDetail.js";

export const categoryApi = api.injectEndpoints({
	endpoints: (build) => ({
		category: category(build),
		categoryDetail: categoryDetail(build),
		createCategory: createCategory(build),
		editCategory: editCategory(build),
		deleteCategory: deleteCategory(build)
	}),
	overrideExisting: false
});

export const {
	useCategoryQuery,
	useCreateCategoryMutation,
	useEditCategoryMutation,
	useDeleteCategoryMutation,
	useCategoryDetailQuery
} = categoryApi;
