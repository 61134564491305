import DataTable from "components/table/DataTable";

import useColumns from "./useColumns";
import { ownerApi } from "services/modules/owner";
import { useSelector } from "react-redux";
import { selectOwner } from "redux/modules";

export default function useBody(page) {
	const { ownerId } = useSelector(selectOwner);

	const result = ownerApi.useGetOwnerInvestmentListQuery({
		currentPage: page,
		ownerId
	});

	return [
		result,
		() => {
			const columns = useColumns();

			const rows = result?.data?.response?.data || [];

			return (
				<DataTable
					columns={columns}
					rows={rows}
					isLoading={result.isFetching}
					isRenderCheckBox={true}
				/>
			);
		}
	];
}
