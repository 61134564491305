import { Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { BasePlate } from "components/layout";
import { StackRow } from "components/shared";
import { useTranslation } from "react-i18next";
import { Stats, StatsName } from "./style";

const SummaryBox = () => {
	const { t } = useTranslation();

	const CustomStack = (props) => {
		const { statName, stat, ...rest } = props;
		return (
			<Stack alignItems="center" gap={14} {...rest}>
				<StatsName>{statName}</StatsName>
				<Stats>{stat}</Stats>
			</Stack>
		);
	};

	return (
		<Stack mb={16}>
			<BasePlate sx={{ paddingY: 24, paddingX: 20 }}>
				<StackRow gap={40} alignItems="center">
					<Typography variant="text17" fontWeight={700}>
						{t("partner.summary.activityStatus")}
					</Typography>
					<Divider orientation="vertical" variant="middle" flexItem />
					<CustomStack statName={t("partner.summary.allPartners")} stat={200} />
					<CustomStack
						statName={t("partner.summary.subscriptionApproval")}
						stat={1000}
					/>
					<CustomStack
						statName={t("partner.summary.subscriptionApproved")}
						stat={345}
					/>
					<CustomStack
						statName={t("partner.summary.subscriptionPending")}
						stat={46}
					/>
					<Divider orientation="vertical" variant="middle" flexItem />
					<CustomStack statName={t("partner.summary.inActive")} stat={10} />
					<CustomStack
						statName={t("partner.summary.insuranceExpired")}
						stat={30000}
					/>
				</StackRow>
			</BasePlate>
		</Stack>
	);
};

export default SummaryBox;
