import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormProvider } from "react-hook-form";

import { ListTitle } from "components/shared";
import PartnerLogo from "assets/icons/sidebar/PartnerIcon";
import {
	Button,
	PRIMARY,
	SelectedOrAllDownloadButtons
} from "components/layout/Button";
import { StackRow } from "components/shared";
import { MuiPagination } from "components/table/Pagination";
import NoData from "components/table/NoData";
import { useNumberSearchParams } from "utils/useNumbered";
import useFilter from "components/shared/list/useFilter";
import KeywordOnlyFilter from "components/shared/list/keywordOnlyFilter";
import MainTitle from "components/shared/list/MainTitle";

import SummaryBox from "../SummaryBox";
import useBody from "./useBody";
import { useTodo } from "utils/useTodo";

const PartnerList = () => {
	const { t } = useTranslation();

	const page = useNumberSearchParams();

	const todo = useTodo();

	const [filters, methods] = useFilter();

	const { watch } = methods;

	const [{ data, isFetching }, Body] = useBody(filters, page);

	const statusFilter = watch("statusFilter");

	const rows = data?.response?.data || [];

	const meta = data?.response?.meta || { total: 0 };

	const isSearchResultEmpty =
		!rows.length & !isFetching & !!Object.values(filters).length &&
		statusFilter === "all";

	return (
		<FormProvider {...methods}>
			<MainTitle pageName={t("Partner Management")} logo={<PartnerLogo />} />

			<SummaryBox />

			<Stack pb={20}>
				<ListTitle>
					{t("Partner List")}({meta?.total || 0})
				</ListTitle>
			</Stack>

			<KeywordOnlyFilter
				searchPlaceholder={t(
					"Search for funding code, funding name, brand name, partner company code, and partner company name"
				)}
			/>

			<>
				{isSearchResultEmpty ? (
					<NoData message={t("alertMessage.noListData")} />
				) : (
					<>
						<Body />

						<StackRow
							pt={20}
							alignItems="center"
							justifyContent="space-between">
							<SelectedOrAllDownloadButtons />

							<Button onClick={todo} color={PRIMARY}>
								{t("funding.create")}
							</Button>
						</StackRow>

						<MuiPagination page={page} meta={meta} />
					</>
				)}
			</>
		</FormProvider>
	);
};

export default PartnerList;
