import { Typography } from "@mui/material";

const ErrorMessage = ({ children, ...props }) => {
	return (
		<Typography
			sx={{ fontSize: "errorMsg.fontSize", color: "error.main", padding: 2 }}
			{...props}>
			{children}
		</Typography>
	);
};

export default ErrorMessage;
