import { Box, Stack, Typography } from "@mui/material";

import { Button, PRIMARY, SECONDARY, WHITE } from "components/layout/Button";
import { formatYearMonthDay } from "utils/formatDate";

export function normalizeRows(data) {
	const rows = data?.response?.data || [];

	return rows.map((rowItem) => {
		const row = {
			...rowItem,

			/* These are the status where we show buttons:

				case "creating":
				case "pending":
				case "approved":
				case "recruiting_complete":
			*/
			// status: rowItem.id === 56 ? "recruiting_complete" : rowItem.status,

			registrationAndModificationDate: {
				createdAt: rowItem.createdAt,
				updatedAt: rowItem.updatedAt
			},

			targetAndRecruitmentAmount: {
				targetAmount: rowItem.expectedProfit,
				recruitmentAmount: rowItem.investmentPrice
			},

			targetAndSalesQuantity: {
				targetQuantity: rowItem.targetQuantity,
				salesQuantity: rowItem.quantity
			},

			investPeriod: {
				investStart: rowItem.investStart,
				investEnd: rowItem.investEnd
			},

			salesPeriod: {
				saleStartDate: rowItem.saleStartDate,
				saleDeadline: rowItem.saleDeadline
			}
		};

		return row;
	});
}

function ActionButton({ msg, ...props }) {
	return (
		<Button
			fullWidth
			onClick={() => alert("Not available right now")}
			{...props}>
			{msg}
		</Button>
	);
}

function getFundingStatusColor(status) {
	if (status === "pending" || status === "approved") {
		return PRIMARY;
	} else if (status === "recruiting_complete") {
		return "GrayText";
	} else {
		return null;
	}
}

export function TopBottom({ top, bottom, divider }) {
	const up = divider ? `${top} ${divider}` : top;

	return (
		<Stack direction="column" alignItems="center">
			<CommonRow>{up}</CommonRow>
			<CommonRow>{bottom}</CommonRow>
		</Stack>
	);
}

export function SlashDivider({ left, right }) {
	return (
		<Stack direction="row">
			<CommonRow>{left}</CommonRow>
			<Box>&nbsp;/&nbsp;</Box>
			<CommonRow color={PRIMARY}>{right}</CommonRow>
		</Stack>
	);
}

const CommonRow = ({ color, children }) => {
	return (
		<Typography sx={{ fontSize: "13px", fontWeight: 400 }} color={color}>
			{children}
		</Typography>
	);
};

function generateColumn(opts) {
	return {
		align: "center",
		minWidth: 150,
		flex: 1,
		headerAlign: "center",
		sortable: false,
		renderCell: ({ formattedValue }) => <CommonRow>{formattedValue}</CommonRow>,
		...opts
	};
}

/*
	Q: When to show buttons?
	A: https://dss-mm.slack.com/files/U01HZQXC951/F04H82SBS8J/screenshot_2023-01-03_at_10.15.44_am.png

	Excerpt:
	Writing (Save as draft) -> Delete, Copy | "created"
	Waiting for approval -> Copy | "pending"
	Recruiting -> Copy | "approved"
	Completion of recruitment -> Secondary registration document, Copy | "recruiting_complete"

*/

export function getColumns(t, hoverIndex) {
	return [
		generateColumn({
			field: "code",
			headerName: t("funding.productList.fundingCode")
		}),
		generateColumn({
			field: "name",
			minWidth: 200,
			headerName: t("funding.productList.fundingName")
		}),
		generateColumn({
			field: "status",
			minWidth: 200,
			headerName: t("funding.productList.fundingStatus"),
			renderCell: ({
				row: {
					statusDetail: { statusValue, statusCode }
				}
			}) => {
				return (
					<CommonRow color={getFundingStatusColor(statusValue)}>
						{t(`funding.statuses.codes.${statusCode}`)}
					</CommonRow>
				);
			}
		}),
		generateColumn({
			field: "registrationAndModificationDate",
			headerName: t("funding.productList.registrationAndModificationDate"),
			minWidth: 250,
			renderCell: ({ formattedValue: { updatedAt, createdAt } }) => (
				<TopBottom
					top={formatYearMonthDay(updatedAt)}
					bottom={formatYearMonthDay(createdAt)}
				/>
			)
		}),
		generateColumn({
			field: "unitPrice",
			headerName: t("funding.productList.productUnitPrice")
		}),
		generateColumn({
			field: "targetAndRecruitmentAmount",
			headerName: t("funding.productList.targetAndRecruitmentAmount"),
			minWidth: 300,
			renderCell: ({ formattedValue: { targetAmount, recruitmentAmount } }) => {
				return <SlashDivider left={targetAmount} right={recruitmentAmount} />;
			}
		}),
		generateColumn({
			field: "targetAndSalesQuantity",
			headerName: t("funding.productList.targetAndSalesQuantity"),
			minWidth: 250,
			renderCell: ({ formattedValue: { targetQuantity, salesQuantity } }) => (
				<SlashDivider left={targetQuantity} right={salesQuantity} />
			)
		}),
		generateColumn({
			field: "investPeriod",
			headerName: t("funding.productList.investmentStart"),
			minWidth: 150,
			renderCell: ({
				row: {
					id,
					statusDetail: { statusValue }
				},
				formattedValue: { investStart, investEnd }
			}) => {
				if (hoverIndex === id) {
					switch (statusValue) {
						case "creating":
						case "pending":
						case "approved":
							return <ActionButton color={WHITE} msg={t("toolTips.copy")} />;

						case "recruiting_complete":
						case "recruiting_fail":
							return (
								<ActionButton
									color={PRIMARY}
									msg={t("toolTips.secondaryDocumentRegistration")}
								/>
							);

						// no default case implemented intentionally because we need to
						// fallthrough and show text if status doesn't match our condition
					}
				}

				return (
					<TopBottom
						top={formatYearMonthDay(investStart)}
						bottom={formatYearMonthDay(investEnd)}
						divider="~"
					/>
				);
			}
		}),
		generateColumn({
			field: "salesPeriod",
			headerName: t("funding.productList.investmentEnd"),
			renderCell: ({
				row: {
					id,
					statusDetail: { statusValue }
				},
				formattedValue: { saleStartDate, saleDeadline }
			}) => {
				if (hoverIndex === id) {
					switch (statusValue) {
						case "creating":
							return (
								<ActionButton color={SECONDARY} msg={t("toolTips.delete")} />
							);

						case "recruiting_complete":
						case "recruiting_fail":
						case "recruiting":
							return <ActionButton color={WHITE} msg={t("toolTips.copy")} />;
					}
				}

				return (
					<TopBottom
						top={formatYearMonthDay(saleStartDate)}
						bottom={formatYearMonthDay(saleDeadline)}
						divider="~"
					/>
				);
			}
		})
	];
}
