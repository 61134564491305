import { Box, Stack, Typography } from "@mui/material";
import BrandDeleteDialog from "components/dialogs/brand/BrandDeleteDialog";
import { CustomTextField } from "components/formComponents/style";
import { Button, PRIMARY, SECONDARY } from "components/layout/Button";
import { StackRow } from "components/shared";
import DataTable from "components/table/DataTable";
import NoData from "components/table/NoData";
import { MuiPagination } from "components/table/Pagination";
import {
	ROUTE_BRAND_CREATE,
	ROUTE_BRAND_EDIT,
	ROUTE_REQUESTED_BRAND_LIST
} from "data/routeVariables";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { assignToBrandPayload, openDeleteBrandDialog } from "redux/modules";
import { useGetBrandsQuery } from "services/modules/brand";
import { debounce } from "utils/debounce";

const BrandsList = () => {
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const page = parseInt(searchParams.get("page") || 1);
	const [searchQuery, setSearchQuery] = useState("");

	const handleChange = (e) => {
		setSearchQuery(e.target.value);
	};

	const createNewBrand = () => {
		dispatch(
			assignToBrandPayload({
				id: "",
				type: "create"
			})
		);
		navigate(ROUTE_BRAND_CREATE);
	};

	const columns = [
		{ field: "koreanName", headerName: t("brand.koreanName"), flex: 1 },
		{ field: "name", headerName: t("brand.englishName"), flex: 1 }
	];

	const { data, isFetching, isError } = useGetBrandsQuery({
		currentPage: page,
		query: searchQuery
	});

	const rows = data?.response?.data || [];
	const meta = data?.response?.meta || [];

	const goToBrandEdit = (cellValues) => {
		const brandEditLink = generatePath(ROUTE_BRAND_EDIT, {
			brandId: cellValues?.row?.id
		});
		dispatch(assignToBrandPayload({ type: "edit", ...cellValues?.row }));
		navigate(brandEditLink);
	};

	const onDeleteClick = (cellValues) => {
		dispatch(openDeleteBrandDialog({ id: cellValues.row?.id }));
	};

	const onEditClick = (cellValues) => {
		goToBrandEdit(cellValues);
	};

	return (
		<Box>
			<Typography component="h2" variant="h2" pb={24}>
				{t("brand.header")}
			</Typography>

			<Stack justifyContent="space-between" direction="row" mb={14}>
				<CustomTextField
					onChange={debounce(handleChange, 1000)}
					placeholder={`${t("Search")}...`}
					size="small"
				/>

				<StackRow gap={12}>
					<Button
						color={SECONDARY}
						onClick={() => navigate(ROUTE_REQUESTED_BRAND_LIST)}>
						{t("sidebar.requestedBrands")}
					</Button>
					<Button color={PRIMARY} onClick={createNewBrand}>
						{t("brand.createBrand.title")}
					</Button>
				</StackRow>
			</Stack>
			{isError ? (
				<NoData message={t("alertMessage.noListData")} />
			) : (
				<DataTable
					columns={columns}
					rows={rows}
					isLoading={isFetching}
					isRenderAction={true}
					onDeleteClick={onDeleteClick}
					onEditClick={onEditClick}
				/>
			)}
			<MuiPagination page={page} meta={meta} />
			<BrandDeleteDialog />
		</Box>
	);
};

export default BrandsList;
