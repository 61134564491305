import { Typography } from "@mui/material";

export const ListTitle = (props) => {
	const { children, ...rest } = props;
	return (
		<Typography
			sx={{ textDecoration: "underline" }}
			component="h2"
			variant="text17"
			fontWeight="bold"
			{...rest}>
			{children}
		</Typography>
	);
};
