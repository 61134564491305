import { API_PARTNER } from "services/endpoints";
import { TAG_PARTNER } from "services/tags";

export default (build) =>
	build.query({
		query: ({ partnerId }) => {
			return {
				url: `${API_PARTNER}/${partnerId}`,
				method: "GET"
			};
		},
		providesTags: [TAG_PARTNER]
	});
