import { Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import theme from "theme/theme";
import { StackRow } from "..";

const TabFilter = ({ tabs }) => {
	const { t } = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams();
	const index = tabs.findIndex((tab) => tab.active);
	const [activeFilter, setActiveFilter] = useState(index);

	const updateURL = () => {
		if (activeFilter < 0) return;
		const activeItem = tabs[activeFilter];
		searchParams.set("filter", activeItem.value || "all");
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	};

	useEffect(() => {
		updateURL();
	}, [activeFilter]);

	const changeActiveFilter = (index) => {
		setActiveFilter(index);
	};

	return (
		<StackRow
			gap={12}
			divider={<Divider orientation="vertical" variant="middle" flexItem />}>
			{tabs?.map((el, index) => (
				<Typography
					color={
						index === activeFilter
							? theme.palette.text.primary
							: theme.palette.text.secondary
					}
					sx={{ cursor: "pointer" }}
					key={el.id}
					onClick={() => changeActiveFilter(index)}>
					{`${t(el.label)} ${el.count}`}
				</Typography>
			))}
		</StackRow>
	);
};

export default TabFilter;
