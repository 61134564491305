import {
	API_FUNDING_EDIT_PRELOAD,
	API_FUNDING_STEP_TWO,
	API_FUNDING_STEP_ONE
} from "services/endpoints";
import { TAG_FUNDING, TAG_FUNDING_ADMIN } from "services/tags";
import { camelToSnakeCasedObjectKeys } from "utils/string";

import { STATUS_PENDING } from "./statuses";

export function normalize(data) {
	const ret = {
		...data,
		photos: data.photos?.filter((photo) => !photo.id),
		related_infos: data.related_infos?.filter(
			(info) =>
				Object.keys(info).length !== 0 && info.source && info.url && info.photo
		),
		deleted_related_info_ids: data.deleted_related_info_ids?.filter(
			(item) => item !== undefined
		),
		deleted_photos: data.deleted_photos?.filter((item) => item !== undefined)
	};

	return ret;
}

const dropRight = (str, sep = " ") => {
	const [right] = str.split(sep);

	return right;
};

function makeRequest(data, url) {
	data = normalize(camelToSnakeCasedObjectKeys(data));

	return {
		method: "PUT",
		url,
		data
	};
}

export default function FundingEditServices(build) {
	const methods = {
		getProduct: build.query({
			query(id) {
				return {
					url: `${API_FUNDING_EDIT_PRELOAD}/${id}`,
					method: "GET"
				};
			},

			providesTags: [TAG_FUNDING, TAG_FUNDING_ADMIN],

			transformResponse({ response: { data } }) {
				// api sends dates as `2022-12-30 12:40:30` so extract date for convenience
				return {
					...data,
					brandId: data.product.brand.id,
					fundingName: data.name,
					name: data.product.name,
					createdAt: dropRight(data.createdAt),
					updatedAt: data.updatedAt,
					relatedInfos: data.relatedInfos?.map((info) => {
						const photo = info.photos
							? info.photos[info.photos.length - 1]
							: {};

						return {
							photo: photo?.path,
							source: info.source,
							url: info.url,
							id: info.id
						};
					})
				};
			}
		}),

		updateStepOne: build.mutation({
			query(data) {
				return makeRequest(data, `${API_FUNDING_STEP_ONE}/${data.id}`);
			},

			transformResponse({ response: { data } }) {
				return data;
			},

			invalidatesTags: [TAG_FUNDING, TAG_FUNDING_ADMIN]
		}),

		updateStepTwo: build.mutation({
			query(data) {
				return makeRequest(
					{ ...data, fundingId: data.id },
					`${API_FUNDING_STEP_TWO}/${data.id}`
				);
			},

			transformResponse({ response: { data } }) {
				return data;
			},

			invalidatesTags: [TAG_FUNDING, TAG_FUNDING_ADMIN]
		}),

		approveFunding: build.mutation({
			query({ stepNumber, ...data }) {
				data = {
					...data,
					status: STATUS_PENDING
				};

				return stepNumber === 1
					? methods.updateStepOne.query(data)
					: methods.updateStepTwo.query(data);
			},
			invalidatesTags: [TAG_FUNDING, TAG_FUNDING_ADMIN]
		})
	};

	return methods;
}
