import { Typography } from "@mui/material";

export const PageTitle = (props) => {
	const { children, ...rest } = props;
	return (
		<Typography component="h2" variant="h2" {...rest}>
			{children}
		</Typography>
	);
};
