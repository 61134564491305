import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInputText } from "components/formComponents";
import { LoadingButton } from "@mui/lab";
import { ROUTE_LOGIN } from "data/routeVariables";
import { StackRow } from "components/shared";
import { SignUpIdSchema } from "utils/validation/signUpIdSchema";
import ErrorMessage from "components/formComponents/ErrorMessage";

export const SignUpIdForm = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const {
		handleSubmit,
		control,
		formState: { errors }
	} = useForm({
		mode: "onBlur",
		resolver: yupResolver(SignUpIdSchema)
	});
	const onSubmit = () => {};

	return (
		<form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
			<Grid container columnSpacing={10}>
				<Grid item xs={3} pt={12}>
					<Typography variant="text15" color="text.black" fontWeight="medium">
						{t("signUp.representativeContact")}
					</Typography>
				</Grid>
				<Grid item xs={9} mb={10}>
					<StackRow justifyContent="space-around" gap={16}>
						<FormInputText
							name="countryCode"
							control={control}
							type="number"
							placeholder="010"
							maxLength={3}
							textAlignCenter={1}
							errorMessage={
								errors?.countryCode || errors?.phoneNumber || errors?.extension
							}
						/>
						<FormInputText
							name="phoneNumber"
							control={control}
							type="number"
							placeholder="0000"
							maxLength={4}
							textAlignCenter={1}
							errorMessage={
								errors?.countryCode || errors?.phoneNumber || errors?.extension
							}
						/>
						<FormInputText
							name="extension"
							control={control}
							type="number"
							placeholder="0000"
							maxLength={4}
							textAlignCenter={1}
							errorMessage={
								errors?.countryCode || errors?.phoneNumber || errors?.extension
							}
						/>
					</StackRow>
					{(errors?.countryCode ||
						errors?.phoneNumber ||
						errors?.extension) && (
						<ErrorMessage>
							{t("signUpValidationMsg.contactCountryCode")}
						</ErrorMessage>
					)}
				</Grid>
				<Grid item xs={3}></Grid>
				<Grid item xs={9} mb={52}>
					<Typography variant="text15" fontWeight="medium">
						{t("signUp.emailWillBeSent")}
					</Typography>
				</Grid>
				<Grid item xs={6}></Grid>
				<Grid item xs={6} my={10}>
					<StackRow justifyContent="space-around" mt={50}>
						<LoadingButton
							fullWidth
							size="large"
							type="submit"
							variant="outlined"
							color="secondary"
							onClick={() => navigate(ROUTE_LOGIN)}
							sx={{
								width: "140px"
							}}>
							{t("Cancel")}
						</LoadingButton>
						<LoadingButton
							fullWidth
							size="large"
							type="submit"
							variant="contained"
							sx={{
								width: "140px"
							}}>
							{t("signUp.findYourId")}
						</LoadingButton>
					</StackRow>
				</Grid>
			</Grid>
		</form>
	);
};
